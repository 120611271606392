import { createStore, createEvent } from 'effector';
import { history } from 'lib/routing';

export const $lastPushed = createStore('');

export const historyPush = createEvent<string>();
export const historyReplace = createEvent<string>();
export const clearParams = createEvent<void>();
export const clearParam = createEvent<string>();
export const clearHash = createEvent();

clearParams.watch(() => history.replace(history.location.pathname));

clearHash.watch(() => {
  history.replace(`${history.location.pathname}${history.location.search}`);
});

clearParam.watch((param) => {
  const params = new URLSearchParams(history.location.search);
  params.delete(param);

  const currentPath = history.location.pathname;

  history.push(
    params.toString()
      ? `${currentPath}?${params}${history.location.hash}`
      : `${currentPath}${history.location.hash}`,
  );
});

historyPush.watch((url) => history.push(url));
historyReplace.watch((url) => history.replace(url));
