import * as React from 'react';
import classNames from 'classnames';
import './style.css';

interface Props {
  children: React.ReactNode;
  mb?: boolean;
  relative?: boolean;
  dark?: boolean;
  violet?: boolean;
  skeleton?: boolean;
  className?: string;
}

export const Article = ({ children, violet, className, skeleton }: Props) => {
  return (
    <div
      className={classNames(
        'article',
        violet && 'article_violet',
        skeleton && 'skeleton',
        className,
      )}
    >
      <div className="article__wrap">{children}</div>
    </div>
  );
};

export const ArticleImg = ({
  children,
  relative,
  className,
  skeleton,
}: Props) => {
  return (
    <div
      className={classNames(
        'article__img',
        skeleton && 'skeleton',
        relative && 'article__img-mask',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ArticleInfo = ({ children, className }: Props) => {
  return (
    <div className={classNames('article__info', className && className)}>
      {children}
    </div>
  );
};

export const ArticleList = ({ children, mb }: Props) => {
  return (
    <div className={classNames('article__list', mb && 'article__list_mb')}>
      {children}
    </div>
  );
};

export const ArticleText = ({ children, dark }: Props) => {
  return (
    <p className={classNames('article__descr', dark && 'article__descr_dark')}>
      {children}
    </p>
  );
};

export const ArticleTextInfo = ({ children }: Props) => {
  return <span className="article__text-info">{children}</span>;
};

export const ArticleSubtitle = ({ children, mb = false }: Props) => {
  const classProps = classNames(
    'article__sub-title',
    mb && 'article__sub-title_mb',
  );
  return <h3 className={classProps}>{children}</h3>;
};

export const ArticleTitle = ({ children }: Props) => {
  return <h2 className="article__title">{children}</h2>;
};
