import { httpClient } from 'lib/request';

/**
 * Получить избранные игры
 */
const getList = (): Promise<Game[]> =>
  httpClient.get(`players/current/games/favorites`);

/**
 * Добавить игру в избранное
 */
const add = (data: FavoriteGameParams): Promise<boolean> =>
  httpClient.post(`players/current/games/favorites`, data);

/**
 * Удалить игру из избранного
 */
const remove = (data: FavoriteGameParams): Promise<boolean> =>
  httpClient.delete(`players/current/games/favorites`, { data });

export const favoriteApi = {
  getList,
  add,
  remove,
};
