export const getApiErrorsArray = (error: ApiError) => {
  return Object.entries(error.items);
};
export const joinErrorsToString = (arr: [string, any][]) => {
  return arr
    .map((el) => el.join('-'))
    .flat()
    .join(';');
};

export const getApiErrorsText = (data) => {
  let err = (data.error as unknown) as ApiError;
  return joinErrorsToString(getApiErrorsArray(err));
};
