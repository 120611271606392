import classNames from 'classnames';
import React, { useRef } from 'react';

import './style.css';

export const FlipBox: React.FC<Props> = ({
  children,
  isFlipped = false,
  className,
}) => {
  const [heightIsChecked, setHeightIsChecked] = React.useState(false);

  const frontRef = useRef<HTMLDivElement>(null);
  const backRef = useRef<HTMLDivElement>(null);

  const frontHeight = frontRef?.current?.offsetHeight;
  const backHeight = backRef?.current?.offsetHeight;

  const minHeight = React.useMemo(() => {
    if (frontHeight && backHeight) {
      setHeightIsChecked(true);
      return frontHeight > backHeight ? frontHeight : backHeight;
    }
    return 'initial';
  }, [frontHeight, backHeight]);

  const checkFrontSideLargerThanBack = React.useCallback(() => {
    if (frontHeight && backHeight) {
      return frontHeight >= backHeight;
    }
    return true;
  }, [frontHeight, backHeight]);

  return (
    <div
      className={classNames(
        'card-container',
        isFlipped && 'flipped',
        className,
      )}
    >
      <div style={{ minHeight }} className="size-component">
        {children[0]}
      </div>
      <div ref={frontRef} className="front">
        {children[0]}
      </div>
      <div
        style={{
          bottom:
            !heightIsChecked && checkFrontSideLargerThanBack() ? 'initial' : 0,
        }}
        ref={backRef}
        className="back"
      >
        {children[1]}
      </div>
    </div>
  );
};

interface Props {
  children: [React.ReactNode, React.ReactNode];
  isFlipped?: boolean;
  className?: string;
}
