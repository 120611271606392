import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { deviceModel } from 'features/device';
import { sessionModel } from 'features/session';
import { AccountContentTemplate, OffCanvas } from 'ui';
import { useLockBodyScroll, useOnClickOutside } from 'lib/hooks';

import { DepositContent } from './deposit-content';

import * as model from '../model';

export const DepositCanvas = () => {
  const { t } = useTranslation();

  const isAuthenticated = useStore(sessionModel.$isAuthenticated);
  const isMobile = useStore(deviceModel.$isMobile);
  const isOpen = useStore(model.$depositModalIsOpen);
  const [isClosing, setIsClosing] = React.useState(false);

  const ref = useRef(null);

  useLockBodyScroll(isOpen && isAuthenticated);

  useOnClickOutside({
    ref,
    handler: () => setIsClosing(true),
    ignorList: ['.popup'],
    disabled: !isOpen,
  });

  if (isOpen && isAuthenticated) {
    return (
      <div className={'popup-mask active'}>
        <OffCanvas
          className="deposit-canvas"
          isRendered={!isClosing}
          onAnimationEnd={() => {
            model.toggleDepositModal(false);
            setIsClosing(false);
          }}
        >
          <AccountContentTemplate
            componentRef={ref}
            onCloseClick={() => setIsClosing(true)}
            isMobile={isMobile}
            title={t('lk:deposit.title')}
          >
            <DepositContent />
          </AccountContentTemplate>
        </OffCanvas>
      </div>
    );
  }

  return null;
};
