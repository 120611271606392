import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { condition, status } from 'patronum';
import i18n from 'i18next';

import { getApiErrorsArray } from 'lib/error/formatApiError';
import { cashBoxApi } from 'api/cashbox';
import { paths } from 'pages/paths';
import {
  CARD_FIELD_NAMES,
  cashboxModel,
  getDataFieldError,
} from 'features/cashbox';
import { navigationModel } from 'features/navigation';
import { paymentsInfoModel } from 'features/payments-info';
import { userModel } from 'features/user';
import { checkObjectIsEmpty, objectUtils } from 'lib/object-utils';
import { loaderModel } from 'features/loader';

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

export const formSubmitted = createEvent<void>();

export const registerWithdrawalFx = createEffect<
  RegisterWithdrawalBody,
  RegisterWithdrawalResponse,
  ApiError
>(cashBoxApi.registerWithdrawal);

export const $isPaymentSystemsIsLoaded = status({
  effect: cashboxModel.getWithdrawPaymentSystemsFx,
}).map((status) => status === 'done');

export const $paymentSystemsList = cashboxModel.$withdrawPaymentGroups.map(
  (groups) =>
    groups.reduce<PaymentSystemItem[]>(
      (list, group) => [...list, ...group.ps],
      [],
    ),
);

/**
 * Форма
 */
export const resetForm = createEvent();

export const changePaymentSystem = createEvent<PaymentSystemItem>();
export const $seletectedPaymentSystem = restore(changePaymentSystem, null);

const $paymentSystemIsSelected = $seletectedPaymentSystem.map(Boolean);

export const sumChanged = createEvent<number>();
export const validateSum = createEvent<void>();
export const resetSum = createEvent<void>();

export const $sum = restore(sumChanged, 0).reset(resetSum);
export const $sumError = createStore<Record<string, any>>({});

export const $dataFields = $seletectedPaymentSystem.map(
  (system) => system?.payment_data_fields || [],
);

export const initDataFieldValues = createEvent<PaymentDataField[]>();
export const dataFieldChanged = createEvent<{
  fieldName: string;
  value: string;
}>();

export const $dataFieldsValues = createStore<Record<string, string>>({})
  .on(initDataFieldValues, (currentValues, fields) =>
    fields.reduce((values, field) => {
      values[field.name] = currentValues[field.name] || '';
      return values;
    }, {}),
  )
  .on(dataFieldChanged, (currentValues, { fieldName, value }) => ({
    ...currentValues,
    [fieldName]: value,
  }));

export const validateDataField = createEvent<{ name: string }>();
export const $dataFieldsErrors = createStore<Record<string, DataFieldError>>(
  {},
);

forward({ from: $dataFields, to: initDataFieldValues });

sample({
  source: guard({
    source: changePaymentSystem,
    filter: (system) => !!system.default_amount,
  }),
  target: sumChanged,
  fn: (ps) => ps.default_amount!,
});

sample({
  clock: guard({
    clock: paymentsInfoModel.$paymentEmail,
    filter: $dataFieldsValues.map((values) => values.hasOwnProperty('email')),
  }),
  target: dataFieldChanged,
  fn: (paymentEmail) => ({ value: paymentEmail, fieldName: 'email' }),
});

sample({
  clock: guard({
    clock: userModel.$email,
    filter: $dataFieldsValues.map(
      (values) => values.hasOwnProperty('email') && !values.email,
    ),
  }),
  target: dataFieldChanged,
  fn: (email) => ({ value: email || '', fieldName: 'email' }),
});

sample({
  clock: guard({
    clock: userModel.$phone,
    filter: $dataFieldsValues.map(
      (values) => values.hasOwnProperty('phone') && !values.phone,
    ),
  }),
  target: dataFieldChanged,
  fn: (phone) => ({ value: phone || '', fieldName: 'phone' }),
});

/**
 * Форма карточки
 */
export const changeCardFormIsOpened = createEvent<boolean>();
export const $cardFormIsOpened = restore(changeCardFormIsOpened, false);

export const $needToFillCardForm = $dataFields.map((fields) =>
  [
    CARD_FIELD_NAMES.CARD_CVV,
    CARD_FIELD_NAMES.CARD_EXP_MONTH,
    CARD_FIELD_NAMES.CARD_EXP_YEAR,
  ].every((name) => fields.some((f) => f.name === name)),
);

export const $submitButtonIsDisabled = combine(
  $sumError,
  $dataFieldsErrors,
  $needToFillCardForm,
  $cardFormIsOpened,
  $paymentSystemIsSelected,
  registerWithdrawalFx.pending,
  (
    sumError,
    dataFieldsErrors,
    needToFillCardForm,
    cardFormIsOpened,
    paymentSystemIsSelected,
    pending,
  ) => {
    if (pending || !paymentSystemIsSelected) {
      return true;
    }

    const needToCheckAllFields = cardFormIsOpened || !needToFillCardForm;

    const ignoreFieldKeys = needToCheckAllFields
      ? []
      : [...Object.values(CARD_FIELD_NAMES)];

    const checkedDataFields = objectUtils.omit(
      dataFieldsErrors,
      ...ignoreFieldKeys,
    );

    return ![sumError, ...Object.values(checkedDataFields)].every((obj) =>
      checkObjectIsEmpty(obj),
    );
  },
);

forward({ from: $dataFields, to: initDataFieldValues });

$sum.reset(resetForm);
$sumError.reset(resetForm);
sample({
  clock: resetForm,
  source: $dataFields,
  target: initDataFieldValues,
});

/**
 * Лимиты и возможность вывода
 */
export const $needToConfirmPaymentEmail = combine(
  $dataFields,
  paymentsInfoModel.$paymentEmail,
  (fields, email) => !email && fields.some((f) => f.name === 'email'),
);

export const $needToConfirmAccount = userModel.$userAccountIsConfirmed.map(
  (confirmed) => !confirmed,
);

const $systemLimits = $seletectedPaymentSystem.map((ps) => ({
  maxWithdraw: ps?.max_withdraw,
  minWithdraw: ps?.min_withdraw || 0,
}));

export const $limits = combine({
  minNumber: $systemLimits.map((el) => el.minWithdraw),
  maxNumber: combine(
    $systemLimits.map((el) => el.maxWithdraw),
    paymentsInfoModel.$withdrawLimits.map((el) => el?.available_sum),
    (systemLimit = 0, playerLimit = 0) =>
      systemLimit === null
        ? playerLimit
        : Math.min(systemLimit, playerLimit) || null,
  ),
});

// если максимальная сумма меньше чем минимальная или равняется 0.
export const $canWithdraw = combine(
  $limits,
  status({ effect: paymentsInfoModel.getWithdrawalLimitsFx }),
  ({ maxNumber, minNumber }, status) => {
    if (status === 'done') {
      return maxNumber === null || maxNumber >= minNumber;
    }
    return true;
  },
);

export const $needToHideFields = combine(
  $canWithdraw,
  paymentsInfoModel.$fullInfoIsProvided,
  paymentsInfoModel.$hasRequiredBet,
  $needToConfirmPaymentEmail,
  $needToConfirmAccount,
  (
    canWithdraw,
    fullInfoIsProvided,
    hasRequiredBet,
    needToConfirmEmail,
    needToConfirmAccount,
  ) =>
    !canWithdraw ||
    !fullInfoIsProvided ||
    hasRequiredBet ||
    needToConfirmEmail ||
    needToConfirmAccount,
);

/**
 * Валидация формы
 */
forward({
  from: sumChanged,
  to: validateSum,
});

sample({
  source: [$sum, $limits, userModel.$balance],
  clock: validateSum,
  target: $sumError,
  fn: ([sum, limits, balance]) => {
    const errors: Record<string, any> = {};
    if (!sum) errors.required = true;

    if (sum < limits.minNumber) errors.minNumber = limits.minNumber;

    if (limits.maxNumber && sum > limits.maxNumber)
      errors.maxNumber = limits.maxNumber;

    if (sum > balance.real_amount) errors.enoughBalance = true;

    return errors;
  },
});

sample({
  clock: initDataFieldValues,
  source: [
    $dataFieldsValues,
    paymentsInfoModel.$paymentEmail,
    userModel.$email,
    userModel.$phone,
  ],
  target: $dataFieldsValues,
  fn: ([currentValues, paymentEmail, email, phone], fields) =>
    fields.reduce((values, field) => {
      let defaultValue = '';

      if (field.name === 'email') {
        defaultValue = paymentEmail || email || '';
      } else if (field.name === 'phone') {
        defaultValue = phone || '';
      }

      values[field.name] = currentValues[field.name] || defaultValue;
      return values;
    }, {}),
});

sample({
  source: $dataFieldsValues,
  clock: initDataFieldValues,
  target: $dataFieldsErrors,
  fn: (values, fields) =>
    fields.reduce<Record<string, { required?: boolean; regexp?: boolean }>>(
      (errors, field) => {
        const value = values[field.name] || '';
        errors[field.name] = getDataFieldError(field, value);
        return errors;
      },
      {},
    ),
});

sample({
  source: [$dataFields, $dataFieldsErrors],
  clock: dataFieldChanged,
  target: $dataFieldsErrors,
  fn: ([fields, errors], { fieldName, value }) => {
    const field = fields.find((f) => f.name === fieldName);
    return field
      ? { ...errors, [fieldName]: getDataFieldError(field, value) }
      : errors;
  },
});

guard({
  source: $limits,
  filter: $sum.map(Boolean),
  target: validateSum,
});

/**
 * Обработка серверный ошибок
 */
export const resetModalError = createEvent();

const API_ERRORS_WITH_MESSAGES = ['payment.data.error'];

export const $modalErrorMessage = createStore<string | 'default'>('')
  .on(registerWithdrawalFx.failData, (_, error) => {
    const errorArray = getApiErrorsArray(error);

    const errorWithMessage = errorArray.find(([error]) =>
      API_ERRORS_WITH_MESSAGES.includes(error),
    );

    if (errorWithMessage) {
      return errorWithMessage[1];
    }

    if (errorArray.length > 0) {
      return 'default';
    }

    return '';
  })
  .reset(resetModalError);

/**
 * Загрузка страницы
 */
forward({
  from: pageLoaded,
  to: [
    cashboxModel.getWithdrawPaymentSystemsFx,
    paymentsInfoModel.getWithdrawalLimitsFx,
  ],
});

// регистрация вывода
const withdrawRegistered = createEvent<void>();

forward({
  from: formSubmitted,
  to: withdrawRegistered,
});

sample({
  source: combine({
    sum: $sum,
    paymentSystem: $seletectedPaymentSystem,
    paymentData: $dataFieldsValues,
  }),
  clock: withdrawRegistered,
  target: registerWithdrawalFx,
  fn: ({ paymentSystem, sum, paymentData }) => ({
    payment_data: paymentData,
    sum,
    ps_id: paymentSystem!.ps_id,
  }),
});

condition({
  source: registerWithdrawalFx.pending,
  if: Boolean,
  then: loaderModel.events.showLoader.prepend(
    () => i18n.t('lk:withdraw.loading') as string,
  ),
  else: loaderModel.events.hideLoader,
});

sample({
  clock: registerWithdrawalFx.doneData,
  target: navigationModel.historyPush,
  fn: paths.history,
});

forward({
  from: pageUnloaded,
  to: resetForm,
});
$cardFormIsOpened.reset(pageUnloaded);
$seletectedPaymentSystem.reset(pageUnloaded);
