import { GameListTypes } from 'lib/constants';

export const paths = {
  about: () => '/about',
  bonusConditions: () => '/bonus-conditions',
  cancellationPolicy: () => '/cancellation-policy',
  category: (category?: string) => `/category/${category}`,
  confirmNewPassword: () => `/confirm-password`,
  searchGames: (value: string) =>
    `${paths.category(GameListTypes.All)}?search=${value}`,
  changePassword: () => '/change-password',
  cookiesPolicy: () => '/cookies-policy',
  contacts: () => '/contacts',
  deposit: () => `/lk/deposit`,
  faq: () => '/faq',
  error: () => '/not-found',
  game: (mode: GameMode | ':mode', provider: string, code: string) =>
    `/game/${mode}/${provider}/${code}`,
  gameRules: () => '/game-rules',
  jackpot: (id: string | number) => `/jackpot/${id}`,
  moneyLaundering: () => '/money-laundering',
  home: () => `/`,
  lk: () => `/lk`,
  lkBonus: () => `/lk/bonus`,
  history: () => `/lk/history`,
  payments: () => '/payments',
  loyaltyProgram: () => '/loyalty',
  presents: () => '/presents',
  privacyPolicy: () => '/privacy-policy',
  profile: () => `/lk/profile`,
  lottery: (id?: string | number) => `/lotteries/${id}`,
  lotteries: () => '/lotteries',
  promotion: (id: number | string) => `/promotions/${id}`,
  promotions: () => '/promotions',
  terms: () => '/terms',
  tournament: (id: string | number) => `/tournaments/${id}`,
  tournaments: () => '/tournaments',
  withdraw: () => `/lk/withdraw`,
  wheelGame: () => `/wheel-of-fortune`,
  successfulPayment: () => '/successful-payment',
  failedPayment: () => '/failed-payment',
  depositProccessing: () => '/deposit-proccessing',
  riskNotice: () => '/risk-notice',
};
