import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import DOMPurify from 'dompurify';

import { Button, IconSprite, LinkButton, Slider } from 'ui';
import { sessionModel } from 'features/session';
import { authFormModel } from 'features/auth';
import { paths } from 'pages/paths';
import { mediaHooks, useMedia } from 'lib/media';
import { IntroNav } from './intro-nav';
import { skinModel } from '../skin-id';

const AUTOPLAY_SLIDER_MS = 6500;

export const Intro = React.memo(({ isDesktop }: { isDesktop: boolean }) => {
  const { t } = useTranslation();

  const isLargeMobile = mediaHooks.useIsLargeMobile();
  const isHabanero = skinModel.selectors.useIsHabanero();

  const imgType = useMedia(
    [
      '(max-width: 600px)',
      '(max-width: 768px)',
      '(max-width: 992px)',
      '(max-width: 1600px)',
      '(max-width: 1992px)',
    ],
    ['600', '768', '992', '1600', '1992'],
    '',
  );

  const getImgType = React.useCallback(
    (imgSrc) => `${imgSrc.split('.png')[0]}${imgType ? `_${imgType}` : ''}.png`,
    [imgType],
  );

  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [autoplay, setAutoplay] = React.useState(true);

  const handleSetCurrentSlide = (number: number) => {
    setAutoplay(false);
    setCurrentSlide(number);
  };

  const slides = React.useMemo(() => {
    if (isHabanero) {
      return [
        <Slide
          imgSrc={getImgType('/img/ha/intro/slides/main-bg1.png')}
          textI18nKey="common:intro.title_3"
          button={
            <LinkButton to={paths.deposit()}>
              {t('common:buttons.deposit')} <IconSprite name="present-box" />
            </LinkButton>
          }
        />,
        <Slide
          imgSrc={getImgType('/img/ha/intro/slides/main-bg2.png')}
          textI18nKey="common:intro.title_1"
          button={
            <LinkButton to={paths.deposit()}>
              {t('common:buttons.deposit')} <IconSprite name="present-box" />
            </LinkButton>
          }
        />,
        <Slide
          imgSrc={getImgType('/img/ha/intro/slides/main-bg3.png')}
          textI18nKey="common:intro.title_2"
          button={
            <LinkButton to={paths.deposit()}>
              {t('common:buttons.deposit')} <IconSprite name="present-box" />
            </LinkButton>
          }
        />,
      ];
    }

    return [
      <Slide
        imgSrc={getImgType('/img/go/intro/slides/main-bg1.png')}
        textI18nKey="common:intro.title_3"
        button={
          <LinkButton to={paths.deposit()}>
            {t('common:buttons.deposit')} <IconSprite name="present-box" />
          </LinkButton>
        }
      />,
      <Slide
        imgSrc={getImgType('/img/go/intro/slides/main-bg2.png')}
        textI18nKey="common:intro.title_1"
        button={
          <LinkButton to={paths.deposit()}>
            {t('common:buttons.deposit')} <IconSprite name="present-box" />
          </LinkButton>
        }
      />,
      <Slide
        imgSrc={getImgType('/img/go/intro/slides/main-bg3.png')}
        textI18nKey="common:intro.title_2"
        button={
          <LinkButton to={paths.deposit()}>
            {t('common:buttons.deposit')} <IconSprite name="present-box" />
          </LinkButton>
        }
      />,
    ];
  }, [imgType]);

  return (
    <div className="intro">
      <Slider
        speed={3}
        currentNumberSlide={currentSlide}
        autoplay={autoplay}
        playMS={AUTOPLAY_SLIDER_MS}
        onSlide={(number) => setCurrentSlide(number)}
        swiper={isLargeMobile}
        withButtons
        slidesCount={1}
        slides={slides}
      />
      {isDesktop && (
        <IntroNav
          currentSlide={currentSlide}
          setCurrentSlide={handleSetCurrentSlide}
          totalSlides={slides.length}
        />
      )}
    </div>
  );
});

interface SlideProps {
  button: React.ReactNode;
  textI18nKey: string;
  imgSrc: string;
}

const Slide: React.FC<SlideProps> = ({ button, textI18nKey, imgSrc }) => {
  const { t } = useTranslation();
  const isLargeMobile = mediaHooks.useIsLargeMobile();
  const isAuth = useStore(sessionModel.$isAuthenticated);

  return (
    <div
      className="slide"
      style={{
        backgroundImage: isLargeMobile ? `url('${imgSrc}')` : '',
      }}
    >
      {!isLargeMobile && <img src={imgSrc} />}
      <div className="intro-slider__text">
        <div className="intro-slider__title-wrap">
          <div
            className="intro-slider__title resources"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t(textI18nKey)),
            }}
          />
        </div>
        {isAuth ? (
          button
        ) : (
          <Button
            className="not-auth__btn"
            onClick={() => authFormModel.registerFormOpened()}
          >
            {t('common:intro.no_auth_btn_text')}
            <IconSprite name="present-box" />
          </Button>
        )}
      </div>
    </div>
  );
};
