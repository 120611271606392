import { favoriteApi } from 'api/favorite';
import {
  createEffect,
  createEvent,
  createStore,
  guard,
  sample,
} from 'effector';
import { some, status } from 'patronum';

export const loadFavoriteGames = createEvent();
export const gameAdded = createEvent<Game>();
export const gameRemoved = createEvent<Game>();

export const loadFavoriteGamesFx = createEffect<null, Game[], Error>(
  favoriteApi.getList,
);

export const addToFavoriteGamesFx = createEffect<
  FavoriteGameParams,
  boolean,
  Error
>(favoriteApi.add);

export const removeFromFavoriteGamesFx = createEffect<
  FavoriteGameParams,
  boolean,
  Error
>(favoriteApi.remove);

export const $isPending = some({
  stores: [addToFavoriteGamesFx.pending, removeFromFavoriteGamesFx.pending],
  predicate: true,
});

export const favoriteGamesIsLoaded = status({
  effect: loadFavoriteGamesFx,
}).map((status) => status === 'done');

export const $favoriteGames = createStore<Game[]>([])
  .on(loadFavoriteGamesFx.doneData, (_, data) => data)

  .on(gameAdded, (state, game) => {
    return [...state, game];
  })

  .on(gameRemoved, (state, removedGame) =>
    state.filter(({ code, provider_code }) => {
      return !(
        code === removedGame.code && provider_code === removedGame.provider_code
      );
    }),
  );

export const $loadFavoriteGamesStatus = status({
  effect: loadFavoriteGamesFx,
  defaultValue: 'pending',
});

guard({
  source: loadFavoriteGames,
  filter: $favoriteGames.map((games) => !games.length),
  // @ts-ignore
  target: loadFavoriteGamesFx,
});

sample({
  source: gameAdded,
  target: addToFavoriteGamesFx,
  fn: ({ code, provider_code }) => ({ code, provider: provider_code }),
});

sample({
  source: gameRemoved,
  target: removeFromFavoriteGamesFx,
  fn: ({ code, provider_code }) => ({ code, provider: provider_code }),
});
