import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { BlockTitle, ButtonLink, Container, Section, Slider } from 'ui';
import { GameCard, GameCardSkeleton } from 'features/halls';
import { paths } from 'pages/paths';
import { Halls } from 'lib/constants';

const SKELETON_SIZE = 5;

interface GameSectionProps {
  gameList: Game[];
  isPending: boolean;
  title: string;
  icon: string | React.ReactNode;
  onButtonClick?: () => void;
  withButton?: boolean;
  className?: string;
  align?: 'start' | 'center' | 'end';
}

export const GamesSliderSection = ({
  gameList,
  isPending,
  title,
  icon,
  withButton = false,
  onButtonClick,
  align,
  className,
}: GameSectionProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const slides = React.useMemo(
    () =>
      isPending
        ? Array.from({ length: SKELETON_SIZE }, (_, idx) => {
            return <GameCardSkeleton key={idx} />;
          })
        : gameList.map((game) => {
            return <GameCard className="game_slider" game={game} />;
          }),
    [isPending, gameList],
  );

  if (!isPending && gameList.length === 0) {
    return null;
  }

  return (
    <Section className={className} type="game">
      <Container>
        <BlockTitle icon={icon} title={title}>
          {withButton && (
            <ButtonLink
              onClick={() => {
                onButtonClick && onButtonClick();
                history.push(paths.category(Halls.All));
              }}
              border
            >
              {t('common:buttons.more-games-v1')}
            </ButtonLink>
          )}
        </BlockTitle>
        <Slider
          smallBtns
          align={align}
          withButtons={!isPending}
          loop={false}
          slidesToScroll={1}
          off={isPending}
          className="game-slider"
          fullWidth
          slides={slides}
        />
      </Container>
    </Section>
  );
};
