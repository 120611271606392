import React from 'react';
import { Link as LinkContainer } from 'react-router-dom';
import classNames from 'classnames';
import './style.css';

interface LinkProps {
  children: React.ReactNode;
  className?: string;
  to: string;
  theme?: 'primary' | 'secondary' | 'tertiary';
  border?: boolean;
}

export const Link = ({
  children,
  to,
  className,
  theme = 'primary',
  border,
}: LinkProps) => {
  const classProps: string = classNames(
    'link',
    theme,
    border && 'border',
    className,
  );
  return (
    <LinkContainer className={classProps} to={to}>
      {children}
    </LinkContainer>
  );
};

interface ButtonLinkProps {
  children: React.ReactNode;
  className?: string;
  theme?: 'primary' | 'secondary' | 'tertiary';
  border?: boolean;
  onClick?: () => void;
  [x: string]: any;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  className,
  border,
  onClick,
  theme = 'primary',
  ...props
}) => {
  const classProps: string = classNames(
    'link',
    theme,
    border && 'border',
    className,
  );

  return (
    <span
      {...props}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      className={classProps}
    >
      {children}
    </span>
  );
};
