import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DateTime, DateObject } from 'lib/datetime';
import { IconSprite } from 'ui';

import './style.css';

interface Props {
  date?: string | number;
  big?: boolean;
  isEnd?: boolean;
  onOver?: () => void;
}

export const Counter = ({
  date = '',
  big,
  onOver = () => {},
  isEnd = false,
}: Props) => {
  const { t } = useTranslation();

  const [differenceDate, setDifferenceDate] = React.useState<DateObject | null>(
    null,
  );

  const [isOver, setIsOver] = React.useState<boolean>(isEnd);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (date && !isOver) {
      timer = setTimeout(function tick() {
        const difference = DateTime.calculateTimeLeft(date);
        if (difference) {
          setDifferenceDate(difference);
          timer = setTimeout(tick, 1000);
        } else {
          setIsOver(true);
          onOver();
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [date, isOver]);

  return (
    <div className={classNames('counter', isOver && 'counter_final')}>
      <div className="counter__wrap">
        <div className="counter__img">
          <IconSprite name={isOver ? 'counter-final-icon' : 'counter-icon'} />
        </div>
        <div className="counter__date">
          <CounterCol
            big={big}
            text={t('common:counter.day')}
            value={differenceDate?.day}
          />
          <CounterCol
            big={big}
            text={t('common:counter.hour')}
            value={differenceDate?.hours}
          />
          <CounterCol
            big={big}
            text={t('common:counter.minute')}
            value={differenceDate?.minutes}
          />
          <CounterCol
            big={big}
            text={t('common:counter.second')}
            value={differenceDate?.seconds}
          />
          {isOver && (
            <span className="counter__descr--final">
              {t('common:counter.сompleted')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const CounterCol = ({ text, value, big }) => (
  <div className="counter__col">
    <span className="counter__text">{text}</span>
    <span className={classNames('counter__count', big && 'counter__count-big')}>
      {value || '-'}
    </span>
  </div>
);
