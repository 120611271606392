import * as React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SectionTitle, Section, Container } from 'ui';
import { PromotionSection } from 'features/promotion';
import { Lottery } from 'features/lotteries';

import * as model from './model';
import './style.css';

export const LotteryPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const lotteryData = useStore(model.$lotteryData);
  const status = useStore(model.$status);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({ id });
    return () => model.pageUnloaded();
  }, [id]);

  return (
    <>
      <Section type="lottery">
        <Container small>
          <SectionTitle>{t('lottery:lottery')}</SectionTitle>
          <Lottery loading={status === 'pending'} lottery={lotteryData} />
        </Container>
        <PromotionSection />
      </Section>
    </>
  );
};
