import * as React from 'react';
import { useStore } from 'effector-react';

import { Grid } from 'ui';
import { TournamentItem, TournamentItemSkeleton } from './tournament-item';
import { tournamentModel } from 'features/tournaments';

import './style.css';

interface ListProps {
  skeletonSize: number;
}

export const TournamentList = ({ skeletonSize }: ListProps) => {
  const list = useStore(tournamentModel.$renderedList);
  const status = useStore(tournamentModel.$status);

  React.useEffect(() => {
    return () => tournamentModel.listUnmounted();
  }, []);

  if (status === 'pending') {
    return <SkeletonList skeletonSize={skeletonSize} />;
  }

  return (
    <Grid container>
      {list.map((item) => (
        <Grid
          className="grid-tournament-item"
          key={item.id}
          xs={12}
          md={6}
          item
        >
          <TournamentItem tournament={item} />
        </Grid>
      ))}
    </Grid>
  );
};

const SkeletonList = ({ skeletonSize }: { skeletonSize: number }) => {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: skeletonSize }, (_, idx) => (
        <Grid className="grid-tournament-item" xs={12} md={6} item key={idx}>
          <TournamentItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );
};
