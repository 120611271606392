import {
  combine,
  createEvent,
  createStore,
  Effect,
  forward,
  guard,
  restore,
  sample,
  Store,
} from 'effector';
import { reshape, status } from 'patronum';
import { createPaginationModel } from 'features/pagination';

interface Props<T, U> {
  effect: Effect<void, U, Error>;
  needToLoad?: Store<boolean>;
  defaultLimit: number;
  paired?: boolean;
  responseFn?: (response: U) => T[];
}

export function createListModel<
  T,
  U extends { data?: any; [propName: string]: any }
>({
  effect,
  defaultLimit,
  paired = false,
  needToLoad = createStore<boolean>(true),
  responseFn = (response) => (response.data ? response.data : response),
}: Props<T, U>) {
  const listInitialized = createEvent<void>();
  const loadList = createEvent<any>();
  const listUnmounted = createEvent();

  const pagination = createPaginationModel<T>({
    resetTriggers: [listUnmounted],
    defaultLimit,
  });

  const $status = status({
    effect: effect,
    defaultValue: 'pending',
  });

  const $error = restore<Error>(effect.failData, null);

  const $isPending = $status.map((status) => status === 'pending');

  const $mounted = createStore<boolean>(false)
    .on(listInitialized, () => true)
    .on(listUnmounted, () => false);

  const $list = createStore<T[]>([]);
  const { $totalCount, $isListEmpty } = reshape({
    source: $list,
    shape: {
      $totalCount: (list) => list.length,
      $isListEmpty: (list) => !list.length,
    },
  });

  const $renderedList = combine(
    $list,
    pagination.$currentPage,
    pagination.$limit,
    (list, currentPage, limit) => {
      const newList = pagination.paginate(list, limit, currentPage);
      // return paired && newList.length % 2 ? newList.slice(0, -1) : newList;
      return newList;
    },
  );

  forward({
    from: listInitialized,
    to: [loadList, pagination.setLimit],
  });

  guard({
    source: loadList,
    filter: combine(effect.pending, needToLoad, (pending, needToLoad) =>
      pending ? false : needToLoad,
    ),
    target: effect,
  });

  sample({
    source: effect.doneData,
    target: $list,
    fn: responseFn,
  });

  forward({
    from: $totalCount,
    to: pagination.setTotal,
  });

  return {
    $mounted,
    $error,
    pagination,
    listInitialized,
    listUnmounted,
    $list,
    loadList,
    $renderedList,
    $status,
    $isPending,
    $isListEmpty,
  };
}
