import { BASE_URI } from 'lib/constants';
import { httpClient } from 'lib/request';

// получить список турниров
const getList = (mobile: boolean): Promise<TournamentListResponse> =>
  httpClient.get(`tournaments/max-bet-sum?mobile=${mobile}`);

// главный турнир
const getMain = (mobile: boolean): Promise<TournamentItem> => {
  return httpClient.get(`tournaments/max-bet-sum/main?mobile=${mobile}`);
};

// вступить в турнир
const join = (id: string | number): Promise<void> => {
  return httpClient.post(`players/current/tournaments/${id}/participants`);
};

// получить info о турнире
const getById = (
  id: string | number,
  mobile: boolean,
): Promise<TournamentItem> =>
  httpClient.get(`tournaments/${id}?mobile=${mobile}`);

const getTournamentImgUrl = (
  id: string | number,
  type: TournamentImgType,
): string => `${BASE_URI}tournaments/images/${id}_${type}`;

export const tournamentApi = {
  getTournamentImgUrl,
  getList,
  getById,
  getMain,
  join,
};
