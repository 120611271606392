import React, { FormEvent, ReactNode } from 'react';
import './style.css';
import classNames from 'classnames';

interface MainFormProps {
  noBorder?: boolean;
  isForm?: boolean;
  className?: string;
  children: ReactNode;
  label?: ReactNode;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

export const MainForm = React.forwardRef<any, MainFormProps>(
  ({ className, children, onSubmit, label, isForm = true, noBorder }, ref) => {
    if (!isForm) {
      return (
        <div ref={ref} className={classNames('main-form', className)}>
          <div
            className={classNames('main-form__wrap', noBorder && 'no-border')}
          >
            {label && <label className="main-form__label">{label}</label>}
            {children}
          </div>
        </div>
      );
    }

    return (
      <form
        ref={ref}
        className={classNames('main-form', className)}
        onSubmit={onSubmit}
      >
        <div className={classNames('main-form__wrap', noBorder && 'no-border')}>
          {label && <label className="main-form__label">{label}</label>}
          {children}
        </div>
      </form>
    );
  },
);

interface RowProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  labelFor?: string;
  white?: boolean;
  sms?: boolean;
  column?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  wrapClassName?: string;
}

export const MainFormRow = ({
  label,
  labelFor,
  children,
  white,
  sms,
  onChange,
  className,
  column,
  wrapClassName,
}: RowProps) => {
  return (
    <div
      className={classNames(
        'main-form__main-wrap',
        wrapClassName === 'main-form__method-wrap' &&
          'main-form__main-wrap_method',
        sms && 'main-form__main-wrap_sms',
        column && 'column-style',
        className,
      )}
      onChange={onChange}
    >
      <label
        className={classNames(
          'main-form__label',
          white && 'main-form__label_white',
          sms && 'main-form__sms-title',
        )}
        htmlFor={labelFor}
      >
        {label}
      </label>
      {wrapClassName ? (
        <div className={wrapClassName}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const MainFormButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  disabled,
  type = 'button',
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        'main-form__btn',
        className,
        disabled && 'disabled',
      )}
    >
      {children}
    </button>
  );
};
