import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import './style.css';

interface Props {
  head: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export const Accordion = ({
  head,
  children,
  className,
  contentClassName = 'accordion__text',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const elem = useRef<HTMLParagraphElement | null>(null);
  const height = elem.current && elem.current.offsetHeight;
  return (
    <div className={classNames('accordion', isOpen && 'open', className)}>
      <div onClick={() => setIsOpen(!isOpen)} className="accordion__head">
        {head}
      </div>
      <div
        className="accordion__content"
        style={{ maxHeight: isOpen && height ? `${height}px` : '0px' }}
      >
        <div ref={elem} className={contentClassName}>
          {children}
        </div>
      </div>
    </div>
  );
};

export const AccordionTitle = ({ children }: { children: React.ReactNode }) => {
  return <h2 className="accordion-title">{children}</h2>;
};
