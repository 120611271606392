import { httpClient } from 'lib/request';

function restoreByEmail(data: RestorePasswordRequestEmail): Promise<any> {
  return httpClient.post(`players/emails/passwords/links`, data);
}

function restoreByPhone(data: RestorePasswordRequestPhone): Promise<any> {
  return httpClient.post(`players/phones/passwords`, data);
}

function confirmNewPassword(data: ConfirmNewPasswordBody): Promise<string> {
  return httpClient.post('/players/emails/passwords', data);
}

export const restoreApi = {
  confirmNewPassword,
  restoreByEmail,
  restoreByPhone,
};
