import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { paths } from 'pages/paths';
import { Slider, NavLink } from 'ui';
import { Halls } from 'lib/constants';
import { i18nModel } from 'features/i18/i18n';

import { hallModel } from '..';

const HALL_RANK = 0;

export const HallSlider = () => {
  const { category } = useParams<{ category: string }>();
  const { t } = useTranslation();

  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canScrollPrev, setCanScrollPrev] = useState(false);

  const halls = useStore(hallModel.$hallsNamesByRank);

  const renderedHall = halls[HALL_RANK] || [];

  const i18nNames = useStore(i18nModel.resourses.gameMenu);

  const slides = React.useMemo(() => {
    const hallsNames = renderedHall.map((hall, idx) => (
      <div className="link-box">
        <NavLink active={category === hall} key={idx} to={paths.category(hall)}>
          {i18nNames[hall] || hall}
        </NavLink>
      </div>
    ));

    return [
      <div className="link-box">
        <NavLink active={category === Halls.All} to={paths.category(Halls.All)}>
          {t(`common:categories.${Halls.All}`)}
        </NavLink>
      </div>,
      ...hallsNames,
      <div className="link-box">
        <NavLink
          active={category === Halls.Favorite}
          to={paths.category(Halls.Favorite)}
        >
          {t(`common:categories.${Halls.Favorite}`)}
        </NavLink>
      </div>,
    ];
  }, [halls, category, i18nNames]);

  return (
    <div
      className={classNames(
        'hall-slider-wrap',
        !canScrollNext && 'cant-scroll-next',
        !canScrollPrev && 'cant-scroll-prev',
      )}
    >
      <Slider
        className={classNames('hall-slider-mob')}
        onCanScrollNext={() => setCanScrollNext(true)}
        onCantScrollNext={() => setCanScrollNext(false)}
        onCanScrollPrev={() => setCanScrollPrev(true)}
        onCantScrollPrev={() => setCanScrollPrev(false)}
        withButtons
        smallBtns
        fullWidth
        dragFree
        slides={slides}
      />
    </div>
  );
};
