import React from 'react';

import { skinModel } from './index';
import { SkinIds } from './model';

const HabaneroTheme = React.lazy(() => import('./ha-theme'));
const GoTheme = React.lazy(() => import('./go-theme'));

export const SkinProvider = ({ children }) => {
  const skinId = skinModel.selectors.useSkinId();

  React.useEffect(() => {
    if (skinId === SkinIds.HA) {
      setImageToLoader('/img/ha/logo.svg');
    }
    if (skinId === SkinIds.GO) {
      setImageToLoader('/img/go/logo.svg');
    }
  }, [skinId]);

  return (
    <>
      <React.Suspense fallback={<ThemeLoader />}>
        {skinId === SkinIds.HA && <HabaneroTheme />}
        {skinId === SkinIds.GO && <GoTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

const ThemeLoader = () => {
  React.useEffect(() => {
    return () => {
      skinModel.events.setThemeIsLoaded(true);
    };
  }, []);

  return null;
};

function setImageToLoader(src: string) {
  const loader = document.getElementById('loader');
  const image = document.createElement('img');
  image.setAttribute('src', src);
  loader?.prepend(image);
}
