import { loyaltyApi } from 'api/loyalty-program';
import {
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
} from 'effector';

import { sessionModel } from 'features/session';

const resetUserLoyaltyProgress = createEvent<void>();
export const getUserLoyaltyProgramProgress = createEvent();

export const getUserLoyaltyProgramProgressFx = createEffect<
  void,
  UserLoyaltyProgramProgressResponse,
  ApiError
>(loyaltyApi.getUserLoyaltyProgramProgress);

export const getLoyaltyProgramStatusesFx = createEffect<
  void,
  LoyaltyProgramStatus[],
  ApiError
>(loyaltyApi.getLoyaltyProgramStatuses);

export const getLoyaltyProgramStructureFx = createEffect<
  void,
  LoyaltyProgramSctuctureItem[],
  ApiError
>(loyaltyApi.getLoyaltyProgramStructure);

export const $loyaltyStatuses = restore(
  getLoyaltyProgramStatusesFx.doneData,
  [],
);
export const $loyaltyStructure = restore(
  getLoyaltyProgramStructureFx.doneData,
  [],
);

export const $userLoyaltyProgress = createStore<UserLoyaltyProgramStructure | null>(
  null,
)
  .on(
    getUserLoyaltyProgramProgressFx.doneData,
    (_, { cashback_percent, level, status, statuses }) => ({
      cashback_percent,
      level,
      statuses,
      status,
    }),
  )
  .reset(resetUserLoyaltyProgress);

guard({
  source: getUserLoyaltyProgramProgress,
  filter: $userLoyaltyProgress.map((state) => !state),
  target: getUserLoyaltyProgramProgressFx,
});

guard({
  source: sessionModel.$isAuthenticated,
  filter: (isAuth) => !isAuth,
  target: resetUserLoyaltyProgress,
});
