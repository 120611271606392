import { createListModel } from 'features/list';
import { attach, createEffect, Effect, guard } from 'effector';
import { languageModel } from 'features/i18';
import { promotionApi } from 'api/promotion';

const DEFAULT_LIMIT = 3;

export const loadPromotionListFx: Effect<
  void,
  PromotionListResponse,
  Error
> = attach({
  effect: createEffect<string, PromotionListResponse, Error>({
    handler: (language) => promotionApi.getList(language),
  }),
  source: languageModel.$language,
  mapParams: (_, language) => language,
});

export const {
  $error,
  $mounted,
  pagination,
  listInitialized,
  listUnmounted,
  $list,
  $renderedList,
  $status,
  $isPending,
  $isListEmpty,
} = createListModel<PromotionPreviewItem, PromotionListResponse>({
  effect: loadPromotionListFx,
  defaultLimit: DEFAULT_LIMIT,
  responseFn: ({ data }) => data,
});

guard({
  source: languageModel.$language,
  filter: $mounted,
  target: loadPromotionListFx,
});
