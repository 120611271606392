import * as React from 'react';
import { useStore } from 'effector-react';
import { Switch, Route } from 'react-router-dom';

import { Footer } from 'ui';
import { compileRoutes, protectRoutes } from 'lib/routing';
import { Header } from 'features/common';
import { PagesNav } from 'features/navigation';
import { $session } from 'features/session/model';
import { skinModel } from 'features/skin-id';
import { routes as ROUTES } from './routes';

export const Pages = () => {
  const session = useStore($session);
  const themeIsLoaded = useStore(skinModel.stores.$themeIsLoaded);

  const routes = React.useMemo(() => {
    const compiled = compileRoutes(ROUTES);
    return protectRoutes(compiled, { session });
  }, [session]);

  React.useEffect(() => {
    if (themeIsLoaded) {
      const loader = document.getElementById('loader');

      if (loader && !loader.hasAttribute('hidden')) {
        loader.setAttribute('hidden', 'true');
      }
    }
  }, [themeIsLoaded]);

  return (
    <div className="wrap">
      <Header />
      <main className="main bg">
        <Switch>
          {routes.map((route) => (
            <Route key={route.name} {...route} />
          ))}
        </Switch>
      </main>
      <PagesNav />
      <Footer />
    </div>
  );
};
