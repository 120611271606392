import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { BlockTitle, Container, Section } from 'ui';
import { BonusSlider } from './bonus-slider';
import { mediaHooks } from 'lib/media';

import * as model from '../model';

export const BonusSection = () => {
  const { t } = useTranslation();

  const isLargeMobile = mediaHooks.useIsLargeMobile();
  const bonusList = useStore(model.$allBonusesList) as Bonus[];
  const isPending = useStore(model.$bonusesIsLoading);

  React.useEffect(() => {
    model.bonusesMounted();
    return () => model.bonusesUnmounted();
  }, []);

  return bonusList.length > 0 ? (
    <Section type="game" className="bonus-section">
      <Container className="bonus-slider-container">
        <BlockTitle
          icon={<img src="/img/icons/donkey-icon.svg" alt="" />}
          title={t('common:menu.presents')}
        />
        <BonusSlider
          withButtons={!isLargeMobile}
          isPending={isPending}
          bonusList={bonusList}
        />
      </Container>
    </Section>
  ) : null;
};
