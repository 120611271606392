import { useStoreMap } from 'effector-react';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'effector';

import { languageModel } from 'features/i18';
import { Field, MainFormRow } from 'ui';

interface Props {
  field: PaymentDataField;
  showLabel?: boolean;
  wrapperClassName?: string;
  onlyField?: boolean;
  extPlaceholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  type?: string;
  inputRef?: any;
  $dataFieldsErrors: Store<Record<string, DataFieldError>>;
  $dataFieldsValues: Store<Record<string, string>>;
}

export const PaymentDataField = React.memo(
  ({
    field,
    showLabel = true,
    wrapperClassName,
    onlyField,
    extPlaceholder,
    onChange,
    onBlur,
    type = 'text',
    inputRef = null,
    $dataFieldsErrors,
    $dataFieldsValues,
  }: Props) => {
    const { t } = useTranslation();

    const [isFocused, setIsFocused] = React.useState(false);

    const {
      label,
      placeholder,
    } = languageModel.selectors.usePaymentFieldDescription(field.name);

    const value = useStoreMap({
      store: $dataFieldsValues,
      keys: [field.name],
      fn: (values, [name]) => values[name],
    });

    const error = useStoreMap({
      store: $dataFieldsErrors,
      keys: [field.name],
      fn: (values, [name]) => values[name],
    });

    const errorText = React.useMemo(() => {
      if (error.required) {
        return t('common:validators.required');
      } else if (error.regexp) {
        return t('common:validators.invalidRegexp');
      } else if (error.invalidDate) {
        return t('common:validators.invalidDate');
      }
      return null;
    }, [t, error]);

    const needToShowError = isFocused || Boolean(value);

    return onlyField ? (
      <Field
        type={type}
        onFocus={() => setIsFocused(true)}
        placeholder={extPlaceholder || placeholder}
        error={needToShowError && errorText}
        name={field.name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        wrapperClassName={wrapperClassName}
        inputRef={inputRef}
      />
    ) : (
      <MainFormRow white={!field.required} label={showLabel ? label : ''}>
        <Field
          type={type}
          onFocus={() => setIsFocused(true)}
          placeholder={extPlaceholder || placeholder}
          error={needToShowError && errorText}
          name={field.name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          wrapperClassName={wrapperClassName}
          inputRef={inputRef}
        />
      </MainFormRow>
    );
  },
);
