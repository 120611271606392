import {
  createEffect,
  createEvent,
  restore,
  createStore,
  sample,
  combine,
  guard,
  forward,
} from 'effector';
import i18n from 'i18next';

import { userApi } from 'api/user';
import { RegTypes } from 'lib/constants';
import { isValidEmail } from 'lib/validators';
import { userModel } from 'features/user';
import { createErrorObject } from 'lib/error/formatApiError';

export const formMounted = createEvent<void>();
export const formUnmounted = createEvent<void>();

export const $canChangeEmail = userModel.$regType.map(
  (type) => type !== RegTypes.EMAIL,
);

// TODO: вынести в сервис
export const $callbackUrl = createStore('').on(
  formMounted,
  () => window.location.origin,
);

export const updateEmailFx = createEffect<
  { mirror_url: string; email: string },
  boolean,
  ApiError
>(userApi.changeEmail);

export const requestEmailConfirmationFx = createEffect<
  { mirror_url: string },
  any,
  ApiError
>(userApi.requestEmailConfirmation);

export const emailChanged = createEvent<string>();
export const emailSubmited = createEvent<void>();
export const emailConfirmed = createEvent<void>();
export const setEmailError = createEvent<string>();
export const resetForm = createEvent<void>();

export const $emailApiError = createStore<string>('')
  .on(updateEmailFx.failData, (_, errors) => createErrorObject(errors)['email'])
  .reset(resetForm)
  .reset(formUnmounted)
  .reset(emailChanged);

export const $newEmail = restore(emailChanged, '');
export const $emailError = restore(setEmailError, '').reset(resetForm);
export const $isFieldChanged = createStore(false).on(emailChanged, () => true);

export const $shownError = combine(
  $emailError,
  $emailApiError,
  (error, apiError) =>
    apiError ? i18n.t(`lk:profile.email.errors.${apiError}`) : error,
);

export const $isformDisabled = combine(
  $isFieldChanged,
  $emailError,
  updateEmailFx.pending,
  (isFieldChanged, error, isPending) =>
    !isFieldChanged || Boolean(error) || isPending,
);

export const $isConfirmSended = createStore(false)
  .on(updateEmailFx.doneData, () => true)
  .on(requestEmailConfirmationFx.doneData, () => true)
  .on(resetForm, () => false);

sample({
  source: emailChanged,
  target: setEmailError,
  fn: (value) => isValidEmail(value),
});

guard({
  clock: emailSubmited,
  source: combine({
    mirror_url: $callbackUrl,
    email: $newEmail,
  }),
  filter: $isformDisabled.map((is) => !is),
  target: updateEmailFx,
});

sample({
  clock: emailConfirmed,
  source: $callbackUrl,
  target: requestEmailConfirmationFx,
  fn: (url) => ({
    mirror_url: url,
  }),
});

forward({
  from: [userModel.$user],
  to: resetForm,
});

sample({
  source: guard(formUnmounted, {
    filter: $isConfirmSended.map((sended) => !sended),
  }),
  fn: () => '',
  target: $newEmail,
});
