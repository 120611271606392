import * as React from 'react';

import { useScript } from 'lib/hooks';

interface Props {
  scriptSrc: string;
  onBalanceChanged: (value: number) => void;
}

export const useGameMessenger = ({ scriptSrc, onBalanceChanged }: Props) => {
  const [loaded, error] = useScript(scriptSrc);

  // PLAYSON
  React.useEffect(() => {
    if (loaded && !error) {
      // @ts-ignore
      new GameMessenger({
        unloaded() {
          // @ts-ignore
          window.top.location = '/';
        },
        gameChangeCash(newBalance) {
          onBalanceChanged(newBalance);
        },
      });
    }
  }, [loaded]);
};
