import React from 'react';
import classNames from 'classnames';

import { ArticleInfo } from '../article';

import './style.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  numeric?: boolean;
  noContent?: boolean;
}

export const TermsWrap = ({
  numeric = true,
  children,
}: {
  numeric?: boolean;
  children: React.ReactNode;
}) => (
  <ArticleInfo className={classNames('terms__wrap', !numeric && 'no-numeric')}>
    {children}
  </ArticleInfo>
);

export const TermsList = ({ children, className }: Props) => (
  <ul className={classNames('terms__list', className)}>{children}</ul>
);

export const TermsSublist = ({
  children,
  className,
  numeric,
  noContent,
}: Props) => (
  <ol
    className={classNames(
      'terms__sub-list',
      numeric && 'numeric',
      noContent && 'no-content',
      className,
    )}
  >
    {children}
  </ol>
);

export const TermsText = ({ children, className }: Props) => {
  return (
    <li className={classNames('terms__text', className && className)}>
      {children}
    </li>
  );
};

export const TermsTitle = ({ children, className }: Props) => {
  return (
    <li className={classNames('terms__title', className && className)}>
      {children}
    </li>
  );
};
