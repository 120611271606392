import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useStore } from 'effector-react';

import { useDisclosure, useModal } from 'lib/hooks';
import { Button, ButtonLink, Counter, FlipBox, IconSprite } from 'ui';
import { EnumEventType } from 'lib/constants';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  BonusTitle,
  CancelBonusModal,
  GiftSpinsBonusTitle,
  NotActiveMessage,
  SubmitActivateBonusModal,
} from '../molecules';

import { WeeklyCashbackCardAvailable } from './weekly-cashback-card-available';

import * as model from '../model';
import { activeBonusModel } from '../bonus-active';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const BonusCardAvailable: React.FC<Props> = (props) => {
  if (props.bonus.event_type === EnumEventType.WEEKLY_CASHBACK) {
    return <WeeklyCashbackCardAvailable {...props} />;
  }

  const hasTwoRewards = model.bonusHasTwoRewards(props.bonus);

  if (hasTwoRewards) {
    return <TwoSidedCard {...props} />;
  }

  return <OneSidedCard {...props} />;
};

const TwoSidedCard: React.FC<Props> = (props) => {
  const cancelModal = useModal();
  const activateModal = useModal();

  const {
    isOpen: isBackSide,
    open: showBackSide,
    close: showFrontSide,
  } = useDisclosure();

  return (
    <>
      {activateModal.isVisible && (
        <SubmitActivateBonusModal
          bonus={props.bonus}
          onReject={activateModal.hide}
          onSubmit={activateModal.hide}
        />
      )}
      {cancelModal.isVisible && (
        <CancelBonusModal
          bonus={props.bonus}
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <FlipBox className="flip-card" isFlipped={isBackSide}>
        <FrontSide
          onCancel={cancelModal.show}
          onActivate={activateModal.show}
          onFlipButtonClick={showBackSide}
          {...props}
        />
        <BackSide
          onActivate={activateModal.show}
          onFlipButtonClick={showFrontSide}
          {...props}
        />
      </FlipBox>
    </>
  );
};

interface DoubleSidedCardProps extends Props {
  onFlipButtonClick: () => void;
  onCancel?: () => void;
  onActivate?: () => void;
}

const FrontSide: React.FC<DoubleSidedCardProps> = ({
  bonus,
  className,
  idx,
  onFlipButtonClick,
  onCancel,
  onActivate,
}) => {
  const { t } = useTranslation();

  const { expired_at, activate_before, reward_giftspins } = bonus;

  const activeBonus = useStore(activeBonusModel.$activeBonus);

  const hasActiveBonus = !!activeBonus;

  return (
    <BonusCardWrap
      iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
      idx={idx}
      className={className}
    >
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <div className="bonus-card__title plus">+</div>
        <GiftSpinsBonusTitle reward_giftspins={reward_giftspins as GiftSpin} />
        <BonusSubtitle>{t('bonus:freespins')}</BonusSubtitle>
        <div
          className={classNames(
            'description-wrap',
            hasActiveBonus && 'has-active',
          )}
        >
          {bonus.wager && (
            <BonusDescription.Item
              className="front-side-wager"
              description={t('bonus:info.wager')}
              text={`x${bonus.wager}`}
            />
          )}
          {(expired_at || activate_before) && (
            <Counter date={expired_at || activate_before} />
          )}
        </div>
        <div className="bonus-card__nav">
          {hasActiveBonus ? (
            <NotActiveMessage />
          ) : (
            <div className="front-btns">
              <Button onClick={onCancel} theme="tertiary">
                <IconSprite name="delete-icon" />
              </Button>
              <Button onClick={onActivate}>
                {t('common:buttons.activate')}
              </Button>
            </div>
          )}
          <ButtonLink onClick={onFlipButtonClick} theme="secondary">
            {t('common:detailed')}
          </ButtonLink>
        </div>
      </div>
    </BonusCardWrap>
  );
};

const BackSide: React.FC<DoubleSidedCardProps> = ({
  bonus,
  className,
  idx = 1,
  onFlipButtonClick,
  onActivate,
}) => {
  const { t } = useTranslation();

  const activeBonus = useStore(activeBonusModel.$activeBonus);

  const hasActiveBonus = !!activeBonus;

  return (
    <BonusCardWrap
      iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
      idx={idx + 2}
      className={classNames(className, 'back-side')}
    >
      <div
        className={classNames(
          'bonus-card__info',
          hasActiveBonus && 'has-active',
        )}
      >
        <BonusSubtitle withReward bonus={bonus} />
        <BonusDescription.MainInfo withGiftspinsInfo={false} bonus={bonus} />
        <BonusSubtitle
          className="second"
          showRewards={{ sum: false, percent: false, giftspins: true }}
          withEvent={false}
          withReward
          bonus={bonus}
        />
        <BonusDescription.GiftSpinInfo
          giftspins={bonus.reward_giftspins as GiftSpin}
        />
        {hasActiveBonus ? (
          <NotActiveMessage />
        ) : (
          <Button onClick={onActivate}>{t('common:buttons.activate')}</Button>
        )}
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:buttons.back')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const OneSidedCard: React.FC<Props> = ({ bonus, className, idx }) => {
  const { t } = useTranslation();

  const cancelModal = useModal();
  const activateModal = useModal();

  const activeBonus = useStore(activeBonusModel.$activeBonus);

  const { activate_before, expired_at, event_type } = bonus;
  const hasActiveBonus = !!activeBonus;

  return (
    <>
      {activateModal.isVisible && (
        <SubmitActivateBonusModal
          bonus={bonus}
          onReject={activateModal.hide}
          onSubmit={activateModal.hide}
        />
      )}
      {cancelModal.isVisible && (
        <CancelBonusModal
          bonus={bonus}
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <BonusCardWrap
        iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
        className={classNames(
          className,
          event_type === 'TASK_ACCOMPLISHED' && 'welcome-bonus',
          model.isBirthdayBonus(bonus) && 'birthday',
          'available',
        )}
        idx={idx}
      >
        <div className="bonus-card__info">
          <BonusTitle
            bonus={bonus}
            color={bonus.event_type === 'WEEKLY_CASHBACK' ? 'blue' : ''}
          />
          <BonusSubtitle bonus={bonus} withEvent />
          <div
            className={classNames(
              'description-wrap',
              hasActiveBonus && 'has-active',
            )}
          >
            {(expired_at || activate_before) && (
              <Counter date={expired_at || activate_before} />
            )}
            <BonusDescription.MainInfo bonus={bonus} />
          </div>
        </div>
        <div className="bonus-card__nav">
          {hasActiveBonus ? (
            <NotActiveMessage />
          ) : (
            <>
              <Button onClick={activateModal.show}>
                {t('common:buttons.activate')}
              </Button>
              <span
                className="player-bonus-card__cancel"
                onClick={cancelModal.show}
              >
                {t('common:buttons.cancel')}
              </span>
            </>
          )}
        </div>
      </BonusCardWrap>
    </>
  );
};
