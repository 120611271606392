import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconSprite } from 'ui';
import { authFormModel } from 'features/auth';

export const HeaderLoginButton = () => {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => authFormModel.loginFormOpened()}
      className="header__log-out"
    >
      <IconSprite name="log-in-icon" />
      <span className="header__log-in">{t('common:buttons.signin')}</span>
    </button>
  );
};
