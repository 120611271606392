import React from 'react';
import { IconSprite } from 'ui';

export const SvgDecorators = () => (
  <>
    <IconSprite
      spriteName="common"
      name="decor1-icon"
      className="decor-abs bottom"
    />
    <IconSprite
      spriteName="common"
      name="decor2-icon"
      className="decor-abs top"
    />
    <IconSprite
      spriteName="common"
      name="decor3-icon"
      className="decor-abs right"
    />
  </>
);
