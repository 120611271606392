import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  forward,
  attach,
  sample,
  guard,
} from 'effector';
import { condition, status } from 'patronum';

import { cashBoxApi } from 'api/cashbox';
import { cashboxModel } from 'features/cashbox';
import { sessionModel } from 'features/session';
import { languageModel } from 'features/i18';
import { currenciesModel } from 'features/currencies';

const PAGE_SCOPE_NAME = 'paymentsPage';

export const pageLoaded = createEvent<void>();
export const pageUnLoaded = createEvent<void>();

export const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .reset(pageUnLoaded);

const loadGuestPSGroups = createEvent<void>();
const loadUserPSGroups = createEvent<void>();

const loadResourceInCurrentPageFx = attach({
  effect: languageModel.getResourceByScopeFx,
  mapParams: (_) => PAGE_SCOPE_NAME,
});

export const $pageResource = createStore<Record<string, string>>({});

const loadGuestWithdrawPSFx = createEffect<void, PaymentGroup[], ApiError>(
  cashBoxApi.getWithdrawalsPSForGuest,
);

const loadGuestDepositPSFx = createEffect<void, PaymentGroup[], ApiError>(
  cashBoxApi.getDepositsPSForGuest,
);

export const $guestDepositPaymentSystemsGroups = restore(
  loadGuestDepositPSFx.doneData,
  [],
);
export const $guestWithdrawPaymentSystemsGroups = restore(
  loadGuestWithdrawPSFx.doneData,
  [],
);

export const $withdrawSystemsIsLoaded = combine(
  status({ effect: loadGuestWithdrawPSFx }),
  status({ effect: cashboxModel.getWithdrawPaymentSystemsFx }),
  sessionModel.$isAuthenticated,
  (guestStatus, userStatus, isAuth) => {
    const currentStatus = isAuth ? userStatus : guestStatus;
    return currentStatus === 'done' || currentStatus === 'fail';
  },
);

export const $depositSystemsIsLoaded = combine(
  status({ effect: loadGuestDepositPSFx }),
  status({ effect: cashboxModel.getDepositPaymentGroupsFx }),
  sessionModel.$isAuthenticated,
  (guestStatus, userStatus, isAuth) => {
    const currentStatus = isAuth ? userStatus : guestStatus;
    return currentStatus === 'done' || currentStatus === 'fail';
  },
);

const $depositPaymentSystemsGroups = combine(
  sessionModel.$isAuthenticated,
  $guestDepositPaymentSystemsGroups,
  cashboxModel.$depositPaymentGroups,
  (isAuth, guestPSGroups, userPSGroups) =>
    isAuth ? userPSGroups : guestPSGroups,
);

const $withdrawPaymentSystemsGroups = combine(
  sessionModel.$isAuthenticated,
  $guestWithdrawPaymentSystemsGroups,
  cashboxModel.$withdrawPaymentGroups,
  (isAuth, guestPSGroups, userPSGroups) =>
    isAuth ? userPSGroups : guestPSGroups,
);

export const $currentCurrencyPaymentSystems = combine(
  $depositPaymentSystemsGroups,
  $withdrawPaymentSystemsGroups,
  (depositPS, withdrawPS) => {
    return {
      withdrawPS: getPayments(withdrawPS),
      depositPS: getPayments(depositPS),
    };
  },
);

forward({
  from: pageLoaded,
  to: [loadResourceInCurrentPageFx, currenciesModel.getCurrencies],
});

guard({
  clock: guard(languageModel.$language, { filter: Boolean }),
  filter: $pageIsLoaded,
  target: loadResourceInCurrentPageFx,
});

condition({
  source: pageLoaded,
  if: sessionModel.$isAuthenticated,
  then: loadUserPSGroups,
  else: loadGuestPSGroups,
});

condition({
  source: guard(sessionModel.$isAuthenticated, { filter: $pageIsLoaded }),
  if: Boolean,
  then: loadUserPSGroups,
  else: loadGuestPSGroups,
});

sample({
  clock: loadResourceInCurrentPageFx.doneData,
  target: $pageResource,
});

forward({
  from: loadGuestPSGroups,
  to: [loadGuestWithdrawPSFx, loadGuestDepositPSFx],
});

forward({
  from: loadUserPSGroups,
  to: [
    cashboxModel.getDepositPaymentGroupsFx,
    cashboxModel.getWithdrawPaymentSystemsFx,
  ],
});

function getPayments(group: PaymentGroup[]) {
  return group?.reduce<PaymentSystemItem[]>((systems, group) => {
    group.ps.forEach((system) => systems.push(system));
    return systems;
  }, []);
}
