import {
  createEffect,
  createEvent,
  restore,
  forward,
  createStore,
  guard,
  attach,
  sample,
} from 'effector';
import connectLocalStorage from 'effector-localstorage/sync';

import { userApi } from 'api/user';
import { RegTypes } from 'lib/constants';
import { reshape } from 'patronum';

const DEFAULT_BALANCE_STATE = {
  amount: 0,
  bonus_amount: 0,
  real_amount: 0,
  fun_amount: 0,
};

const USER_BALANCE_LS_KEY = 'USER_BALANCE_LS_KEY';

export const resetUser = createEvent<void>();

export const setEmailIsConfirmed = createEvent();

export const setUser = createEvent<User | null>();

export const loadUserFx = createEffect<void, User, Error>(userApi.getProfile);
export const loadUserSummaryFx = createEffect<void, UserSummary, Error>(
  userApi.getUserSummary,
);

export const $user = restore(setUser, null)
  .on(setEmailIsConfirmed, (user) =>
    user ? { ...user, email_confirmed: true } : null,
  )
  .reset(resetUser);

export const $userSummary = restore(loadUserSummaryFx.doneData, null).reset(
  resetUser,
);

export const {
  $currency,
  $email,
  $phone,
  $isEmailConfirmed,
  $isPhoneConfirmed,
  $regType,
  $userIsLoaded,
  $userAccountIsConfirmed,
  $userLanguage,
} = reshape({
  source: $user,
  shape: {
    $currency: (user) => user?.currency || null,
    $email: (user) => user?.email || null,
    $phone: (user) => user?.phone || null,
    $isEmailConfirmed: (user) => user?.email_confirmed || false,
    $isPhoneConfirmed: (user) => user?.phone_confirmed || false,
    $regType: (user) => user?.registration_type || null,
    $userIsLoaded: (user) => !!user,
    $userLanguage: (user) => user?.language || null,
    $userAccountIsConfirmed: (user) => {
      if (!user) {
        return false;
      }

      if (
        user.registration_type === RegTypes.EMAIL ||
        user.registration_type === RegTypes.SOCIAL
      ) {
        return user.email_confirmed;
      }

      if (user.registration_type === RegTypes.PHONE) {
        return user.phone_confirmed;
      }

      return false;
    },
  },
});

export const { $userTotalCashbacks } = reshape({
  source: $userSummary,
  shape: {
    $userTotalCashbacks: (summary) => summary?.total_cashbacks || 0,
  },
});

forward({
  from: loadUserFx.doneData,
  to: setUser,
});

/**
 * Баланс пользователя
 */
export const loadUserBalanceFx = createEffect<void, Balance, ApiError>(
  userApi.getBalance,
);

export const $balanceIsLoaded = createStore(false);

export const setRealAmount = createEvent<number>();
export const setFunAmount = createEvent<number>();

export const setBalance = createEvent<Balance>();

const balanceLocalStorage = connectLocalStorage(USER_BALANCE_LS_KEY)
  .onError((err) => console.log(err))
  .onChange(setBalance);

export const $balance = createStore<Balance>(
  balanceLocalStorage.init(DEFAULT_BALANCE_STATE),
);

$balance.watch(balanceLocalStorage);

sample({
  source: $userSummary,
  target: setBalance,
  fn: (user) => {
    return user ? { ...user.account } : DEFAULT_BALANCE_STATE;
  },
});

export const $isBalancePositive = $balance.map(
  ({ real_amount, bonus_amount }) => real_amount + bonus_amount > 0,
);

sample({
  clock: [loadUserBalanceFx.doneData, loadUserFx.doneData],
  target: $balanceIsLoaded,
  fn: () => true,
});

const userLoggedOut = guard($user, { filter: (user) => !user });

sample({
  source: userLoggedOut,
  target: $balanceIsLoaded,
  fn: () => false,
});

userLoggedOut.watch(() =>
  localStorage.setItem(
    USER_BALANCE_LS_KEY,
    JSON.stringify(DEFAULT_BALANCE_STATE),
  ),
);

$balance.on(setRealAmount, (state, amount) => ({
  ...state,
  real_amount: amount,
}));

$balance.on(setFunAmount, (state, amount) => ({
  ...state,
  fun_amount: amount,
}));

$balance.on(setBalance, (_, newBalance) => newBalance);

$balance.on(
  loadUserBalanceFx.doneData,
  (_, { real_amount, amount, fun_amount, bonus_amount }) => ({
    real_amount,
    amount,
    fun_amount,
    bonus_amount,
  }),
);

/**
 * Обновление баланса пользователя
 */
const FREQUENCY_DEFAULT = 5000;

export const createBalancePolling = ({ frequency = FREQUENCY_DEFAULT }) => {
  const $frequency = createStore(frequency);

  const startTick = createEvent<void>();

  const startPolling = createEvent<void>();
  const stopPolling = createEvent<void>();

  const $canPolling = createStore(false)
    .on(startPolling, () => true)
    .on(stopPolling, () => false);

  const tickFx = attach({
    source: $frequency,
    mapParams: (_, frequency) => frequency,
    effect: createEffect(
      (frequency) => new Promise((resolve) => setTimeout(resolve, frequency)),
    ),
  });

  forward({ from: startPolling, to: startTick });

  guard({
    clock: startTick,
    filter: tickFx.pending.map((pending) => !pending),
    target: tickFx,
  });

  guard({
    source: tickFx.done,
    filter: $canPolling,
    target: startTick,
  });

  forward({
    from: tickFx.done,
    to: loadUserBalanceFx,
  });

  return {
    startTick,
    startPolling,
    stopPolling,
    tickFx,
  };
};
