import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Container, IconSprite } from 'ui';
import { paths } from 'pages/paths';
import { mediaHooks } from 'lib/media';
import { languageModel } from 'features/i18';
import { skinModel } from 'features/skin-id';

import './style.css';

export const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isMobile = mediaHooks.useIsLargeMobile();
  const skinId = skinModel.selectors.useSkinId();

  const needToHideFooter =
    pathname.includes('/game/') ||
    pathname === paths.successfulPayment() ||
    pathname === paths.failedPayment();

  if (needToHideFooter) {
    return null;
  }

  return (
    <footer className="footer">
      <img className="bg-img" src={`/img/${skinId}/footer-bg.svg`} alt="" />
      <Container padding>
        <ul className="partners">
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="ezugi-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="novomatic-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="egt-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="igrosoft-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="amatic-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="arist-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="netent-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="microgaming-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="apollo-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="igt-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="merkur-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="wazdan-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="endorphina-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="playtech-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="quickspin-icon" />
            </Link>
          </li>
          <li className="partners__item">
            <Link className="partners__link" to="/">
              <IconSprite name="greentube-icon" />
            </Link>
          </li>
        </ul>
        <nav className="footer-nav">
          <ul className="footer-nav__list">
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.home()}>
                {t('footer:nav.main')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.about()}>
                {t('footer:nav.aboutUs')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.contacts()}>
                {t('footer:nav.contactUs')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.faq()}>
                {t('footer:nav.FAQ')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.bonusConditions()}>
                {t('footer:nav.bonusRules')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.terms()}>
                {t('footer:nav.terms')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.gameRules()}>
                {t('footer:nav.gameRules')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link
                className="footer-nav__link"
                to={paths.cancellationPolicy()}
              >
                {t('footer:nav.cancellationPolicy')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.privacyPolicy()}>
                {t('footer:nav.privacy')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.moneyLaundering()}>
                {t('footer:nav.moneyLaundering')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.cookiesPolicy()}>
                {t('footer:nav.cookies')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to={paths.riskNotice()}>
                {t('footer:nav.riskNotice')}
              </Link>
            </li>
          </ul>
        </nav>
        <ul className="method">
          <li className="method__item">
            <IconSprite name="visa-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="mastercard-icon" />
          </li>
          {isMobile && <ValidatorLink />}
          <li className="method__item">
            <IconSprite name="qiwi-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="yandex-pay" />
          </li>
          <li className="method__item">
            <IconSprite name="bitcoin-icon" />
          </li>
          {!isMobile && <ValidatorLink />}
          <li className="method__item">
            <IconSprite name="alfa-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="beeline-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="mts-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="megafon-icon" />
          </li>
          <li className="method__item">
            <IconSprite name="tele-icon" />
          </li>
        </ul>
        <FooterText />
        <div className="footer__contacts-wrap">
          <div className="footer__contacts">
            <div className="contact-links">
              {/*<a className="contact-link" href="tel:+78122009512">*/}
              {/*  <IconSprite name="tel-icon" />*/}
              {/*  <span>+7 812 200 95 12</span>*/}
              {/*</a>*/}
              <EmailLink />
            </div>
            {/*<ul className="soc-wrap">*/}
            {/*  <li>*/}
            {/*    <Link to="/">*/}
            {/*      <IconSprite name="google-icon" />*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <Link to="/">*/}
            {/*      <IconSprite name="vk-icon" />*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/* <li>
                <Link to="/">
                  <IconSprite name="fb-icon" />
                </Link>
              </li> */}
            {/* <li>
                <Link to="/">
                  <IconSprite name="twitter-icon" />
                </Link>
              </li> */}
            {/*  <li>*/}
            {/*    <Link to="/">*/}
            {/*      <IconSprite name="odnoklasniki-icon" />*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
          <ContactsText />
          <CopyrightText />
          <div className="footer__copyright-icons-wrap">
            <IconSprite className="old-icon" name="old-icon" />
            <IconSprite className="gamcare-icon" name="gamcare-icon" />
            <IconSprite className="aware-icon" name="aware-icon" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

const EmailLink = () => {
  const email = useStore(languageModel.resourses.contactEmail);

  return (
    <a className="contact-link" href={`mailto:${email}`}>
      <IconSprite name="email-icon" />
      <span>{email}</span>
    </a>
  );
};

const FooterText = () => {
  const { mainText } = useStore(languageModel.resourses.footerResource);

  return <p className="footer__text">{mainText}</p>;
};

const ContactsText = () => {
  const { siteOperatedText, licenceText } = useStore(
    languageModel.resourses.footerResource,
  );

  return (
    <>
      <p className="footer__contacts-text">{siteOperatedText}</p>
      <p className="footer__contacts-text">{licenceText}</p>
    </>
  );
};

const CopyrightText = () => {
  const { copyrightText } = useStore(languageModel.resourses.footerResource);

  return (
    <div className="footer__copyright-wrap">
      <p className="footer__contacts-text">{copyrightText}</p>
    </div>
  );
};

const ValidatorLink = () => {
  const sealId = process.env.REACT_APP_VALIDATOR_SEAL_ID;
  const stamp = process.env.REACT_APP_VALIDATOR_STAMP;
  const domain = window.location.host;

  const link = `https://validator.antilliphone.com/validate?domain=${domain}&seal_id=${sealId}&stamp=${stamp}`;

  return (
    <li className="method__item validator-link">
      <a href={link} target="_blank" rel="noreferrer">
        <img
          src="/img/validator-link.png"
          className="method__pay-icon"
          alt=""
        />
      </a>
    </li>
  );
};
