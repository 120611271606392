import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { UserInfoForm } from './ui';
import { UserDocumentForm } from 'features/documents';
import * as model from './model';
import { userModel } from 'features/user';

import { UserContactsForm } from './contact-form';
import { UserPasswordForm } from './change-password';

import './style.css';

export const ProfilePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const user = useStore(userModel.$user);

  if (!user) {
    return null;
  }

  return (
    <>
      <span className="acc-content__id">ID {user.id}</span>
      <UserContactsForm />
      <UserInfoForm />
      <UserPasswordForm />
      <UserDocumentForm />
    </>
  );
};
