import React from 'react';
import classNames from 'classnames';
import './style.css';

export const Container = ({
  children,
  small,
  padding = true,
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        'container',
        small && 'container_small',
        padding && 'container_padding',
        className,
      )}
    >
      {children}
    </div>
  );
};

interface Props {
  children: React.ReactElement[] | React.ReactElement | React.ReactNode;
  small?: boolean;
  padding?: boolean;
  className?: string;
}
