import * as React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Input } from 'ui';
import { useDebouncedValue } from 'lib/hooks';

import * as model from '../model';

const UPDATE_INTERVAL = 500;

export const Search = () => {
  const [isFirstValue, setIsFirstValue] = React.useState(true);
  const { search } = useLocation();
  const { t } = useTranslation();

  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useRef(null);

  const [debouncedSearchString, setDebouncedSearchString] = useDebouncedValue(
    searchString,
    UPDATE_INTERVAL,
  );

  React.useEffect(() => {
    if (!search) {
      model.searchCleared();
      setSearchString('');
    }
  }, [search]);

  React.useEffect(() => {
    const initialQuery = new URLSearchParams(search).get('search') || '';

    if (initialQuery !== '') {
      setSearchString(initialQuery);
    }

    return () => model.searchCleared();
  }, []);

  React.useEffect(() => {
    if (!isFirstValue) {
      model.searchTriggered(debouncedSearchString);
    } else {
      setIsFirstValue(false);
    }
  }, [debouncedSearchString]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchString(value);
  };

  return (
    <Input
      type="search"
      className="search-form__input"
      inputRef={inputRef}
      value={searchString}
      onChange={handleChange}
      placeholder={t('common:menu.search')}
      autoComplete="off"
    />
  );
};
