import { createEvent, createStore, guard, sample } from 'effector';

import { Halls } from 'lib/constants';
import { loadAllGames, loadGamesByHall } from 'features/halls';
import { sessionModel } from 'features/session';

export const pageLoaded = createEvent<string>();
export const pageUnloaded = createEvent();
export const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .reset(pageUnloaded);

sample({
  source: pageLoaded,
  clock: sessionModel.$isAuthenticated,
  target: pageLoaded,
});

guard({
  source: pageLoaded,
  filter: (hallName) => hallName !== Halls.All && hallName !== Halls.Favorite,
  target: loadGamesByHall,
});

guard({
  source: pageLoaded,
  filter: (hallName) => hallName === Halls.All,
  target: loadAllGames,
});
