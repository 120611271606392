import React from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconSprite } from 'ui';
import { useFormaters } from 'features/i18';
import { userModel } from '../../model';
import './style.css';

export const UserBalance = React.memo(
  ({ header = false }: { header?: boolean }) => {
    const { t } = useTranslation();
    const { bonus_amount, real_amount } = useStore(userModel.$balance);

    return (
      <>
        <BalanceItem
          count={real_amount}
          icon="red"
          main
          title={t(`common:balance.${header ? 'main' : 'mainBalance'}`)}
        />
        <BalanceItem
          count={bonus_amount}
          title={t(`common:balance.${header ? 'bonus' : 'bonusBalance'}`)}
        />
      </>
    );
  },
);

type IconColor = 'white' | 'red' | 'blue';

interface Props {
  count: number;
  icon?: IconColor;
  title: string;
  bold?: boolean;
  commission?: string;
  main?: boolean;
}

export const BalanceItem = ({
  count,
  commission,
  main,
  bold,
  title,
  icon = 'blue',
}: Props) => {
  const { currencyNumber } = useFormaters();

  return (
    <div className="balance">
      <div className="balance__wrap">
        <span className={classNames(bold ? 'balance__info' : 'balance__title')}>
          {title}
        </span>
        <div className="balance__count-wrap">
          <div className="balance__img">
            <IconSprite spriteName="common" name={`balance-${icon}-icon`} />
          </div>
          <span
            className={classNames(
              main ? 'balance__count balance__count_main' : 'balance__count',
            )}
          >
            {currencyNumber(count, { minimumFractionDigits: 0 })}
          </span>
        </div>
        {commission && (
          <span className="balance__commission">{commission}</span>
        )}
      </div>
    </div>
  );
};
