import * as React from 'react';
import { useStore } from 'effector-react';

import { DeviceTypes } from 'lib/constants';
import { useMedia } from 'lib/media';
import { CommonLoader } from 'features/common';
import { deviceModel } from 'features/device';
import { FormatersComp } from 'features/i18';
import { Popup } from 'features/popup';
import { SearchMobile } from 'features/search';
import { Pages } from 'pages';
import { SkinProvider } from './features/skin-id';
import './global-styles.css';
import { Loader } from 'features/loader';
import { Metrics } from 'features/metrics';
import { EmailModal } from './features/user';

export const App = () => {
  const isDeviceSettled = useStore(deviceModel.$isTriggered);

  const media: DeviceType = useMedia(
    ['(max-width: 768px)'],
    [DeviceTypes.Mobile],
    DeviceTypes.Desktop,
  );

  React.useEffect(() => {
    deviceModel.setDeviceType(media);
  }, [media]);

  return isDeviceSettled ? (
    <SkinProvider>
      <FormatersComp>
        <CommonLoader>
          <Metrics />
          <Loader />
          <Popup />
          <SearchMobile />
          <EmailModal />
          <Pages />
        </CommonLoader>
      </FormatersComp>
    </SkinProvider>
  ) : null;
};
