import { paths } from './paths';
import { Error404Page } from './internal-pages/error404';
import { LotteryPage } from './lotteries/lottery';
import { LotteriesPage } from './lotteries/lotteries';
import { AboutPage } from './about';
import { FAQPage } from './faq';
import { PaymentsPage } from './payments';
import { TermsPage } from './terms';
import { ChangePasswordPage } from './change-password';
import { TournamentsPage } from './tournaments/tournaments';
import { TournamentPage } from './tournaments/tournament';
import { GamePage } from './game/page';
import { PresentsPage } from './presents';
import { BonusConditionsPage } from './bonus-conditions';
import { MoneyLaunderingPage } from './money-laundering';
import { CookiesPolicyPage } from './cookies-policy';
import { SimpleRoute } from 'lib/routing';
import { sessionModel } from 'features/session';
import { JackpotPage } from './jackpot';
import { LkPage } from './lk';
import { MainPage } from './main';
import { CancellationPolicyPage } from './cancellation-policy';
import { PrivacyPolicyPage } from './privacy-police';
import { GamesRulesPage } from './game-rules';
import { LoyaltyProgramPage } from './loyalty';
import { ContactsPage } from './contacts';
import { WheelPage } from './wheel';
import { ConfirmNewPasswordPage } from './confirm-new-password';
import { PromotionPage } from './promotions/promotion';
import { PromotionsPage } from './promotions/promotions';
import { SuccessfulPaymentPage } from './successful-payment/page';
import { FailedPaymentPage } from './failed-payment/page';
import { DepositProccessingPage } from './internal-pages/deposit-processing/page';
import { RiskNoticePage } from './risk-notice';

type RoutesProps<T> = {
  [key: string]: SimpleRoute<T>;
};

export const routes: RoutesProps<any> = {
  about: {
    path: paths.about(),
    exact: true,
    name: 'O лотерее',
    component: AboutPage,
    guards: [],
  },
  wheel: {
    path: paths.wheelGame(),
    exact: true,
    name: 'Колесо фортуны',
    component: WheelPage,
    guards: [sessionModel.onlyUsers()],
  },
  promotion: {
    path: paths.promotion(':id'),
    exact: true,
    name: 'Акции',
    component: PromotionPage,
    guards: [],
  },
  bonusConditions: {
    path: paths.bonusConditions(),
    exact: true,
    name: 'Бонусные условия',
    component: BonusConditionsPage,
    guards: [],
  },
  confirmNewPassword: {
    path: paths.confirmNewPassword(),
    exact: true,
    name: 'Подтверждение нового пароля',
    component: ConfirmNewPasswordPage,
    guards: [],
  },
  changePassword: {
    path: paths.changePassword(),
    exact: true,
    name: 'Сменить пароль',
    component: ChangePasswordPage,
    guards: [sessionModel.onlyAnon()],
  },
  cancellationPolicy: {
    path: paths.cancellationPolicy(),
    exact: true,
    name: 'cancellationPolicy',
    component: CancellationPolicyPage,
    guards: [],
  },
  contacts: {
    path: paths.contacts(),
    exact: true,
    name: 'Контакты',
    component: ContactsPage,
    guards: [],
  },
  cookiePolicy: {
    path: paths.cookiesPolicy(),
    exact: true,
    name: 'Политика использования файлов cookie',
    component: CookiesPolicyPage,
    guards: [],
  },
  faq: {
    path: paths.faq(),
    exact: true,
    name: 'FAQ',
    component: FAQPage,
    guards: [],
  },
  game: {
    path: paths.game(':mode', ':provider', ':code'),
    exact: true,
    name: 'Game',
    component: GamePage,
    guards: [],
  },
  gameRules: {
    path: paths.gameRules(),
    exact: true,
    name: 'Game rules',
    component: GamesRulesPage,
    guards: [],
  },
  jackpot: {
    path: paths.jackpot(':id'),
    exact: true,
    name: 'Game',
    component: JackpotPage,
    guards: [],
  },
  moneyLaundering: {
    path: paths.moneyLaundering(),
    exact: true,
    name: 'Game',
    component: MoneyLaunderingPage,
    guards: [],
  },
  riskNotice: {
    path: paths.riskNotice(),
    exact: true,
    name: 'Risk notice',
    component: RiskNoticePage,
    guards: [],
  },
  payments: {
    path: paths.payments(),
    exact: true,
    name: 'Платежи',
    component: PaymentsPage,
    guards: [],
  },
  successfulPayment: {
    path: paths.successfulPayment(),
    exact: true,
    name: 'Платеж выполнен',
    component: SuccessfulPaymentPage,
    guards: [sessionModel.onlyUsers()],
  },
  failedPayment: {
    path: paths.failedPayment(),
    exact: true,
    name: 'Платеж не выполнен',
    component: FailedPaymentPage,
    guards: [sessionModel.onlyUsers()],
  },
  loyaltyProgram: {
    path: paths.loyaltyProgram(),
    exact: true,
    name: 'Программа лояльности',
    component: LoyaltyProgramPage,
    guards: [],
  },
  presents: {
    path: paths.presents(),
    exact: true,
    name: 'Подарки',
    component: PresentsPage,
    guards: [],
  },
  privacyPolice: {
    path: paths.privacyPolicy(),
    exact: true,
    name: 'Подарки',
    component: PrivacyPolicyPage,
    guards: [],
  },
  lottery: {
    path: paths.lottery(':id'),
    exact: true,
    name: 'Лотерея',
    component: LotteryPage,
    guards: [],
  },
  lotteries: {
    path: paths.lotteries(),
    exact: true,
    name: 'Лотереи',
    component: LotteriesPage,
    guards: [],
  },
  promotions: {
    path: paths.promotions(),
    exact: true,
    name: 'Акции',
    component: PromotionsPage,
    guards: [],
  },
  terms: {
    path: paths.terms(),
    exact: true,
    name: 'Правила и условии',
    component: TermsPage,
    guards: [],
  },
  tournaments: {
    path: paths.tournaments(),
    exact: true,
    name: 'Турниры',
    component: TournamentsPage,
    guards: [],
  },
  tournament: {
    path: paths.tournament(':id'),
    exact: true,
    name: 'Турнир',
    component: TournamentPage,
    guards: [],
  },
  main: {
    // @ts-ignore
    path: [paths.category(':category'), paths.home()],
    exact: true,
    name: 'Главная category',
    component: MainPage,
    guards: [],
  },
  lk: {
    path: paths.lk(),
    name: 'Личный кабинет',
    component: LkPage,
    guards: [sessionModel.onlyUsers()],
  },
  depositProccessing: {
    path: paths.depositProccessing(),
    name: 'Проведение депозита',
    component: DepositProccessingPage,
    guards: [sessionModel.onlyUsers()],
  },
  errorPage: {
    path: '*',
    name: '404',
    component: Error404Page,
    guards: [],
  },
};
