import * as React from 'react';
import { useStore } from 'effector-react';

import { mediaHooks } from 'lib/media';
import { Container, Section, SvgDecorators } from 'ui';
import { DailyWinnersList } from 'features/winners';
import { JackpotArticle } from './jackpot-article';

import * as model from '../model';

export const JackpotSection = () => {
  const isLargeMobile = mediaHooks.useIsLargeMobile();

  const jackpotData = useStore(model.$mainJackpotData);
  const error = useStore(model.$error);
  const status = useStore(model.$status);

  return error || (status !== 'pending' && jackpotData === null) ? null : (
    <Section type="game">
      <Container padding={false}>
        <div className="jackpot__inner">
          <JackpotArticle
            list
            jackpot={jackpotData}
            isLoading={status === 'pending'}
            withButton
          />
          {!isLargeMobile && (
            <div className="lottery lottery_game">
              <SvgDecorators />
              <DailyWinnersList />
            </div>
          )}
        </div>
      </Container>
    </Section>
  );
};
