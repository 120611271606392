import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import DOMPurify from 'dompurify';

import { Article, Container, Section, SectionTitle, TermsWrap } from 'ui';

import './style.css';

import * as model from './model';

export const GamesRulesPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const { text } = useStore(model.$pageResource);

  const { t } = useTranslation();

  return (
    <Section type="info">
      <Container small padding={false}>
        <SectionTitle>{t('game-rules:title')}</SectionTitle>
        <Article violet className="game-rules-article">
          <TermsWrap numeric={false}>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          </TermsWrap>
        </Article>
      </Container>
    </Section>
  );
};
