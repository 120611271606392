import { BASE_URI } from 'lib/constants';

export type Token = {
  access_token: string;
  refresh_token: string;
};

export class TokenStorage {
  public static readonly LOCAL_STORAGE_TOKEN = 'token';
  public static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  public static getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      return fetch(`${BASE_URI}login/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login_info: {
            after_registration: false,
            fp: '',
            mirror_url: '',
            useragent: window.navigator.userAgent,
          },
          token: this.getRefreshToken(),
        }),
      })
        .then((response) => response.json())
        .then((response: any) => {
          this.storeToken(response.data.access_token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.access_token);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken,
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  public static getBarear(): string | null {
    return `Bearer ${TokenStorage.getToken()}`;
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }
}
