import React, { useRef } from 'react';
import classNames from 'classnames';

interface PaginationProps {
  total: number;
  current: number;
  onChange: (page: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  defaultPageSize?: number;
  isLast: boolean;
  isFirst: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  total,
  current,
  onChange,
  onNextPage,
  isLast,
  isFirst,
  onPrevPage,
  defaultPageSize = 5,
}) => {
  const handleNextPage = () => {
    if (!isLast) {
      fixJumping();
      onNextPage();
    }
  };

  const handlePrevPage = () => {
    if (!isFirst) {
      onPrevPage();
      fixJumping();
    }
  };

  const ref = useRef<HTMLUListElement>(null);

  const handleChange = (e: React.MouseEvent, page: number) => {
    fixJumping();
    onChange(page);
  };

  function fixJumping() {
    const initialPos = ref.current?.offsetTop;
    setImmediate(() => {
      const pos = ref.current?.offsetTop;
      if (initialPos && pos) {
        const difference = pos - initialPos;
        difference < 0 && window.scrollBy(0, difference);
      }
    });
  }

  const pages = createPaginationArray(total, current, defaultPageSize);

  return (
    <ul ref={ref} className="pagination">
      <li className="pagination__item">
        <span onClick={handlePrevPage} className="pagination__link">
          &lt;&lt;
        </span>
      </li>
      {pages.map((page) => {
        return (
          <li key={page} className="pagination__item">
            <span
              onClick={(e) => handleChange(e, page)}
              className={classNames(
                'pagination__link',
                page == current && 'pagination__link_active',
              )}
            >
              {page}
            </span>
          </li>
        );
      })}
      <li className="pagination__item">
        <span onClick={handleNextPage} className="pagination__link">
          &gt;&gt;
        </span>
      </li>
    </ul>
  );
};

const createPaginationArray = (
  totalPages: number,
  currentPage: number,
  pageSize: number,
): number[] => {
  const pages: number[] = Array.from({ length: totalPages }, (v, k) => k + 1);
  let from = currentPage - 3;
  if (totalPages - from < pageSize) {
    let difference = pageSize + from - totalPages;
    from = from - difference;
  }
  if (from < 0) from = 0;
  return pages.splice(from, pageSize);
};
