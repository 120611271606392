import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';

import { useFormaters } from 'features/i18';
import { userModel } from 'features/user';
import { IconSprite } from 'ui';
import { paths } from 'pages/paths';

export const HeaderLkLink = ({ withBalance = false }) => {
  const { currencyNumber } = useFormaters();
  const { real_amount } = useStore(userModel.$balance);
  return (
    <Link to={paths.lk()} className="header__lk">
      <IconSprite name="user-icon" />
      {withBalance && (
        <span className="header__balance-lk">
          {currencyNumber(real_amount)}
        </span>
      )}
    </Link>
  );
};
