import { currencyApi } from 'api/currency';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { reshape } from 'patronum';

import {
  getApiErrorsArray,
  joinErrorsToString,
} from 'lib/error/formatApiError';
import { errorModel } from 'features/error-boundary';
import { DEFAULT_CURRENCY } from 'lib/constants';

export const getCurrencies = createEvent<void>();

export const getCurrenciesFx = createEffect(currencyApi.getAvailableCurrency);

export const $currencies = createStore<AvailableCurrencyResponse>({
  available: [],
  default: DEFAULT_CURRENCY,
}).on(getCurrenciesFx.doneData, (_, res) => res);

export const { $availableCurrencies, $defaultCurrency } = reshape({
  source: $currencies,
  shape: {
    $availableCurrencies: (currencies) => currencies.available,
    $defaultCurrency: (currencies) => currencies.default,
  },
});

forward({
  from: getCurrencies,
  to: getCurrenciesFx,
});

getCurrenciesFx.fail.watch((data) => {
  let err = (data.error as unknown) as ApiError;
  const errText = joinErrorsToString(getApiErrorsArray(err));
  errorModel.createErrorNotify(errText);
});
