import { createEvent, createEffect, createStore, sample } from 'effector';

import { jackpotApi } from 'api/jackpot';
import { navigationModel } from 'features/navigation';
import { paths } from '../paths';

const pageLoaded = createEvent<{ jackpotId: string }>();
const pageUnloaded = createEvent();

const loadJackpotByIdFx = createEffect(jackpotApi.getById);
const loadJackpotWinnersByIdFx = createEffect(jackpotApi.getWinnersJackpotById);

const $jackpot = createStore<Jackpot | null>(null);
const $winners = createStore<JackpotWinner[]>([]);

const $loading = loadJackpotByIdFx.pending;

sample({
  clock: pageLoaded,
  target: [loadJackpotByIdFx, loadJackpotWinnersByIdFx],
  fn: ({ jackpotId }) => jackpotId,
});

$jackpot.on(loadJackpotByIdFx.doneData, (_, jackpot) => jackpot);
$winners.on(loadJackpotWinnersByIdFx.doneData, (_, winners) => winners);

sample({
  clock: loadJackpotByIdFx.failData,
  target: navigationModel.historyPush,
  fn: paths.home,
});

$jackpot.reset(pageUnloaded);
$winners.reset(pageUnloaded);

export const events = { pageUnloaded, pageLoaded };
export const stores = { $winners, $jackpot, $loading };
