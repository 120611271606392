import React from 'react';
import classNames from 'classnames';

import { IconSprite } from 'ui';

export const BonusCardWrap: React.FC<Props> = ({
  children,
  className,
  idx = 0,
  iconName,
}) => {
  const svgIdx = (idx % 6) + 1;

  return (
    <div className={classNames('bonus-card', className)}>
      <div className="bonus-card__wrap">
        <IconSprite
          className="bonus-card__icon"
          name={iconName || `bonus-card${svgIdx}`}
        />
        {children}
      </div>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
  className?: string;
  idx?: number;
  iconName?: string;
}
