import * as React from 'react';
import { useTranslation } from 'react-i18next';
import fscreen from 'fscreen';

import { IconSprite } from 'ui';
import { chatModel } from 'features/chat';
import { GameModes } from 'lib/constants';

import './style.css';

interface Props {
  // rulesBtnFn: () => void;
  name: string;
  mode: GameMode;
  onCloseClick: () => void;
}

export const GameHeader: React.FC<Props> = ({ name, mode, onCloseClick }) => {
  const { t } = useTranslation();

  return (
    <div className="frame__header">
      <div className="frame__container">
        <ul className="frame__list">
          <li className="frame__item">
            <span
              className="frame__link"
              onClick={() => chatModel.chatOpened()}
            >
              {t('game:support')}
            </span>
          </li>
        </ul>
        <h1 className="frame__name">
          {name} {mode === GameModes.Demo && '(DEMO)'}
        </h1>
        <div className="frame__nav">
          <button
            onClick={openFullscreen}
            className="frame__btn frame__btn_full"
            type="button"
          >
            <IconSprite name="frame-full" />
          </button>
          <button
            onClick={onCloseClick}
            className="frame__btn frame__btn_close"
          >
            <IconSprite name="frame-close" />
          </button>
        </div>
      </div>
    </div>
  );
};

function openFullscreen() {
  fscreen.requestFullscreen(document.querySelector('.frame__frame') as Element);
}
