import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';
import * as model from '../model';

export const PaymentEmailModals = () => {
  const { t } = useTranslation();

  const { isOpen, content } = useStore(model.$modal);

  return isOpen ? (
    <Toast
      isOpen
      type={content.toLowerCase().includes('error') ? 'error' : 'success'}
      onAnimationEnd={model.resetModal}
    >
      {t(`common:popup.${content}`)}
    </Toast>
  ) : null;
};
