import {
  attach,
  createEffect,
  createEvent,
  createStore,
  Effect,
  guard,
  restore,
  sample,
  forward,
} from 'effector';
import i18n from 'i18next';
import { useStoreMap } from 'effector-react';
import { reshape, spread } from 'patronum';

import { i18nApi } from 'api/i18n';
import { userModel } from 'features/user';
import { Languages } from 'lib/constants';
import { skinModel } from 'features/skin-id';

export enum SCOPES {
  GamesMenu = 'gamesMenu',
  PaymentsInfo = 'paymentsInfo',
  PaymentsFields = 'paymentsFields',
  Contacts = 'contacts',
  Footer = 'footer',
  StatusNames = 'statusNames',
}

export const initLanguage = createEvent();
export const changeLanguage = createEvent<string>();
export const setLanguage = createEvent<string>();

export const changeLanguageFx = createEffect<string, string>(
  i18nApi.changeLanguage,
);

export const $language = restore(
  setLanguage.filterMap((language) => language.toLowerCase()),
  '',
);
export const $languageIsSetted = createStore(false).on(setLanguage, () => true);

export const getAvaiableLocalesFx = createEffect<
  void,
  AvaiableLocalesResponse,
  ApiError
>(i18nApi.getAvaiableLocalesList);

export const getCurrentLanguageFx = createEffect<void, { language: string }>(
  i18nApi.getCurrentLanguage,
);

export const getResourcesByScopesListFx = createEffect<
  string,
  Record<string, ResourceList>,
  ApiError
>((language) => {
  const scopesString = Object.values(SCOPES).join(',');
  return i18nApi.getAllResourceByScope(language, scopesString);
});

export const getResourceByScopeFx: Effect<string, ResourceList> = attach({
  effect: createEffect<any, any>(({ language, scope }) =>
    i18nApi.getResourceByScope(language, scope),
  ),
  source: $language,
  mapParams: (scope, language) => ({
    language,
    scope,
  }),
});

const $defaultLanguage = createStore(Languages.RU);
export const $avaiableLocales = createStore<string[]>(['RU', 'EN']);

export const $resources = createStore<Record<string, ResourceList>>({});

export const resourses = reshape({
  source: $resources,
  shape: {
    userStatusNames: (resource) => resource[SCOPES.StatusNames] || {},
    contactsResource: (resource) => resource[SCOPES.Contacts] || {},
    footerResource: (resource) => resource[SCOPES.Footer] || {},
    gameMenu: (resource) => resource[SCOPES.GamesMenu] || {},
    contactEmail: (resource) => resource[SCOPES.Contacts]?.email || '',
  },
});

export const $clubName = skinModel.stores.$skinId.map((id) =>
  id === skinModel.SkinIds.HA ? 'El-Habanero' : 'Golden Ocean',
);

forward({
  from: initLanguage,
  to: [getAvaiableLocalesFx, getCurrentLanguageFx],
});

sample({
  clock: getCurrentLanguageFx.doneData,
  target: setLanguage,
  fn: ({ language }) => language,
});

spread({
  source: getAvaiableLocalesFx.doneData,
  targets: {
    locales: $avaiableLocales,
    default: $defaultLanguage,
  },
});

guard({
  source: $language,
  filter: Boolean,
  target: getResourcesByScopesListFx,
});

sample({
  clock: getResourcesByScopesListFx.doneData,
  target: $resources,
});

/**
 * Смена языка
 */
forward({
  from: changeLanguage,
  to: changeLanguageFx,
});

sample({
  clock: changeLanguageFx.done,
  target: setLanguage,
  fn: ({ params: language }) => language,
});

sample({
  clock: guard(userModel.$userLanguage, { filter: Boolean }),
  target: setLanguage,
  fn: (language) => language,
});

setLanguage.watch((language) => {
  i18n.changeLanguage(language.toLowerCase());
});

/**
 * SELECTORS
 */
const useResourceDotDescription = (
  scope: SCOPES,
  fieldName: string,
  keys: { key: string; required?: boolean }[],
) =>
  useStoreMap({
    store: $resources,
    keys: [fieldName],
    fn: (resources, [fieldName]) => {
      const resource = resources[scope] || {};

      return keys.reduce<Record<string, string>>(
        (result, { key, required }) => {
          const fieldResource = resource[`${fieldName}.${key}`];

          if (fieldResource) {
            result[key] = fieldResource;
          } else {
            if (required) {
              result[key] = fieldName;
            }
          }

          return result;
        },
        {},
      );
    },
  });

const usePaymentDescription = (paymentGroupName: string) =>
  useResourceDotDescription(SCOPES.PaymentsInfo, paymentGroupName, [
    { key: 'title', required: true },
    { key: 'fee' },
    { key: 'processing' },
  ]);

const usePaymentFieldDescription = (fieldName: string) =>
  useResourceDotDescription(SCOPES.PaymentsFields, fieldName, [
    { key: 'label', required: true },
    { key: 'placeholder' },
  ]);

export const selectors = {
  usePaymentDescription,
  usePaymentFieldDescription,
};
