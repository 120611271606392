import {
  createEffect,
  createEvent,
  restore,
  createStore,
  sample,
  combine,
  guard,
  forward,
  merge,
} from 'effector';
import i18n from 'i18next';

import { userApi } from 'api/user';
import { userModel } from 'features/user';
import { RegTypes } from 'lib/constants';
import { isValidPhone } from 'lib/validators';
import { createErrorObject } from 'lib/error/formatApiError';
import { captchaModel } from 'features/captcha';

const CODE_LENGTH = 6; // Длина кода для подтверждения телефона

export const formUnmounted = createEvent<void>();

export const $canChangePhone = userModel.$regType.map(
  (type) => type !== RegTypes.PHONE,
);

export const addPhoneCaptcha = captchaModel.createCaptcha('confirm-phone');
const { $captcha } = addPhoneCaptcha;

// Шаг 1. Изменение телефона
export const updatePhoneFx = createEffect<
  { phone: string; captcha: Captcha },
  boolean,
  ApiError
>(userApi.changePhone);

export const phoneChanged = createEvent<{
  value: string;
  formattedValue: string;
}>();
export const setPhoneError = createEvent<string>();

export const phoneSubmited = createEvent();

const clearNewPhone = createEvent();
export const $newPhone = createStore('')
  .on(phoneChanged, (_, { value }) => value)
  .reset(clearNewPhone);

export const $phoneError = restore(setPhoneError, '').reset(formUnmounted);
export const $apiPhoneError = createStore('')
  .reset(phoneChanged)
  .reset(formUnmounted);

export const $phoneFormData = combine(
  $newPhone,
  $captcha,
  (phone, captcha) => ({
    phone,
    captcha,
  }),
);

export const $isPhoneFieldChanged = combine(
  $newPhone,
  userModel.$phone,
  (updated, initial) => updated !== initial,
);
export const $isformDisabled = combine(
  $isPhoneFieldChanged,
  $phoneError,
  addPhoneCaptcha.$isCaptchaValid,
  updatePhoneFx.pending,
  (isPhoneChanged, phoneError, isCaptchaValid, isPending) =>
    !isPhoneChanged || Boolean(phoneError) || !isCaptchaValid || isPending,
);

sample({
  source: phoneChanged,
  target: setPhoneError,
  fn: ({ formattedValue }) => isValidPhone(formattedValue),
});

guard({
  clock: phoneSubmited,
  source: $phoneFormData,
  filter: $isformDisabled.map((is) => !is),
  target: updatePhoneFx,
});

// Шаг 2. Подтверждение телефона
export const codeChanged = createEvent<string>();
export const codeSubmited = createEvent<void>();
export const resendRequested = createEvent<void>();
export const formRefreshed = createEvent<void>();

export const confirmPhoneFx = createEffect<
  { phone: string; code: string },
  boolean,
  ApiError
>(userApi.confirmPhone);

export const $isCodeRequested = createStore(false).reset(formRefreshed);
export const $codeValue = createStore('')
  .on(codeChanged, (value, newValue) =>
    newValue.length > CODE_LENGTH ? value : newValue,
  )
  .reset(formRefreshed)
  .reset(formUnmounted);

export const $codeError = createStore('')
  .on(
    confirmPhoneFx.failData,
    (_, errors) => createErrorObject(errors)['code'] || '',
  )
  .reset(codeChanged)
  .reset(formUnmounted);

export const $shownPhoneError = combine(
  $phoneError,
  $apiPhoneError,
  (error, apiError) =>
    apiError ? i18n.t(`lk:profile.phone.errors.${apiError}`) : error,
);

sample({
  source: updatePhoneFx,
  clock: resendRequested,
  target: updatePhoneFx,
});

sample({
  clock: updatePhoneFx.doneData,
  target: $isCodeRequested,
  fn: () => true,
});

guard({
  source: codeChanged,
  filter: (code) => code.length === CODE_LENGTH,
  target: codeSubmited,
});

sample({
  clock: codeSubmited,
  source: combine({
    phone: $newPhone,
    code: $codeValue,
  }),
  // filter: $isCodeValid,
  target: confirmPhoneFx,
});

forward({
  from: confirmPhoneFx.doneData,
  to: [userModel.loadUserFx, formRefreshed],
});

sample({
  source: updatePhoneFx.failData,
  target: addPhoneCaptcha.setApiCaptchaError,
  fn: (errors) => createErrorObject(errors)['captcha'],
});

updatePhoneFx.failData.watch((d) => console.log('update phone failed', d));

sample({
  source: merge([updatePhoneFx.failData, confirmPhoneFx.failData]),
  target: $apiPhoneError,
  fn: (errors) => createErrorObject(errors)['phone'] || '',
});

forward({
  from: userModel.$user,
  to: [formRefreshed, clearNewPhone],
});

guard({
  source: formUnmounted,
  filter: $isCodeRequested.map((isRequested) => !isRequested),
  target: clearNewPhone,
});
