import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { version } from '../../../package.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    debug: false,
    whitelist: ['en', 'ru'],
    load: 'languageOnly',
    ns: [
      'auth',
      'about',
      'bonus',
      'bonus-conditions',
      'cancellation-policy',
      'contacts',
      'cookies-policy',
      'privacy-police',
      'common',
      'confirm-password',
      'change-password',
      'failed-payment',
      'successful-payment',
      'faq',
      'footer',
      'game-rules',
      'game',
      'jackpot',
      'wof',
      'lottery',
      'money-laundering',
      'lk',
      'terms',
      'tournament',
      'risk-notice',
      'payments',
      'payment-systems',
    ],
    defaultNS: 'common',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    },
  });

export default i18n;
export { i18n };
export * as i18nModel from './model';
