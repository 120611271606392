import React from 'react';
import { useStore } from 'effector-react';
import DOMPurify from 'dompurify';

import { Article, ArticleImg, ArticleInfo, Container, Section } from 'ui';
import { skinModel } from 'features/skin-id';

import './style.css';
import * as model from './model';

export const LoyaltyProgramPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const { text } = useStore(model.$pageResource);
  const skinId = skinModel.selectors.useSkinId();

  return (
    <Section type="info">
      <Container padding={false} small>
        <Article className="loyalty-article">
          <ArticleImg relative>
            <img
              className="about-img"
              src={`/img/${skinId}/about-img.jpg`}
              alt=""
            />
          </ArticleImg>
          <ArticleInfo>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          </ArticleInfo>
        </Article>
      </Container>
    </Section>
  );
};
