import * as React from 'react';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';

import {
  Article,
  ArticleImg,
  ArticleInfo,
  ArticleTitle,
  Container,
  Section,
} from 'ui';
import { PromotionSection } from 'features/promotion';
import { promotionApi } from 'api/promotion';

import * as model from './model';
import './style.css';

// const DEFAULT_IMG_SRC = '/img/bonus-bg.jpg';

export const PromotionPage = () => {
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    model.pageLoaded(id);
    window.scrollTo(0, 0);
    return () => model.pageUnloaded();
  }, [id]);

  const promotionData = useStore(model.$promotionData);
  const isLoaded = useStore(model.$isLoaded);

  return (
    <>
      <Section type="info">
        <Container padding={false} small>
          {isLoaded ? (
            <Article>
              <div className="bonus--article">
                <ArticleImg>
                  <img
                    src={promotionApi.getPromotionImageUrl(
                      promotionData?.image_id,
                    )}
                    alt=""
                  />
                </ArticleImg>
                <ArticleInfo>
                  <ArticleTitle>{promotionData?.title}</ArticleTitle>
                  <div
                    className="resources"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(promotionData?.text),
                    }}
                  />
                </ArticleInfo>
              </div>
            </Article>
          ) : (
            <SkeletonPageContent />
          )}
        </Container>
        <PromotionSection />
      </Section>
    </>
  );
};

const SkeletonPageContent = () => {
  return (
    <Article skeleton>
      <ArticleImg>{null}</ArticleImg>
      <ArticleInfo>
        <ArticleTitle>{null}</ArticleTitle>
      </ArticleInfo>
    </Article>
  );
};
