import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Counter, LinkButton } from 'ui';

import { paths } from 'pages/paths';
import { useFormaters } from 'features/i18';
import { tournamentApi } from 'api/tournament';
import { skinModel } from 'features/skin-id';

import './style.css';

interface Props {
  isLoading?: boolean;
  tournament?: TournamentItem | null;
}

// eslint-disable-next-line quotes
const getDefaultTournamentCardImg = (skinId) =>
  `url('/img/${skinId}/tour-card-default-img.png')`;

export const TournamentItem: React.FC<Props> = ({ tournament }) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();
  const [imgIsNotLoaded, setImgIsNotLoaded] = React.useState(false);
  const skinId = skinModel.selectors.useSkinId();

  if (!tournament) {
    return null;
  }

  const { end_date, title, prize_fund, status, id, start_date } = tournament;

  const imgUrl = tournamentApi.getTournamentImgUrl(id, 'title');

  return (
    <div className="tournament">
      <img
        style={{ display: 'none' }}
        src={imgUrl}
        alt=""
        onError={() => setImgIsNotLoaded(true)}
      />
      <div
        className="tournament__wrap"
        style={{
          backgroundImage: !imgIsNotLoaded
            ? `url('${imgUrl}')`
            : getDefaultTournamentCardImg(skinId),
        }}
      >
        <div className="tournament__info">
          <h3 className="tournament__name">{title}</h3>
          <span className="tournament__price">
            {currencyNumber(prize_fund)}
          </span>
          <span className="tournament__sub-title">
            {t('tournament:prizeFund')}
          </span>
          <LinkButton to={paths.tournament(id)}>
            {t('common:buttons.more')}
          </LinkButton>
          <Counter
            isEnd={status === 'END'}
            date={status === 'CREATED' ? start_date : end_date}
          />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line quotes
const DEFAULT_BIG_IMG_URL = "url('/img/t5.jpg')";

export const TournamentItemBig: React.FC<Props> = ({
  tournament,
  isLoading,
}) => {
  const [imgIsNotLoaded, setImgIsNotLoaded] = React.useState(false);
  const { currencyNumber } = useFormaters();
  const { t } = useTranslation();

  if (isLoading) {
    return <TournamentBigItemSkeleton />;
  }

  if (!tournament) {
    return null;
  }

  const {
    end_date,
    prize_fund,
    status,
    description,
    name,
    id,
    start_date,
  } = tournament;

  const imgUrl = tournamentApi.getTournamentImgUrl(id, 'title');

  return (
    <div className="tournament tournament_big">
      <img
        style={{ display: 'none' }}
        src={imgUrl}
        alt=""
        onError={() => setImgIsNotLoaded(true)}
      />
      <div
        className="tournament__wrap"
        style={{
          backgroundImage: !imgIsNotLoaded
            ? `url('${imgUrl}')`
            : DEFAULT_BIG_IMG_URL,
        }}
      >
        <div className="tournament__info">
          <span className="tournament__sub-title">
            {t('tournament:prizeFund')}
          </span>
          <span className="tournament__price">
            {prize_fund && currencyNumber(prize_fund)}
          </span>
          <h3 className="tournament__name">{name}</h3>
          {status !== 'END' && (
            <span className="tournament__sub-title">
              {status === 'CREATED'
                ? t('tournament:timeToStart')
                : t('tournament:timeToCompletion')}
            </span>
          )}
          <Counter
            isEnd={status === 'END'}
            date={status === 'CREATED' ? start_date : end_date}
          />
          <p className="tournament__descr">{description}</p>
        </div>
      </div>
    </div>
  );
};

export const TournamentBigItemSkeleton: React.FC = () => {
  return (
    <div className="tournament tournament_big loading">
      <div className="tournament__wrap"></div>
    </div>
  );
};

export const TournamentItemSkeleton: React.FC = () => {
  return <div className="tournament loading"></div>;
};
