import React from 'react';
import { PaymentSystem } from './payment-item';

interface Props {
  paymentSystems: PaymentSystemItem[] | null;
  currentPaymentSystem: PaymentSystemItem | null;
  isLoading: boolean;
  onChange: (ps: PaymentSystemItem) => void;
}

export const PaymentGroupSelect: React.FC<Props> = React.memo(
  ({ paymentSystems, currentPaymentSystem, isLoading, onChange }) => {
    if (!paymentSystems || isLoading) {
      return (
        <>
          {Array.from({ length: 8 }, (_, idx) => (
            <PaymentSystem key={idx} />
          ))}
        </>
      );
    }

    return (
      <>
        {paymentSystems?.map((payment) => {
          return (
            <PaymentSystem
              key={payment.ps_id}
              onChange={() => onChange(payment)}
              checked={currentPaymentSystem?.ps_id === payment.ps_id}
              id={payment.ps_id}
              name={payment.ps_name}
            />
          );
        })}
      </>
    );
  },
);
