import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTitle, Container, Section } from 'ui';
import { TournamentList, tournamentModel } from 'features/tournaments';
import { PaginationBlock } from 'features/pagination';
import { PromotionSection } from 'features/promotion';

import * as model from './model';

export const TournamentsPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({ tournamentLimit: 4 });
  }, []);

  return (
    <>
      <Section type="info">
        <Container>
          <BlockTitle
            icon="pyramid-icon"
            title={t('common:menu.tournaments')}
          />
          <TournamentList skeletonSize={4} />
          <PaginationBlock pagination={tournamentModel.pagination} />
          <PromotionSection />
        </Container>
      </Section>
    </>
  );
};
