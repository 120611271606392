import * as React from 'react';

import { Grid } from 'ui';

import { BonusActionResultInfoToast } from '../molecules';

import { BonusCardSkeleton } from './bonus-program-card';
import { GetBonusCard } from './get-bonus-card';

import './style.css';

interface ListProps {
  skeletonSize?: number;
  list: Bonus[];
  isLoading: boolean;
}

export const BonusList = ({
  list,
  isLoading,
  skeletonSize = 10,
}: ListProps) => {
  ``;
  if (isLoading) {
    return <SkeletonList skeletonSize={skeletonSize} />;
  }

  return (
    <>
      <BonusActionResultInfoToast />
      <Grid className="grid-bonus__container" container>
        {list.map((item, idx) => (
          <Grid className="grid-bonus-item" key={idx} item>
            <GetBonusCard bonus={item} idx={idx} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const SkeletonList = ({ skeletonSize }: { skeletonSize: number }) => {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: skeletonSize }, (_, idx) => (
        <Grid className="grid-bonus-item" item key={idx}>
          <BonusCardSkeleton idx={idx} />
        </Grid>
      ))}
    </Grid>
  );
};
