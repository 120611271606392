import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { userModel } from 'features/user';
import { useFormaters } from 'features/i18';

export const TotalCashbacks = () => {
  const { t } = useTranslation();
  const sum = useStore(userModel.$userTotalCashbacks);
  const { currencyNumber } = useFormaters();

  return (
    <div className="total-cashback__wrap">
      <h4 className="total-cashback__title">
        {t('bonus:total_cashback_received')}
      </h4>
      <div className="total-cashback__sum">
        {currencyNumber(sum, { minimumFractionDigits: 0 })}
      </div>
    </div>
  );
};
