import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { Container, Grid, ResultText, ResultWrap } from 'ui';
import { promotionModel } from 'features/promotion';
import { PromotionCard, PromotionCardSkeleton } from './promotion-card';

interface PromotionListProps {
  mob?: boolean;
  skeletonSize?: number;
}

export const PromotionList: React.FC<PromotionListProps> = ({
  mob = false,
  skeletonSize = 3,
}) => {
  const { t } = useTranslation();
  const renderedList = useStore(promotionModel.$renderedList);
  const listIsEmpty = useStore(promotionModel.$isListEmpty);
  const isLoading = useStore(promotionModel.$isPending);

  if (isLoading)
    return (
      <Grid container spacing={2}>
        {Array.from({ length: skeletonSize }, (_, idx) => (
          <Grid className="grid-stock-item" xs={6} md={4} item key={idx}>
            <PromotionCardSkeleton mob={mob} />
          </Grid>
        ))}
      </Grid>
    );

  if (listIsEmpty) {
    return (
      <Container>
        <ResultWrap>
          <ResultText bg>{t('common:listEmpty')}</ResultText>
        </ResultWrap>
      </Container>
    );
  }

  return (
    <Grid container spacing={2}>
      {renderedList.map((item) => (
        <Grid className="grid-stock-item" key={item.item_id} xs={6} md={4} item>
          <PromotionCard mob={mob} promotion={item} />
        </Grid>
      ))}
    </Grid>
  );
};
