import { createStore, createEvent, createEffect } from 'effector';
import { popupModel } from 'features/popup';
import {
  getApiErrorsArray,
  joinErrorsToString,
  setErrorsToForm,
} from 'lib/error/formatApiError';

export const setErrors = createEvent<Array<string[]>>();
export const clearErrors = createEvent();

export const $errorState = createStore<Array<string[]>>([])
  .on(setErrors, (_, newState) => newState)
  .reset(clearErrors);

export const createErrorNotify = createEffect((errorText: string) => {
  popupModel.setText(errorText);
});

export const handleError = createEffect((params) => {
  let unknownErrors: ApiError = { items: {}, empty: true };
  if (params.form) {
    unknownErrors = setErrorsToForm(
      params.errType,
      params.data.error.items,
      params.form,
      params.rename || {},
    );
    if (!unknownErrors.empty) {
      const errText = joinErrorsToString(getApiErrorsArray(unknownErrors));
      return createErrorNotify(errText);
    }
    return false;
  }
  let err = (params.data.error as unknown) as ApiError;
  const errText = joinErrorsToString(getApiErrorsArray(err));
  return createErrorNotify(errText);
});
