import React from 'react';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router';

import { sessionModel } from 'features/session';
import { searchModel } from 'features/search';
import { useMedia } from 'lib/media';
import { paths } from 'pages/paths';

import { DesktopHeader } from './desktop-header';
import { MobileHeader } from './mobile-header';

import './style.css';

export const Header = () => {
  const mediaType = useMedia(['(max-width: 600px)'], ['mobile'], 'desktop');

  const isAuth = useStore(sessionModel.$isAuthenticated);
  const isOpen = useStore(searchModel.$isFormOpen);

  const { pathname } = useLocation();

  const needToHideHeader =
    pathname.includes('/game/') ||
    pathname === paths.successfulPayment() ||
    pathname === paths.failedPayment();

  if (needToHideHeader) {
    return null;
  }

  switch (mediaType) {
    case 'mobile':
      return <MobileHeader fixed={isOpen} isAuth={isAuth} />;
    case 'desktop':
      return <DesktopHeader fixed={isOpen} isAuth={isAuth} />;
  }

  return null;
};
