import { createEffect, createEvent, forward, restore } from 'effector';
import { locationsApi } from 'api/location';

export const setRegions = createEvent<Region[]>();
export const setCities = createEvent<City[]>();

export const loadCountriesFx = createEffect(async () => {
  return await locationsApi.getCountries();
});
export const $countries = restore<Country[]>(loadCountriesFx.doneData, []);

export const $loadRegions = createEffect(async (countryId: string | number) => {
  return await locationsApi.getRegions(countryId);
});
export const $regions = restore<Region[]>($loadRegions.doneData, []);

forward({
  from: setRegions,
  to: $regions,
});

export const $loadCities = createEffect(async (regionId: string | number) => {
  return await locationsApi.getCities(regionId);
});
export const $cities = restore<City[]>($loadCities.doneData, []);

forward({
  from: setCities,
  to: $cities,
});
