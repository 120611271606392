import * as React from 'react';
import classNames from 'classnames';

import { GameLink } from './game-link';
import { LikeButton } from '../molecules';
import { skinModel } from 'features/skin-id';

import './style.css';

interface Props {
  className?: string;
  game: Game;
  handlePlayButtonClick?: () => void;
}

const getDefaultImageSrc = (skinId) => `/img/${skinId}/game-card.png`;

export const GameCard: React.FC<Props> = React.memo(
  ({ game, className, handlePlayButtonClick }) => {
    const { name, provider_code, code, images } = game;
    const skinId = skinModel.selectors.useSkinId();

    const [src, setSrc] = React.useState(
      images?.logo ? images.logo.link : getDefaultImageSrc(skinId),
    );

    return (
      <div className={classNames('game', className)}>
        <div className="game__wrap">
          <img
            onError={() => setSrc(getDefaultImageSrc(skinId))}
            className="game__img"
            src={src}
            alt="game logo"
          />
          <div className="game__nav">
            <GameLink
              handlePlayButtonClick={handlePlayButtonClick}
              code={code}
              provider={provider_code}
            />
            <div className="game__name">
              <LikeButton game={game} />
              <span>{name}</span>
            </div>
            {/*<div className="game__rating-wrap">*/}
            {/*  <IconSprite name="rating-star" />*/}
            {/*  <span className="game__rating">4.5</span>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  },
);

export const GameCardSkeleton = () => (
  <div className="game loading">
    <div className="game__wrap"></div>
  </div>
);
