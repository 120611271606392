import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import DOMPurify from 'dompurify';

import { Link } from 'ui';
import { mediaHooks } from 'lib/media';
import { paths } from 'pages/paths';
import { promotionApi } from 'api/promotion';

import './style.css';

interface PromotionCardProps {
  mob?: boolean;
  promotion: PromotionPreviewItem;
}

export const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  mob,
}) => {
  const { title, short_text, item_id, image_id } = promotion;
  const { t } = useTranslation();

  const isTablet = mediaHooks.useIsTablet();

  const showSmallCard = isTablet && mob;

  const imgSrc = React.useMemo(
    () => (image_id ? promotionApi.getPromotionImageUrl(image_id) : ''),
    [image_id],
  );

  if (showSmallCard) {
    return (
      <RouterLink to={paths.promotion(item_id)}>
        <article className="stock-mob">
          <div className="stock-mob__wrap">
            <div className="stock-mob__img">
              <img src={imgSrc} alt="promo-img" />
            </div>
            <h3 className="stock-mob__title">{title}</h3>
          </div>
        </article>
      </RouterLink>
    );
  }

  return (
    <div className="stock-card">
      <article className="stock-card__wrap">
        <div className="stock-card__img">
          <img src={imgSrc} alt="promo-img" />
        </div>
        <div className="stock-card__info">
          <header className="stock-card__text-info">
            <h2 className="stock-card__title">{title}</h2>
            <p
              className="stock-card__descr"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(short_text),
              }}
            />
          </header>
          <div className="stock-card__btns">
            <div className="stock-card__more-wrap">
              <Link to={paths.promotion(item_id)} theme="secondary">
                {t('common:detailed')}
              </Link>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

interface PromotionCardSkeletonProps {
  mob?: boolean;
}

export const PromotionCardSkeleton: React.FC<PromotionCardSkeletonProps> = ({
  mob,
}) => {
  const isTablet = mediaHooks.useIsTablet();

  const showSmallCard = isTablet && mob;

  if (showSmallCard) {
    return <article className="stock-mob loading"></article>;
  }

  return (
    <div className="stock-card">
      <article className="stock-card__wrap">
        <div className="stock-card__img loading">
          <img alt="" />
        </div>
        <div className="stock-card__info">
          <header className="stock-card__text-info">
            <p className="stock-card__descr "></p>
          </header>
          <div className="stock-card__btns">
            <div className="stock-card__more-wrap"></div>
          </div>
        </div>
      </article>
    </div>
  );
};
