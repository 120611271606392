import React from 'react';
import './style.css';

interface GameTemplateProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

export const GameTemplate = ({ header, children }: GameTemplateProps) => {
  return (
    <div className="frame">
      {header}
      <div className="frame__container">{children}</div>
    </div>
  );
};
