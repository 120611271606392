import classNames from 'classnames';
import { disableScroll, enableScroll } from 'lib/scroll-toggle';
import React from 'react';
import './style.css';

interface Props {
  children: React.ReactNode;
  isRendered?: boolean;
  onAnimationEnd?: () => void;
  className?: string;
}

const DEFAULT_MS = 300;

export const OffCanvas: React.FC<Props> = ({
  children,
  isRendered,
  onAnimationEnd = () => {},
  className,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);

  const [animationIsEnded, setAnimationIsEnded] = React.useState(false);
  const [styles, setStyles] = React.useState({ left: '-50%' });

  React.useEffect(() => {
    setStyles({ left: '50%' });
    disableScroll();
  }, []);

  React.useEffect(() => {
    if (animationIsEnded) {
      return enableScroll();
    }
  }, [animationIsEnded]);

  React.useEffect(() => {
    if (isMounted) {
      if (!isRendered) {
        setStyles({ left: '-50%' });
        setTimeout(() => {
          setAnimationIsEnded(true);
          onAnimationEnd();
        }, DEFAULT_MS);
      } else {
        setAnimationIsEnded(false);
      }
    } else {
      setIsMounted(true);
    }
  }, [isRendered, isMounted, onAnimationEnd]);

  if (animationIsEnded) return null;

  return (
    <div
      style={{ transitionDuration: `${DEFAULT_MS}ms`, left: styles.left }}
      className={classNames('off-canvas', className)}
    >
      {children}
    </div>
  );
};
