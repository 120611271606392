import { createEffect, createEvent, forward, restore, sample } from 'effector';
import { status } from 'patronum';

import { promotionApi } from 'api/promotion';
import { historyReplace } from 'features/navigation/model';
import { paths } from 'pages/paths';

export const pageLoaded = createEvent<string>();
export const pageUnloaded = createEvent<void>();

const loadPromotionDataFx = createEffect<string, PromotionItem, ApiError>(
  promotionApi.getPromotion,
);

export const $isLoaded = status({ effect: loadPromotionDataFx })
  .map((status) => status === 'done')
  .reset(pageUnloaded);

export const $promotionData = restore(loadPromotionDataFx.doneData, null).reset(
  pageUnloaded,
);

forward({
  from: pageLoaded,
  to: loadPromotionDataFx,
});

sample({
  source: loadPromotionDataFx.failData,
  target: historyReplace,
  fn: () => paths.promotions(),
});
