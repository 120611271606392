import {
  combine,
  createEvent,
  createStore,
  Event,
  sample,
  Store,
} from 'effector';

interface Pagination {
  resetTriggers?: Event<any>[];
  defaultLimit?: number;
}

export interface PaginationModel<T> {
  $limit: Store<number>;
  $totalRecords: Store<number>;
  $totalPages: Store<number>;
  $currentPage: Store<number>;
  $isFirst: Store<boolean>;
  $isLast: Store<boolean>;
  setTotal: Event<number>;
  setLimit: Event<number>;
  pageChanged: Event<number>;
  goToNextPage: Event<number>;
  goToPrevPage: Event<number>;
  paginate: (array: T[], pageSize: number, pageNumber: number) => T[];
}

export function createPaginationModel<T>({
  resetTriggers = [],
  defaultLimit = 1,
}: Pagination): PaginationModel<T> {
  const setTotal = createEvent<number>();
  const setLimit = createEvent<number>();
  const pageChanged = createEvent<number>();
  const goToNextPage = createEvent<number>();
  const goToPrevPage = createEvent<number>();

  const $limit = createStore<number>(defaultLimit).on(
    setLimit,
    (_, limit) => limit,
  );

  const $totalRecords = createStore<number>(0).on(
    setTotal,
    (_, total) => total,
  );

  const $currentPage = createStore<number>(1)
    .on(pageChanged, (_, pageNumber) => pageNumber)
    .reset(resetTriggers);

  const $totalPages = combine($totalRecords, $limit, (totalRecords, limit) =>
    Math.ceil(totalRecords / limit),
  );

  const $isFirst = $currentPage.map((current) => current === 1);

  const $isLast = combine(
    $currentPage,
    $totalPages,
    (current, total) => current === total,
  );

  sample({
    source: $currentPage,
    clock: goToNextPage,
    target: pageChanged,
    fn: (current) => current + 1,
  });

  sample({
    source: $currentPage,
    clock: goToPrevPage,
    target: pageChanged,
    fn: (current) => current - 1,
  });

  const paginate = (array: T[], pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  return {
    $limit,
    $totalRecords,
    $totalPages,
    $currentPage,
    $isFirst,
    $isLast,
    setTotal,
    setLimit,
    pageChanged,
    goToNextPage,
    goToPrevPage,
    paginate,
  };
}
