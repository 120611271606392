import React from 'react';

export const BonusStars = ({ white = false }) => {
  const bonusStarSrc = white
    ? '/img/icons/white-bonus-star.svg'
    : '/img/icons/bonus-star.svg';

  return (
    <>
      <img
        src={bonusStarSrc}
        className="bonus-card__star bonus-card__star_left"
      />
      <img
        src={bonusStarSrc}
        className="bonus-card__star bonus-card__star_right"
      />
    </>
  );
};
