import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { DateTime } from 'lib/datetime';
import {
  Button,
  ButtonLink,
  Counter,
  IconSprite,
  Link,
  SvgDecorators,
  WinnerTable,
  WinnerTableRow,
} from 'ui';
import { useFormaters } from 'features/i18';
import { lotteryApi } from 'api/lottery';
import { paths } from 'pages/paths';
import { DEFAULT_RENDER_WINNERS_SIZE } from 'lib/constants';

import { LotteryRulesModal } from './lottery-rules-modal';
import { lotteryModel } from '..';

interface Props {
  lottery: LotteryItem | null;
  loading: boolean;
}

const DEFAULT_IMG_SRC = '/img/loto_zaglushka.png';

export const Lottery: React.FC<Props> = ({ lottery, loading }) => {
  const { t } = useTranslation();

  const [rulesIsOpen, setRulesIsOpen] = React.useState(false);

  const [imgError, setImgError] = React.useState(false);

  if (loading) {
    return <LotterySkelet />;
  }

  if (!lottery) {
    return null;
  }

  const {
    name,
    preview,
    end_at,
    state,
    winners,
    start_image_id,
    description,
  } = lottery;

  return (
    <div className="lottery">
      {/*TODO: ТЕКСТ ДЛЯ ПРАВИЛ */}
      {rulesIsOpen && !!description && (
        <LotteryRulesModal
          html={description}
          onCloseFn={() => setRulesIsOpen(false)}
        />
      )}
      <SvgDecorators />
      <div className="lottery__wrap lottery__wrap_open">
        <div className="lottery__name-wrap ">
          <h2 className="lottery__name">
            <IconSprite name="ticket-icon" />
            {name}
          </h2>
          <LotteryStatus lottery={lottery} />
        </div>
        <div className="lottery__time-info">
          <LotteryPeriod lottery={lottery} />
          <Counter isEnd={state === 'COMPLETED'} date={end_at} />
          <LotteryButton lottery={lottery} />
        </div>
        <div
          className="lottery__main-img"
          style={{
            backgroundImage: imgError
              ? `url('${DEFAULT_IMG_SRC}')`
              : `url('${lotteryApi.getImage(start_image_id)}')`,
          }}
        >
          <img
            alt=""
            style={{ display: 'none' }}
            src={lotteryApi.getImage(start_image_id)}
            onError={() => setImgError(true)}
          />
        </div>
        {!!preview && <LotteryDescription description={preview} />}
        <div className="lottery__btns">
          <ButtonLink onClick={() => setRulesIsOpen(true)} theme="tertiary">
            {t('lottery:rules')}
          </ButtonLink>
          <Link to={paths.lotteries()}>{t('lottery:comeBack')}</Link>
        </div>
        {!!winners?.length ? <WinnerSection list={winners} /> : null}
      </div>
    </div>
  );
};

export const LotteryPeriod = ({ lottery }: { lottery: LotteryItem }) => {
  const { t } = useTranslation();
  const { end_at, start_at } = lottery;

  const startAtTime = DateTime.getDayMonthString(start_at);
  const endAtTime = DateTime.getDayMonthString(end_at);

  return (
    <div className="lottery__period-wrap">
      <span className="lottery__period-title">{t('lottery:period')}:</span>
      <div className="lottery__period-date">
        {startAtTime && <span>{startAtTime}</span>}
        <span> - </span>
        {endAtTime && <span>{endAtTime}</span>}
      </div>
    </div>
  );
};

export const LotteryStatus = ({ lottery }: { lottery: LotteryItem }) => {
  const { t } = useTranslation();
  const { state } = lottery;

  if (state !== 'ACTIVE') {
    return (
      <div className="lottery__status lottery__status_finished">
        <span className="lottery__status-text finished">
          {t('lottery:finished')}
        </span>
      </div>
    );
  }

  return (
    <div className="lottery__status lottery__status_active">
      <span className="lottery__status-text">{t('lottery:active')}</span>
    </div>
  );
};

export const LotteryButton = ({ lottery }: { lottery: LotteryItem }) => {
  const { t } = useTranslation();
  const { state, winners } = lottery;

  const winnerTable = document.querySelector('.lottery-winners__table');

  if (state !== 'ACTIVE') {
    return winners?.length !== 0 ? (
      <Button
        onClick={() => window.scroll(winnerTable?.getBoundingClientRect())}
      >
        {t('lottery:winners')}
      </Button>
    ) : null;
  }

  return (
    <Button onClick={() => lotteryModel.buttonClicked(lottery)}>
      {t('lottery:participate')}
    </Button>
  );
};

export const LotteryDescription = ({
  description,
}: {
  description: string;
}) => (
  <div
    className="lottery__descr resources"
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
  />
);

export const WinnerSection = ({ list }: { list: LotteryWinner[] }) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  return (
    <WinnerTable
      className="lottery-winners__table"
      title={t('lottery:table.title')}
    >
      <WinnerTableRow
        head
        values={[
          {
            className: 'lottery-table__ticket',
            value: t('lottery:table.ticketCount'),
          },
          {
            className: 'lottery-table__player',
            value: t('lottery:table.player'),
          },
          {
            className: 'lottery-table__prize',
            value: t('lottery:table.prize'),
          },
        ]}
      />
      {list.slice(0, DEFAULT_RENDER_WINNERS_SIZE).map((winner) => (
        <WinnerTableRow
          key={winner.player_id}
          values={[
            {
              className: 'lottery-table__ticket',
              value: winner.ticket_count,
            },
            {
              className: 'lottery-table__player',
              value: winner.display_name,
            },
            {
              className: 'lottery-table__prize',
              value: currencyNumber(winner.amount),
            },
          ]}
        />
      ))}
    </WinnerTable>
  );
};

const LotterySkelet = () => {
  const { t } = useTranslation();
  return (
    <div className="lottery">
      <SvgDecorators />
      <div className="lottery__wrap lottery__wrap_open">
        <div className="lottery__name-wrap ">
          <span className="lottery__name loading" />
        </div>
        <div className="lottery__time-info">
          <div className="lottery__period-wrap">
            <span className="lottery__period-title" />
            <div className="lottery__period-date loading">
              {t('lottery:period')}:
            </div>
          </div>
          <Counter />
        </div>
        <div className="lottery__main-img loading" />
        <div className="lottery__descr">
          <p />
        </div>
        <div className="lottery__btns">
          <ButtonLink theme="tertiary">{t('lottery:rules')}</ButtonLink>
          <Link to={paths.lotteries()}>{t('lottery:comeBack')}</Link>
        </div>
      </div>
    </div>
  );
};
