import { httpClient } from 'lib/request';

/**
 * Получить список доступных игроку платежных групп на депозит
 */
function getAvaibleDepositPS(): Promise<PaymentGroup[]> {
  return httpClient.get('players/current/deposits/payment-systems');
  // .then((res) => [...res, fakeDepositGroup]);
}

/**
 * Получить список направлений доступных для совершения выплат.
 * Информация для незалогиненных пользователей.
 */

function getWithdrawalsPSForGuest(): Promise<PaymentGroup[]> {
  return httpClient.get('withdrawals/payment-systems');
}
/**
 *
 * Получить список направлений доступных для совершения депозита
 * Информация для незалогиненных пользователей.
 */

function getDepositsPSForGuest(): Promise<PaymentGroup[]> {
  return httpClient.get('deposits/payment-systems');
}

/**
 * Получить данные последнего успешного депозита
 */
function getLastSuccessDeposit(): Promise<any> {
  return httpClient.get('players/current/deposits/last-successful');
}

/**
 * Отправить запрос на регистрацию депозита
 */
function registerDeposit(
  data: RegisterDepositBody,
): Promise<RegisterDepositResponse> {
  return httpClient.post(`players/current/deposits`, data, {
    timeout: 35 * 1000,
  });
}

/**
 * Получить список доступных игроку платежных групп на выплаты
 */
function getAvaibleWithdrawalPS(): Promise<PaymentGroup[]> {
  return httpClient.get('players/current/withdrawals/payment-systems');
}

/**
 * Отправить запрос на регистрацию выплаты
 */
function registerWithdrawal(
  data: RegisterWithdrawalBody,
): Promise<RegisterWithdrawalResponse> {
  return httpClient.post('players/current/withdrawals', data, {
    timeout: 35 * 1000,
  });
}

/**
 * Получить лимиты на выплаты для игрока
 */
function getLimitationsWithdrawal() {
  return httpClient.get('players/current/withdrawals/limitations');
}

/**
 * Получить историю транзакций игрока
 * @param {number} limit - количество операций на страницу
 * @param {number} offset - сдвиг по страницам
 */
function getHistory({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): Promise<HistoryItem[]> {
  return httpClient.get(
    `players/current/payments?limit=${limit}&offset=${offset}`,
  );
}

/**
 * Отменить выплату
 * @param {number} transactionId - id отменяемой транзакции
 */
function cancelWithdrawal(transactionId: number): Promise<string> {
  return httpClient.delete(`players/current/withdrawals/${transactionId}`);
}

export const cashBoxApi = {
  getWithdrawalsPSForGuest,
  getDepositsPSForGuest,

  getAvaibleDepositPS,
  getLastSuccessDeposit,
  registerDeposit,
  getAvaibleWithdrawalPS,
  getLimitationsWithdrawal,
  registerWithdrawal,
  cancelWithdrawal,
  getHistory,
};

// const fakeDepositGroup: PaymentGroup = {
//   provider_name: 'provider_name',
//   ps: [
//     {
//       currency: 'ru',
//       card_required: true,
//       full_card_required: false,
//       account_required: true,
//       email_required: true,
//       holder_required: true,
//       iframe_required: false,
//       phone_required: false,
//       default_amount: 100,
//       max_deposit: 10000,
//       max_withdraw: 10000,
//       min_deposit: 0,
//       min_withdraw: 100,
//       ps_id: 321321321231,
//       ps_name: 'fake deposit group FRONT',
//     },
//   ],
//   ps_group: 'fake deposit group FRONT',
// };
