import moment from 'moment';
import { EnumDays } from '../constants';

export interface DateObject {
  year?: string | number;
  month?: string | number;
  day?: string | number;
  hours?: string | number;
  minutes?: string | number;
  seconds?: string | number;
}

enum Days {
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
}

export class DateTime {
  public static getData = (dateInitial: string | number): DateObject => {
    const date =
      typeof dateInitial === 'string'
        ? dateInitial
        : new Date(dateInitial).toISOString();
    const [year, month, day] = date.split('T')[0].split('-');
    let [hours, minutes, seconds] = date.split('T')[1].split(':');
    seconds = seconds.split('.')[0];
    return { year, month, day, hours, minutes, seconds };
  };

  public static getDay(): EnumDays {
    const now = new Date();
    const day = now.getDay();

    return Object.values(EnumDays)[day];
  }

  public static getMS = (date: string) => {
    return new Date(date).getTime();
  };

  public static getUTCDateByDay = (day: Day): string => {
    const dateByDay = new Date(Date.now());

    let different = dateByDay.getDay() - Days[day];

    if (Math.sign(different) === -1) {
      different = Math.abs(different);
    } else {
      different = 7 - different;
    }

    dateByDay.setDate(dateByDay.getDate() + different);
    dateByDay.setUTCHours(0, 0, 0, 0);

    return dateByDay.toISOString();
  };

  public static getDayMonthString = (dateInitial: string | number) => {
    const { year, month, day } = DateTime.getData(dateInitial);
    return `${year}.${month}.${day}`;
  };

  public static toMinutes = (ms) => {
    return moment.duration(ms, 'milliseconds').asMinutes();
  };

  public static msToTime = (ms: number): DateObject => {
    let seconds: string | number = Math.floor((ms / 1000) % 60);
    seconds = seconds < 10 ? '0' + seconds : seconds;

    let minutes: string | number = Math.floor((ms / (1000 * 60)) % 60);
    minutes = minutes < 10 ? '0' + minutes : minutes;

    let hours: string | number = Math.floor((ms / (1000 * 60 * 60)) % 24);
    hours = hours < 10 ? '0' + hours : hours;

    let day: string | number = Math.floor(ms / (1000 * 60 * 60 * 24));
    day = day < 10 ? '0' + day : day;

    return { seconds, minutes, hours, day };
  };

  public static isISOFormat = (str: string) => {
    return !!Date.parse(str);
  };

  public static calculateTimeLeft = (
    date: string | number,
  ): DateObject | null => {
    const parseDate = typeof date === 'number' ? date : Date.parse(date);
    const difference = parseDate - Date.now();
    if (difference < 0) {
      return null;
    }
    return DateTime.msToTime(difference);
  };
  public static daysInMonth = (m: number, y: number) => {
    return m === 2
      ? y & 3 || (!(y % 25) && y & 15)
        ? 28
        : 29
      : 30 + ((m + (m >> 3)) & 1);
  };
  public static getYearsArray = (from: number, to: number) => {
    let arr: string[][] = [];
    for (let y = from; y >= from - to; --y) {
      arr.push([String(y), String(y)]);
    }
    return arr;
  };
  public static getDaysArray = (total: number) => {
    let arr: string[][] = [];
    for (let y = 1; y <= total; y++) {
      arr.push([String(y), String(y)]);
    }
    return arr;
  };
  public static getMonthArray = (lang = 'eng') => {
    moment.locale(lang);
    const monthNames = moment.monthsShort();
    let arr: string[][] = [];
    for (let y = 1; y <= 12; y++) {
      arr.push([String(y), String(monthNames[y - 1])]);
    }
    return arr;
  };

  public static toStringData = (dateInitial): string => {
    const date =
      typeof dateInitial === 'string'
        ? dateInitial
        : new Date(dateInitial).toISOString();

    const [year, month, day] = date.split('T')[0].split('-');
    let [hours, minutes, seconds] = date.split('T')[1].split(':');

    seconds = seconds.split('.')[0];

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };
}
