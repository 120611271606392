import React, { FC } from 'react';
import { IconSprite } from 'ui';
import { useTranslation } from 'react-i18next';

type DocProps = {
  name: string;
  isPending?: boolean;
  pendingProgress?: number;
  confirmed?: boolean;
  loaded?: boolean;
  onRemove?: () => void;
  src?: string;
};

export const Document: FC<DocProps> = ({
  name,
  isPending,
  pendingProgress,
  confirmed,
  loaded,
  onRemove,
  src,
}) => {
  const { t } = useTranslation();
  const getType = (name: string) => {
    let parts = name.split('.');
    return parts[parts.length - 1];
  };
  return (
    <div className="document">
      <IconSprite
        name={`${getType(name)}-icon`}
        className={`document__img ${isPending ? '' : 'document__img_download'}`}
      />
      <div className="document__wrap">
        <div className="document__info">
          {src ? (
            <a
              className="document__name"
              download={name}
              href={`data:image/png;base64,${src}`}
            >
              {name}
            </a>
          ) : (
            <span className="document__name">{name}</span>
          )}
          {isPending && pendingProgress! < 100 && (
            <button
              className="document__cross-btn"
              type="button"
              onClick={onRemove}
            >
              <IconSprite name="cross" className="document__cross" />
            </button>
          )}
          {!confirmed && !isPending && (
            <button type="button" onClick={onRemove}>
              <IconSprite name="table-delete" className="document__delete" />
            </button>
          )}
        </div>
        {isPending && (
          <div className="document__line">
            <span
              className="document__percent"
              style={{ width: `${pendingProgress}%` }}
            ></span>
          </div>
        )}
        {!loaded && !isPending && (
          <span className="document__status">
            {t('lk:profile.document.notLoaded')}
          </span>
        )}
      </div>
    </div>
  );
};
