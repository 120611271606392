import { httpClient } from 'lib/request';

const getCountries = () => httpClient.get(`/geolocation/countries`);
const getRegions = (countryId: string | number) =>
  httpClient.get(`/geolocation/countries/${countryId}`);
const getСities = (regionId: string | number) =>
  httpClient.get(`/geolocation/regions/${regionId}`);

export const locationsApi = {
  getCountries,
  getRegions,
  getCities: getСities,
};
