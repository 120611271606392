import { createEvent } from 'effector';
import { spread } from 'patronum';

import { promotionModel } from 'features/promotion';

export const pageLoaded = createEvent<{ promotionLimit: number }>();
export const pageUnloaded = createEvent();

spread({
  source: pageLoaded,
  targets: {
    promotionLimit: [promotionModel.listInitialized],
  },
});
