import { userModel } from 'features/user';
import { useStore } from 'effector-react';
import React, { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  $languageIsSetted,
  getAvaiableLocalesFx,
  initLanguage,
} from '../model';
import { DEFAULT_CURRENCY } from 'lib/constants';

export const initFormaters = (lng: string, currencyProfile: any) => {
  const numberWithSpace = (number: number, options: any) =>
    new Intl.NumberFormat(lng, {
      ...options,
    }).format(number);

  const currencyNumber = (
    number: number,
    options: any,
    currency = currencyProfile,
  ) => {
    return new Intl.NumberFormat(lng, {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency,
      ...options,
    }).format(number);
  };

  return { numberWithSpace, currencyNumber };
};

export const FormatersComp = ({ children }: any) => {
  const [_, i18n] = useTranslation();
  const isSetted = useStore($languageIsSetted);

  const user = useStore(userModel.$user);
  const currency = user?.currency || DEFAULT_CURRENCY;

  React.useEffect(() => {
    initLanguage();
  }, []);

  return isSetted ? (
    <FormatersProvider currency={currency} lng={i18n?.language ?? 'ru'}>
      {children}
    </FormatersProvider>
  ) : null;
};

interface NumberFormatOptions {
  localeMatcher?: string;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  currencySign?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

const FormatersСtx = createContext({
  currencyNumber: (
    number: number = 0,
    options: NumberFormatOptions = {},
  ): any => {},
  numberWithSpace: (
    number: number = 0,
    options: NumberFormatOptions = {},
  ): any => {},
});

export const FormatersProvider = ({ children, currency, lng }: any) => {
  const utils: any = useMemo(() => initFormaters(lng, currency), [
    currency,
    lng,
  ]);
  return (
    <FormatersСtx.Provider value={utils}>{children}</FormatersСtx.Provider>
  );
};

export const useFormaters = () => useContext(FormatersСtx);
