import * as React from 'react';
import classNames from 'classnames';
import { useEmblaCarousel } from 'embla-carousel/react';
import { useRecursiveTimeout } from './useRecursiveTimeout';
import { useDebounce, useWindowRatio } from 'lib/hooks';
import { IconSprite } from 'ui';

import './style.css';
import { AlignmentOption } from 'embla-carousel/vanilla/components/alignment';

const DEFAULT_AUTOPLAY_MS = 2000;

// аккуратно менять в слайдере список зависимостей, иначе сломается
export const Slider: React.FC<SliderProps> = ({
  autoplay,
  dragFree,
  currentNumberSlide,
  fullWidth,
  slides,
  withButtons,
  className,
  swiper,
  smallBtns,
  slidesCount,
  loading,
  needReinit = true,
  playMS = DEFAULT_AUTOPLAY_MS,
  loop = true,
  off = false,
  slidesToScroll = 1,
  speed = 10,
  align = 'start',
  onSlide = () => {},
  onCantScrollNext = () => {},
  onCantScrollPrev = () => {},
  onCanScrollNext = () => {},
  onCanScrollPrev = () => {},
  onReinit = () => {},
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align,
    containScroll: 'trimSnaps',
    loop,
    dragFree,
    draggable: !off,
    slidesToScroll,
    inViewThreshold: 1,
    speed,
  });

  const ratio = useWindowRatio();
  const [debouncedWindowRatio] = useDebounce(ratio, 200);

  const [currentSlide, setCurrentSlide] = React.useState(currentNumberSlide);
  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const { play, stop } = useRecursiveTimeout(
    React.useCallback(() => {
      if (!embla) return;
      embla.scrollNext();
    }, [embla]),
    playMS,
  );

  React.useEffect(() => {
    if (!prevBtnEnabled) {
      onCantScrollPrev();
    } else {
      onCanScrollPrev();
    }
  }, [prevBtnEnabled]);

  React.useEffect(() => {
    if (!nextBtnEnabled) {
      onCantScrollNext();
    } else {
      onCanScrollNext();
    }
  }, [nextBtnEnabled]);

  const scrollPrev = React.useCallback(() => {
    embla && embla.scrollPrev();
  }, [embla]);

  const scrollNext = React.useCallback(() => {
    embla && embla.scrollNext();
  }, [embla]);

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setCurrentSlide(embla.selectedScrollSnap());
    onSlide(embla.selectedScrollSnap());
  }, [embla]);

  const scrollTo = React.useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla],
  );

  React.useEffect(() => {
    scrollTo(currentNumberSlide);
  }, [currentNumberSlide]);

  React.useEffect(() => {
    if (embla) {
      embla.reInit();
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }
  }, [off, loop, dragFree, slidesCount, loading, slides.length]);

  React.useEffect(() => {
    if (embla && needReinit) {
      embla.reInit();
      onReinit();
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }
  }, [needReinit]);

  React.useEffect(() => {
    if (embla) {
      embla.reInit();
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }
  }, [debouncedWindowRatio]);

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, stop]);

  React.useEffect(() => {
    if (autoplay) {
      play();
    } else {
      stop();
    }
  }, [play, autoplay, stop]);

  return (
    <div className={classNames('embla', className, smallBtns && 'smallBtns')}>
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {slides.map((slide, index) => (
            <div
              className="embla__slide"
              style={{
                minWidth:
                  !fullWidth && slidesCount
                    ? `${Math.round(100 / slidesCount)}%`
                    : 'auto',
                maxWidth:
                  !fullWidth && slidesCount
                    ? `${Math.round(100 / slidesCount)}%`
                    : 'auto',
              }}
              key={index}
            >
              <div className="embla__slide__inner">{slide}</div>
            </div>
          ))}
        </div>
        {swiper && (
          <div className="swiper-pagination swiper-pagination-bullets">
            {Array.from(
              { length: Math.ceil(slides.length / slidesToScroll) },
              (_, idx) => (
                <span
                  onClick={() => scrollTo(idx)}
                  key={idx}
                  className={classNames(
                    'swiper-pagination-bullet',
                    idx === currentSlide && 'swiper-pagination-bullet-active',
                  )}
                />
              ),
            )}
          </div>
        )}
      </div>

      {withButtons && (
        <>
          <button
            disabled={!prevBtnEnabled}
            className="embla__button embla__button--prev"
            onClick={scrollPrev}
          >
            <IconSprite className="embla__button__svg" name="intro-arrow" />
          </button>
          <button
            disabled={!nextBtnEnabled}
            className="embla__button embla__button--next"
            onClick={scrollNext}
          >
            <IconSprite className="embla__button__svg" name="intro-arrow" />
          </button>
        </>
      )}
    </div>
  );
};

interface SliderProps {
  speed?: number;
  align?: AlignmentOption;
  currentNumberSlide?: number;
  autoplay?: boolean;
  slides: React.ReactNode[];
  withButtons?: boolean;
  slidesCount?: number;
  dragFree?: boolean;
  swiper?: boolean;
  playMS?: number;
  className?: string;
  loop?: boolean;
  off?: boolean;
  fullWidth?: boolean;
  slidesToScroll?: number;
  smallBtns?: boolean;
  loading?: boolean;
  needReinit?: boolean;
  onSlide?: (number: number) => void;
  onCantScrollNext?: () => void;
  onCantScrollPrev?: () => void;
  onCanScrollNext?: () => void;
  onCanScrollPrev?: () => void;
  onReinit?: () => void;
}
