import React from 'react';
import { useStore } from 'effector-react';
import { Grid } from 'ui';
import { lotteryModel } from 'features/lotteries';
import { LotteryItem } from './lottery-item';
import './style.css';

interface ListProps {
  skeletonSize?: number;
}

export const LotteryList: React.FC<ListProps> = ({ skeletonSize = 2 }) => {
  const lotteryList = useStore(lotteryModel.$renderedList);
  const listIsEmpty = lotteryList.length === 0;
  const isPending = useStore(lotteryModel.$isPending);

  React.useEffect(() => {
    return () => lotteryModel.listUnmounted();
  }, []);

  if (isPending) {
    return (
      <Grid container>
        {Array.from({ length: skeletonSize }, (_, idx) => (
          <Grid className="grid-lot-item" xs={6} item key={idx}>
            <div className="lot skeleton loading" />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (listIsEmpty) {
    return null;
  }

  return (
    <Grid container>
      {lotteryList.map((lot) => (
        <Grid className="grid-lot-item" key={lot.id} item xs={6}>
          <LotteryItem lottery={lot} />
        </Grid>
      ))}
    </Grid>
  );
};
