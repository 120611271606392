import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Button, Counter } from 'ui';
import { useModal } from 'lib/hooks';
import { skinModel } from 'features/skin-id';

import { availableBonusesModel } from '../bonus-available';
import {
  BonusCardWrap,
  BonusSubtitle,
  BonusTitle,
  CancelWofSpinsModal,
  BonusDescription,
} from '../molecules';

import * as model from '../model';

interface Props {
  className?: string;
  ws: WofSpin;
  idx?: number;
}

export const WofCard: React.FC<Props> = ({ className, ws, idx = 0 }) => {
  const { t } = useTranslation();
  const { multiplier } = ws;

  const skinId = skinModel.selectors.useSkinId();

  const wofSpins = availableBonusesModel.selectors.useSpinsListByMultiplier(
    multiplier,
  );

  const cancelModal = useModal();

  const wofSpinsCount = wofSpins.length;

  if (wofSpinsCount === 0) {
    return null;
  }

  return (
    <>
      {cancelModal.isVisible && (
        <CancelWofSpinsModal
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <BonusCardWrap className={className} idx={idx}>
        <div className="bonus-card__info">
          <BonusTitle color="red">{wofSpinsCount}</BonusTitle>
          <BonusSubtitle>
            {t('bonus:subtitle.wof', { count: wofSpinsCount })}
          </BonusSubtitle>
          <Counter
            onOver={() => {
              availableBonusesModel.loadWofSpinsFx();
            }}
            date={wofSpins[0].expire_at}
          />
          <img
            src={`/img/${skinId}/wheel-of-fortune/wof-card-icon.svg`}
            className="wof-icon"
          />
          <BonusDescription.Wrap>
            <BonusDescription.Item
              className="multiplier-item"
              text={`x${multiplier}`}
              description={t('bonus:info.multiplier')}
            />
          </BonusDescription.Wrap>
        </div>
        <div className="bonus-card__nav">
          <Button onClick={() => model.wofSpinButtonClicked(ws)}>
            {t('bonus:buttons.spinWof')}
          </Button>
          <span
            className="player-bonus-card__cancel"
            onClick={cancelModal.show}
          >
            {t('common:buttons.cancel')}
          </span>
        </div>
      </BonusCardWrap>
    </>
  );
};
