import * as React from 'react';
import { useStore } from 'effector-react';

import { $session, fetchSession, $isWaitingFor, killSession } from '../model';
import { getQueryParam } from 'lib/query';
import { authFormModel } from 'features/auth';

export function useSession(): Session | null {
  return useStore($session);
}

export function useSessionWaiting(): boolean {
  return useStore($isWaitingFor);
}

export function useSessionFetch(): void {
  React.useEffect(() => {
    const token = getQueryParam(document.location.search, 'token');
    if (token) {
      authFormModel.checkUserFx(token);
    } else {
      fetchSession();
    }
  }, []);
}

export function useSessionKiller(): () => void {
  return React.useCallback(() => killSession(), []);
}
