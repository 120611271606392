import React from 'react';
import { useStore } from 'effector-react';

import { Trans, useTranslation } from 'react-i18next';

import {
  Article,
  Container,
  Section,
  SectionTitle,
  TermsList,
  TermsSublist,
  TermsText,
  TermsTitle,
  TermsWrap,
} from 'ui';
import { chatModel } from 'features/chat';
import { languageModel } from 'features/i18';

import './style.css';

export const ContactsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const { email, operator, site } = useStore(
    languageModel.resourses.contactsResource,
  );
  const clubName = useStore(languageModel.$clubName);

  return (
    <Section type="info" className="contact--page">
      <Container small padding={false}>
        <SectionTitle>{t('contacts:title')}</SectionTitle>
        <Article violet>
          <TermsWrap numeric={false}>
            <TermsList>
              <TermsTitle>{t('contacts:0.title', { clubName })}</TermsTitle>
              <TermsTitle>
                {t('contacts:1.title')}
                <TermsSublist noContent>
                  {/*<TermsText>*/}
                  {/*  <Trans i18nKey="contacts:1.0">*/}
                  {/*    text*/}
                  {/*    <a className="contact-link" href="tel:+78122009512">*/}
                  {/*      <span>+78122009512</span>*/}
                  {/*    </a>*/}
                  {/*  </Trans>*/}
                  {/*</TermsText>*/}
                  <TermsText>
                    <Trans i18nKey="contacts:1.1">
                      text
                      <a className="contact-link" href={`mailto:${email}`}>
                        {{ email }}
                      </a>
                    </Trans>
                  </TermsText>
                  <TermsText>
                    <Trans i18nKey="contacts:1.2">
                      text
                      <span
                        onClick={() => chatModel.chatOpened()}
                        className="contact-link"
                      >
                        online chat
                      </span>
                    </Trans>
                  </TermsText>
                </TermsSublist>
              </TermsTitle>
              <TermsTitle>
                <Trans i18nKey="contacts:2.title">
                  text
                  <a className="contact-link" href={`mailto:${email}`}>
                    {{ email }}
                  </a>
                </Trans>
              </TermsTitle>
              <TermsTitle>
                {t('contacts:3.title', { operator, site })}
              </TermsTitle>
            </TermsList>
          </TermsWrap>
        </Article>
      </Container>
    </Section>
  );
};
