import {
  attach,
  createEvent,
  createStore,
  forward,
  guard,
  sample,
} from 'effector';

import { languageModel } from 'features/i18';

const PAGE_SCOPE_NAME = 'cancellationPolicyPage';

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .on(pageUnloaded, () => false);

const loadResourceInCurrentPageFx = attach({
  effect: languageModel.getResourceByScopeFx,
  mapParams: (_) => PAGE_SCOPE_NAME,
});

export const $pageResource = createStore<{ text: string }>({ text: '' });

forward({
  from: pageLoaded,
  to: loadResourceInCurrentPageFx,
});

sample({
  clock: loadResourceInCurrentPageFx.doneData,
  target: $pageResource,
  fn: ({ text }) => ({ text }),
});

guard({
  clock: guard(languageModel.$language, { filter: Boolean }),
  filter: $pageIsLoaded,
  target: loadResourceInCurrentPageFx,
});
