import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { useStore } from 'effector-react';

import {
  Article,
  ArticleImg,
  ArticleInfo,
  Container,
  Section,
  SectionTitle,
} from 'ui';
import { PromotionSection } from 'features/promotion';
import { skinModel } from 'features/skin-id';

import * as model from './model';
import './style.css';

export const AboutPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const { text } = useStore(model.$pageResource);
  const skinId = skinModel.selectors.useSkinId();

  return (
    <>
      <Section type="info">
        <Container padding={false} small>
          <SectionTitle>{t('about:title')}</SectionTitle>
          <Article className="about-article">
            <ArticleImg>
              <img
                className="about-img"
                src={`/img/${skinId}/about-img.jpg`}
                alt=""
              />
            </ArticleImg>
            <ArticleInfo>
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
              />
            </ArticleInfo>
          </Article>
        </Container>
        <PromotionSection />
      </Section>
    </>
  );
};
