import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AnimatedNumber from 'react-animated-number';

import { LinkButton, SectionTitle, SvgDecorators } from 'ui';
import { useFormaters } from 'features/i18';
import { paths } from 'pages/paths';
import { jackpotApi } from 'api/jackpot';
import { mediaHooks } from 'lib/media';

import * as model from '../model';
import './style.css';
import { skinModel } from '../../skin-id';

interface Props {
  jackpot?: Jackpot | null;
  isLoading?: boolean;
  withButton?: boolean;
  className?: string;
  list?: boolean;
}

const DEFAULT_IMAGE_SRC = '/img/jack-img.png';
const GET_JACKPOT_WORD_SRC = (skinId) => `/img/${skinId}/JACKPOT.svg`;

export const JackpotArticle: React.FC<Props> = ({
  jackpot,
  isLoading = false,
  withButton,
  className,
  list,
}) => {
  const { t } = useTranslation();
  const skinId = skinModel.selectors.useSkinId();

  if (isLoading || !jackpot) {
    return <JackpotArticleSkeleton />;
  }

  const { title, jackpot_id } = jackpot;

  return (
    <div className={classNames('jackpot-article', className)}>
      <BgImage jackpot={jackpot} list={list} />
      <div className="jackpot-article__wrap">
        <div className="upper-section">
          <SectionTitle>{title}</SectionTitle>
          <img
            src={GET_JACKPOT_WORD_SRC(skinId)}
            alt=""
            className="jackpot-word"
          />
        </div>
        <div className="footer-section">
          <div className="lottery">
            <SvgDecorators />
            <div className="lottery__wrap">
              <JackpotSum isLoading={isLoading} />
            </div>
          </div>
          {withButton && (
            <LinkButton to={paths.jackpot(jackpot_id)}>
              {t('common:buttons.more')}
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  );
};

const BgImage = ({ jackpot, list }: { jackpot: Jackpot; list?: boolean }) => {
  const isLargeMobile = mediaHooks.useIsLargeMobile();
  const [imageHasError, setImageHasError] = React.useState(false);

  const {
    details_image_id,
    preview_image_id,
    mobile_preview_image_id,
  } = jackpot;

  const jackpotImageSrc = useMemo(() => {
    if (imageHasError) {
      return DEFAULT_IMAGE_SRC;
    }

    if (list) {
      return jackpotApi.getImage(
        (isLargeMobile ? mobile_preview_image_id : preview_image_id) ||
          details_image_id,
      );
    }

    return jackpotApi.getImage(details_image_id);
  }, [imageHasError, preview_image_id, details_image_id, isLargeMobile]);

  return (
    <img
      className="jackpot-bg-image"
      src={jackpotImageSrc}
      onError={() => setImageHasError(true)}
      alt=""
    />
  );
};

const JackpotSum = ({ isLoading }: { isLoading: boolean }) => {
  const { currencyNumber } = useFormaters();
  const sum = useStore(model.$jackpotSum);

  return (
    <span className={classNames('lottery__count', isLoading && 'loading')}>
      {sum && !isLoading && (
        <AnimatedNumber
          component="span"
          value={sum}
          duration={500}
          formatValue={(n) => currencyNumber(n)}
        />
      )}
    </span>
  );
};

const JackpotArticleSkeleton = () => (
  <div className="jackpot-article">
    <div className="jackpot-article__wrap">
      <div className="lottery">
        <SvgDecorators />
        <div className="lottery__wrap">
          <span className={classNames('lottery__count', 'loading')} />
        </div>
      </div>
    </div>
  </div>
);
