import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { sessionModel } from 'features/session';

import { IconSprite } from 'ui';

export const HeaderLogoutButton = ({ withText = false }) => {
  const { t } = useTranslation();
  const isAuth = useStore(sessionModel.$isAuthenticated);

  return isAuth ? (
    <button
      className="header__log-out"
      onClick={() => sessionModel.killSession()}
      type="button"
    >
      <IconSprite name="log-out-icon" />
      {withText && (
        <span className="header__log-in">{t('common:buttons.exit')}</span>
      )}
    </button>
  ) : null;
};
