import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'effector';

import { useFormaters } from 'features/i18';
import { Button, IconSprite, MainForm, MainFormRow } from 'ui';
import { CARD_FIELD_NAMES } from '../constants';
import { PaymentDataField } from './payment-data-field';
import { useStore } from 'effector-react';

import * as model from '../model';

interface Props {
  fields: PaymentDataField[];
  sum: number;
  type: 'withdraw' | 'deposit';
  disabled: boolean;
  $dataFieldsErrors: Store<Record<string, DataFieldError>>;
  $dataFieldsValues: Store<Record<string, string>>;
  onSubmit: () => void;
  onFieldChanged: (body: { fieldName: string; value: string }) => void;
}

export const CardInfoForm: React.FC<Props> = ({
  fields,
  sum,
  disabled,
  type,
  onSubmit,
  onFieldChanged,
  $dataFieldsErrors,
  $dataFieldsValues,
}) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const {
    monthField,
    yearField,
    cardField,
    cvvField,
    holderField,
  } = React.useMemo(() => {
    const cardField = fields.find(
      (f) => f.name === CARD_FIELD_NAMES.CARD_NUMBER,
    );
    const monthField = fields.find(
      (f) => f.name === CARD_FIELD_NAMES.CARD_EXP_MONTH,
    );
    const yearField = fields.find(
      (f) => f.name === CARD_FIELD_NAMES.CARD_EXP_YEAR,
    );
    const holderField = fields.find(
      (f) => f.name === CARD_FIELD_NAMES.CARD_HOLDER,
    );
    const cvvField = fields.find((f) => f.name === CARD_FIELD_NAMES.CARD_CVV);

    return { monthField, yearField, cvvField, cardField, holderField };
  }, [fields]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <MainForm className="card-form__description" isForm={false}>
        {/* <MainFormRow label="Описание:">
          <div>Invoice i_141599300</div>
        </MainFormRow> */}
        <MainFormRow
          label={t(
            `lk:deposit.${
              type === 'deposit' ? 'sumForPayment' : 'sumForWithdraw'
            }`,
          )}
        >
          <div className="card-form__description-sum">
            {currencyNumber(sum)}
          </div>
        </MainFormRow>
      </MainForm>
      <MainForm className="card-form cashbox-mainform" onSubmit={handleSubmit}>
        {cardField && (
          <PaymentDataField
            onChange={(e) =>
              onFieldChanged({
                fieldName: cardField.name,
                value: e.target.value,
              })
            }
            $dataFieldsValues={$dataFieldsValues}
            $dataFieldsErrors={$dataFieldsErrors}
            field={cardField}
          />
        )}
        <MainFormRow>
          <div className="full_card_info">
            <div className="exp">
              {monthField && (
                <MonthField
                  field={monthField}
                  onFieldChanged={onFieldChanged}
                  $dataFieldsValues={$dataFieldsValues}
                  $dataFieldsErrors={$dataFieldsErrors}
                />
              )}
              <span>{`/`}</span>
              {yearField && (
                <YearField
                  field={yearField}
                  onFieldChanged={onFieldChanged}
                  $dataFieldsValues={$dataFieldsValues}
                  $dataFieldsErrors={$dataFieldsErrors}
                />
              )}
            </div>
            <div className="exp">
              {cvvField && (
                <CvvField
                  field={cvvField}
                  onFieldChanged={onFieldChanged}
                  $dataFieldsValues={$dataFieldsValues}
                  $dataFieldsErrors={$dataFieldsErrors}
                />
              )}
              <IconSprite className="cvv-icon" name="cvv-icon" />
            </div>
          </div>
        </MainFormRow>
        {holderField && (
          <PaymentDataField
            onChange={(e) =>
              onFieldChanged({
                fieldName: holderField.name,
                value: e.target.value,
              })
            }
            $dataFieldsValues={$dataFieldsValues}
            $dataFieldsErrors={$dataFieldsErrors}
            field={holderField}
          />
        )}
        <Button disabled={disabled} type="submit">
          {t('common:buttons.pay')}
        </Button>
        <MainFormRow>
          <div className="payments__imgs">
            <img alt="" src="/img/payments/footer-dss-logo.svg" />
            <img alt="" src="/img/payments/footer-ms-logo.svg" />
            <img alt="" src="/img/payments/footer-visa-logo.svg" />
          </div>
        </MainFormRow>
      </MainForm>
    </>
  );
};

interface FieldProps {
  field: PaymentDataField;
  $dataFieldsErrors: Store<Record<string, DataFieldError>>;
  $dataFieldsValues: Store<Record<string, string>>;
  onFieldChanged: (body: { fieldName: string; value: string }) => void;
}

const MonthField: React.FC<FieldProps> = ({
  field,
  onFieldChanged,
  $dataFieldsErrors,
  $dataFieldsValues,
}) => {
  const handleChange = (e) => {
    let value = e.target.value as string;

    if (value.length > 2) {
      value = value.substring(0, 2);
    }

    if (value === '00') {
      value = value.substring(0, 1);
    }

    const valueNum = parseInt(value) || 0;
    if (valueNum > 12 && valueNum < 20) {
      value = value.substring(0, 1);
    }

    if (valueNum >= 20) {
      value = `0${value.charAt(0)}`;
    }

    onFieldChanged({
      fieldName: field.name,
      value,
    });

    if (value.length === 2) {
      model.focusYear(true);
    }
  };

  return (
    <PaymentDataField
      type="number"
      onChange={handleChange}
      $dataFieldsValues={$dataFieldsValues}
      $dataFieldsErrors={$dataFieldsErrors}
      onlyField={true}
      showLabel={false}
      field={field}
      extPlaceholder="00"
    />
  );
};

const YearField: React.FC<FieldProps> = ({
  field,
  onFieldChanged,
  $dataFieldsErrors,
  $dataFieldsValues,
}) => {
  const yearFocused = useStore(model.$yearFocus);

  const inputRef = useRef(null);

  useEffect(() => {
    if (yearFocused && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [yearFocused]);

  const handleChange = (e) => {
    let value = e.target.value as string;

    if (value.length > 2) {
      value = value.substring(0, 2);
    }

    onFieldChanged({
      fieldName: field.name,
      value,
    });

    if (value.length === 2) {
      model.focusCvv(true);
    }
  };

  return (
    <PaymentDataField
      type="number"
      onChange={handleChange}
      $dataFieldsValues={$dataFieldsValues}
      $dataFieldsErrors={$dataFieldsErrors}
      onlyField={true}
      showLabel={false}
      field={field}
      extPlaceholder="00"
      inputRef={inputRef}
      onBlur={() => model.focusYear(false)}
    />
  );
};

const CvvField: React.FC<FieldProps> = ({
  field,
  onFieldChanged,
  $dataFieldsErrors,
  $dataFieldsValues,
}) => {
  const cvvFocused = useStore(model.$cvvFocus);

  const inputRef = useRef(null);

  useEffect(() => {
    if (cvvFocused && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [cvvFocused]);

  const handleChange = (e) => {
    let value = e.target.value as string;

    if (value.length > 3) {
      value = value.substring(0, 3);
    }

    onFieldChanged({
      fieldName: field.name,
      value,
    });
  };

  return (
    <PaymentDataField
      type="number"
      onChange={handleChange}
      $dataFieldsValues={$dataFieldsValues}
      $dataFieldsErrors={$dataFieldsErrors}
      onlyField={true}
      wrapperClassName="cvv"
      showLabel={false}
      field={field}
      extPlaceholder="CVV"
      inputRef={inputRef}
      onBlur={() => model.focusCvv(false)}
    />
  );
};
