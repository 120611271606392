import { createListModel } from 'features/list';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  Effect,
  guard,
  restore,
  sample,
} from 'effector';
import { status } from 'patronum';

import { deviceModel } from 'features/device';
import { tournamentApi } from 'api/tournament';
import { sessionModel } from 'features/session';

const DEFAULT_LIMIT = 2;

export const loadMainTournament = createEvent();

export const loadTournamentListFx: Effect<
  void,
  TournamentListResponse,
  Error
> = attach({
  effect: createEffect<boolean, TournamentListResponse, Error>(
    async (isMobile) => tournamentApi.getList(isMobile),
  ),
  source: deviceModel.$isMobile,
  mapParams: (_, isMobile) => isMobile,
});

export const getMainTournamentFx: Effect<void, TournamentItem, Error> = attach({
  effect: createEffect<boolean, TournamentItem, Error>(async (isMobile) =>
    tournamentApi.getMain(isMobile),
  ),
  source: deviceModel.$isMobile,
  mapParams: (_, isMobile) => isMobile,
});

const $mainTournamentStatus = status({ effect: getMainTournamentFx });
const $mainTournamentIsNotLoaded = $mainTournamentStatus.map(
  (status) => status !== 'done',
);
export const $error = restore(getMainTournamentFx.failData, null);
export const $mainTournamentData = createStore<TournamentItem | null>(null);

export const $needToLoad = createStore(true).reset(
  sessionModel.$isAuthenticated,
);

export const {
  pagination,
  listInitialized,
  listUnmounted,
  $list,
  loadList,
  $renderedList,
  $status,
  $isPending,
  $isListEmpty,
  $mounted,
} = createListModel<TournamentItem, TournamentListResponse>({
  effect: loadTournamentListFx,
  defaultLimit: DEFAULT_LIMIT,
  needToLoad: $needToLoad,
  paired: true,
  responseFn: ({ active, future, archive }) =>
    [active, ...future, ...archive].filter(Boolean),
});

sample({
  source: loadTournamentListFx.doneData,
  target: $needToLoad,
  fn: () => false,
});

sample({
  source: sessionModel.$isAuthenticated,
  target: $needToLoad,
  fn: () => true,
});

guard({
  source: $needToLoad,
  filter: $mounted,
  target: loadList,
});

sample({
  source: getMainTournamentFx.doneData,
  target: $mainTournamentData,
});

guard({
  source: loadMainTournament,
  filter: $mainTournamentIsNotLoaded,
  target: getMainTournamentFx,
});
