import * as React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ButtonLink } from 'ui';

import * as model from '../model';

export const InifiniteLoader = ({
  list,
  render,
  withScroll = true,
  withButton = true,
  renderSize = 12,
  name,
  ...props
}) => {
  const didMountRef = React.useRef(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    model.listInitialized({ list, name, renderSize });
  }, [list, name]);

  React.useEffect(() => {
    if (didMountRef.current) {
      model.setRenderSize({ name, count: renderSize });
    } else didMountRef.current = true;
  }, [renderSize]);

  React.useEffect(() => {
    return () => {
      model.resetTotalSize(name);
    };
  }, [name]);

  const gamesList = useStore(model.$renderedGameList);
  const isLoadedList = useStore(model.$isAllGameLoaded);
  const currentGameList = gamesList[name];

  if (!currentGameList) {
    return null;
  }

  const currentGamesIsLoaded = isLoadedList[name];

  const length = currentGameList?.length || 0;

  return withScroll ? (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      loader={null}
      hasMore={!currentGamesIsLoaded}
      dataLength={length}
      next={() => model.addRenderSize(name)}
      {...props}
    >
      {render(currentGameList)}
    </InfiniteScroll>
  ) : (
    <>
      {render(currentGameList)}
      {withButton && (
        <div className="game-nav">
          {!currentGamesIsLoaded && (
            <ButtonLink
              onClick={() => model.addRenderSize(name)}
              border
              theme="primary"
            >
              {t('common:buttons.more-games-v2')}
            </ButtonLink>
          )}
        </div>
      )}
    </>
  );
};
