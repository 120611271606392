import * as React from 'react';
import { reflect } from '@effector/reflect';

import { Button } from 'ui';

export const SubmitPaymentButton = reflect({
  view: Button,
  bind: {
    type: 'submit',
  },
});
