import { createEffect, createEvent, forward, restore } from 'effector';

import { bonusApi } from 'api/bonus';
import { userModel } from 'features/user';

export const deleteBonus = createEvent<PlayerBonus>();

export const loadActiveBonusFx = createEffect<
  void,
  PlayerBonus | null,
  ApiError
>(bonusApi.getActiveBonus);

export const $activeBonus = restore(
  loadActiveBonusFx.doneData,
  null,
).on(deleteBonus, (active, bonus) => (active?.id === bonus.id ? null : active));
