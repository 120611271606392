import { isValidPhoneNumber } from 'libphonenumber-js';

export function validateEmail(value: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );
}

export function validateIsFilled(value: string): boolean {
  return value.length > 0;
}

export function validatePhone(value: string): boolean {
  return isValidPhoneNumber(value.startsWith('+') ? value : `+${value}`);
}
