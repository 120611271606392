import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { Route, Switch } from 'react-router-dom';

import { paths } from 'pages/paths';
import { deviceModel } from 'features/device';
import { HallNav, HallsNamesSlider } from 'features/halls';
import { Intro } from 'features/intro';

import { LobbyPage } from './lobby/page';
import { HomePage } from './home/page';

import * as model from './model';

export const MainPage = () => {
  const isDesktop = useStore(deviceModel.$isDesktop);
  const ref = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    model.pageLoaded();
    window.scrollTo(0, 0);
    return () => model.pageUnloaded();
  }, []);

  React.useEffect(() => {
    model.setInfoSectionRef(ref);
  }, [ref]);

  return (
    <>
      <Intro isDesktop={isDesktop} />
      {isDesktop ? <HallsNamesSlider ref={ref} /> : <HallNav />}
      <div ref={ref}>
        <Switch>
          <Route path={paths.category(':category')} component={LobbyPage} />
          <Route path={paths.home()} component={HomePage} />
        </Switch>
      </div>
    </>
  );
};
