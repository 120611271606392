import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { paymentsInfoModel } from 'features/payments-info';
import { BalanceItem } from 'features/user';

export const RequiredBetBalance = () => {
  const { t } = useTranslation();
  const { fee, required_bet } = useStore(paymentsInfoModel.$depositWageringFee);

  return (
    <BalanceItem
      icon="white"
      commission={`${t('common:balance.comission')} ${fee}%`}
      count={required_bet}
      title={t('common:balance.unplayed')}
    />
  );
};
