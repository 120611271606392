import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, ResultInfo, ResultText, ResultWrap } from 'ui';

interface Props {
  count: number;
  value: string;
}

export const SearchResultMessage: React.FC<Props> = ({ count, value }) => {
  const { t } = useTranslation();

  return value ? (
    <Container>
      <ResultWrap>
        {count ? (
          <ResultText>
            {t('game:search.onRequest')} <ResultInfo>{value}</ResultInfo>{' '}
            {t('game:search.foundGames', { count })}
          </ResultText>
        ) : (
          <ResultText bg>
            {t('game:search.notFound')}: {value}
          </ResultText>
        )}
      </ResultWrap>
    </Container>
  ) : null;
};
