import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Container, SectionTitle } from 'ui';
import { mediaHooks } from 'lib/media';
import { PromotionList } from './promotion-list';

import { promotionModel } from '..';

export const PromotionSection = () => {
  const { t } = useTranslation();
  const isTablet = mediaHooks.useIsTablet();

  const promotionLimit = React.useMemo(() => (isTablet ? 5 : 3), [isTablet]);

  const promotionList = useStore(promotionModel.$list);
  const isPending = useStore(promotionModel.$isPending);
  const error = useStore(promotionModel.$error);

  React.useEffect(() => {
    promotionModel.listInitialized();
    return () => promotionModel.listUnmounted();
  }, []);

  React.useEffect(() => {
    promotionModel.pagination.setLimit(promotionLimit);
  }, [promotionLimit]);

  if ((!isPending && promotionList.length === 0) || error) {
    return null;
  }

  return (
    <div className="stock-section">
      <Container small padding>
        <SectionTitle>{t('common:menu.stocks')}</SectionTitle>
        <PromotionList mob />
      </Container>
    </div>
  );
};
