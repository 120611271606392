export const objectToQuery = (params: Object) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const getQueryParam = (query: string, param: string) => {
  return query
    ?.substring(1)
    ?.split('&')
    ?.find((el) => el.startsWith(param))
    ?.substring(param.length + 1);
};
