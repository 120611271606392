import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SecondaryInfoModal } from 'ui';
import { useModal } from 'lib/hooks';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  GiftSpinsBonusTitle,
  FreespinImg,
} from '../molecules';

import * as model from '../model';

interface Props {
  className?: string;
  bonus: BonusProgramTask;
  idx: number;
}

export const TaskBonusCard: React.FC<Props> = ({ className, bonus, idx }) => {
  const { t } = useTranslation();

  const { giftspins, spins_left = 0 } = bonus;

  return (
    <>
      <BonusCardWrap className={classNames(className, 'task-gs')} idx={idx}>
        <div className="bonus-card__info">
          <GiftSpinsBonusTitle reward_giftspins={giftspins} />
          <BonusSubtitle>
            {t('bonus:subtitle.freeSpins', { count: spins_left })}
          </BonusSubtitle>
          <FreespinImg />
          <BonusDescription.GiftSpinInfo giftspins={giftspins} />
        </div>
        <div className="bonus-card__nav">
          <Button onClick={() => model.giftSpinClicked(giftspins)}>
            {t('bonus:buttons.spinFS')}
          </Button>
        </div>
      </BonusCardWrap>
    </>
  );
};
