import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './style.css';

export const NavLink = ({
  children,
  to,
  active,
  onClick,
  style,
}: {
  children: React.ReactNode;
  to: string;
  active?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}) => {
  return (
    <Link
      style={style}
      onClick={onClick}
      className={classNames('nav__link', active && 'active')}
      to={to}
    >
      {children}
    </Link>
  );
};

export const NavLinkButton = ({
  children,
  onClick,
  active,
}: {
  active?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <span
      className={classNames('nav__link', active && 'active')}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
