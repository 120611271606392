import {
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  sample,
} from 'effector';
import { spread } from 'patronum';

import { jackpotModel } from 'features/jackpot';
import { lotteryModel } from 'features/lotteries';
import { tournamentModel } from 'features/tournaments';
import { hallModel } from 'features/halls';
import { Halls } from 'lib/constants';
import { sessionModel } from 'features/session';

import { $infoSectionRef } from '../model';

export const pageLoaded = createEvent<{
  lotteryLimit: number;
}>();
export const pageUnloaded = createEvent();

export const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .on(pageUnloaded, () => false);

export const moreGamesButtonClicked = createEvent<void>();

export const $newGames = hallModel.$gamesByHall.map(
  (halls) => halls[Halls.New] || [],
);
export const $slotsGames = hallModel.$gamesByHall.map(
  (halls) => halls[Halls.Slots] || [],
);

export const $slotsGamesIsLoading = combine(
  $slotsGames,
  hallModel.$gamesByHallStatus,
  (games, status) => games.length === 0 && status === 'pending',
);

export const $newGamesIsLoading = combine(
  $newGames,
  hallModel.$gamesByHallStatus,
  (games, status) => games.length === 0 && status === 'pending',
);

sample({
  clock: pageLoaded,
  target: hallModel.loadGamesByHall,
  fn: () => Halls.New,
});

sample({
  clock: pageLoaded,
  target: hallModel.loadGamesByHall,
  fn: () => Halls.Slots,
});

sample({
  clock: guard(sessionModel.$isAuthenticated, { filter: $pageIsLoaded }),
  target: hallModel.loadGamesByHall,
  fn: () => Halls.Slots,
});

forward({
  from: pageLoaded,
  to: [
    jackpotModel.mounted,
    lotteryModel.listMounted,
    tournamentModel.listInitialized,
    tournamentModel.loadMainTournament,
    hallModel.loadAllGames,
    hallModel.loadPopularGames,
  ],
});

spread({
  source: pageLoaded,
  targets: {
    lotteryLimit: lotteryModel.setLimit,
  },
});

moreGamesButtonClicked.watch(() => {
  $infoSectionRef.getState()?.current?.scrollIntoView();
});

forward({
  from: pageUnloaded,
  to: [jackpotModel.unmounted, tournamentModel.listUnmounted],
});
