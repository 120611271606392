import Payment from 'payment';

const currentYear = Number(new Date().getFullYear().toString().slice(2));

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCVC(value) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  // if (allValues.number) {
  //   const issuer = Payment.fns.cardType(allValues.number);
  //   console.log(issuer);
  //   maxLength = issuer === 'amex' ? 4 : 3;
  // }

  return clearValue.slice(0, maxLength);
}

export function formatHolder(value) {
  const regexp = /^[a-zA-Z-\s.]*$/;

  if (!regexp.test(value)) {
    return value.slice(0, value.length - 1);
  }

  return value;
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'visa':
    case 'mastercard':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatExpirationMonth(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length === 2 && +clearValue > 12) {
    return `${clearValue.slice(0, 1)}`;
  }

  if (clearValue.length > 2) {
    return `${clearValue.slice(0, 2)}`;
  }

  return clearValue;
}

export function formatExpirationYear(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length === 2 && +clearValue > currentYear + 20) {
    return `${clearValue.slice(0, 1)}`;
  }

  if (clearValue.length === 2 && +clearValue < currentYear) {
    return '';
  }

  if (clearValue.length > 2) {
    return `${clearValue.slice(0, 2)}`;
  }

  return clearValue;
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}`;
  }

  return clearValue;
}
