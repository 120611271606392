import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { emailVerification } from 'features/user/model';
import { Toast } from 'ui';

export const EmailModal = () => {
  const { t } = useTranslation();
  const { isOpen, content } = useStore(emailVerification.$modal);
  return isOpen ? (
    <Toast
      type={content.toLowerCase().includes('error') ? 'error' : 'success'}
      isOpen
      onAnimationEnd={emailVerification.closeModal}
    >
      {t(`lk:profile.${content}`)}
    </Toast>
  ) : null;
};
