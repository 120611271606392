import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';

import { Container, Section, SectionTitle } from 'ui';
import { Button } from 'ui/atoms/button';
import { TournamentItemBig, TournamentTable } from 'features/tournaments';
import { GamesSection } from 'features/halls';
import { useMedia } from 'lib/media';

import { userModel } from 'features/user/model';
import { sessionModel } from 'features/session';

import './style.css';
import * as model from './model';

export const TournamentPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({ id });
    return () => model.pageUnloaded();
  }, [id]);

  const tournamentData = useStore(model.$tournamentData);
  const status = useStore(model.$status);
  const userBalance = useStore(userModel.$balance);
  const userCurrency = useStore(userModel.$currency);
  const isAuth = useStore(sessionModel.$isAuthenticated);
  const isTournamentParticipant = useStore(model.$isTournamentParticipant);

  const tournametJoinText = useMemo(() => {
    return tournamentData?.entrance_fee
      ? t('tournament:joinFeeButton', {
          summa: tournamentData?.entrance_fee,
          currency: userCurrency,
        })
      : t('tournament:joinForFreeButton');
  }, [t, tournamentData?.entrance_fee, userCurrency]);

  const showJoinButton =
    isAuth && Boolean(tournamentData) && !isTournamentParticipant;
  const enabledJoinButton =
    tournamentData && tournamentData?.entrance_fee < userBalance.amount;
  const joinClicked = useCallback(() => model.tournamentJoined(), []);

  return (
    <>
      <Helmet>
        {tournamentData?.title && <title>{tournamentData.title}</title>}
        {tournamentData?.keywords && (
          <meta name="keywords" content={tournamentData.keywords} />
        )}
      </Helmet>
      <Section className="tournament-section" type="info">
        <Container padding={false} small>
          <SectionTitle>{t('tournament:tournament')}</SectionTitle>
          <TournamentItemBig
            isLoading={status === 'pending'}
            tournament={tournamentData}
          />
          {showJoinButton && (
            <Button
              className="join-turnament-btn"
              onClick={joinClicked}
              disabled={!enabledJoinButton}
            >
              {tournametJoinText}
              {/* {t('auth:socialRegister.submitButton')} */}
            </Button>
          )}
        </Container>
        <Container>
          {tournamentData && (
            <>
              <TournamentDescription
                description={tournamentData.htmlDescription}
              />
              <TournamentTable showEmptyTable tournament={tournamentData} />
            </>
          )}
        </Container>
      </Section>
      <TournamentGames
        games={tournamentData?.games}
        isLoading={status !== 'done'}
      />
    </>
  );
};

const TournamentGames = ({
  isLoading,
  games,
}: {
  isLoading: boolean;
  games?: Game[];
}) => {
  const renderSize = useMedia(
    ['(max-width: 400px)', '(max-width: 768px)', '(max-width: 991px)'],
    [6, 9, 8],
    10,
  );

  const { t } = useTranslation();
  return (
    <GamesSection
      renderSize={renderSize}
      icon="pyramid-icon"
      withScroll={false}
      gameList={games || []}
      isPending={isLoading}
      showEmpty={false}
      className="tournament-games"
      gameListName="tournament-games"
      title={t('tournament:tournamentGames')}
    />
  );
};

const TournamentDescription = ({ description }: { description: string }) => {
  //h3 - subtitle, p - абзац, p.mb - абзац с margin 20px
  return (
    <div
      className="tour-descr resources"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
    />
  );
};
