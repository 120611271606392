import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { DocTypes } from 'lib/constants';
import { Button, IconSprite, MainForm, MainFormRow, Select } from 'ui';
import { Document } from './document';
import * as model from '../model';
import { createFilesList } from 'lib/files';

export const UserDocumentForm = () => {
  const { t } = useTranslation();

  const inputFile = useRef<HTMLInputElement>(null);
  const filesForUpload = useStore(model.$filesForUpload);
  const uploadedFiles = useStore(model.$uploadedFiles);
  const uploadingFiles = useStore(model.$filesInUploading);
  const uploadEnabled = useStore(model.$uploadEnabled);
  const constraints = useStore(model.$constraints);

  React.useEffect(() => {
    model.pageLoaded();
  }, []);

  const onChangeFile = (e) => {
    const files = e.target.files;

    model.validateFiles(files);
  };

  const onRemove = (name: string) => {
    const fileInput = inputFile.current;
    let inputFiles = fileInput?.files as FileList;
    let validFiles = [...inputFiles].filter((el) => el.name !== name);
    fileInput!.files = createFilesList(validFiles);
    model.fileRemoved(name);
  };

  return (
    <MainForm>
      <MainFormRow
        wrapClassName="main-form__select-wrap"
        label={t('lk:profile.document.label')}
      >
        {/* <Select
          value={docType}
          onChange={onChangeDocType}
          options={[
            [DocTypes.Passport, t(`lk:profile.document.${DocTypes.Passport}`)],
            [
              DocTypes.Certificate,
              t(`lk:profile.document.${DocTypes.Certificate}`),
            ],
          ]}
        /> */}
      </MainFormRow>

      <div className="main-form__document">
        {uploadedFiles.map((file) => {
          if (!file.confirmed) {
            return (
              <Document
                name={file.name}
                key={file.name}
                confirmed={false}
                loaded
                onRemove={() => model.deleteUploadedFile(file.id)}
              />
            );
          } else {
            return (
              <Document name={file.name} key={file.name} confirmed loaded />
            );
          }
        })}

        {filesForUpload.map((el) => {
          const file = uploadingFiles.find(
            (uploading) => uploading.name == el.name,
          );
          const isPending = !!file;
          const pendingProgress = file?.progress || 0;
          return (
            <Document
              name={el.name}
              key={el.name}
              confirmed={false}
              loaded={false}
              isPending={isPending}
              pendingProgress={pendingProgress}
              onRemove={() => {
                !isPending ? onRemove(el.name) : file?.cancel();
              }}
            />
          );
        })}
      </div>

      <div className="file">
        <input
          disabled={false}
          ref={inputFile}
          className="file__input"
          type="file"
          id="file"
          accept={model.AVAILABLE_TYPES.join(',')}
          multiple
          onChange={onChangeFile}
        />
        <label className="file__label" htmlFor="file">
          <IconSprite name="file-icon" className="file__img" />
          <span className="file__title">{t('lk:profile.document.load')}</span>
        </label>
        <p className="file__text">
          {t('lk:profile.document.supportFormatsText', {
            size: constraints.max_upload_size / model.BYTE_IN_MEGABYTE,
            count:
              constraints.max_upload_count -
              uploadedFiles.length -
              filesForUpload.length,
          })}
        </p>
      </div>
      <Button
        theme="primary"
        onClick={() => model.uploadSubmitted()}
        disabled={!uploadEnabled}
      >
        {t('common:buttons.send')}
      </Button>
    </MainForm>
  );
};
