import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { NavLink } from 'ui';
import { deviceModel } from 'features/device';
import { SearchDesktop } from 'features/search';
import { paths } from 'pages/paths';
import { hallModel } from 'features/halls';
import { i18nModel } from 'features/i18/i18n';
import { Halls } from 'lib/constants';

import './style.css';
import { skinModel } from '../skin-id';

interface Props {
  totalSlides: number;
  currentSlide: number;
  setCurrentSlide: (number: number) => void;
}

const HALL_RANK = 0;

export const IntroNav: React.FC<Props> = ({
  totalSlides,
  currentSlide,
  setCurrentSlide,
}) => {
  const { t } = useTranslation();
  const isDesktop = useStore(deviceModel.$isDesktop);
  const [imgIsLoaded, setImgIsLoaded] = React.useState(false);
  const skinId = skinModel.selectors.useSkinId();

  const halls = useStore(hallModel.$hallsNamesByRank);

  const renderedHalls = halls[HALL_RANK] || [];

  return (
    <div className="intro-nav">
      <Link to={paths.payments()}>
        <div className="intro-nav__left">
          <img src={`/img/${skinId}/intro/payment-wrap.svg`} alt="" />
          <span>{t('common:menu.payments')}</span>
        </div>
      </Link>
      <div className="intro-nav__center">
        <div className="intro-nav__bullets">
          {totalSlides > 1 &&
            Array.from({ length: totalSlides }, (_, idx) => (
              <span
                style={{ visibility: imgIsLoaded ? 'visible' : 'hidden' }}
                key={idx}
                onClick={() => setCurrentSlide(idx)}
                className={classNames(
                  'intro-nav__bullets-item',
                  idx === currentSlide && 'active',
                )}
              />
            ))}
        </div>
        <img src={`/img/${skinId}/intro/hall-wrap.svg`} alt="" />
        {imgIsLoaded ? (
          <nav className="nav nav_lk">
            <HallList halls={renderedHalls} />
          </nav>
        ) : null}
      </div>
      <div className="intro-nav__right">
        <img
          onLoad={() => setImgIsLoaded(true)}
          src={`/img/${skinId}/intro/search-wrap.svg`}
          alt=""
        />
        {isDesktop && <SearchDesktop />}
      </div>
    </div>
  );
};

const HallList = React.memo(({ halls }: { halls: string[] }) => {
  const { t } = useTranslation();
  const { category } = useParams<any>();

  const i18nNames = useStore(i18nModel.resourses.gameMenu);

  return (
    <ul className="nav__list">
      <li className="nav__item">
        <NavLink active={category === Halls.All} to={paths.category(Halls.All)}>
          {t(`common:categories.${Halls.All}`)}
        </NavLink>
      </li>
      {halls.map((hall, idx) => (
        <li key={idx} className="nav__item">
          <NavLink active={category === hall} to={paths.category(hall)}>
            {i18nNames[hall] || hall}
          </NavLink>
        </li>
      ))}
      <li className="nav__item">
        <NavLink
          active={category === Halls.Favorite}
          to={paths.category(Halls.Favorite)}
        >
          {t(`common:categories.${Halls.Favorite}`)}
        </NavLink>
      </li>
    </ul>
  );
});
