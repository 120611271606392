import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Counter, IconSprite, Button } from 'ui';
import { lotteryModel } from 'features/lotteries';
import { lotteryApi } from 'api/lottery';
import { useFormaters } from 'features/i18';
import './style.css';

interface Props {
  lottery: LotteryItem;
}

// eslint-disable-next-line quotes
const DEFAULT_IMG_URL = "url('/img/actual.svg')";

export const FundItem: React.FC<Props> = ({ lottery }) => {
  const {
    name,
    prize_fund,
    end_at,
    ticket_count,
    state,
    start_image_id,
  } = lottery;

  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const backgroundImage = start_image_id
    ? `url('${lotteryApi.getImage(start_image_id)}')`
    : DEFAULT_IMG_URL;

  return (
    <div className="fund">
      <h2 className="fund__title">{name}</h2>
      <div className="fund__wrap" style={{ backgroundImage }}>
        <div className="fund__info">
          <span className="fund__prize-title">{t('common:prizeFund')}</span>
          <span className="fund__prize">{currencyNumber(prize_fund)}</span>
          <Counter isEnd={state === 'COMPLETED'} date={end_at} />
        </div>
      </div>
      <div className="fund__ticket-wrap">
        <div className="fund__title-wrap">
          <IconSprite name="ticket-icon" />
          <span className="fund__ticket-title">
            {t('lottery:ticket_count')}
            <span className="fund__count">{ticket_count}</span>
          </span>
        </div>
        {state === 'ACTIVE' && (
          <Button
            onClick={() => lotteryModel.buttonClicked(lottery)}
            theme="primary"
          >
            {t('common:buttons.buy')}
          </Button>
        )}
      </div>
    </div>
  );
};
