import { combine, createEvent, sample } from 'effector';

import { bonusModel } from 'features/bonus';
import { createPaginationModel } from 'features/pagination';

const DEFAULT_LIMIT = 10;

export const pageLoaded = createEvent<{ bonusLimit: number }>();
export const pageUnloaded = createEvent();

export const pagination = createPaginationModel<
  GiftSpin | BonusProgram | PlayerBonus | WofSpin | null
>({
  resetTriggers: [pageUnloaded],
  defaultLimit: DEFAULT_LIMIT,
});

export const $renderedList = combine(
  bonusModel.$allBonusesList,
  pagination.$currentPage,
  pagination.$limit,
  (list, currentPage, limit) => pagination.paginate(list, limit, currentPage),
);

sample({
  source: pageLoaded,
  target: pagination.setLimit,
  fn: ({ bonusLimit }) => bonusLimit,
});

sample({
  source: bonusModel.$allBonusesList,
  target: pagination.setTotal,
  fn: (list) => list.length,
});
