import { httpClient } from 'lib/request';
import { BASE_URI } from '../lib/constants';

/**
 * Получить данные джекпота по его id
 * @param id {number} - id джекпота
 */
function getById(id: number | string): Promise<Jackpot> {
  return httpClient.get(`jackpots/${id}`);
}

/**
 * Получить данные главного джекпота
 */
function getMain(): Promise<Jackpot> {
  return httpClient.get('jackpots/main');
}

/**
 * Получить список победителей джекпота по его id
 * @param id {number} - id джекпота
 */
function getWinnersJackpotById(id: number | string): Promise<JackpotWinner[]> {
  return httpClient.get(`jackpots/${id}/winners`);
}

/**
 * Получить изображения джекпота
 * @param id {number} - id джекпота
 */
function getImage(id: number) {
  return `${BASE_URI}jackpots/images/${id}`;
}

export const jackpotApi = {
  getById,
  getWinnersJackpotById,
  getMain,
  getImage,
};
