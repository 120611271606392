import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Effect,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { condition, debounce, some } from 'patronum';

import { lotteryApi } from 'api/lottery';
import { createPaginationModel } from 'features/pagination';
import { createLotteryModel } from './create-model';
import { paths } from 'pages/paths';
import { sessionModel } from 'features/session';
import { authFormModel } from 'features/auth';
import { navigationModel } from 'features/navigation';

const DEFAULT_LIMIT = 2;

export const listUnmounted = createEvent();
export const listMounted = createEvent();

export const loadAllLotteries = createEvent();

export const getLotteryWithUserFx = createEffect<
  void,
  LotteryResponseType,
  ApiError
>(lotteryApi.getLotteryWithUser);

const loadActiveLotteryListFx: Effect<
  void,
  LotteryResponseType,
  Error
> = createEffect(lotteryApi.getActiveLotteries);

const loadCompletedLotteryListFx: Effect<
  void,
  LotteryResponseType,
  Error
> = createEffect(lotteryApi.getCompletedLotteries);

export const loadMainLotteryFx: Effect<void, LotteryItem, Error> = createEffect(
  lotteryApi.getMainLottery,
);

export const $isMounted = createStore(false)
  .on(listMounted, () => true)
  .reset(listUnmounted);

// нужно ли грузить лотереи
export const setNeedToLoad = createEvent<boolean>();
export const $needToLoad = restore(setNeedToLoad, true).reset(
  sessionModel.$isAuthenticated,
);

export const activeLotteries = createLotteryModel(loadActiveLotteryListFx);
export const completedLotteries = createLotteryModel(
  loadCompletedLotteryListFx,
);

export const $lotteriesWithUser = createStore<LotteryItem[]>(
  [],
).on(getLotteryWithUserFx.doneData, (_, { data }) =>
  data.sort(sortLotteriesByState),
);

export const $activeLottery = $lotteriesWithUser.map((lotteries) => {
  const active = lotteries.find((lottery) => lottery.state === 'ACTIVE');
  return active || null;
});

export const $mainLottery = createStore<LotteryItem | null>(null);

export const $isPending = some({
  predicate: 'pending',
  stores: [activeLotteries.$status, completedLotteries.$status],
});

export const $allLotteries = combine(
  activeLotteries.$lotteries,
  completedLotteries.$lotteries,
  (active, completed) => active.concat(completed).sort(sortLotteriesByState),
);

export const $hasError = some({
  predicate: (state) => !!state,
  stores: [activeLotteries.$error, completedLotteries.$error],
});

export const pagination = createPaginationModel<LotteryItem>({
  resetTriggers: [listUnmounted],
  defaultLimit: DEFAULT_LIMIT,
});

export const $totalCount = $allLotteries.map((list) => list.length);

forward({
  from: $totalCount,
  to: pagination.setTotal,
});

export const setLimit = createEvent<number>();

export const $renderedList = combine(
  $allLotteries,
  pagination.$currentPage,
  pagination.$limit,
  (list, currentPage, limit) => {
    const newList = pagination.paginate(list, limit, currentPage);
    if (newList.length === 1) return newList;
    return newList.length % 2 ? newList.slice(0, -1) : newList;
  },
);

export const $totalRenderSize = $renderedList.map((list) => list.length);

forward({
  from: setLimit,
  to: pagination.setLimit,
});

sample({
  source: loadMainLotteryFx.doneData,
  target: $mainLottery,
});

guard({
  source: listMounted,
  filter: $needToLoad,
  target: loadAllLotteries,
});

guard({
  source: sessionModel.$isAuthenticated,
  filter: $isMounted,
  target: loadAllLotteries,
});

forward({
  from: debounce({ source: loadAllLotteries, timeout: 1 }),
  to: [
    activeLotteries.loadLotteries,
    completedLotteries.loadLotteries,
    setNeedToLoad.prepend(() => false),
  ],
});

// ОБРАБОТКА КЛИКА ПО ЛОТЕРЕИ
export const buttonClicked = createEvent<LotteryItem>();
export const buyClicked = createEvent<LotteryItem>();

condition({
  source: buttonClicked,
  if: sessionModel.$isAuthenticated,
  then: buyClicked,
  else: authFormModel.loginFormOpened.prepend(() => {}),
});

sample({
  source: buyClicked,
  fn: () => paths.deposit(),
  target: navigationModel.historyPush,
});

function sortLotteriesByState(a: LotteryItem, b: LotteryItem) {
  const priority = ['ACTIVE', 'REGISTERED', 'COMPLETED', 'CANCELED'];
  return (
    priority.findIndex((state) => state === a.state) -
    priority.findIndex((state) => state === b.state)
  );
}
