import * as React from 'react';

import { IconSprite } from 'ui';
import { Search } from './search';

export const SearchDesktop = React.memo(() => (
  <div className="search-form">
    <div className="search-form__wrap">
      <Search />
      <button type="button" className="search-form__btn">
        <IconSprite name="search-btn" spriteName="common" />
      </button>
    </div>
  </div>
));
