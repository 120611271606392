import { httpClient } from 'lib/request';
import { v4 as uuidv4 } from 'uuid';

/**
 * Получить список призов колеса фортуны
 */
const getFortuneSectionList = (): Promise<FortuneSection[]> => {
  return httpClient
    .get('players/current/wof/reel')
    .then((data: FortuneSection[]) => {
      data.forEach((section) =>
        section.prizes.forEach((prize) => {
          if (prize.id === 0) {
            prize.id = uuidv4();
          }
        }),
      );
      return data;
    });
};

/**
 * Прокрутить колесо фортуны
 * @param spinId - ID спина
 */
const getPrize = (spinId: number): Promise<WofSpin> => {
  return httpClient.post(`players/current/wof/spins/${spinId}`);
};

/**
 * Купить спин и прокрутить колесо
 */
const buySpinAndGetPrize = (multiplier: number): Promise<WofSpin> => {
  return httpClient.post(
    `players/current/wof/spins/buy?multiplier=${multiplier}`,
  );
};

/**
 * Отменить ВСЕ доступные игроку вращения Колеса Фортуны
 */
const deleteAllWofSpins = (): Promise<void> => {
  return httpClient.delete('players/current/wof/spins');
};

export const WheelOfFortuneApi = {
  getFortuneSectionList,
  getPrize,
  buySpinAndGetPrize,
  deleteAllWofSpins,
};
