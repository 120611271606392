import React, { ReactElement, useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IconSprite } from 'ui';
import { useOnClickOutside } from 'lib/hooks';
import './styles.css';

interface Props {
  children: React.ReactNode[];
}

export const DropMenu = React.memo(({ children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { pathname } = useLocation();
  const ref = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    handleClose();
  }, [pathname]);

  const handleClose = () => setIsOpen(false);

  useOnClickOutside({
    ref,
    ignorList: ['.burger-menu'],
    handler: handleClose,
    disabled: !isOpen,
  });

  return (
    <>
      <button
        ref={ref}
        className={classNames('burger', isOpen && 'active')}
        type="button"
        onClick={toggleMenu}
      >
        <div className="burger__wrap">
          <span className="burger__line" />
          <span className="burger__line" />
          <span className="burger__line" />
        </div>
      </button>
      <div
        className={classNames('burger-menu js_burger-menu', isOpen && 'active')}
      >
        <div className={classNames('burger-menu__wrap', 'with-nav')}>
          {children}
        </div>
      </div>
    </>
  );
});

interface ListProps {
  children: ReactElement[];
}

export const DropMenuList = React.memo(({ children }: ListProps) => {
  return <ul className="burger-menu__list">{children}</ul>;
});

interface ItemProps {
  value: string;
  icon?: string | React.ReactNode;
  to?: string;
  active?: boolean;
}

export const DropMenuListItem = React.memo(
  ({ icon, to = '/', value, active }: ItemProps) => {
    return (
      <li className="burger-menu__item">
        {typeof icon === 'string' ? (
          <IconSprite
            spriteName="common"
            name={`${icon}`}
            className="burger-menu__img"
          />
        ) : (
          icon
        )}
        <Link
          to={to}
          className={classNames('burger-menu__link', active && 'active')}
        >
          {value}
        </Link>
      </li>
    );
  },
);

interface NavProps {
  children: React.ReactNode[];
}

export const DropMenuNav = React.memo(({ children }: NavProps) => {
  return <div className="burger-menu__nav">{children}</div>;
});
