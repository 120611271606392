import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { reflect, createReflect } from '@effector/reflect';
import PhoneInput from 'react-phone-input-2';
import i18n from 'i18next';

import { Button, Field, IconSprite, MainFormButton, MainFormRow } from 'ui';
import { trimEvent, trimPreventedEvent } from 'lib/events';
import { userModel } from 'features/user';
import { Captcha } from 'features/captcha';

import * as model from './model';

export const Phone = () => {
  React.useEffect(() => {
    return () => {
      model.formUnmounted();
    };
  }, []);

  return (
    <>
      <PhoneRow />
      <ConfirmCodeRow />
    </>
  );
};

const PhoneRow = () => {
  const { t } = useTranslation();
  const canChangePhone = useStore(model.$canChangePhone);
  const isPhoneConfirmed = useStore(userModel.$isPhoneConfirmed);
  const phone = useStore(userModel.$phone);

  const newPhone = useStore(model.$newPhone);
  const codeRequested = useStore(model.$isCodeRequested);

  const userIsLoaded = useStore(userModel.$userIsLoaded);

  const changePhone = (e: React.SyntheticEvent): void => {
    model.formRefreshed();
  };

  if (!userIsLoaded) {
    return (
      <MainFormRow
        className="main-form__row-phone"
        label={t('lk:profile.phone.label')}
      >
        {null}
      </MainFormRow>
    );
  }

  if (codeRequested) {
    return (
      <MainFormRow
        className="main-form__row-phone"
        label={t('lk:profile.phone.label')}
      >
        <div
          style={{ justifyContent: 'space-between' }}
          className="main-form__text-content-row"
        >
          <PhoneInput
            placeholder=""
            disabled
            specialLabel=""
            value={newPhone}
          />
          <div className="try-again__message">
            <MainFormButton onClick={changePhone}>
              {t('lk:profile.change')}
            </MainFormButton>
          </div>
        </div>
      </MainFormRow>
    );
  }

  if (!canChangePhone || isPhoneConfirmed) {
    return (
      <MainFormRow
        className="main-form__row-phone"
        label={t('lk:profile.phone.label')}
      >
        <div className="main-form__text-content-row">
          <span>
            <PhoneInput placeholder="" disabled specialLabel="" value={phone} />
            <IconSprite name="success-icon" />
          </span>
        </div>
      </MainFormRow>
    );
  }

  return (
    <MainFormRow
      className="main-form__row-phone"
      label={t('lk:profile.phone.label')}
    >
      <SendCodeForm />
    </MainFormRow>
  );
};

const SendCodeForm = () => {
  const { t } = useTranslation();
  return (
    <form>
      <PhoneBlock />
      <Captcha captcha={model.addPhoneCaptcha} />
      <SubmitPhone>{t('common:buttons.confirm')}</SubmitPhone>
    </form>
  );
};

const ConfirmCodeRow = () => {
  const { t } = useTranslation();
  const codeIsRequested = useStore(model.$isCodeRequested);

  const isPending = useStore(model.confirmPhoneFx.pending);
  const didMountRef = React.useRef(false);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (didMountRef.current) {
      if (!isPending) {
        ref?.current?.focus();
      }
    } else didMountRef.current = true;
  }, [isPending, codeIsRequested]);

  return codeIsRequested ? (
    <MainFormRow sms label={t('lk:profile.sms.label')}>
      <form className="main-form__sms-wrap">
        <SmsField
          inputRef={ref}
          placeholder={t('lk:profile.sms.placeholder')}
        />
        <MainFormButton onClick={() => model.resendRequested()}>
          {t('lk:profile.sms.resend')}
        </MainFormButton>
      </form>
    </MainFormRow>
  ) : null;
};

const PhoneField = ({ value, onChange, error }) => {
  const { t } = useTranslation();

  return (
    <Field
      error={error}
      inputComponent={
        <PhoneInput
          inputClass="field__input"
          specialLabel=""
          value={value}
          onChange={onChange}
          placeholder={t('lk:profile.phone.placeholder')}
        />
      }
    />
  );
};

export const reflectButton = createReflect(Button);

const PhoneBlock = reflect({
  view: PhoneField,
  bind: {
    value: model.$newPhone,
    onChange: (value, country, e, formattedValue) =>
      model.phoneChanged({ value, formattedValue }),
    error: model.$shownPhoneError,
  },
});

const SmsField = reflect({
  view: Field,
  bind: {
    error: model.$codeError.map(
      (error) => error && i18n.t(`lk:profile.sms.errors.${error}`),
    ),
    disabled: model.confirmPhoneFx.pending,
    value: model.$codeValue,
    onChange: model.codeChanged.prepend(trimEvent),
  },
});

const SubmitPhone = reflectButton({
  disabled: model.$isformDisabled,
  onClick: model.phoneSubmited.prepend(trimPreventedEvent),
  type: 'submit',
});
