import React from 'react';
import { useStore } from 'effector-react';
import ReactDOM from 'react-dom';

import { useLockBodyScroll } from 'lib/hooks';

import './style.css';
import * as model from './model';

export const Loader = () => {
  const loaderRoot = document.getElementById('loader-root');
  const show = useStore(model.stores.$showLoader);

  useLockBodyScroll(show);

  return loaderRoot && show
    ? ReactDOM.createPortal(
        <div className="loader-wrap">
          <LoaderText />
          <Spinner />
        </div>,
        loaderRoot,
      )
    : null;
};

const LoaderText = () => {
  const text = useStore(model.stores.$text);

  return text ? <p className="loader-text">{text}</p> : null;
};

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner yellow" />
    <div className="spinner orange" />
    <div className="spinner red" />
    <div className="spinner pink" />
    <div className="spinner violet" />
    <div className="spinner mauve" />
    <div className="spinner light-yellow" />
  </div>
);
