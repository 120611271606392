import { createEvent } from 'effector';
import { condition } from 'patronum';
import { sha256 } from 'js-sha256';

import { userModel } from 'features/user';
import { deleteCookie } from 'lib/cookie';

const CHAT_KEY = '624d49e0c72df874911d40ad';
const CHAT_ID_COOKIE_KEY = '__tawkuuid';

declare global {
  interface Window {
    Tawk_API: any;
  }
}

export const chatOpened = createEvent();

export const setUserInfo = createEvent<User>();
export const setAnonymous = createEvent();

condition({
  source: userModel.$user,
  if: Boolean,
  then: setUserInfo,
  else: setAnonymous,
});

setUserInfo.watch((user) => {
  if (user.email) {
    const attributes = {
      id: user.id,
      email: user.email,
      ...(user.name && { name: user.name }),
      hash: sha256.hmac(CHAT_KEY, user.email),
    };

    setAttributesToChat(attributes);
  }
});

setAnonymous.watch(() => {
  deleteCookie(CHAT_ID_COOKIE_KEY);
});

chatOpened.watch(() => {
  openChatInMainWindow();
});

const setAttributesToChat = (attributes) => {
  window?.Tawk_API.setAttributes(attributes, (error) => {
    error && console.error(`Tawk_API error: ${error}`);
  });
};

const openChatInMainWindow = (win: Window = window) => {
  if (win.parent === win) {
    win?.Tawk_API.toggle();
  } else {
    openChatInMainWindow(win.parent);
  }
};
