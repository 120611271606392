import { bonusApi } from 'api/bonus';
import { createEffect, restore } from 'effector';

export const loadCashbackBonusFx = createEffect<
  void,
  BonusProgram | null,
  ApiError
>(bonusApi.getCashbackBonusProgram);

export const $cashbackBonus = restore(loadCashbackBonusFx.doneData, null);
