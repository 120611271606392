import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  restore,
  sample,
} from 'effector';
import { cashBoxApi } from 'api/cashbox';
import { bonusModel } from 'features/bonus';
import { deviceModel } from 'features/device';
import { lotteryModel } from 'features/lotteries';
import { userModel } from 'features/user';
import { bonusApi } from 'api/bonus';
import { getApiErrorsArray } from 'lib/error/formatApiError';
import { condition, debounce, status } from 'patronum';
import { loadUserBalanceFx } from 'features/user/model/user-model';
import { checkObjectIsEmpty, objectUtils } from 'lib/object-utils';
import { paymentsInfoModel } from '../payments-info';
import { getDataFieldError } from './helpers';
import { CARD_FIELD_NAMES } from './constants';
import { loaderModel } from '../loader';
import i18n from 'i18next';

export const pageMounted = createEvent<void>();
export const pageUnmounted = createEvent<void>();

export const toggleDepositModal = createEvent<boolean>();
export const $depositModalIsOpen = restore(toggleDepositModal, false);

export const userRedirectedToPaymentSystem = createEvent<void>();

export const mainFormSubmitted = createEvent();

export const formSubmitted = createEvent<void>();
const resetForm = createEvent();
const registerDeposit = createEvent<void>();

/**
 * Платёжные системы для вывода
 */
export const getWithdrawPaymentSystemsFx = createEffect<
  void,
  PaymentGroup[],
  ApiError
>(cashBoxApi.getAvaibleWithdrawalPS);

export const $withdrawPaymentGroups = restore(
  getWithdrawPaymentSystemsFx.doneData,
  [],
);

/**
 * Платёжные системы для депозита
 */
export const getDepositPaymentGroupsFx = createEffect<void, PaymentGroup[]>(
  cashBoxApi.getAvaibleDepositPS,
);
export const $depositPaymentGroups = restore(
  getDepositPaymentGroupsFx.doneData,
  [],
);

export const $depositSystemsIsLoaded = status({
  effect: getDepositPaymentGroupsFx,
}).map((status) => status === 'done');

const getBonusProgramsForCurrentDepositFx = createEffect<
  string,
  BonusProgram[]
>(bonusApi.getAllBonusProgramsForDeposit);

export const registerDepositFx = createEffect<
  RegisterDepositBody,
  RegisterDepositResponse,
  ApiError
>(cashBoxApi.registerDeposit);

export const $paymentSystemsList = $depositPaymentGroups.map((groups) =>
  groups.reduce<PaymentSystemItem[]>(
    (list, group) => [...list, ...group.ps],
    [],
  ),
);

export const $depositTransactionData = restore(
  registerDepositFx.doneData,
  null,
).reset(userRedirectedToPaymentSystem);

export const $showFrame = $depositTransactionData.map(
  (data) => data?.iframe ?? false,
);
export const $showWindow = $depositTransactionData.map(
  (data) => !data?.iframe ?? false,
);

/**
 * Флаг, показывать ли бонус на первый деп
 */
export const getFirstDepInfoBonus = createEffect<void, boolean>(
  bonusApi.getFirstDepInfo,
);

export const $needShowBonusInfo = restore(
  getFirstDepInfoBonus.doneData,
  false,
).reset(pageUnmounted);

/**
 * Форма
 */
export const changePaymentSystem = createEvent<PaymentSystemItem>();
export const $seletectedPaymentSystem = restore(changePaymentSystem, null);
export const $paymentSystemIsSelected = $seletectedPaymentSystem.map(Boolean);

export const sumChanged = createEvent<number>();
export const validateSum = createEvent<void>();
export const resetSum = createEvent<void>();

export const $sum = restore(sumChanged, 0).reset(resetSum);
export const $sumError = createStore<Record<string, any>>({});

export const changeNeedBonus = createEvent<boolean>();
export const $needBonus = restore(changeNeedBonus, true);

export const focusYear = createEvent<boolean>();
export const $yearFocus = restore(focusYear, false);
export const focusCvv = createEvent<boolean>();
export const $cvvFocus = restore(focusCvv, false);

export const $dataFields = $seletectedPaymentSystem.map(
  (system) => system?.payment_data_fields || [],
);

export const initDataFieldValues = createEvent<PaymentDataField[]>();
export const dataFieldChanged = createEvent<{
  fieldName: string;
  value: string;
}>();

export const $dataFieldsValues = createStore<Record<string, string>>({})
  .on(initDataFieldValues, (currentValues, fields) =>
    fields.reduce((values, field) => {
      values[field.name] = currentValues[field.name] || '';
      return values;
    }, {}),
  )
  .on(dataFieldChanged, (currentValues, { fieldName, value }) => ({
    ...currentValues,
    [fieldName]: value,
  }));

export const validateDataField = createEvent<{ name: string }>();
export const $dataFieldsErrors = createStore<Record<string, DataFieldError>>(
  {},
);

sample({
  clock: guard({
    clock: paymentsInfoModel.$paymentEmail,
    filter: $dataFieldsValues.map((values) => values.hasOwnProperty('email')),
  }),
  target: dataFieldChanged,
  fn: (paymentEmail) => ({ value: paymentEmail, fieldName: 'email' }),
});

sample({
  clock: guard({
    clock: userModel.$email,
    filter: $dataFieldsValues.map(
      (values) => values.hasOwnProperty('email') && !values.email,
    ),
  }),
  target: dataFieldChanged,
  fn: (email) => ({ value: email || '', fieldName: 'email' }),
});

sample({
  clock: guard({
    clock: userModel.$phone,
    filter: $dataFieldsValues.map(
      (values) => values.hasOwnProperty('phone') && !values.phone,
    ),
  }),
  target: dataFieldChanged,
  fn: (phone) => ({ value: phone || '', fieldName: 'phone' }),
});

/**
 * Форма карточки
 */
export const changeCardFormIsOpened = createEvent<boolean>();
export const $cardFormIsOpened = restore(changeCardFormIsOpened, false);

export const $needToFillCardForm = $dataFields.map((fields) =>
  [
    CARD_FIELD_NAMES.CARD_CVV,
    CARD_FIELD_NAMES.CARD_EXP_MONTH,
    CARD_FIELD_NAMES.CARD_EXP_YEAR,
  ].every((name) => fields.some((f) => f.name === name)),
);

export const $submitButtonIsDisabled = combine(
  $sumError,
  $dataFieldsErrors,
  $needToFillCardForm,
  $cardFormIsOpened,
  $paymentSystemIsSelected,
  registerDepositFx.pending,
  (
    sumError,
    dataFieldsErrors,
    needToFillCardForm,
    cardFormIsOpened,
    paymentSystemIsSelected,
    pending,
  ) => {
    if (pending || !paymentSystemIsSelected) {
      return true;
    }

    const needToCheckAllFields = cardFormIsOpened || !needToFillCardForm;

    const ignoreFieldKeys = needToCheckAllFields
      ? []
      : Object.values(CARD_FIELD_NAMES);

    const checkedDataFields = objectUtils.omit(
      dataFieldsErrors,
      ...ignoreFieldKeys,
    );

    return ![sumError, ...Object.values(checkedDataFields)].every((obj) =>
      checkObjectIsEmpty(obj),
    );
  },
);

forward({ from: $dataFields, to: initDataFieldValues });

$sum.reset(resetForm);
$sumError.reset(resetForm);
sample({
  clock: resetForm,
  source: $dataFields,
  target: initDataFieldValues,
});

/**
 * Лимиты
 */
export const $limits = $seletectedPaymentSystem.map((system) => ({
  minNumber: system?.min_deposit || 0,
  maxNumber: system?.max_deposit || null,
}));

/**
 * Валидация формы
 */

forward({
  from: sumChanged,
  to: validateSum,
});

sample({
  source: [$sum, $limits, userModel.$balance],
  clock: validateSum,
  target: $sumError,
  fn: ([sum, limits, balance]) => {
    const errors: Record<string, any> = {};
    if (!sum) errors.required = true;

    if (sum < limits.minNumber) errors.minNumber = limits.minNumber;

    if (limits.maxNumber && sum > limits.maxNumber)
      errors.maxNumber = limits.maxNumber;

    return errors;
  },
});

sample({
  clock: initDataFieldValues,
  source: [
    $dataFieldsValues,
    paymentsInfoModel.$paymentEmail,
    userModel.$email,
    userModel.$phone,
  ],
  target: $dataFieldsValues,
  fn: ([currentValues, paymentEmail, email, phone], fields) =>
    fields.reduce((values, field) => {
      let defaultValue = '';

      if (field.name === 'email') {
        defaultValue = paymentEmail || email || '';
      } else if (field.name === 'phone') {
        defaultValue = phone || '';
      }

      values[field.name] = currentValues[field.name] || defaultValue;
      return values;
    }, {}),
});

sample({
  clock: initDataFieldValues,
  source: $dataFieldsValues,
  target: $dataFieldsErrors,
  fn: (values, fields) =>
    fields.reduce<Record<string, { required?: boolean; regexp?: boolean }>>(
      (errors, field) => {
        const value = values[field.name] || '';
        errors[field.name] = getDataFieldError(field, value);
        return errors;
      },
      {},
    ),
});

sample({
  source: [$dataFields, $dataFieldsErrors],
  clock: dataFieldChanged,
  target: $dataFieldsErrors,
  fn: ([fields, errors], { fieldName, value }) => {
    const field = fields.find((f) => f.name === fieldName);
    return field
      ? { ...errors, [fieldName]: getDataFieldError(field, value) }
      : errors;
  },
});

guard({
  source: $limits,
  filter: $sum.map(Boolean),
  target: validateSum,
});

/**
 * API ERRORS
 */
export const resetModalError = createEvent();

const API_ERRORS_WITH_MESSAGES = ['payment.data.error'];

export const $modalErrorMessage = createStore<string | 'default'>('')
  .on(registerDepositFx.failData, (_, error) => {
    const errorArray = getApiErrorsArray(error);

    const errorWithMessage = errorArray.find(([error]) =>
      API_ERRORS_WITH_MESSAGES.includes(error),
    );

    if (errorWithMessage) {
      return errorWithMessage[1];
    }

    if (errorArray.length > 0) {
      return 'default';
    }

    return '';
  })
  .reset(resetModalError);

/**
 * BONUS PROGRAM INFO
 */
const $bonusProgramsForCurrentDeposit = restore(
  getBonusProgramsForCurrentDepositFx,
  [],
);

export const $currentBonusProgramForDeposit = combine(
  $sum,
  $bonusProgramsForCurrentDeposit,
  (sum, programs) => {
    if (programs.length === 0) {
      return null;
    }

    const suitableProgram = programs.find(
      (program) => program.min_dep_sum <= +sum && program?.max_dep_sum >= +sum,
    );

    return suitableProgram ? suitableProgram : null;
  },
);

export const $bonusSum = combine(
  $currentBonusProgramForDeposit,
  $sum,
  (couponProgram, sum) =>
    couponProgram ? getSumFromBonus(+sum, couponProgram) : null,
);

forward({
  from: pageMounted,
  to: [getDepositPaymentGroupsFx, getFirstDepInfoBonus],
});

sample({
  source: bonusModel.$couponCode,
  clock: debounce({
    source: merge([pageMounted, bonusModel.setCouponCode]),
    timeout: 200,
  }),
  target: getBonusProgramsForCurrentDepositFx,
});

// sample({
//   source: getDepositPaymentGroupsFx.doneData,
//   target: changePaymentSystem,
//   fn: (psGroup) => psGroup[0].ps[0],
// });

/**
 * Сабмит формы
 */
condition({
  source: mainFormSubmitted,
  if: $needToFillCardForm,
  then: changeCardFormIsOpened.prepend(() => true),
  else: formSubmitted,
});

$depositTransactionData.reset(formSubmitted);

forward({ from: formSubmitted, to: registerDeposit });

sample({
  clock: registerDeposit,
  source: combine({
    paymentSystem: $seletectedPaymentSystem,
    paymentData: $dataFieldsValues,
    coupon: bonusModel.$couponCode,
    isMobile: deviceModel.$isMobile,
    sum: $sum,
    needBonus: $needBonus,
  }),
  fn: ({ paymentSystem, isMobile, coupon, sum, needBonus, paymentData }) => ({
    sum,
    coupon,
    payment_data: paymentData,
    mobile: isMobile,
    ps_id: paymentSystem!.ps_id,
    back_url: window.location.origin,
    no_bonus: !needBonus,
  }),
  target: registerDepositFx,
});

condition({
  source: registerDepositFx.pending,
  if: Boolean,
  then: loaderModel.events.showLoader.prepend(
    () => i18n.t('lk:deposit.loading') as string,
  ),
  else: loaderModel.events.hideLoader,
});

/**
 * UPDATE BALANCE AFTER REGISTER DEPOSIT
 */
const { startPolling, stopPolling, tickFx } = userModel.createBalancePolling(
  {},
);
const UPDATE_TIMES = 20;
const $updateTimes = createStore(UPDATE_TIMES);

const $initialBalance = createStore(0);

sample({
  source: userModel.$balance,
  clock: registerDepositFx.doneData,
  target: $initialBalance,
  fn: ({ real_amount }) => real_amount,
});

$cardFormIsOpened.reset(registerDepositFx.doneData);

forward({
  from: registerDepositFx.doneData,
  to: startPolling,
});

$updateTimes.on(tickFx, (times) => times - 1).reset(stopPolling);

guard({
  source: $updateTimes,
  filter: (times) => times <= 0,
  target: stopPolling,
});

guard({
  source: $initialBalance,
  clock: loadUserBalanceFx.doneData,
  filter: (initialBalance, { real_amount }) => real_amount !== initialBalance,
  target: stopPolling,
});

/**
 * Анмаунт
 */
forward({
  from: pageUnmounted,
  to: [stopPolling, resetForm],
});

$seletectedPaymentSystem.reset(pageUnmounted);
$depositTransactionData.reset(pageUnmounted);
$cardFormIsOpened.reset(pageUnmounted);

/**
 * При клике на купить в лотереи
 */
sample({
  source: lotteryModel.buyClicked,
  fn: ({ ticket_cost }) => ticket_cost,
  target: sumChanged,
});

/**
 * Клик на депозитную бон. программу
 */
sample({
  source: bonusModel.depositBonusProgramClicked,
  fn: ({ min_dep_sum, max_dep_sum }) => {
    const sum = min_dep_sum * 2;
    return sum > max_dep_sum ? max_dep_sum : sum;
  },
  target: sumChanged,
});

/**
 * HELP FUNCTIONS
 */
function getSumFromBonus(sum: number, bonus: BonusProgram) {
  const { reward_percent, reward_sum } = bonus;
  if (reward_percent) {
    return (sum * reward_percent) / 100;
  }
  if (reward_sum) {
    return reward_sum;
  }
  return 0;
}
