import classNames from 'classnames';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Input, IconSprite } from 'ui';
import './style.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties;
  autoComplete?: string;
  className?: string;
  wrapperClassName?: string;
  fieldTextClassName?: string;
  onButtonClick?: () => void;
  warning?: string;
  error?: string | boolean | null;
  icon?: string;
  iconButton?: string;
  inputComponent?: React.ReactNode;
  noWarnIcon?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const Field: React.FC<Props> = ({
  autoComplete = 'off',
  className,
  wrapperClassName,
  fieldTextClassName,
  onButtonClick,
  error,
  warning,
  inputRef,
  icon,
  iconButton,
  inputComponent,
  noWarnIcon,
  style,
  ...props
}) => {
  const type = props.type || '';

  const classProps = classNames(
    'field',
    error && 'field_error',
    warning && 'field_warning',
  );
  const iconName =
    (error && 'error-field') || (warning && 'warning-field') || icon;

  const [typeInput, setTypeInput] = React.useState(type);

  const handleShowPasswordIconClick = () => {
    setTypeInput(typeInput === 'password' ? 'text' : 'password');
    onButtonClick && onButtonClick();
  };

  const handleIconButtonClick = () => {
    onButtonClick && onButtonClick();
  };

  return (
    <div className={classProps + ` ${wrapperClassName || ''}`} style={style}>
      {inputComponent ? (
        inputComponent
      ) : (
        <Input
          className={classNames('field__input', className)}
          autoComplete={autoComplete}
          inputRef={inputRef}
          {...props}
          type={typeInput}
        />
      )}

      {(error || warning) && (
        <span
          className={`field__text ${
            fieldTextClassName ? fieldTextClassName : ''
          }`}
        >
          {error || warning}
        </span>
      )}
      {noWarnIcon ? null : (
        <>
          {iconName && (
            <IconSprite
              spriteName="common"
              name={`${iconName}`}
              className="field__icon"
            />
          )}
          {type === 'password' && !iconName && !iconButton && (
            <button
              className="field__btn"
              type="button"
              onClick={() => handleShowPasswordIconClick()}
            >
              <IconSprite
                spriteName="common"
                name={typeInput === 'password' ? 'show-field' : 'hide-field'}
                className="field__icon"
              />
            </button>
          )}
          {!iconName && iconButton && (
            <button
              className="field__btn"
              type="button"
              onClick={() => handleIconButtonClick()}
            >
              <IconSprite
                spriteName="common"
                name={`${iconButton}`}
                className="field__icon"
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};
