import React from 'react';

import { languageModel } from 'features/i18';

import { IMAGES_NAMES } from '../constants';
import './style.css';

interface Props {
  name?: string;
  id?: string | number;
  checked?: boolean;
  onChange?: (value: string) => void;
}

export const PaymentSystem = ({
  name = '',
  id,
  checked,
  onChange = () => {},
}: Props) => {
  const { title } = languageModel.selectors.usePaymentDescription(name);

  const handleChange = () => {
    onChange(name);
  };

  return (
    <div className="pay-method">
      <input
        onChange={handleChange}
        className="pay-method__input"
        type="radio"
        id={id?.toString()}
        value={name}
        checked={checked}
      />
      <label className="pay-method__label" htmlFor={id?.toString()}>
        <div className="pay-method__img">
          <img
            src={`/img/payments/${IMAGES_NAMES[name] || 'default.svg'}`}
            alt=""
          />
        </div>
        <div title={title} className="pay-method__name">
          <span className="pay-method__text">{title}</span>
        </div>
      </label>
    </div>
  );
};
