import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui';

import { authFormModel } from '..';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'secondary' | 'tertiary' | 'deposit';
  className?: string;
}

export const OpenRegisterFormButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      className="register-btn"
      onClick={() => authFormModel.registerFormOpened()}
    >
      {t('common:buttons.signup')}
    </Button>
  );
};
