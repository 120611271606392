import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Section, Container, BlockTitle } from 'ui';
import { LotteryList } from 'features/lotteries';
import { PromotionSection } from 'features/promotion';

import './style.css';
import * as model from './model';

export const LotteriesPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({ lotteriesLimit: 4 });
    return () => model.pageUnloaded();
  }, []);

  return (
    <>
      <Section type="info">
        <Container>
          <BlockTitle icon="star-lottery" title={t('common:menu.lottery')} />
          <LotteryList skeletonSize={4} />
        </Container>
        <PromotionSection />
      </Section>
    </>
  );
};
