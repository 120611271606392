import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { BlockTitle, Container, Link, Section } from 'ui';
import { paths } from 'pages/paths';
import { TournamentTable } from './tournament-table';
import { TournamentItem } from './tournament-item';
import * as model from '../model';

const DEFAULT_WINNERS_SIZE = 3;

export const TournamentSection = () => {
  const { t } = useTranslation();

  const tournamentData = useStore(model.$list)[0];
  const hasError = useStore(model.$error);

  if (hasError || !tournamentData) {
    return null;
  }

  return (
    <Section type="game" className="tournament-section">
      <Container>
        <BlockTitle icon="pyramid-icon" title={t('common:menu.tournaments')}>
          <Link theme="primary" to={paths.tournaments()} border>
            {t('common:buttons.all-tournaments')}
          </Link>
        </BlockTitle>
        <div className="tournament-content">
          <TournamentItem tournament={tournamentData} />
          <TournamentTable
            withLoader={false}
            winnersSize={DEFAULT_WINNERS_SIZE}
            tournament={tournamentData}
            footer={
              tournamentData.participants?.length !== 0 && (
                <Link to={paths.tournament(tournamentData.id)}>
                  {t('tournament:table.allWinners')}
                </Link>
              )
            }
          />
        </div>
      </Container>
    </Section>
  );
};
