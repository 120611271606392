import React from 'react';
import classNames from 'classnames';

import { Container } from 'ui';

import {
  HeaderDepositLink,
  HeaderLkLink,
  HeaderLoginButton,
  HeaderLogo,
  HeaderNavMenu,
} from '../molecules';

interface Props {
  isAuth: boolean;
  fixed?: boolean;
}

export const MobileHeader = ({ isAuth, fixed = false }: Props) => (
  <header className={classNames('header header_lk', fixed && 'fixed')}>
    <Container>
      <HeaderNavMenu isMobile />
      {isAuth ? (
        <>
          <HeaderLkLink withBalance />
          <HeaderDepositLink />
        </>
      ) : (
        <>
          <HeaderLogo />
          <HeaderLoginButton />
        </>
      )}
    </Container>
  </header>
);
