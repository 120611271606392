import {
  createEvent,
  createEffect,
  createStore,
  forward,
  restore,
  sample,
  guard,
} from 'effector';
import moment from 'moment';
import { createForm, Rule } from 'effector-forms';
import { condition } from 'patronum';
import i18next from 'i18next';

import {
  createCommonErrorModal,
  getApiErrorsArray,
  joinErrorsToString,
  setApiErrorsToForm,
} from 'lib/error/formatApiError';
import { RegTypes } from 'lib/constants';
import { profileApi } from 'api/profile';
import { userApi } from 'api/user';
import { popupModel } from 'features/popup';
import { errorModel } from 'features/error-boundary';
import { locationModels } from 'features/locations';
import { userModel } from 'features/user';

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

const setError = createEffect((data) => {
  let err = (data.error as unknown) as ApiError;
  const errText = joinErrorsToString(getApiErrorsArray(err));
  errorModel.createErrorNotify(errText);
});

export const $wasBirthdayFilled = createStore(false);

export const setConfirmationText = createEvent<string>();
export const $confirmationText = restore(setConfirmationText, '');

const getProfileFx = createEffect(() => {
  return userApi.getProfile();
});

forward({
  from: getProfileFx.fail,
  to: setError,
});

sample({
  source: getProfileFx.doneData,
  target: $wasBirthdayFilled,
  fn: ({ birthday }) => !!birthday,
});

const phoneVerificationRequestFx = createEffect({
  handler: ({ phone, user, solution, task_id }) => {
    userModel.setUser({ ...user, phone });
    return userApi.phoneVerificationRequest({
      phone,
      captcha: {
        solution,
        task_id,
      },
    });
  },
});

const emailVerificationRequestFx = createEffect({
  handler: ({ user, email }) => {
    userModel.setUser({ ...user, email });
    return userApi.emailVerificationRequest({
      email,
      callback_url: window.document.location.origin,
    });
  },
});

forward({
  from: phoneVerificationRequestFx.fail,
  to: setError,
});

const defineVerificationMethod = createEvent<any>();

condition({
  source: defineVerificationMethod,
  if: (data) => data.regType === RegTypes.EMAIL,
  then: phoneVerificationRequestFx,
  else: emailVerificationRequestFx,
});

export const phoneVerificationFx = createEffect({
  handler: (data: PhoneVerificationForm) => {
    return profileApi.phoneVerification(data);
  },
});
sample({
  clock: phoneVerificationFx.doneData,
  source: $confirmationText,
  fn: (text) => {
    popupModel.setText(text);
  },
});

forward({
  from: phoneVerificationFx.fail,
  to: setError,
});

forward({
  from: emailVerificationRequestFx.fail,
  to: setError,
});

const $fieldRequiredList = createStore<Record<string, boolean>>({});

export const $userInfoForm = createForm({
  fields: {
    name: {
      init: '',
      rules: [profileRequiredField('name')],
      validateOn: ['blur'],
    },
    surname: {
      init: '',
      rules: [profileRequiredField('surname')],
      validateOn: ['blur'],
    },
    gender: {
      init: '',
      rules: [profileRequiredField('gender')],
    },
    country: {
      init: '',
      rules: [profileRequiredField('country')],
    },
    region: {
      init: '',
      rules: [profileRequiredField('region')],
    },
    day: {
      init: '',
      rules: [fullBirthDayFilled()],
    },
    month: {
      init: '',
      rules: [fullBirthDayFilled()],
    },
    year: {
      init: '',
      rules: [fullBirthDayFilled()],
    },
    locality: {
      init: '',
      rules: [profileRequiredField('locality')],
    },
    street: {
      init: '',
      rules: [profileRequiredField('street')],
      validateOn: ['blur'],
    },
    house: {
      init: '',
      rules: [profileRequiredField('house')],
      validateOn: ['blur'],
    },
    apartment: {
      init: '',
      rules: [profileRequiredField('apartment')],
      validateOn: ['blur'],
    },
  },
  validateOn: ['submit'],
});

export const updateProfileFx = createEffect<UserUpdateProfile, User, ApiError>(
  userApi.updateProfile,
);

updateProfileFx.failData.watch((errors) => {
  setApiErrorsToForm(errors, $userInfoForm, 'lk:profile.errors');
});

export const $updateProfileErrorModal = createCommonErrorModal(
  Object.keys($userInfoForm.fields),
);

forward({
  from: updateProfileFx.failData,
  to: $updateProfileErrorModal.setError,
});

sample({
  source: updateProfileFx.doneData,
  target: $wasBirthdayFilled,
  fn: (result) => {
    userModel.setUser(result);
    return !!result.birthday;
  },
});

forward({
  from: updateProfileFx.doneData,
  to: $userInfoForm.resetTouched,
});

sample({
  source: locationModels.$countries,
  clock: $userInfoForm.fields.country.onChange,
  target: locationModels.$loadRegions,
  fn: (countries, name: string) => {
    locationModels.setRegions([]);
    locationModels.setCities([]);

    $userInfoForm.fields.region.onChange('');
    $userInfoForm.fields.locality.onChange('');

    return countries.find((el) => el.name === name)?.id || 0;
  },
});

condition({
  source: $userInfoForm.fields.region.onChange,
  if: (name) => Boolean(name),
  then: createEffect((name) => {
    locationModels.setCities([]);
    $userInfoForm.fields.locality.onChange('');
    locationModels.$loadCities(
      locationModels.$regions.getState().find((el) => el.name === name)?.id ||
        0,
    );
  }),
});

sample({
  source: $wasBirthdayFilled,
  clock: $userInfoForm.formValidated,
  target: updateProfileFx,
  fn: (wasBirthdayFilled, { year, month, day, ...form }) => {
    let birthday = '';
    if (year && month && day && !wasBirthdayFilled) {
      birthday = moment([year, +month - 1, day]).format();
    }
    const updatedProfile: UserUpdateProfile = {
      birthday,
      ...form,
    };
    if (wasBirthdayFilled) {
      delete updatedProfile.birthday;
    }
    return updatedProfile;
  },
});

const setUsersForm = createEffect((result) => {
  const {
    name,
    surname,
    gender,
    country,
    region,
    locality,
    street,
    house,
    apartment,
    birthday,
  } = result;
  const resultObject: any = {
    name,
    surname,
    gender,
    country,
    region,
    locality,
    street,
    house,
    apartment,
  };
  for (const key in resultObject) {
    if (Object.prototype.hasOwnProperty.call(resultObject, key)) {
      if (!!!resultObject[key]) {
        resultObject[key] = '';
      }
    }
  }
  if (!birthday) {
    resultObject.day = '';
    resultObject.month = '';
    resultObject.year = '';
  } else {
    const birthdayDate = new Date(birthday);
    resultObject.day = String(birthdayDate.getDate());
    resultObject.month = String(birthdayDate.getMonth() + 1);
    resultObject.year = String(birthdayDate.getFullYear());
  }

  $userInfoForm.setForm(resultObject);
});

// Set profile data to form
sample({
  source: getProfileFx.done,
  target: setUsersForm,
  fn: ({ params, result }) => {
    if (result.country) {
      const cName =
        locationModels.$countries
          .getState()
          .find((el) => el.name === result.country)?.id || 0;
      locationModels.$loadRegions(cName).then((data) => {
        if (result.region) {
          const rName = data.find((el) => el.name === result.region)?.id || 0;
          locationModels.$loadCities(rName);
        }
      });
    }
    userModel.setUser(result);
    return result;
  },
});

sample({
  source: getProfileFx.doneData,
  target: $fieldRequiredList,
  fn: (user) =>
    Object.entries(user).reduce((result, [field, value]) => {
      result[field] = !!value;
      return result;
    }, {}),
});

condition({
  source: pageLoaded,
  if: locationModels.$countries.map((arr) => !arr.length),
  then: locationModels.loadCountriesFx,
});

guard({
  source: locationModels.$countries,
  clock: [pageLoaded, locationModels.loadCountriesFx.doneData],
  filter: (countries) => countries.length !== 0,
  target: getProfileFx,
});

///
forward({
  from: pageUnloaded,
  to: [$userInfoForm.reset],
});

/**
 * MODAL
 */
export const closeModal = createEvent<void>();
export const showSuccessModal = createEvent<void>();
export const $successModalIsOpen = createStore<boolean>(false)
  .on(showSuccessModal, () => true)
  .reset(closeModal);

sample({
  source: updateProfileFx.doneData,
  target: showSuccessModal,
});

// help functions
function profileRequiredField(fieldName, shortMessage = false): Rule<string> {
  return {
    name: 'required',
    source: $fieldRequiredList,
    validator: (value: string, form, requiredList) => ({
      isValid: requiredList[fieldName] ? Boolean(value) : true,
      errorText: i18next.t(
        `common:validators.required${shortMessage ? 'Short' : ''}`,
      ),
    }),
  };
}
function fullBirthDayFilled(): Rule<string> {
  return {
    name: 'required',
    validator: (value: string, { day, month, year }) => {
      let isValid = false;

      if (day || month || year) {
        isValid = !!value;
      }

      return {
        isValid,
        errorText: i18next.t(`lk:profile.errors.needToFillAllBirthdayDate`),
      };
    },
  };
}
