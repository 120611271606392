import React from 'react';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga4';
import ym from 'react-yandex-metrika';

export const useTrackers = () => {
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    ReactGA.initialize('G-8VW0GHFKWV');
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    if (initialized) {
      const page = location.pathname + location.search;
      ReactGA.send({
        hitType: 'pageview',
        page,
      });

      ym('hit', page);
    }
  }, [initialized, location]);
};
