import {
  createEffect,
  createEvent,
  createStore,
  combine,
  forward,
  restore,
  sample,
  guard,
} from 'effector';
import { delay, spread } from 'patronum';

import { activeBonusModel } from 'features/bonus/bonus-active';
import { loadPopularGames, $popularGames } from 'features/halls';
import { userModel } from 'features/user';
import { GameModes } from 'lib/constants';
import { gameApi } from 'api/game';

export const pageMounted = createEvent();
export const pageUnmounted = createEvent<void>();

export const gameReseted = createEvent();
export const gameToggled = createEvent<GameProps>();
export const gameCodeReceived = createEvent<string>();
export const modeChanged = createEvent<GameMode>();
export const toggleRulesModal = createEvent<void>();

export const loadGameUrlFx = createEffect<GameRequest, string>(gameApi.getUrl);
export const loadGameRulesFx = createEffect(({ code }) =>
  gameApi.getRules(code),
);
export const loadGameMessageConvertFx = createEffect(
  gameApi.getGameMessageConvert,
);
export const loadGameDataFx = createEffect<
  { code: string; provider: string },
  Game[]
>(gameApi.getGamesData);

export const $gameCode = restore(gameCodeReceived, '').reset(gameReseted);
export const $gameName = createStore('')
  .on(loadGameDataFx.doneData, (_, [game]) => game.name)
  .reset(pageUnmounted);
export const $gameURL = restore(loadGameUrlFx.doneData, '').reset(gameReseted);
export const $gameRules = restore(loadGameRulesFx.doneData, '').reset(
  gameReseted,
);
export const $gameMode = restore(modeChanged, GameModes.Demo).reset(
  gameReseted,
);
export const $gameIsReal = $gameMode
  .map((mode) => mode === GameModes.Real)
  .reset(gameReseted);

export const $renderedPopularGames = createStore<Game[]>([]);

export const $rulesIsOpen = createStore(false).on(
  toggleRulesModal,
  (state) => !state,
);

forward({
  from: pageMounted,
  to: activeBonusModel.loadActiveBonusFx,
});

forward({
  from: gameToggled,
  to: [loadPopularGames, loadGameMessageConvertFx],
});

forward({
  from: [pageUnmounted],
  to: gameReseted,
});

spread({
  source: gameToggled,
  targets: {
    code: gameCodeReceived,
    mode: modeChanged,
  },
});

sample({
  source: gameToggled,
  target: loadGameDataFx,
  fn: ({ code, provider }) => ({ code, provider }),
});

sample({
  source: gameToggled,
  target: loadGameUrlFx,
  fn: ({ code, provider, mode }) => ({
    code,
    mode,
    provider_code: provider,
    fp: null,
    mirror_url: null,
    mobile: false, // TODO: Пока для мобилок будет другая страница игры
    xreferer: null,
    exit_url: null,
    useragent: null,
  }),
});

sample({
  source: combine($popularGames, $gameCode, (games, code) => ({ games, code })),
  target: $renderedPopularGames,
  fn: ({ games, code }) => games.filter((game) => game.code !== code),
});

// Обновление баланса при игре

// export const balanceChanged = createEvent<number>().filterMap(convertBalance);

export const balanceReceived = createEvent<number>().filterMap(convertBalance);

forward({
  from: delay({ source: balanceReceived, timeout: 300 }),
  to: userModel.loadUserBalanceFx,
});

// Поллинг баланса
const providersWhoNeedPolling = ['b2b', 'cs'];

const { startPolling, stopPolling } = userModel.createBalancePolling({});

guard({
  clock: gameToggled,
  filter: ({ provider }) => providersWhoNeedPolling.includes(provider),
  target: startPolling,
});

forward({
  from: pageUnmounted,
  to: stopPolling,
});

function convertBalance(value) {
  if (!isNaN(value)) {
    return value === '' ? 0 : value;
  }
}

/**
 * Модалка редиректа
 */
export const closeModal = createEvent<void>();
export const modalSubmitted = createEvent();

export const setSubmitModalFn = createEvent<() => void>();
const $submitModalFn = restore(setSubmitModalFn, null);

export const $redirectModalIsOpen = $submitModalFn.map(Boolean);

modalSubmitted.watch(() => {
  const func = $submitModalFn.getState();
  func && func();
});

$submitModalFn.reset(closeModal);
$submitModalFn.reset(delay({ source: modalSubmitted, timeout: 500 }));
