import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { IconSprite } from '../svg';

import './style.css';

interface Props {
  children: React.ReactNode;
  type?: 'success' | 'error';
  isOpen?: boolean;
  onAnimationEnd?: () => void;
}

const TOAST_DURATION_SECONDS = 7;

// TODO: ресет анимации при ререндере
export const Toast: React.FC<Props> = ({
  children,
  type,
  isOpen,
  onAnimationEnd = () => {},
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const modalRoot = document.getElementById('modal-root');

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    timeout = setTimeout(() => {
      onAnimationEnd();
    }, TOAST_DURATION_SECONDS * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, children, type, ref]);

  return modalRoot
    ? ReactDOM.createPortal(
        isOpen ? (
          <div
            ref={ref}
            style={{ animationDuration: `${TOAST_DURATION_SECONDS}s` }}
            className={classNames('toast', type)}
          >
            {children}
            <IconSprite
              onClick={onAnimationEnd}
              name="close-btn"
              className="close-btn"
            />
          </div>
        ) : null,
        modalRoot,
      )
    : null;
};
