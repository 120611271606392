import { httpClient } from 'lib/request';

/**
 * Передать запрос и получить его id
 * @param {{ query, result_count }}  - текст запроса и количество результатов поиска
 */
function sendSearchData({
  query,
  result_count,
}: {
  query: string;
  result_count: number;
}): Promise<number> {
  return httpClient.post('/game-searches', { query, result_count });
}

/**
 * Отправить событие клика по результату запроса
 * @param {number} statId - id запроса
 */
function sendClick(statId: number): Promise<any> {
  return httpClient.post(`/game-searches/${statId}/clicks`);
}

export const statisticApi = {
  sendSearchData,
  sendClick,
};
