import * as React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui';
import { searchModel } from 'features/search';
import * as model from '../model';
import { userModel } from 'features/user';
import { sessionModel } from 'features/session';

interface GameLinkProps {
  provider: string;
  code: string;
  handlePlayButtonClick?: () => void;
}

export const GameLink = ({
  provider,
  code,
  handlePlayButtonClick,
}: GameLinkProps) => {
  const { t } = useTranslation();

  const isStatActive = useStore(searchModel.$isStatActive);
  const balanceIsLoaded = useStore(userModel.$balanceIsLoaded);
  const isAuth = useStore(sessionModel.$isAuthenticated);

  // TODO: Подумать, как перенести логику в модель и избежать циклических зависимостей
  const handleGameClick = () => {
    if (handlePlayButtonClick) {
      return handlePlayButtonClick();
    }

    if (isStatActive) {
      searchModel.gameClicked({ provider, code });
    } else {
      model.gameClicked({ provider, code });
    }
  };

  const buttonDisabled = isAuth && !balanceIsLoaded;

  return (
    <Button
      className="play-btn"
      disabled={buttonDisabled}
      onClick={handleGameClick}
    >
      {t('common:buttons.play')}
    </Button>
  );
};
