import * as React from 'react';

import { MainForm } from 'ui';

import { Email } from './email/email-form';
import { Phone } from './phone/phone-form';

export const UserContactsForm = () => {
  return (
    <>
      <MainForm className="contact-form" isForm={false}>
        <Email />
        <Phone />
      </MainForm>
    </>
  );
};
