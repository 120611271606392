import { BASE_URI } from 'lib/constants';
import { httpClient } from 'lib/request';

/**
 * получить список превью промо акций
 * @param language - локализация
 */
const getList = (language: string): Promise<PromotionListResponse> =>
  httpClient.get(`articles?locale=${language}`);

/**
 * Получить данные промоакции по ID || static_link
 */

const getPromotion = (id: string): Promise<PromotionItem> =>
  httpClient.get(`articles/${id}`);

const getPromotionImageUrl = (id) => `${BASE_URI}articles/images/${id}`;

export const promotionApi = {
  getList,
  getPromotion,
  getPromotionImageUrl,
};
