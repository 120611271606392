import {
  createEffect,
  createEvent,
  createStore,
  combine,
  guard,
  forward,
  sample,
  merge,
} from 'effector';
import { debounce, reshape, condition, debug } from 'patronum';

import i18next from 'i18next';
import { createForm } from 'effector-forms';

import { DeviceTypes } from 'lib/constants';
import { isValidEmail, isValidPhone, rules } from 'lib/validators';
import { restoreApi } from 'api/password-restore';
import { captchaModel } from 'features/captcha';
import { deviceModel } from 'features/device';
import { userModel } from 'features/user';
import { createRateLimitModel } from 'lib/error/rate-limit-model';
import {
  createErrorObject,
  hasErrorsIsObject,
  setApiErrorsToForm,
} from 'lib/error/formatApiError';
import { navigationModel } from 'features/navigation';
import { paths } from 'pages/paths';

export enum RestoreTypes {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export const resetPasswordEmailCaptcha = captchaModel.createCaptcha(
  'restore-password',
  'byEmail',
);
export const resetPasswordPhoneCaptcha = captchaModel.createCaptcha(
  'restore-password',
  'byPhone',
);

export const captchaEmail = resetPasswordEmailCaptcha;
export const captchaPhone = resetPasswordPhoneCaptcha;

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

export const setRestoreType = createEvent<RegistrationType>();
export const restoreTypeChanged = createEvent<RestoreTypes>();
export const valuePhoneChanged = createEvent<{
  value: string;
  formattedValue: string;
}>();
export const valueEmailChanged = createEvent<string>();
const setPhone = createEvent<RestoreTypes>();
const setEmail = createEvent<RestoreTypes>();
const initEmail = createEvent<void>();
const initPhone = createEvent<void>();
const sendByEmail = createEvent<void>();
const sendByPhone = createEvent<void>();
export const passwordReseted = createEvent<void>();
export const formSubmited = createEvent<void>();

export const requestPasswordByEmailFx = createEffect<
  RestorePasswordRequestEmail,
  any,
  ApiError
>(restoreApi.restoreByEmail);
export const requestPasswordByPhoneFx = createEffect<
  RestorePasswordRequestPhone,
  any,
  ApiError
>(restoreApi.restoreByPhone);

export const phoneRequestRateLimitModel = createRateLimitModel({
  effect: requestPasswordByPhoneFx,
});

export const $valuePhone = createStore('');
export const $valueEmail = createStore('');
const $notYetToggledByUser = createStore(true);

export const $restoreType = createStore<RegistrationType>(RestoreTypes.EMAIL);

export const $recoveryUserTypes = reshape({
  source: userModel.$user,
  shape: {
    byEmail: (user) => (user ? user.email_confirmed : true),
    byPhone: (user) => (user ? user.phone_confirmed : true),
  },
});

sample({
  // clock: pageLoaded,
  source: guard({
    source: combine({
      type: deviceModel.$deviceType,
      user: userModel.$user,
    }),
    filter: $notYetToggledByUser,
  }),
  target: setRestoreType,
  fn: ({ type, user }) => {
    if (type === DeviceTypes.Desktop && user?.registration_type === 'EMAIL') {
      return RestoreTypes.EMAIL;
    }

    if (type === DeviceTypes.Desktop && user?.registration_type === 'PHONE') {
      return RestoreTypes.PHONE;
    }

    if (type === DeviceTypes.Desktop) {
      return RestoreTypes.EMAIL;
    }

    return RestoreTypes.PHONE;
  },
});

forward({
  from: [setRestoreType, restoreTypeChanged],
  to: [
    $restoreType,
    phoneRequestRateLimitModel.resetTimeLeft.prepend(() => {}),
  ],
});

condition({
  source: merge([setRestoreType, restoreTypeChanged]),
  if: (type) => type === RestoreTypes.EMAIL,
  then: setEmail,
  else: setPhone,
});

sample({
  clock: guard(setEmail, { filter: userModel.$email.map(Boolean) }),
  source: userModel.$email,
  fn: (email) => email || '',
  target: [valueEmailChanged, initEmail],
});

sample({
  clock: debounce({ source: setPhone, timeout: 200 }),
  target: initPhone,
});

sample({
  clock: guard(setPhone, { filter: userModel.$phone.map(Boolean) }),
  source: userModel.$phone,
  fn: (value) => ({
    value: value || '',
    formattedValue: value || '',
  }),
  target: valuePhoneChanged,
});

sample({
  clock: restoreTypeChanged,
  target: $notYetToggledByUser,
  fn: () => false,
});

forward({
  from: valueEmailChanged,
  to: $valueEmail,
});

sample({
  source: valuePhoneChanged,
  target: $valuePhone,
  fn: ({ value }) => value,
});

export const $emailValidationError = createStore('').on(
  valueEmailChanged,
  (_, email) => isValidEmail(email),
);

export const $phoneValidationError = createStore('').on(
  valuePhoneChanged,
  (_, { formattedValue }) => isValidPhone(formattedValue),
);

export const $isCaptchaValid = combine(
  $restoreType,
  captchaEmail.$isCaptchaRequired,
  captchaEmail.$isCaptchaValid,
  captchaPhone.$isCaptchaRequired,
  captchaPhone.$isCaptchaValid,
  (
    type,
    captchaEmailRequired,
    captchaEmailValid,
    captchaPhoneRequired,
    captchaPhoneValid,
  ) => {
    if (type === RestoreTypes.EMAIL) {
      return captchaEmailRequired ? captchaEmailValid : true;
    }

    if (type === RestoreTypes.PHONE) {
      return captchaPhoneRequired ? captchaPhoneValid : true;
    }
  },
);

condition({
  source: formSubmited,
  if: $restoreType.map((type) => type === RestoreTypes.EMAIL),
  then: sendByEmail,
  else: sendByPhone,
});

sample({
  clock: sendByEmail,
  source: combine({
    email: $valueEmail,
    captcha: captchaEmail.$captcha,
    callback_url: window.location.origin,
  }),
  target: requestPasswordByEmailFx,
});

sample({
  source: requestPasswordByEmailFx.failData,
  target: captchaEmail.setApiCaptchaError,
  fn: (errors) => createErrorObject(errors)['captcha'],
});

sample({
  clock: sendByPhone,
  source: combine({
    phone: $valuePhone,
    captcha: captchaPhone.$captcha,
  }),
  target: requestPasswordByPhoneFx,
});

sample({
  source: requestPasswordByPhoneFx.failData,
  target: captchaPhone.setApiCaptchaError,
  fn: (errors) => createErrorObject(errors)['captcha'],
});

forward({
  from: [requestPasswordByEmailFx.done, requestPasswordByPhoneFx.done],
  to: [passwordReseted],
});

forward({
  from: passwordReseted,
  to: [captchaEmail.refreshCaptcha, captchaPhone.refreshCaptcha],
});

sample({
  source: guard(userModel.$user, { filter: (user) => !user }),
  fn: () => '',
  target: [$valueEmail, $valuePhone],
});

/**
 * API errors
 */
const $emailApiError = createStore('');
const $phoneApiError = createStore('');
const $generalApiError = createStore<boolean>(false);

const FIELD_ERRORS = ['phone', 'email', 'captcha', 'rateLimit'];

sample({
  source: requestPasswordByEmailFx.failData,
  target: $emailApiError,
  fn: (errors) => createErrorObject(errors)['email'],
});

sample({
  source: requestPasswordByPhoneFx.failData,
  target: $phoneApiError,
  fn: (errors) => createErrorObject(errors)['phone'],
});

export const $emailError = combine(
  $emailApiError,
  $emailValidationError,
  (api, validation) =>
    (api && i18next.t(`change-password:errors.email.${api}`)) ||
    validation ||
    '',
);

export const $phoneError = combine(
  $phoneApiError,
  $phoneValidationError,
  (api, validation) =>
    (api && i18next.t(`change-password:errors.phone.${api}`)) ||
    validation ||
    '',
);

sample({
  source: guard(
    merge([
      requestPasswordByEmailFx.failData,
      requestPasswordByPhoneFx.failData,
    ]),
    {
      filter: (errors) => !hasErrorsIsObject(errors, FIELD_ERRORS),
    },
  ),
  target: $generalApiError,
  fn: () => true,
});

sample({
  source: guard(valueEmailChanged, {
    filter: $emailApiError.map(Boolean),
  }),
  target: $emailApiError,
  fn: () => '',
});

sample({
  source: guard(valuePhoneChanged, {
    filter: $phoneApiError.map(Boolean),
  }),
  target: $phoneApiError,
  fn: () => '',
});

export const $isFormDisabled = combine(
  $restoreType,
  $isCaptchaValid,
  $emailError,
  $valueEmail,
  $phoneError,
  $valuePhone,
  phoneRequestRateLimitModel.isRunning,
  (
    type,
    isCaptchaValid,
    emailError,
    email,
    phoneError,
    phone,
    hasPhoneRateLimit,
  ) => {
    if (type === RestoreTypes.EMAIL) {
      return !isCaptchaValid || Boolean(emailError) || Boolean(!email);
    }

    if (type === RestoreTypes.PHONE) {
      return (
        !isCaptchaValid ||
        Boolean(phoneError) ||
        Boolean(!phone) ||
        hasPhoneRateLimit
      );
    }
  },
);

/**
 * Confirm password
 */
const FORGOT_CODE_PARAM = 'forgotCode';

export const checkForgotCodeParam = createEvent<string>();

export const confirmNewPasswordFx = createEffect<
  ConfirmNewPasswordBody,
  string,
  ApiError
>(restoreApi.confirmNewPassword);

export const $forgotPasswordCode = createStore<string>('');

export const $canChangePassword = $forgotPasswordCode.map((code) => !!code);

export const $confirmPasswordForm = createForm({
  fields: {
    password: {
      init: '',
      rules: [rules.required(), rules.minLength(6), rules.maxLength(32)],
      validateOn: ['blur'],
    },
    repassword: {
      init: '',
      rules: [rules.required(), rules.isMatch(() => true, 'password')],
      validateOn: ['blur'],
    },
  },
});

sample({
  source: checkForgotCodeParam,
  target: $forgotPasswordCode,
  fn: (search) => {
    const params = new URLSearchParams(search);
    return params.get(FORGOT_CODE_PARAM) ?? '';
  },
});

guard({
  source: $canChangePassword,
  filter: Boolean,
  target: navigationModel.historyReplace.prepend(paths.confirmNewPassword),
});

guard({
  source: $forgotPasswordCode,
  filter: Boolean,
  target: navigationModel.clearParam.prepend(() => FORGOT_CODE_PARAM),
});

sample({
  source: $forgotPasswordCode,
  clock: $confirmPasswordForm.formValidated,
  target: confirmNewPasswordFx,
  fn: (code, values) => ({ code, ...values }),
});

confirmNewPasswordFx.failData.watch((error) =>
  setApiErrorsToForm(error, $confirmPasswordForm, 'change-password:errors'),
);

/**
 * confirm password modal
 */

export enum ConfirmPasswordModalContent {
  SUCCESS = 'passwordHasChanged',
  GENERAL_ERROR = 'general_error',
}

export const closeConfirmPasswordModal = createEvent();

export const $confirmPasswordModal = createStore({
  isOpen: false,
  content: '',
}).reset(closeConfirmPasswordModal);

sample({
  source: confirmNewPasswordFx.doneData,
  target: $confirmPasswordModal,
  fn: () => ({
    isOpen: true,
    content: ConfirmPasswordModalContent.SUCCESS,
  }),
});

sample({
  source: guard(confirmNewPasswordFx.failData, {
    filter: (errors) => !hasErrorsIsObject(errors, ['password', 'repassword']),
  }),
  target: $confirmPasswordModal,
  fn: () => ({
    isOpen: true,
    content: ConfirmPasswordModalContent.GENERAL_ERROR,
  }),
});

sample({
  source: confirmNewPasswordFx.doneData,
  target: $confirmPasswordModal,
  fn: () => ({
    isOpen: true,
    content: ConfirmPasswordModalContent.SUCCESS,
  }),
});

sample({
  source: guard($confirmPasswordModal, { filter: ({ isOpen }) => isOpen }),
  target: navigationModel.historyReplace,
  fn: () => paths.home(),
});

/**
 * main modal
 */
export enum ModalContent {
  EMAIL = 'email',
  PHONE = 'phone',
  GENERAL_ERROR = 'general_error',
  RATE_LIMIT = 'rate_limit_error',
}

export const closeModal = createEvent<void>();
export const $modal = createStore({
  isOpen: false,
  content: '',
});

sample({
  source: requestPasswordByPhoneFx.doneData,
  target: $modal,
  fn: () => ({
    isOpen: true,
    content: ModalContent.PHONE,
  }),
});

sample({
  source: requestPasswordByEmailFx.doneData,
  target: $modal,
  fn: () => ({
    isOpen: true,
    content: ModalContent.EMAIL,
  }),
});

sample({
  source: $generalApiError,
  target: $modal,
  fn: () => ({ isOpen: true, content: ModalContent.GENERAL_ERROR }),
});

sample({
  source: phoneRequestRateLimitModel.requestFailedByRateTime,
  target: $modal,
  fn: () => ({ isOpen: true, content: ModalContent.RATE_LIMIT }),
});

sample({
  source: guard(closeModal, { filter: $generalApiError }),
  target: $generalApiError,
  fn: () => false,
});

$modal.on(closeModal, () => ({ isOpen: false, content: '' }));
