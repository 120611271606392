import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from 'pages/paths';
import { skinModel } from 'features/skin-id';

export const HeaderLogo = () => {
  const skinId = skinModel.selectors.useSkinId();

  return (
    <Link to={paths.home()} className="header__logo">
      <img src={`/img/${skinId}/logo.svg`} alt="logo" />
    </Link>
  );
};
