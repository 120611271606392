import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconSprite } from 'ui';
import { DateTime } from 'lib/datetime';

import {
  BonusCardWrap,
  BonusSubtitle,
  BonusTitle,
  GiftSpinsBonusTitle,
} from '../molecules';

import * as model from '../model';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const BonusCardKilled: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { idx, className, bonus } = props;

  const hasTwoRewards = model.bonusHasTwoRewards(props.bonus);

  const endDate = React.useMemo(
    () => (bonus.ended_at ? DateTime.getData(bonus.ended_at) : null),
    [bonus.ended_at],
  );

  if (hasTwoRewards) {
    return <TwoRewardsKilledBonus {...props} />;
  }

  return (
    <BonusCardWrap
      idx={idx}
      className={classNames(className, 'killed', 'one-side')}
    >
      <div className="bonus-card__info">
        <BonusTitle whiteStars bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <div className="bonus-end-message">
          {t('bonus:messages.bonus-end-message')}
        </div>
        <div className="good-luck-message">
          <IconSprite name="bonus-fail-message-icon" />
          <span>{t('bonus:messages.stillLucky')}</span>
        </div>
        <div className="wagering-end-message">
          {t('bonus:messages.wagering-end-message')}
        </div>
        {endDate && (
          <div className="bonus__end-date">
            {endDate.day}.{endDate.month}.{endDate.year}
          </div>
        )}
      </div>
    </BonusCardWrap>
  );
};

const TwoRewardsKilledBonus: React.FC<Props> = ({ idx, className, bonus }) => {
  const { t } = useTranslation();

  const endDate = React.useMemo(
    () => (bonus.ended_at ? DateTime.getData(bonus.ended_at) : null),
    [bonus.ended_at],
  );

  return (
    <BonusCardWrap idx={idx} className={classNames(className, 'killed')}>
      <div className="bonus-card__info">
        <BonusTitle whiteStars bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <div className="bonus-card__title plus">+</div>
        <GiftSpinsBonusTitle
          whiteStars
          reward_giftspins={bonus.reward_giftspins as GiftSpin}
        />
        <BonusSubtitle>{t('bonus:freespins')}</BonusSubtitle>
        <div className="bonus-end-message">
          {t('bonus:messages.bonus-end-message')}
        </div>
        <div className="good-luck-message">
          <IconSprite name="bonus-fail-message-icon" />
          <span>{t('bonus:messages.stillLucky')}</span>
        </div>
        <div className="wagering-end-message">
          {t('bonus:messages.wagering-end-message')}
        </div>
        {endDate && (
          <div className="bonus__end-date">
            {endDate.day}.{endDate.month}.{endDate.year}
          </div>
        )}
      </div>
    </BonusCardWrap>
  );
};
