import * as React from 'react';

import { BonusCard } from './bonus-card';
import { WofCard } from './wof-card';
import { GiftspinCard } from './giftspin-card';
import { WelcomeBonusCard } from './welcome-bonus-card';
import { TaskBonusCard } from './task-bonus-card';
import { BonusProgramCard } from './bonus-program-card';

import * as model from '../model';

export const GetBonusCard = ({ bonus, idx }) => {
  if (model.isPlayerBonus(bonus)) {
    return <BonusCard idx={idx} bonus={bonus} />;
  } else if (model.isWofSpin(bonus)) {
    return <WofCard idx={idx} ws={bonus} />;
  } else if (model.isGiftSpin(bonus)) {
    return <GiftspinCard idx={idx} giftspin={bonus} />;
  } else if (model.isWelcomeBonus(bonus)) {
    return <WelcomeBonusCard idx={idx} bonus={bonus} />;
  } else if (model.isBonusTaskProgram(bonus)) {
    return <TaskBonusCard idx={idx} bonus={bonus} />;
  }

  return <BonusProgramCard idx={idx} bonus={bonus} />;
};
