import React from 'react';
import classNames from 'classnames';
import './styles.css';

interface GridProps {
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
  container?: boolean;
  item?: boolean;
  spacing?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  width?: number;
}

export const Grid = ({
  children,
  container,
  item,
  spacing = 2,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
}: GridProps) => {
  const classProps = classNames(
    container && 'grid-container',
    item && 'grid-item',
    container && `spacing-${spacing}`,
    className,
    item && xs && `xs-${xs}`,
    item && sm && `sm-${sm}`,
    item && md && `md-${md}`,
    item && lg && `lg-${lg}`,
    item && xl && `xl-${xl}`,
  );
  return <div className={classProps}>{children}</div>;
};
