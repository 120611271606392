import { httpClient } from 'lib/request';

/**
 *
 */
const getUserLoyaltyProgramProgress = (): Promise<UserLoyaltyProgramProgressResponse> => {
  return httpClient.get('players/current/loyalty-program');
};

/**
 *
 */
const getLoyaltyProgramStructure = (): Promise<
  LoyaltyProgramSctuctureItem[]
> => {
  return httpClient.get('loyalty-program');
};

/**
 *
 */
const getLoyaltyProgramStatuses = (): Promise<LoyaltyProgramStatus[]> => {
  return httpClient.get('loyalty-program/statuses');
};

export const loyaltyApi = {
  getLoyaltyProgramStatuses,
  getUserLoyaltyProgramProgress,
  getLoyaltyProgramStructure,
};
