import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import {
  Article,
  ArticleInfo,
  Container,
  Grid,
  Section,
  SectionTitle,
  TermsText,
  TermsTitle,
} from 'ui';
import { mediaHooks } from 'lib/media';
import { languageModel, useFormaters } from 'features/i18';
import { PromotionSection } from 'features/promotion';
import { IMAGES_NAMES } from 'features/cashbox';
import { userModel } from 'features/user';
import { sessionModel } from 'features/session';
import { currenciesModel } from 'features/currencies';

import * as model from './model';
import './style.css';

const SKELETON_SIZE = 4;

export const PaymentsPage = () => {
  const { t } = useTranslation();
  const isTablet = mediaHooks.useIsTablet();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();

    return model.pageUnLoaded;
  }, []);

  return (
    <Section type="info">
      <Container padding small>
        <SectionTitle>{t('common:menu.payments')}</SectionTitle>
        {isTablet ? <MobileContent /> : <DekstopContent />}
      </Container>
      <PromotionSection />
    </Section>
  );
};

const DekstopContent = () => {
  const {
    depositSectionTitle,
    depositSectionText,
    withdrawSectionText,
    withdrawSectionTitle,
  } = useStore(model.$pageResource);

  const withdrawPSIsLoaded = useStore(model.$withdrawSystemsIsLoaded);
  const depositPSIsLoaded = useStore(model.$depositSystemsIsLoaded);

  const { withdrawPS, depositPS } = useStore(
    model.$currentCurrencyPaymentSystems,
  );

  return (
    <Article violet>
      <ArticleInfo className="terms__pay-wrap">
        <TermsTitle className="terms__title_pay">
          {depositSectionTitle}
        </TermsTitle>
        <TermsText className="terms__text_pay">{depositSectionText}</TermsText>
        <div className="payment">
          <TableRowHead />
          {depositPSIsLoaded
            ? depositPS?.map((ps) => (
                <PaymentItem key={ps.ps_id} type="deposit" payment={ps} />
              ))
            : Array.from({ length: SKELETON_SIZE }, (_, idx) => (
                <PaymentLoading key={idx} />
              ))}
        </div>
        <TermsTitle className="terms__title_pay">
          {withdrawSectionTitle}
        </TermsTitle>
        <TermsText className="terms__text_pay">{withdrawSectionText}</TermsText>
        <div className="payment">
          <TableRowHead />
          {withdrawPSIsLoaded
            ? withdrawPS?.map((ps) => (
                <PaymentItem key={ps.ps_id} type="withdraw" payment={ps} />
              ))
            : Array.from({ length: SKELETON_SIZE }, (_, idx) => (
                <PaymentLoading key={idx} />
              ))}
        </div>
      </ArticleInfo>
    </Article>
  );
};

const MobileContent = () => {
  const { t } = useTranslation();

  const withdrawPSIsLoaded = useStore(model.$withdrawSystemsIsLoaded);
  const depositPSIsLoaded = useStore(model.$depositSystemsIsLoaded);

  const { withdrawPS, depositPS } = useStore(
    model.$currentCurrencyPaymentSystems,
  );

  return (
    <div className="terms">
      <TermsTitle className="terms__title_pay">
        {t('payments:depositMethods')}
      </TermsTitle>
      <TermsText className="terms__text_pay">
        {t('payments:depositText')}
      </TermsText>
      <Grid className="payment__container-mob" spacing={1} container>
        {depositPSIsLoaded
          ? depositPS?.map((ps) => (
              <Grid key={ps.ps_id} xs={12} sm={6} item>
                <MobilePaymentItem type="deposit" payment={ps} />
              </Grid>
            ))
          : Array.from({ length: SKELETON_SIZE }, (_, idx) => (
              <Grid key={idx} xs={12} sm={6} item>
                <PaymentLoading mobile />
              </Grid>
            ))}
      </Grid>
      <TermsTitle className="terms__title_pay">
        {t('payments:withdrawalMethods')}
      </TermsTitle>
      <TermsText className="terms__text_pay">
        {t('payments:withdrawalText')}
      </TermsText>
      <Grid className="payment__container-mob" spacing={1} container>
        {withdrawPSIsLoaded
          ? withdrawPS?.map((ps) => (
              <Grid key={ps.ps_id} xs={12} sm={6} item>
                <MobilePaymentItem type="withdraw" payment={ps} />
              </Grid>
            ))
          : Array.from({ length: SKELETON_SIZE }, (_, idx) => (
              <Grid key={idx} xs={12} sm={6} item>
                <PaymentLoading mobile />
              </Grid>
            ))}
      </Grid>
    </div>
  );
};

interface PaymentItemProps {
  payment: PaymentSystemItem;
  type: 'deposit' | 'withdraw';
}

const PaymentItem: React.FC<PaymentItemProps> = ({ payment, type }) => {
  const { currencyNumber } = useFormaters();

  const userCurrency = useStore(userModel.$currency);
  const isAuth = useStore(sessionModel.$isAuthenticated);
  const defaultCurrency = useStore(currenciesModel.$defaultCurrency);

  const {
    title,
    processing,
    fee,
  } = languageModel.selectors.usePaymentDescription(payment.ps_name);

  const maxLimit = payment[`max_${type}`] as number;
  const minLimit = payment[`min_${type}`] as number;

  const currentCurrency =
    isAuth && userCurrency ? userCurrency : defaultCurrency;

  return (
    <div className="payment__row">
      <div className="payment__method">
        <div className="payment__img">
          <PaymentImage payment={payment} />
        </div>
        <span className="payment__text">{title}</span>
      </div>
      <div className="payment__price">
        <div className="payment__text">{fee}</div>
      </div>
      <div className="payment__time">
        <div className="payment__text">{processing}</div>
      </div>
      <div className="payment__limit">
        <div className="payment__text">
          Min{' '}
          {currencyNumber(minLimit, {
            currency: currentCurrency,
          })}{' '}
          / Max{' '}
          {currencyNumber(maxLimit, {
            currency: currentCurrency,
          })}
        </div>
      </div>
    </div>
  );
};

const MobilePaymentItem: React.FC<PaymentItemProps> = ({ payment, type }) => {
  const { currencyNumber } = useFormaters();

  const userCurrency = useStore(userModel.$currency);
  const isAuth = useStore(sessionModel.$isAuthenticated);
  const defaultCurrency = useStore(currenciesModel.$defaultCurrency);

  const { t } = useTranslation();

  const {
    fee,
    processing,
    title,
  } = languageModel.selectors.usePaymentDescription(payment.ps_name);

  const maxLimit = payment[`max_${type}`] as number;
  const minLimit = payment[`min_${type}`] as number;

  const currentCurrency =
    isAuth && userCurrency ? userCurrency : defaultCurrency;

  return (
    <div className="payment__item-mob">
      <div className="payment__row-mob payment__head-mob">
        <span className="payment__text-mob">{t('payments:table.method')}</span>
        <span className="payment__text-mob payment__text_color-mob with-img">
          {title}
          <div className="payment__img-mob">
            <PaymentImage payment={payment} />
          </div>
        </span>
      </div>
      <div className="payment__body-mob">
        <div className="payment__row-mob">
          <span className="payment__text-mob">{t('payments:table.price')}</span>
          <span className="payment__text-mob payment__text_color-mob">
            {fee}
          </span>
        </div>
        <div className="payment__row-mob">
          <span className="payment__text-mob">{t('payments:table.time')}</span>
          <span className="payment__text-mob payment__text_color-mob">
            {processing}
          </span>
        </div>
        <div className="payment__row-mob">
          <span className="payment__text-mob">{t('payments:table.limit')}</span>
          <span className="payment__text-mob payment__limits-mob">
            <span>
              Min{' '}
              {currencyNumber(minLimit, {
                currency: currentCurrency,
              })}
              /{' '}
            </span>
            <span>
              Max{' '}
              {currencyNumber(maxLimit, {
                currency: currentCurrency,
              })}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

const TableRowHead = () => {
  const { t } = useTranslation();
  return (
    <div className="payment__head payment__row">
      <div className="payment__method">
        <span className="payment__text">{t('payments:table.method')}</span>
      </div>
      <div className="payment__price">
        <div className="payment__text">{t('payments:table.price')}</div>
      </div>
      <div className="payment__time">
        <div className="payment__text">{t('payments:table.time')}</div>
      </div>
      <div className="payment__limit">
        <div className="payment__text">{t('payments:table.limit')}</div>
      </div>
    </div>
  );
};

const DEFAULT_IMG_NAME = 'default.svg';

const PaymentImage: React.FC<{ payment: PaymentSystemItem }> = ({
  payment,
}) => {
  const [hasError, setHasError] = React.useState(false);

  const { ps_name } = payment;

  return (
    <img
      onError={() => setHasError(true)}
      src={
        hasError
          ? `/img/payments/${IMAGES_NAMES[ps_name] || DEFAULT_IMG_NAME}`
          : DEFAULT_IMG_NAME
      }
      alt=""
    />
  );
};

const PaymentLoading = ({ mobile }: { mobile?: boolean }) => {
  const { t } = useTranslation();

  if (mobile) {
    return (
      <div className="payment__item-mob">
        <div className="payment__row-mob payment__head-mob">
          <span className="payment__text-mob">
            {t('payments:table.method')}
          </span>
          <span className="payment__text-mob"></span>
        </div>
        <div className="payment__body-mob">
          <div className="payment__row-mob">
            <span className="payment__text-mob">
              {t('payments:table.type')}
            </span>
            <span className="payment__text-mob"></span>
          </div>
          <div className="payment__row-mob">
            <span className="payment__text-mob">
              {t('payments:table.price')}
            </span>
            <span className="payment__text-mob payment__text_color-mob"></span>
          </div>
          <div className="payment__row-mob">
            <span className="payment__text-mob">
              {t('payments:table.time')}
            </span>
            <span className="payment__text-mob payment__text_color-mob"></span>
          </div>
          <div className="payment__row-mob">
            <span className="payment__text-mob">
              {t('payments:table.limit')}
            </span>
            <span className="payment__text-mob"></span>
          </div>
        </div>
      </div>
    );
  }

  return <div className="payment__row"></div>;
};
