import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';

import { clearParam } from 'features/navigation/model';
import { userApi } from 'api/user';
import { userModel } from '.';

const EMAIL_PARAM = 'email-code';

export const checkEmailParam = createEvent<string>();
export const clearEmailVerificationCode = createEvent<void>();
export const setEmailVerificationCode = createEvent<string>();

export const sendEmailCodeFx = createEffect<string, Session, ApiError>(
  userApi.confirmEmail,
);

export const $emailVerificationCode = restore(
  setEmailVerificationCode,
  '',
).reset(clearEmailVerificationCode);

sample({
  clock: checkEmailParam,
  target: setEmailVerificationCode,
  fn: (search) => {
    const params = new URLSearchParams(search);
    return params.get(EMAIL_PARAM) ?? '';
  },
});

$emailVerificationCode.watch((code) => {
  console.log('$emailVerificationCode', code);
});

guard({
  source: $emailVerificationCode,
  filter: (code) => Boolean(code),
  target: sendEmailCodeFx,
});

forward({
  from: sendEmailCodeFx.finally,
  to: clearEmailVerificationCode,
});

sample({
  source: clearEmailVerificationCode,
  fn: () => EMAIL_PARAM,
  target: clearParam,
});

forward({
  from: sendEmailCodeFx.doneData,
  to: userModel.loadUserFx,
});

/**
 * MODAL
 */
enum ModalContent {
  SUCCESS = 'successfulEmailConfirmation',
  INVALID_CODE = 'errorInvalidEmailCode',
  FAIL = 'defaultErrorText',
}

export const closeModal = createEvent();
export const $modal = createStore({
  isOpen: false,
  content: '',
});

sample({
  source: sendEmailCodeFx.failData,
  target: $modal,
  fn: ({ items }) => {
    const isCodeInvalid = Object.keys(items).includes('code.wrong');
    return {
      isOpen: true,
      content: isCodeInvalid ? ModalContent.INVALID_CODE : ModalContent.FAIL,
    };
  },
});

sample({
  source: sendEmailCodeFx.doneData,
  target: $modal,
  fn: () => ({
    isOpen: true,
    content: ModalContent.SUCCESS,
  }),
});

$modal.reset(closeModal);
