import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';
import { EnumBonusModalsContent } from 'lib/constants';

import * as modalModel from '../model/modal-model';

export const BonusActionResultInfoToast = () => {
  const { t } = useTranslation();
  const { content } = useStore(modalModel.$modal);
  return (
    <>
      {content === EnumBonusModalsContent.CancelDone && (
        <Toast isOpen onAnimationEnd={modalModel.reset}>
          {t('bonus:popup.canceled')}
        </Toast>
      )}
      {content === EnumBonusModalsContent.ActivateDone && (
        <Toast isOpen onAnimationEnd={modalModel.reset}>
          {t('bonus:popup.activated')}
        </Toast>
      )}
      {content === EnumBonusModalsContent.WofSpinsCancelDone && (
        <Toast isOpen onAnimationEnd={modalModel.reset}>
          {t('bonus:popup.wofSpinsCanceled')}
        </Toast>
      )}
    </>
  );
};
