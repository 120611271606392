import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';

import { bonusApi } from 'api/bonus';
import { navigationModel } from 'features/navigation';
import { paths } from 'pages/paths';

import { sessionModel } from 'features/session';
import { bonusModel } from '..';

export const setParams = createEvent<string>();
export const $params = createStore('').on(setParams, (_, params) => params);

export const $couponParamName = createStore('');

export const activateInviteCode = createEvent<string>();

export const codeActivated = createEvent();

export const getCouponParamNameFx = createEffect<void, string>(
  bonusApi.getInviteCouponParamName,
);

export const activateInviteCodeFx = createEffect<
  string,
  BonusProgram[],
  ApiError
>(bonusApi.activateInviteCode);

export const checkParam = createEvent<string>();
export const clearInviteCode = createEvent<void>();
export const setInviteCode = createEvent<string>();

export const $inviteCode = restore(setInviteCode, '').reset(clearInviteCode);

forward({
  from: getCouponParamNameFx.doneData,
  to: $couponParamName,
});

sample({
  clock: guard({
    clock: $couponParamName,
    filter: Boolean,
  }),
  source: $params,
  target: checkParam,
});

sample({
  source: $couponParamName,
  clock: checkParam,
  target: setInviteCode,
  fn: (paramName, search) => {
    const params = new URLSearchParams(search);
    return params.get(paramName) ?? '';
  },
});

// активировать код при изменение стора
guard({
  source: $inviteCode,
  filter: Boolean,
  target: activateInviteCode,
});

// активировать код при авторизации пользователя, если стор кода не пустой
sample({
  source: $inviteCode,
  clock: guard(sessionModel.$isAuthenticated, {
    filter: $inviteCode.map(Boolean),
  }),
  target: activateInviteCode,
});

// проверить авторизацию при активации
guard({
  source: activateInviteCode,
  filter: sessionModel.$isAuthenticated,
  target: activateInviteCodeFx,
});

// очистить стор инвайт кода при завершении запроса
forward({
  from: activateInviteCodeFx.finally,
  to: clearInviteCode,
});

// очистить параметры
sample({
  source: clearInviteCode,
  target: navigationModel.clearParams,
});

// успешная активация кода
guard({
  source: activateInviteCodeFx.doneData,
  filter: (programs) => programs.length !== 0,
  target: codeActivated,
});

// обновить бон. программы и редирект на страницу мои бонусы
forward({
  from: codeActivated,
  to: [
    bonusModel.playerBonusesRequested,
    navigationModel.historyPush.prepend(() => paths.lkBonus()),
  ],
});
