import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Section, SectionTitle } from 'ui';
import { PaginationBlock } from 'features/pagination';
import { promotionModel, PromotionList } from 'features/promotion';

import * as model from './model';

export const PromotionsPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    model.pageLoaded({ promotionLimit: 6 });
    window.scrollTo(0, 0);
    return () => model.pageUnloaded();
  }, []);

  return (
    <>
      <Container small>
        <Section type="info">
          <SectionTitle>{t('common:menu.stocks')}</SectionTitle>
          <PromotionList skeletonSize={6} />
          <PaginationBlock
            onPageChanged={() => window.scrollTo(0, 0)}
            pagination={promotionModel.pagination}
          />
        </Section>
      </Container>
    </>
  );
};
