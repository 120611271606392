import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const useOnClickOutside = ({
  ref,
  handler,
  ignorList = [],
  disabled,
}: {
  ref: React.RefObject<HTMLElement>;
  handler: Function;
  ignorList?: string[];
  disabled?: boolean;
}) => {
  const listener: any = useCallback(
    (event: React.ChangeEvent) => {
      // console.log('useOnClickOutSide handler', ref?.current, '-елемент');
      // Do nothing if clicking ref's element or descendent elements
      const isContain = ignorList?.length
        ? ignorList
            .map((element) => document.querySelector(element))
            .some((elem: any) => elem && elem.contains(event.target))
        : false;
      if (!ref?.current || ref?.current?.contains(event.target) || isContain) {
        return;
      }
      handler(event);
    },
    [ref, handler, ignorList],
  );

  useEffect(() => {
    if (disabled) {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    } else {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }
  }, [disabled, listener]);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
};

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  return { show, hide, isVisible };
};

export function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return { isOpen, open, close };
}

export const useLockBodyScroll = (isLocked = true) => {
  const [originalStyle, setOriginalStyle] = useState<any>(null);

  useLayoutEffect((): any => {
    setOriginalStyle(window.getComputedStyle(document.body).overflow);
    if (isLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle;
    }
  }, [isLocked]);

  useEffect((): any => {
    return () => (document.body.style.overflow = originalStyle);
  }, []);
};

export function useAnimation(easingName = 'linear', duration = 500, delay = 0) {
  const elapsed = useAnimationTimer(duration, delay);
  const n = Math.min(1, elapsed / duration);
  return easing[easingName](n);
}

const easing: any = {
  linear: (n: any) => n,
  elastic: (n: any) =>
    n * (33 * n * n * n * n - 106 * n * n * n + 126 * n * n - 67 * n + 15),
  inExpo: (n: any) => Math.pow(2, 10 * (n - 1)),
};

function useAnimationTimer(duration: number = 1000, delay: number = 0) {
  const [elapsed, setTime] = useState<number>(0);

  useEffect(() => {
    let animationFrame: any, timerStop: any, start: number;

    function onFrame() {
      setTime(Date.now() - start);
      loop();
    }

    function loop() {
      animationFrame = requestAnimationFrame(onFrame);
    }

    function onStart() {
      timerStop = setTimeout(() => {
        cancelAnimationFrame(animationFrame);
        setTime(Date.now() - start);
      }, duration);

      // Start the loop
      start = Date.now();
      loop();
    }

    const timerDelay = setTimeout(onStart, delay);

    return () => {
      clearTimeout(timerStop);
      clearTimeout(timerDelay);
      cancelAnimationFrame(animationFrame);
    };
  }, [duration, delay]);

  return elapsed;
}

// Hook
export function useDebounce(value: any, delay: any) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setIsCalculated(true);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        setIsCalculated(true);
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return [debouncedValue, setDebouncedValue, isCalculated];
}

export function useKeyPress({
  targetKey,
  disabled,
  handler = () => {},
}: {
  targetKey: any;
  disabled?: boolean;
  handler?: Function;
}) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
      handler();
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    if (disabled) {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    } else {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    }
  }, [disabled]);

  useEffect(() => {
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

export function useWindowRatio() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowRatio, setWindowRatio] = useState<number>(0);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowRatio(window.innerHeight * window.innerWidth);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowRatio;
}

export { useDebounce as useDebouncedValue };
export { useScript } from './use-script';
export { useGameMessenger } from './use-game-message';
export { usePrevious } from './use-previous';
