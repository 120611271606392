import { httpClient } from 'lib/request';

/**
 * Получить бонусные программы на депозит
 */
const getDepositBonusProgramList = (): Promise<BonusResponse<BonusProgram>> =>
  httpClient.get('/bonuses/coupons');

/**
 * Получить бонусные программы по промокоду
 */
const getBonusProgramsByCoupon = (coupon: string) =>
  httpClient.get(`players/current/bonuses/coupons/${coupon}`);

/**
 * Получить имя параметра для мониторинга invite code
 */
const getInviteCouponParamName = (): Promise<string> =>
  httpClient.get('players/current/bonuses/invites');

/**
 * Получить бонусы игрока
 */
const getAllPlayerBonus = (): Promise<BonusResponse<PlayerBonus>> =>
  httpClient.get('players/current/bonuses');
// new Promise((resolve) => resolve(fakePlayerBonusResponse));

// активировать бонус по id
const activateBonus = (id: number): Promise<PlayerBonus> =>
  httpClient.post(`players/current/bonuses/active`, id);
// new Promise((resolve) => resolve(getBonus('ACTIVE', id)));

// отменить бонус по id
const cancelBonus = (id: number): Promise<PlayerBonus> =>
  httpClient.delete(`players/current/bonuses/${id}`);
// new Promise((resolve) => resolve(getBonus('KILLED', id)));

/**
 * Получить бонусную программу @type = "WEEKLY_CASHBACK"
 */
function getCashbackBonusProgram(): Promise<BonusProgram | null> {
  return httpClient.get('players/current/bonuses/actions/weekly-cashback');
}

/**
  Активировать промокод для получения либо бонуса либо вращения колеса фортуны
  @param value - промокод
*/
function activePromocode(value: string): Promise<ActivatePromocodeResponse> {
  return httpClient.post('players/current/bonuses/coupons', value);
}
/**
 * Получить все welcome bonus programs
 */
const getWelcomeBonusPrograms = (): Promise<BonusProgram[]> =>
  httpClient.get('players/current/bonuses/actions/welcome');

/**
 * Получить все welcome bonus programs
 */
const getTaskPrograms = (): Promise<BonusProgram[]> =>
  httpClient.get('players/current/bonuses/actions/tasks');

/**
 *Активировать задания welcome бон. программы
 @param taskId - номер задания
 */
const activateWelcomeBonusProgramTask = (
  taskId: number,
): Promise<{ task_id: number; giftspins: GiftSpin; type: string }> =>
  httpClient.post('players/current/bonuses/actions/task', taskId);

/**
 *Получить доступные фриспины
 */
const getAvaibleGiftspins = (mobile: boolean): Promise<GiftSpin[]> =>
  httpClient.get(`players/current/bonuses/giftspins?mobile=${mobile}`);

/*
  получить все завершенные бонусы
*/
const getAllCompletedBonuses = (limit: number): Promise<PlayerBonus[]> =>
  httpClient
    .get('players/current/bonuses/old', { limit })
    .then((data) => data.data);

/**
 * Получить активный бонус игрока
 */
function getActiveBonus(): Promise<PlayerBonus | null> {
  return httpClient.get('players/current/bonuses/active');
  // return new Promise((resolve) => resolve(fakeActiveBonus));
}

/**
 * Бонусная программа на следующий депозит игрока
 */
function getSequenceBonuses(): Promise<BonusProgram[]> {
  return httpClient.get('/players/current/bonuses/actions/sequence');
}

/**
  Все бонусные программы доступные игроку при совершении депозита
  * @param code - промокод, необязательный
*/
function getAllBonusProgramsForDeposit(code: string): Promise<BonusProgram[]> {
  const url = code
    ? `players/current/bonuses/deposit?code=${code}`
    : 'players/current/bonuses/deposit';
  return httpClient.get(url);
}

/**
 * Активировать invite-код для получения доступа к персональной бонусной программе
 * @param invite - код, string
 */
function activateInviteCode(code: string): Promise<BonusProgram[]> {
  return httpClient.post('players/current/bonuses/invites', code);
}

/**
 * Бонусные программы для reload-бонусов (бонусы на депозит в определенный день)
 */
function getReloadBonuses(): Promise<BonusProgram[]> {
  return httpClient.get('/players/current/bonuses/actions/reload');
}

/**
 * Бонусные программы, доступные для пользователя
 */

function getAvailableBonuses(): Promise<BonusResponse<PlayerBonus>> {
  return httpClient.get('/players/current/bonuses/available');
}

/**
 * Вращения колеса фортуны, доступные для пользователя
 */
function getWofSpins(): Promise<WofSpin[]> {
  return httpClient.get('/players/current/wof/spins/active');
}

/**
 * Бонусные программы, отображаемые для гостей
 */
function getGuestBonuses(): Promise<BonusProgram[]> {
  return httpClient.get('/bonuses/actions/guest');
}

/**
 * Маркер отсутствие депозитов (возможность получить бонус на первый депозит)
 */
function getFirstDepInfo(): Promise<boolean> {
  return httpClient.get('/bonuses/actions/fd-possible');
}

export const bonusApi = {
  activateInviteCode,
  activePromocode,
  activateBonus,
  getTaskPrograms,
  getCashbackBonusProgram,
  cancelBonus,
  getAllBonusProgramsForDeposit,
  getDepositBonusProgramList,
  getBonusProgramsByCoupon,
  getAllCompletedBonuses,
  getAllPlayerBonus,
  getInviteCouponParamName,
  getAvaibleGiftspins,
  getWelcomeBonusPrograms,
  activateWelcomeBonusProgramTask,
  getActiveBonus,
  getSequenceBonuses,
  getReloadBonuses,
  getAvailableBonuses,
  getWofSpins,
  getGuestBonuses,
  getFirstDepInfo,
};
