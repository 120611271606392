import { AxiosRequestParams, httpClient } from 'lib/request';

// TODO: Удалить - дублирует userApi

const getProfile = (): Promise<User> => {
  return httpClient.get(`players/current/`);
};
const updateProfile = (data: UserUpdateProfile): Promise<User> => {
  return httpClient.put(`players/current/`, data);
};
const phoneVerificationRequest = (data: PhoneVerificationRequest) => {
  return httpClient.post('players/current/phone/codes', data);
};
const phoneVerification = (data: PhoneVerificationForm) => {
  return httpClient.post('players/current/phone/confirmations', data);
};
const uploadFile = (
  data: FileUploadForm,
  config?: AxiosRequestParams,
): Promise<FileUploadResponse> => {
  return httpClient.post('players/current/files', data, config);
};
const getFiles = (): Promise<getFilesResponse> => {
  return httpClient.get('players/current/files');
};
const getFile = (id: number): Promise<FileUploadForm> => {
  return httpClient.get(`players/current/files/${id}`);
};
const deleteFile = (id: number): Promise<FileUploadForm> => {
  return httpClient.delete(`players/current/files/${id}`);
};

export const profileApi = {
  getProfile,
  updateProfile,
  phoneVerificationRequest,
  phoneVerification,
  uploadFile,
  getFiles,
  getFile,
  deleteFile,
};
