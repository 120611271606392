import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { chatModel } from 'features/chat';
import { skinModel } from 'features/skin-id';

import './style.css';

const IMG_BG_SRC = './img/failed-payment-bg.png';
const FAILED_ICON_SRC = './img/failed-payment-icon.svg';

export const FailedPaymentPage = () => {
  const { t } = useTranslation();

  const isHab = skinModel.selectors.useIsHabanero();

  return (
    <div
      className="payment-info-wrap failed"
      style={{
        background: isHab
          ? 'initial'
          : 'radial-gradient(50% 50% at 50% 50%, #83212B 0%, #25133C 100%)',
      }}
    >
      <div className="payment-info-content">
        {isHab && <img src={IMG_BG_SRC} className="bg-img" />}
        <div className="message">
          <img src={FAILED_ICON_SRC} className="failed-icon" alt="" />
          <h3>{t('failed-payment:title')}</h3>
          <p>
            <Trans i18nKey="failed-payment:text">
              Попробуйте еще раз, или свяжитесь со <br />
              <span onClick={() => chatModel.chatOpened()} className="chat-btn">
                службой поддержки
              </span>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};
