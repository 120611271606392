import { Store } from 'effector';
import { Rule } from 'effector-forms';
import i18n from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const rules = {
  required: (
    shouldValidate = () => true,
    shortMessage = false,
  ): Rule<string> => ({
    name: 'required',
    validator: (value: string) => ({
      isValid: shouldValidate()
        ? Boolean(typeof value === 'string' ? value.trim() : value)
        : true,
      errorText: i18n.t(
        `common:validators.required${shortMessage ? 'Short' : ''}`,
      ),
    }),
  }),
  email: (shouldValidate = () => true): Rule<string> => ({
    name: 'email',
    validator: (value: string) => ({
      isValid: shouldValidate() ? /\S+@\S+\.\S+/.test(value) : true,
      errorText: i18n.t('common:validators.email'),
    }),
  }),
  isMatch: (
    shouldValidate = () => true,
    fieldName,
    errorI18nKey = 'common:validators.passwordsNotMatch',
  ): Rule<string> => ({
    name: 'phone',
    validator: (value: string, form) => ({
      isValid: shouldValidate() ? value === form[fieldName] : true,
      errorText: i18n.t(errorI18nKey),
    }),
  }),
  phone: (shouldValidate = () => true): Rule<string> => ({
    name: 'phone',
    validator: (value: string | null) => {
      if (shouldValidate() === true) {
        return {
          isValid: value
            ? isValidPhoneNumber(value.startsWith('+') ? value : `+${value}`)
            : false,
          errorText: i18n.t('common:validators.phone'),
        };
      }

      return {
        isValid: true,
      };
    },
  }),
  card: (shouldValidate = () => true): Rule<string> => ({
    name: 'phone',
    validator: (value: string) => ({
      isValid: shouldValidate() ? value.length === 16 : true,
      errorText: i18n.t('common:validators.card'),
    }),
  }),
  minLength: (min: number, shouldValidate = () => true): Rule<string> => ({
    name: 'minLength',
    validator: (value: string) => ({
      isValid: shouldValidate() ? value.length >= min : true,
      errorText: `${i18n.t('common:validators.min')} ${min}`,
    }),
  }),
  maxLength: (max: number, shouldValidate = () => true): Rule<string> => ({
    name: 'maxLength',
    validator: (value: string) => ({
      isValid: shouldValidate() ? value.length <= max : true,
      errorText: `${i18n.t('common:validators.max')} ${max}`,
    }),
  }),
  onlyNumbers: (shouldValidate = () => true): Rule<string> => ({
    name: 'Only numbers',
    validator: (value: string) => ({
      isValid: shouldValidate() ? validateOnlyNumbers(value) : true,
      errorText: i18n.t('common:validators.onlyNumbers'),
    }),
  }),
  string: (shouldValidate = () => true): Rule<string> => ({
    name: 'Only string',
    validator: (value: string) => ({
      isValid: shouldValidate() ? validateOnlyString(value) : true,
      errorText: i18n.t('common:validators.onlyLetters'),
    }),
  }),
};

export function validateOnlyNumbers(string: string) {
  return !/\D/.test(string);
}

export function validateOnlyString(string: string) {
  return !/^[a-zA-Z\s]*$/.test(string);
}

export const isStoreValueMatch = (store: Store<any>, value: any) => {
  let isMatch = false;
  store.map((storeValue) => (isMatch = storeValue === value));
  return isMatch;
};

export const isAnyValueNotEmpty = (obj: anyObj) => {
  let isValid = true;
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (typeof obj[prop] !== 'boolean' || 'number') {
        if (!obj[prop]) {
          isValid = false;
        }
      }
    }
  }
  return isValid;
};

export function validateUrl(str: string): string {
  const pattern = new RegExp(
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:d{1,5})?(/.*)?$',
  );

  if (!pattern.test(str)) {
    return '';
  }
  return str;
}

export { isValidEmail, isValidPhone, isValidCaptcha } from './validation';
