import React from 'react';

import { EnumBonusState } from 'lib/constants';

import { BonusCardKilled } from './bonus-card-killed';
import { BonusCardWon } from './bonus-card-won';
import { BonusCardActive } from './bonus-card-active';
import { BonusCardAvailable } from './bonus-card-available';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const BonusCard: React.FC<Props> = (props) => {
  if (
    props.bonus.state === EnumBonusState.Killed ||
    props.bonus.state === EnumBonusState.Expired
  ) {
    return <BonusCardKilled {...props} />;
  }

  if (props.bonus.state === EnumBonusState.Win) {
    return <BonusCardWon {...props} />;
  }

  if (props.bonus.state === EnumBonusState.Active) {
    return <BonusCardActive {...props} />;
  }

  return <BonusCardAvailable {...props} />;
};
