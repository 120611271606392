import { httpClient } from 'lib/request';

/**
 * Получить url игры
 * @param data - тело запроса: код, провайдер, режим, фингерпринт
 */
function getUrl(data: GameRequest): Promise<string> {
  return httpClient.post(`/games/launcher`, data);
}

// TODO: Ожидаем бэка
/**
 * Получить правила игры в формате html
 * @param code - код игры
 */
function getRules(code: string): Promise<any> {
  return httpClient.get(`/game/rules/${code}`);
}

// TODO: Ожидаем бэка
/**
 * Получить данные по игре
 * @param code - код игры
 * @param provider - кодпровайдера
 */
function getGamesData({
  code,
  provider,
}: {
  code: string;
  provider: string;
}): Promise<any> {
  return httpClient.get(`/games?code=${code}&provider_code=${provider}`);
}

/**
 * Получить данные о конвертации клиентских сообщений от игр
 */
function getGameMessageConvert(): Promise<{
  divisor: number;
  game_codes: string[];
}> {
  return httpClient.get('/properties/game-message-convert');
}

export const gameApi = {
  getUrl,
  getRules,
  getGameMessageConvert,
  getGamesData,
};
