/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Guard } from 'lib/routing';
import {
  createEvent,
  createStore,
  createEffect,
  sample,
  guard,
  combine,
  restore,
  forward,
} from 'effector';
import { debug, delay } from 'patronum';
import connectLocalStorage from 'effector-localstorage/sync';

import { TokenStorage } from 'lib/request/TokenStorage';
import { authApi } from 'api/auth';
import { fpModel } from 'features/fingerPrint';
import { userAdditionalData } from 'features/user';
import { historyReplace } from 'features/navigation/model';
import { paths } from 'pages/paths';
import { sendEmailCodeFx } from 'features/user/model/confirm-email';

const LS_IS_AUTH_KEY = 'IS_AUTH';

const isAuthLocalStorageStateWasChanged = createEvent<boolean>();

const isAuthConnection = connectLocalStorage(LS_IS_AUTH_KEY).onChange(
  isAuthLocalStorageStateWasChanged,
);

const $isAuthLS = createStore(isAuthConnection.init(false));
$isAuthLS.watch(isAuthConnection);

export const fetchSession = createEvent<void>();
export const setSession = createEvent<Session>();
export const $session = restore(setSession, null);
export const $isAuthenticated = $session.map((is) => Boolean(is));

export const checkSessionFx = createEffect(() => {
  return new Promise((resolve, reject) => {
    const token = TokenStorage.getRefreshToken();
    if (token) {
      resolve(token);
    } else {
      reject();
    }
  });
});

export const killSession = createEffect(() => {
  TokenStorage.clear();
  setSession(null);
  historyReplace(paths.home());
});

const setTokensFx = createEffect((data: any) => {
  if (data.access_token && data.refresh_token) {
    TokenStorage.storeToken(data.access_token);
    TokenStorage.storeRefreshToken(data.refresh_token);
  }
});

// initial pending with true
export const $isPending = createStore(true);
export const setIsPending = createEvent<boolean>();
export const loadCurrentUser = createEffect(() => {
  const data = {
    login_info: {
      after_registration: false,
      useragent: userAdditionalData.$userAgent.getState(),
      fp: fpModel.$fp.getState(),
      mirror_url: window.document.location.href,
    },
    token: TokenStorage.getRefreshToken(),
  };
  return authApi.loginWithToken(data);
});

export const $isWaitingFor = combine(
  $isPending,
  $session,
  (isPending, session) => (session ? false : isPending),
);

forward({
  from: $isAuthenticated,
  to: $isAuthLS,
});

guard({
  source: isAuthLocalStorageStateWasChanged,
  filter: (data) => !data,
  target: checkSessionFx,
});

const userIsLoggedInAnotherTab = guard({
  source: delay({
    source: guard(isAuthLocalStorageStateWasChanged, { filter: Boolean }),
    timeout: 1000,
  }),
  filter: $isAuthenticated.map((data) => !data),
});

forward({
  from: userIsLoggedInAnotherTab,
  to: loadCurrentUser,
});

forward({
  from: checkSessionFx.failData,
  to: killSession,
});

$isPending
  .on(loadCurrentUser.finally, () => false)
  .on(setIsPending, (_, data) => data);

guard({
  source: fetchSession,
  filter: loadCurrentUser.pending.map((is) => !is),
  target: loadCurrentUser,
});

// forward({
//   from: killSession.finally,
//   to: fetchSession,
// });

$session
  .on(loadCurrentUser.done, (_, { result }) => setSession(result))
  .on(loadCurrentUser.fail, () => null);

loadCurrentUser.fail.watch(({ error }) => {
  // eslint-disable-next-line no-console
  console.error('Failed to load session:', error);
});

sample({
  source: setSession,
  target: setTokensFx,
  fn: (data) => {
    const access_token = data?.access_token;
    const refresh_token = data?.refresh_token;
    if (access_token && refresh_token) {
      return {
        access_token: data?.access_token,
        refresh_token: data?.refresh_token,
      };
    } else {
      return {};
    }
  },
});

forward({
  from: sendEmailCodeFx.doneData,
  to: setSession,
});

export interface UserContext {
  session: Session | null;
}

export function onlyAnon(): Guard<UserContext> {
  return (route, context) => (context?.session ? null : route);
}

export function onlyUsers(): Guard<UserContext> {
  return (route, context) => (context?.session ? route : null);
}

export function isConditionTruth(condition): Guard<any> {
  return (route) => (condition() ? route : null);
}
