import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResultText, ResultWrap } from 'ui';
import { InifiniteLoader } from 'features/infinite-loader';
import { GameList } from 'features/halls';

interface Props {
  games: Game[];
  query: string;
}

export const SearchResultMobile = ({ games, query }: Props) => {
  const { t } = useTranslation();

  if (query === '') {
    return null;
  }

  return (
    <ResultWrap>
      {games && games.length ? (
        <>
          <ResultText>
            {t('game:search.onRequest')}{' '}
            <span className="results-info">{query} </span>
            {t('game:search.foundGames', { count: games.length })}
          </ResultText>

          <InifiniteLoader
            name="mobile-search"
            list={games}
            render={(gameList) => (
              <GameList gameList={gameList} isPending={false} />
            )}
            scrollThreshold={0.99}
            scrollableTarget="scroll-games"
            loader={null}
          />
        </>
      ) : (
        <ResultText bg>
          {t('game:search.notFound')}
          {': '}
          <span className="results-info">{query}</span>
        </ResultText>
      )}
    </ResultWrap>
  );
};
