import React from 'react';
import { Select } from 'ui';

interface Props {
  onChange?: (value: any) => void;
  options: string[][];
  value: string;
  placeholder?: string;
}

export const HallSelect: React.FC<Props> = ({
  options,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <Select
      className="hall-select"
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
};
