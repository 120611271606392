import { createEvent, forward } from 'effector';
import { spread } from 'patronum';

import { lotteryModel } from 'features/lotteries';

export const pageUnloaded = createEvent();
export const pageLoaded = createEvent<{
  lotteriesLimit: number;
}>();

spread({
  source: pageLoaded,
  targets: {
    lotteriesLimit: lotteryModel.setLimit,
  },
});

forward({
  from: pageLoaded,
  to: lotteryModel.listMounted,
});
