import React from 'react';
import { useStore } from 'effector-react';
import './style.css';
import { Pagination } from 'features/pagination/organisms/pagination';
import { PaginationModel } from '../model';

export const PaginationBlock: React.FC<{
  pagination: PaginationModel<any>;
  onPageChanged?: () => void;
}> = ({ pagination, onPageChanged = () => {} }): React.ReactElement | null => {
  const totalPages = useStore(pagination.$totalPages);
  const currentPage = useStore(pagination.$currentPage);
  const isLast = useStore(pagination.$isLast);

  const isFirst = currentPage === 1;

  const { pageChanged, goToNextPage, goToPrevPage } = pagination;

  const handleChange = (page: number) => {
    pageChanged(page);
    onPageChanged();
  };

  return totalPages > 1 ? (
    <Pagination
      isFirst={isFirst}
      isLast={isLast}
      total={totalPages}
      current={currentPage}
      onChange={handleChange}
      onNextPage={goToNextPage}
      onPrevPage={goToPrevPage}
      defaultPageSize={5}
    />
  ) : null;
};
