import { createEffect, createEvent, restore } from 'effector';

import { bonusApi } from 'api/bonus';

export const deleteTaskProgramById = createEvent<number>();

export const loadStartedTaskProgramsFx = createEffect<
  void,
  BonusProgram[],
  ApiError
>(bonusApi.getTaskPrograms);

export const $bonusTaskPrograms = restore(
  loadStartedTaskProgramsFx.doneData,
  [],
).on(deleteTaskProgramById, (programs, taskId) =>
  programs.filter((program) => program.task.task_id !== taskId),
);
