import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { paths } from 'pages/paths';
import { navigationModel } from 'features/navigation';
import { HallSelect } from 'features/halls';
import { HallSlider } from './hall-slider';

import * as model from '../model';
import '../style.css';

// const HALL_RANK = 1;

export const HallNav = () => {
  const { t } = useTranslation();

  const { category } = useParams<{ category: string }>();
  // const hallNamesByRank = useStore(model.$hallsNamesByRank);

  // const hallNames = hallNamesByRank[HALL_RANK] || [];

  const hallNames = useStore(model.$allHallsExceptZeroRank);

  const options = hallNames.map((name) => [name, name]);

  const handleSelect = (value: string) => {
    navigationModel.historyPush(paths.category(value));
  };

  return (
    <>
      <div className="hall-nav">
        <HallSelect
          onChange={handleSelect}
          options={options}
          value={category}
          placeholder={t('common:providers')}
        />
      </div>
      <HallSlider />
    </>
  );
};
