import {
  createEvent,
  createStore,
  Effect,
  sample,
  restore,
  forward,
} from 'effector';
import { status } from 'patronum';

export function createLotteryModel(
  effect: Effect<void, LotteryResponseType, Error>,
) {
  const loadLotteries = createEvent<void>();

  const $lotteries = createStore<LotteryItem[]>([]);
  const $hasNotLotteries = $lotteries.map((list) => !list.length);
  const $error = restore(effect.failData, null);
  const $status = status({
    effect,
    defaultValue: 'pending',
  });

  forward({
    from: loadLotteries,
    to: effect,
  });

  sample({
    source: effect.doneData,
    target: $lotteries,
    fn: (response) => response.data,
  });

  return {
    $error,
    $lotteries,
    $hasNotLotteries,
    $status,
    loadLotteries,
  };
}
