import {
  createEvent,
  createStore,
  Effect,
  guard,
  sample,
  forward,
} from 'effector';

import { interval } from '../interval';

// {items: {rateLimit: "51"}, empty: false}

export const createRateLimitModel = ({
  effect,
}: {
  effect: Effect<any, any, ApiError>;
}) => {
  const startTick = createEvent();
  const stopTick = createEvent();

  const resetTimeLeft = createEvent<void>();

  const requestFailedByRateTime = createEvent();

  const initTimeLeft = createEvent<number>();
  const $timeLeft = createStore(0).reset(resetTimeLeft);

  const { tick, isRunning } = interval({
    timeout: 1000,
    start: startTick,
    stop: stopTick,
  });

  sample({
    clock: guard({
      clock: effect.failData,
      filter: (error) => !!error.items?.rateLimit,
    }),
    target: [initTimeLeft, requestFailedByRateTime],
    fn: (error) => +error.items?.rateLimit,
  });

  $timeLeft.on(initTimeLeft, (_, leftTime) => leftTime);

  forward({
    from: initTimeLeft,
    to: startTick,
  });

  $timeLeft.on(tick, (timeLeft) => timeLeft - 1);

  guard({
    clock: $timeLeft,
    filter: (time) => time === 0,
    target: stopTick,
  });

  return {
    isRunning,
    timeLeft: $timeLeft,
    requestFailedByRateTime,
    resetTimeLeft,
  };
};
