import React, { useRef } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';

import { Button, IconSprite } from 'ui';
import { useKeyPress, useLockBodyScroll, useOnClickOutside } from 'lib/hooks';
import { popupModel } from 'features/popup';

import './style.css';

export const Popup: React.FC = () => {
  const popupText = useStore(popupModel.$text);
  const isOpen = useStore(popupModel.$isOpen);
  const ref = useRef<HTMLDivElement>(null);

  useLockBodyScroll(isOpen);
  useOnClickOutside({ ref, handler: popupModel.close, disabled: !isOpen });
  useKeyPress({
    targetKey: 'Enter',
    handler: popupModel.close,
    disabled: !isOpen,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames('popup-mask', 'active')}>
      <div ref={ref} className="popup">
        <IconSprite
          onClick={() => popupModel.close()}
          name="close-btn"
          className="close-btn"
        />
        <p>{popupText}</p>
        <Button onClick={() => popupModel.close()}>Ок</Button>
      </div>
    </div>
  );
};
