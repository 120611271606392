import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { reflect } from '@effector/reflect';

import { trimEvent, trimPreventedEvent } from 'lib/events';
import { Button, Field, MainFormButton, MainFormRow } from 'ui';

import * as model from '../model';
import './style.css';

export const PaymentEmailForm = () => {
  const { t } = useTranslation();

  return (
    <form onSubmit={model.emailSubmitted.prepend(trimPreventedEvent)}>
      <MainFormRow
        className="deposit__email"
        label={t('lk:withdraw.paymentEmail.label')}
      >
        <div className="deposit__email-wrap">
          <PaymentEmailField
            name="email"
            placeholder={t('lk:withdraw.paymentEmail.placeholder')}
          />
          <EmailConfirmation />
        </div>
      </MainFormRow>
    </form>
  );
};

const EmailConfirmation = () => {
  const { t } = useTranslation();
  const secondsToRetry = useStore(model.$secondsToRetry);
  const linkIsSent = useStore(model.$linkIsSent);

  const refreshConfimation = () => {
    if (!secondsToRetry) {
      model.resetForm();
    }
  };

  return linkIsSent ? (
    <div className="try-again__message">
      <span>{t('lk:profile.email.goToEmail')}</span>
      <MainFormButton onClick={refreshConfimation}>
        {t('common:buttons.sendAgain')}{' '}
        {!!secondsToRetry && ` (${secondsToRetry})`}
      </MainFormButton>
    </div>
  ) : (
    <PaymentEmailButton type="submit">
      {t('common:buttons.confirm')}
    </PaymentEmailButton>
  );
};

const PaymentEmailField = reflect({
  view: Field,
  bind: {
    value: model.$email,
    disabled: model.$linkIsSent,
    onChange: model.emailChanged.prepend(trimEvent),
    error: model.$shownPhoneError,
  },
  hooks: {
    unmounted: model.formUnmounted,
  },
});

const PaymentEmailButton = reflect({
  view: Button,
  bind: {
    disabled: model.$isFormDisabled,
  },
});
