import React from 'react';
import { useStore, useStoreMap } from 'effector-react';

import { IconSprite } from 'ui';
import { favouriteGamesModel } from 'features/favorite';
import { sessionModel } from 'features/session';

export const LikeButton = ({ game }: { game: Game }) => {
  const isAuthenticated = useStore(sessionModel.$isAuthenticated);
  const isPending = useStore(favouriteGamesModel.$isPending);

  const isFavorite = useStoreMap({
    store: favouriteGamesModel.$favoriteGames,
    keys: [game],
    fn: (favoritesGames, [game]) =>
      !!favoritesGames.find(
        (item) =>
          item.provider_code === game.provider_code && item.code === game.code,
      ),
  });

  const handleClick = () => {
    if (isPending) return;
    if (isFavorite) {
      favouriteGamesModel.gameRemoved(game);
    } else {
      favouriteGamesModel.gameAdded(game);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  if (isFavorite) {
    return (
      <IconSprite
        onClick={handleClick}
        className="like-button"
        name="like-button--filled"
      />
    );
  }
  return (
    <IconSprite
      onClick={handleClick}
      className="like-button"
      name="like-button"
    />
  );
};
