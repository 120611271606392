import { AxiosRequestParams, httpClient } from 'lib/request';

/**
 * Получить расширенные данные игрока (+анкета)
 */
function getProfile(): Promise<User> {
  return httpClient.get(`players/current/`);
}

/**
 * Получить сводную информацию об игроке
 */
function getUserSummary(): Promise<UserSummary> {
  return httpClient.get(`players/current/header`);
}

/**
 * Изменить анкетные данные игрока и получить обновленную версию
 * @param data - обновленные данные
 */
function updateProfile(data: UserUpdateProfile): Promise<User> {
  return httpClient.put(`players/current/`, data);
}

/**
 * Получить данные о счете игрока
 */
function getBalance(): Promise<Balance> {
  return httpClient.get('players/current/accounts');
}

// TODO: delete
const phoneVerificationRequest = (data: PhoneVerificationRequest) => {
  return httpClient.post('players/current/phone/codes', data);
};
// TODO: delete
const emailVerificationRequest = (data: EmailVerificationRequest) => {
  return httpClient.put('players/current/email', data);
};
// TODO: delete
const phoneVerification = (data: PhoneVerificationForm) => {
  return httpClient.post('players/current/phone/confirmations', data);
};
const uploadFile = (data: FileUploadForm, config?: AxiosRequestParams) => {
  return httpClient.post('players/current/files', data, config);
};
const getFiles = (): Promise<getFilesResponse> => {
  return httpClient.get('players/current/files');
};
const getFile = (id: number): Promise<FileUploadForm> => {
  return httpClient.get(`players/current/files/${id}`);
};
const deleteFile = (id: number): Promise<FileUploadForm> => {
  return httpClient.delete(`players/current/files/${id}`);
};

/**
 * Сохранить новый email и отправить ссылку для его подтверждения
 * @param data
 */
function changeEmail(data: {
  mirror_url: string;
  email: string;
}): Promise<boolean> {
  return httpClient.put('players/current/email', data);
}

/**
 * Отправить ссылку для подтверждения текущего email
 * @param data
 */
function requestEmailConfirmation(data: {
  mirror_url: string;
}): Promise<boolean> {
  return httpClient.post('players/current/email/confirm', data);
}

/**
 * ППодтвердить email, связанный с кодом
 * @param data
 */
function confirmEmail(data: string): Promise<Session> {
  return httpClient.post('players/email/confirmations', data, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
}

/**
 * Отправить код подтверждения на телефонный номер (при изменении/добавлении телефона)
 * @param data
 */
function changePhone(data: {
  phone: string;
  captcha: Captcha;
}): Promise<boolean> {
  return httpClient.post('players/current/phone/codes', data);
}

/**
 * Подтвердить телефонный номер
 * @param data
 */
function confirmPhone(data: { phone: string; code: string }): Promise<boolean> {
  return httpClient.post('players/current/phone/confirmations', data);
}

export const userApi = {
  getProfile,
  updateProfile,
  getUserSummary,
  getBalance,
  phoneVerificationRequest,
  phoneVerification,
  emailVerificationRequest,
  uploadFile,
  getFiles,
  getFile,
  deleteFile,

  changeEmail,
  requestEmailConfirmation,
  confirmEmail,
  changePhone,
  confirmPhone,
};
