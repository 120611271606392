import React from 'react';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { searchModel, SearchResultDesktop } from 'features/search';
import { FavouriteGamesSection } from 'features/favorite';
import { DEFAULT_RENDER_SIZE, Halls } from 'lib/constants';
import { deviceModel } from 'features/device';
import { ResultText, ResultWrap } from 'ui';
import {
  $allGames,
  $allGamesStatus,
  $gamesByHall,
  $gamesByHallStatus,
  GamesSection,
  $mobGamesByHall,
  $allHallsNames,
  $hallNamesStatus,
} from 'features/halls';

import * as model from './model';

export const LobbyPage = () => {
  const { category } = useParams<{ category: string }>();
  const isDesktop = useStore(deviceModel.$isDesktop);

  React.useEffect(() => {
    model.pageLoaded(category);
    return () => model.pageUnloaded();
  }, [category, isDesktop]);

  const isQueryEmpty = useStore(searchModel.$isQueryEmpty);

  const showHall = isDesktop ? isQueryEmpty : true;

  return (
    <>
      {!isQueryEmpty && <SearchResultDesktop />}
      {showHall && <Hall category={category} />}
    </>
  );
};

const Hall = ({ category }: { category: string }) => {
  const { t } = useTranslation();

  const allGames = useStore($allGames);
  const allGamesStatus = useStore($allGamesStatus);

  const isDesktop = useStore(deviceModel.$isDesktop);

  const allHalls = useStore($gamesByHall);
  const mobHalls = useStore($mobGamesByHall);

  const gamesByHall =
    (isDesktop ? allHalls[category] : mobHalls[category]) || [];
  const gamesByHallStatus = useStore($gamesByHallStatus);

  const gamesByHallIsLoading = gamesByHall
    ? gamesByHallStatus === 'pending'
    : true;

  const allHallsNames = useStore($allHallsNames);
  const hallNamesStatus = useStore($hallNamesStatus);

  if (category === Halls.Favorite) {
    return <FavouriteGamesSection />;
  }

  if (category === Halls.All) {
    return (
      <GamesSection
        gameList={allGames}
        isPending={allGamesStatus === 'pending'}
        skeletonSize={DEFAULT_RENDER_SIZE}
      />
    );
  }

  if (hallNamesStatus === 'done' && hallIsNotExist(allHallsNames, category)) {
    return (
      <ResultWrap>
        <ResultText bg>{t('common:listEmpty')}</ResultText>
      </ResultWrap>
    );
  }

  return (
    <GamesSection
      gameList={gamesByHall}
      isPending={gamesByHallIsLoading}
      skeletonSize={DEFAULT_RENDER_SIZE}
    />
  );
};

const hallIsNotExist = (hallNames: HallName[], category) =>
  hallNames.findIndex((hall) => hall.name === category) === -1;
