import React, { useRef } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import { IconSprite } from 'ui';
import { useLockBodyScroll, useOnClickOutside } from 'lib/hooks';

export const LotteryRulesModal = ({
  onCloseFn = () => {},
  html,
}: {
  onCloseFn: () => void;
  html: string;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside({ ref, handler: onCloseFn, ignorList: ['.close-btn'] });
  useLockBodyScroll();

  return (
    <div className="popup-mask active game-popup">
      <div ref={ref} className="game-rules">
        <h3 className="game-rules__title">{t('game:rulesTitle')}</h3>
        <div
          className="game-rules__descr resources"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
        />
      </div>
      <IconSprite
        onClick={onCloseFn}
        className="close-btn"
        name="frame-close"
      />
    </div>
  );
};
