import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './style.css';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'secondary' | 'tertiary' | 'deposit';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  disabled,
  theme = 'primary',
  type = 'button',
}) => {
  const classProps: string = classNames(
    'btn',
    className,
    disabled && 'disabled',
    theme,
  );
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classProps}
    >
      {children}
    </button>
  );
};

interface LinkButtonProps {
  children: React.ReactNode;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'deposit';
  className?: string;
  to: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  theme = 'primary',
  children,
  className,
  to,
}) => {
  const classProps: string = classNames('btn', className, theme);
  return (
    <Link className={classProps} to={to}>
      {children}
    </Link>
  );
};
