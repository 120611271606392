import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { SecondaryConfirmModal } from 'ui';
import { BonusDescription } from './bonus-description';
import { useFormaters } from 'features/i18';
import { userModel } from 'features/user';

import * as model from '../model';

interface Props {
  bonus: PlayerBonus;
  onSubmit: () => void;
  onReject: () => void;
}

export const CancelBonusModal: React.FC<Props> = ({
  bonus,
  onSubmit,
  onReject,
}) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const { bonus_amount } = useStore(userModel.$balance);
  const { reward_giftspins } = bonus;

  const modalText = React.useMemo(() => {
    if (bonus_amount && reward_giftspins?.spins_left) {
      return t('bonus:popup.cancelBonusWithALossBonusAndGiftSpins', {
        bonus_amount: currencyNumber(bonus_amount),
        spins_left: reward_giftspins.spins_left,
      });
    }

    if (bonus_amount) {
      return t('bonus:popup.cancelBonusWithALossBonus', {
        bonus_amount: currencyNumber(bonus_amount),
      });
    }

    if (reward_giftspins?.spins_left) {
      return t('bonus:popup.cancelBonusWithALossGiftspins', {
        spins_left: reward_giftspins.spins_left,
      });
    }

    return t('bonus:popup.cancelBonus');
  }, [bonus_amount, reward_giftspins]);

  const handleSubmit = () => {
    onSubmit();
    model.bonusCanceled(bonus);
  };

  const handleReject = () => {
    onReject();
  };

  return (
    <SecondaryConfirmModal
      onRejectFn={handleReject}
      onSubmitFn={handleSubmit}
      title={t('bonus:popup.bonusCancel')}
      submitText={t('common:buttons.cancel')}
    >
      {modalText}
    </SecondaryConfirmModal>
  );
};

export const CancelWofSpinsModal = ({ onSubmit, onReject }) => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    model.cancelWofSpins();
    onSubmit();
  };

  const handleReject = () => {
    onReject();
  };

  return (
    <SecondaryConfirmModal
      onRejectFn={handleReject}
      onSubmitFn={handleSubmit}
      title={t('bonus:popup.wofSpinCancelTitle')}
      submitText={t('common:buttons.cancel')}
    >
      {t('bonus:popup.wofSpinCancelText')}
    </SecondaryConfirmModal>
  );
};

export const SubmitActivateBonusModal = ({
  bonus,
  onReject,
  onSubmit,
}: {
  bonus: PlayerBonus;
  onReject: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const { cost_amount, wager, max_withdraw_sum, max_bet } = bonus;

  const handleSubmit = async () => {
    model.bonusActivated(bonus);
    model.setShowModalAfterCancel(true);
    onSubmit();
  };

  return (
    <SecondaryConfirmModal
      onRejectFn={onReject}
      onSubmitFn={handleSubmit}
      title={t('bonus:popup.activateBonus')}
      submitText={t('bonus:popup.submitBtn')}
      rejectText={t('bonus:popup.rejectBtn')}
      className="submit-bonus-modal"
    >
      {bonus.max_bet ? (
        <div>
          {t('bonus:popup.activateBonusText_maxBet', {
            count: currencyNumber(bonus.max_bet),
          })}
        </div>
      ) : (
        <div>{t('bonus:popup.activateBonusText')}</div>
      )}
      <h4 style={{ margin: '26px 0' }}>{t('bonus:popup.bonusConditions')}</h4>
      <BonusDescription.Wrap>
        {!!cost_amount && (
          <BonusDescription.Item
            description={t('bonus:info.bonusSize')}
            text={currencyNumber(cost_amount)}
          />
        )}
        {!!wager && (
          <BonusDescription.Item
            description={t('bonus:info.wager')}
            text={`x${wager}`}
          />
        )}
        {!!max_withdraw_sum && (
          <BonusDescription.Item
            description={t('bonus:info.maxWithdraw')}
            text={currencyNumber(max_withdraw_sum)}
          />
        )}
        {!!max_bet && (
          <BonusDescription.Item
            text={max_bet}
            description={t('bonus:info.maxBet')}
          />
        )}
      </BonusDescription.Wrap>
    </SecondaryConfirmModal>
  );
};
