import { createEffect, createEvent, restore } from 'effector';

import { bonusApi } from 'api/bonus';

export const deleteWelcomeBonusFromListByTaskId = createEvent<number>();

export const loadWelcomeBonusProgramsFx = createEffect<
  void,
  BonusProgram[],
  ApiError
>(bonusApi.getWelcomeBonusPrograms);

export const $welcomeBonusPrograms = restore(
  loadWelcomeBonusProgramsFx.doneData,
  [],
).on(deleteWelcomeBonusFromListByTaskId, (programs, taskId) =>
  programs.filter((program) => program.task.task_id !== taskId),
);
