import React from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { EnumBonusModalsContent } from 'lib/constants';
import { Slider } from 'ui';

import { BonusCardSkeleton } from './bonus-program-card';
import { GetBonusCard } from './get-bonus-card';
import { BonusActionResultInfoToast } from '../molecules';

import * as modalModel from '../model/modal-model';

interface Props {
  skeletonSize?: number;
  count?: number;
  withBullets?: boolean;
  withButtons?: boolean;
  isPending: boolean;
  bonusList: Bonus[];
  slidesToScroll?: number;
}

export const BonusSlider = ({
  withBullets,
  skeletonSize = 5,
  slidesToScroll = 1,
  isPending,
  bonusList,
  withButtons,
}: Props) => {
  const [isLastSlide, setIsLastSlide] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const { content } = useStore(modalModel.$modal);

  React.useEffect(() => {
    if (content === EnumBonusModalsContent.ActivateDone) {
      setCurrentSlide(0);
    } else if (
      content === EnumBonusModalsContent.CancelDone ||
      content === EnumBonusModalsContent.WofSpinsCancelDone
    ) {
      setCurrentSlide((currentSlide) => currentSlide - 1);
    }
  }, [content]);

  const slides = React.useMemo(
    () =>
      bonusList.map((bonus, idx) => <GetBonusCard bonus={bonus} idx={idx} />),
    [bonusList],
  );

  const skeletonSlides = React.useMemo(
    () =>
      Array.from({ length: skeletonSize }, (_, idx) => {
        return <BonusCardSkeleton idx={idx} key={idx} />;
      }),
    [],
  );

  return (
    <>
      <BonusActionResultInfoToast />
      <Slider
        fullWidth
        smallBtns
        loop={false}
        slidesCount={2}
        slidesToScroll={slidesToScroll}
        off={isPending}
        loading={isPending}
        className={classNames('bonus-slider', isLastSlide && 'last')}
        onSlide={setCurrentSlide}
        currentNumberSlide={currentSlide}
        withButtons={withButtons && !isPending}
        swiper={withBullets && !isPending}
        slides={isPending ? skeletonSlides : slides}
        onCanScrollNext={() => setIsLastSlide(false)}
        onCantScrollNext={() => setIsLastSlide(true)}
      />
    </>
  );
};
