import { combine, createEvent, createStore, sample } from 'effector';

import { DEFAULT_RENDER_SIZE } from 'lib/constants';

export const resetTotalSize = createEvent<string>();
export const listInitialized = createEvent<{
  list: Game[];
  name: string;
  renderSize?: number;
}>();
export const setRenderSize = createEvent<{ name: string; count: number }>();
export const addRenderSize = createEvent<string>();

export const $initialsList = createStore<{ [key: string]: Game[] }>({}).on(
  listInitialized,
  (state, { list, name }) => ({
    ...state,
    [name]: list,
  }),
);

export const $renderSizes = createStore<{ [key: string]: number }>({})
  .on(listInitialized, (state, { name, renderSize }) => ({
    ...state,
    [name]: renderSize || DEFAULT_RENDER_SIZE,
  }))
  .on(setRenderSize, (state, { name, count }) => ({ ...state, [name]: count }));

export const $totalSizes = createStore<{ [key: string]: number }>({}).on(
  setRenderSize,
  (state, { name, count }) => {
    const currentListTotalSize = state[name];
    const difference = currentListTotalSize % count;
    const result =
      currentListTotalSize >= difference
        ? currentListTotalSize - difference
        : count;
    return { ...state, [name]: result };
  },
);

sample({
  source: combine([$renderSizes, $totalSizes]),
  clock: addRenderSize,
  target: $totalSizes,
  fn: ([renderSizes, state], key) => ({
    ...state,
    [key]: state[key] + renderSizes[key],
  }),
});

sample({
  source: combine([$renderSizes, $totalSizes]),
  clock: listInitialized,
  target: $totalSizes,
  fn: ([renderSizes, state], { name }) =>
    state[name] ? state : { ...state, [name]: renderSizes[name] },
});

sample({
  source: combine([$renderSizes, $totalSizes]),
  clock: resetTotalSize,
  target: $totalSizes,
  fn: ([renderSizes, state], name) => {
    const newState = Object.assign({}, state);
    newState[name] = renderSizes[name];
    return newState;
  },
});

export const $isAllGameLoaded = combine(
  $initialsList,
  $totalSizes,
  (lists, sizes) => {
    const result = {};
    for (let key in lists) {
      const list = lists[key];
      const size = sizes[key];
      result[key] = list?.length <= size;
    }
    return result;
  },
);

export const $renderedGameList = combine(
  $initialsList,
  $totalSizes,
  (lists, sizes) => {
    const renderedList = Object.assign({}, lists);
    for (let key in renderedList) {
      let totalSize = sizes[key];
      renderedList[key] = renderedList[key]?.slice(0, totalSize);
    }
    return renderedList;
  },
);
