import React from 'react';
import { useTranslation } from 'react-i18next';

import { WinnerTable, WinnerTableRow } from 'ui';
import { DateTime } from 'lib/datetime';
import { useFormaters } from 'features/i18';
import { DEFAULT_RENDER_WINNERS_SIZE } from 'lib/constants';

import './style.css';

export const JackpotWinnersTable = ({
  winners,
  id,
}: {
  winners: JackpotWinner[];
  id?: string;
}) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  return (
    <WinnerTable
      id={id}
      className="jackpot-table"
      title={t('jackpot:table.title')}
    >
      <WinnerTableRow
        head
        values={[
          { className: 'jackpot-table__number', value: '№' },
          {
            className: 'jackpot-table__player',
            value: t('jackpot:table.player'),
          },
          {
            className: 'jackpot-table__date',
            value: t('jackpot:table.date'),
          },
          {
            className: 'jackpot-table__prize',
            value: t('jackpot:table.prize'),
          },
        ]}
      />
      {winners.length !== 0 &&
        winners.slice(0, DEFAULT_RENDER_WINNERS_SIZE).map((winner, idx) => (
          <WinnerTableRow
            key={idx}
            values={[
              { className: 'jackpot-table__number', value: idx + 1 },
              { className: 'jackpot-table__player', value: winner.nickname },
              {
                className: 'jackpot-table__date',
                value: DateTime.getDayMonthString(winner.date),
              },
              {
                className: 'jackpot-table__prize',
                value: currencyNumber(winner.sum),
              },
            ]}
          />
        ))}
    </WinnerTable>
  );
};
