import {
  combine,
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
  sample,
} from 'effector';
import { cashBoxApi } from 'api/cashbox';
import { paymentsInfoModel } from 'features/payments-info';

const HYSTORY_LIMIT = 20;

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

export const historyTypeChanged = createEvent<HistoryType>();
export const cancelPayment = createEvent<number>();

export const loadHistoryListFx = createEffect<
  { limit: number; offset: number },
  HistoryItem[],
  ApiError
>(({ limit, offset }) => cashBoxApi.getHistory({ limit, offset }));

export const cancelPaymentFx = createEffect<number, any, ApiError>((id) =>
  cashBoxApi.cancelWithdrawal(id),
);

export const $historyList = restore(loadHistoryListFx.doneData, []);
export const $historyListByType = createStore<HistoryItem[]>([]);
export const $currentListType = createStore<HistoryType>('ALL').on(
  historyTypeChanged,
  (_, type) => type,
);

export const $canceledId = createStore<number | null>(null);

export const $limit = createStore<number>(HYSTORY_LIMIT);
export const $offset = createStore<number>(0);

export const $error = restore(loadHistoryListFx.failData, null);

sample({
  source: combine({
    limit: $limit,
    offset: $offset,
  }),
  clock: pageLoaded,
  target: loadHistoryListFx,
});

sample({
  source: combine({
    list: $historyList,
    type: $currentListType,
  }),
  target: $historyListByType,
  fn: ({ list, type }) => {
    if (type === 'ALL') {
      return list;
    }

    return list.filter((item) => item.type === type);
  },
});

guard({
  source: cancelPayment,
  filter: paymentsInfoModel.$isCancelAvailable,
  target: cancelPaymentFx,
});

guard({
  source: cancelPayment,
  filter: paymentsInfoModel.$isCancelAvailable,
  target: $canceledId,
});

sample({
  source: combine({
    list: $historyList,
    id: $canceledId,
  }),
  clock: cancelPaymentFx.doneData,
  target: $historyList,
  fn: ({ list, id }) => list.filter((item) => item.id !== id),
});

sample({
  source: cancelPaymentFx.doneData,
  target: paymentsInfoModel.withdrawalCanceled,
});
