import React from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconSprite } from 'ui';
import { useOnClickOutside } from 'lib/hooks';

import * as model from '../model';
import './style.css';

export const SelectLanguage = () => {
  const language = useStore(model.$language);
  const languages = useStore(model.$avaiableLocales);

  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation();

  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside({
    ref,
    handler: () => setIsOpen(false),
    disabled: !isOpen,
  });

  const getIconName = (locale) => `${locale.toLowerCase()}-icon`;

  return (
    <div
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
      className={classNames('select-language', isOpen && 'active')}
    >
      {language && (
        <div className="current-option">
          <IconSprite name={getIconName(language)} />
          {t(`common:menu.language.${language}`)}
        </div>
      )}
      <div
        onClick={() => setIsOpen(false)}
        className={classNames('select-language__options', isOpen && 'open')}
      >
        {languages.map((lang, idx) => (
          <Option
            key={idx}
            currentValue={language}
            value={lang}
            icon={getIconName(lang)}
          >
            {t(`common:menu.language.${lang.toLowerCase()}`)}
          </Option>
        ))}
      </div>
    </div>
  );
};

const Option = ({ icon, children, currentValue, value }: OptionProps) => {
  const active = currentValue === value;
  return (
    <div
      onClick={() => model.changeLanguage(value)}
      className={classNames('options-item', active && 'active')}
    >
      <IconSprite name={icon} />
      {children}
    </div>
  );
};

interface OptionProps {
  icon: string;
  children: React.ReactNode;
  currentValue: string;
  value: string;
}
