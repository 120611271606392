import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DateTime } from 'lib/datetime';
import { Button, ButtonLink, Counter, FlipBox } from 'ui';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  BonusTitle,
  TotalCashbacks,
} from '../molecules';

interface Props {
  titleTheme?: 'violet' | 'yellow' | 'lilac';
  className?: string;
  idx?: number;
  bonus: BonusProgram;
}

export const WeeklyCashbackProgramCard: React.FC<Props> = (props) => {
  const [showBackSide, setShowBackSide] = React.useState(false);

  return (
    <FlipBox className="flip-card" isFlipped={showBackSide}>
      <FrontSide
        onFlipButtonClick={() => setShowBackSide((side) => !side)}
        {...props}
      />
      <BackSide
        onFlipButtonClick={() => setShowBackSide((side) => !side)}
        {...props}
      />
    </FlipBox>
  );
};

const FrontSide: React.FC<Props & { onFlipButtonClick: () => void }> = ({
  bonus,
  idx = 0,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <BonusCardWrap idx={idx}>
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <WeeklyCashbackCounter bonusProgram={bonus} />
        <BonusDescription.MainInfo withCashbackInfo bonus={bonus} />
        <TotalCashbacks />
      </div>
      <div className="bonus-card__nav">
        <Button disabled>{t('common:buttons.activate')}</Button>
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:detailed')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const BackSide: React.FC<Props & { onFlipButtonClick: () => void }> = ({
  className,
  bonus,
  idx = 0,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  const day = bonus.days_of_week[0];

  return (
    <BonusCardWrap className={classNames('back-side', className)} idx={idx + 2}>
      <div className="bonus-card__info">
        <BonusSubtitle>
          {t('bonus:subtitle.WEEKLY_CASHBACK_FIERY')}
        </BonusSubtitle>
        <p className="cashback-descr">
          {t('bonus:WEEKLY_CASHBACK_FIERY_TEXT')}
          {day && t(`bonus:WEEKLY_CASHBACK_FIERY_TEXT_DAY_${day}`)}
        </p>
      </div>
      <div className="bonus-card__nav">
        <Button disabled>{t('common:buttons.activate')}</Button>
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:buttons.back')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const WeeklyCashbackCounter = ({
  bonusProgram,
}: {
  bonusProgram: BonusProgram;
}) => {
  const { t } = useTranslation();

  if (bonusProgram.event_type !== 'WEEKLY_CASHBACK') {
    return null;
  }

  const date = DateTime.getUTCDateByDay(bonusProgram.days_of_week[0]);

  return (
    <>
      <div className="counter__title">
        {t('bonus:cashback_will_be_available_through')}
      </div>
      <Counter date={date} />
    </>
  );
};
