import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useField } from 'effector-forms';
import { reflect } from '@effector/reflect';

import {
  Field,
  Button,
  Select,
  SectionTitle,
  MainForm,
  MainFormRow,
  MainFormButton,
} from 'ui';
import { currenciesModel } from 'features/currencies';
import { mediaHooks } from 'lib/media';
import { Captcha } from 'features/captcha/';
import { paths } from 'pages/paths';
import { trimEvent, trimPreventedEvent } from 'lib/events';

import './style.css';
import * as model from '../model/form-model';

export const SocialRegisterForm = () => {
  const { t } = useTranslation();

  const isRulesAgree = useStore(model.$isRulesAgree);
  const isLargeMobile = mediaHooks.useIsLargeMobile();

  const needCodeToRegistration = useStore(model.$needCodeForRegistration);

  return (
    <div className="modal__content">
      <div className="modal__wrap">
        {!isLargeMobile ? (
          <SectionTitle>{t('auth:socialRegister.title')}</SectionTitle>
        ) : (
          <div className="modal__img-header">
            <img src="/img/bonus12.png" alt="" />
          </div>
        )}
        <MainForm
          onSubmit={model.$registerForm.submit.prepend(trimPreventedEvent)}
          noBorder
        >
          {needCodeToRegistration && (
            <>
              <EmailRow />
              <CodeRow />
            </>
          )}
          <div className="main-form__check-rules">
            <input
              className="main-form__input-check"
              type="checkbox"
              id="rules"
              name="rules"
              checked={isRulesAgree}
              onChange={() => model.setRulesAgree(!isRulesAgree)}
            />
            <label className="main-form__label-rules" htmlFor="rules">
              {t('auth:register.iAgreeWith')}
              <Link
                onClick={() => model.formClosed()}
                to={paths.terms()}
                className="main-form__rules-link"
              >
                {t('auth:register.casinoRules')}
              </Link>
            </label>
          </div>
          <CurrencySelect />
          <Captcha captcha={model.addRegisterCaptcha} />
          <SubmitRegisterButton className="sign-up-btn" type="submit">
            {t('auth:socialRegister.submitButton')}
          </SubmitRegisterButton>
        </MainForm>
      </div>
    </div>
  );
};

const EmailRow = () => {
  const { t } = useTranslation();
  const emailInputRef = useRef<HTMLInputElement>(null);

  const confirmCodeIsSent = useStore(model.$confirmCodeIsSent);
  const codeIsSending = useStore(model.sendConfirmCodeToEmailFx.pending);

  const [canSendCode, setCanSendCode] = React.useState(true);

  React.useEffect(() => {
    if (confirmCodeIsSent) {
      setCanSendCode(false);
    } else {
      setCanSendCode(true);
    }
  }, [confirmCodeIsSent]);

  React.useEffect(() => {
    if (canSendCode) {
      emailInputRef?.current?.focus();
    }
  }, [canSendCode]);

  const { value, onChange, errorText, onBlur } = useField(
    model.$sendCodeToEmailForm.fields.email,
  );

  return (
    <MainFormRow label="Подтвердите ваш email">
      <div className="confirm-email">
        <Field
          inputRef={emailInputRef}
          value={value}
          disabled={!canSendCode}
          onChange={onChange.prepend(trimEvent)}
          onBlur={() => onBlur()}
          name="email"
          type="email"
          autoComplete="on"
          placeholder={t('auth:inputs.email.placeholder')}
          error={errorText()}
        />
        {canSendCode ? (
          <Button
            theme="secondary"
            onClick={() => model.$sendCodeToEmailForm.submit()}
            className="send-code__button"
            disabled={!!errorText() || codeIsSending}
          >
            Отправить код
          </Button>
        ) : (
          <MainFormButton onClick={() => setCanSendCode(true)}>
            Отправить ещё раз
          </MainFormButton>
        )}
      </div>
    </MainFormRow>
  );
};

const CodeRow = () => {
  const { value, onChange, errorText, onBlur } = useField(
    model.$registerForm.fields.code,
  );

  const confirmCodeIsSent = useStore(model.$confirmCodeIsSent);

  return confirmCodeIsSent ? (
    <MainFormRow label="Код подтверждения">
      <Field
        value={value}
        onChange={onChange.prepend(trimEvent)}
        onBlur={() => onBlur()}
        name="email"
        placeholder="Введите код"
        error={errorText()}
      />
    </MainFormRow>
  ) : null;
};

const CurrencySelect = () => {
  const { t } = useTranslation();

  const { value, onChange } = useField(model.$registerForm.fields.currency);

  const currencies = useStore(currenciesModel.$availableCurrencies);

  const currenciesOptions = React.useMemo(
    () => currencies.map((el) => [el, el]),
    [currencies],
  );

  if (currencies.length <= 1) {
    return null;
  }

  return (
    <MainFormRow
      label={t('auth:register.currency')}
      wrapClassName="main-form__select-wrap"
    >
      <Select
        className="currency-select"
        onChange={onChange}
        value={value}
        options={currenciesOptions}
      />
    </MainFormRow>
  );
};

const SubmitRegisterButton = reflect({
  view: Button,
  bind: {
    disabled: model.$socialRegisterButtonIsDisabled,
  },
});
