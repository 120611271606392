import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router';
import { useStore } from 'effector-react';

import {
  Button,
  ButtonLink,
  SecondaryConfirmModal,
  SecondaryInfoModal,
} from 'ui';
import { GAME_SCRIPT_URL, GameModes } from 'lib/constants';
import { useGameMessenger, useModal } from 'lib/hooks';
import { paths } from 'pages/paths';
import { authFormModel } from 'features/auth';
import { depositModel } from 'features/cashbox';
import { GameCard, hallModel } from 'features/halls';
import {
  GameContainer,
  GameHeader,
  GameRulesModal,
  GameTemplate,
} from 'features/game';
import { sessionModel } from 'features/session';
import { UserBalance, UserInfo } from 'features/user';
import { useFormaters } from 'features/i18';
import { activeBonusModel } from 'features/bonus/bonus-active';
import { searchModel } from 'features/search';
import { navigationModel } from 'features/navigation';

import * as model from './model';
import './style.css';

const POPULAR_GAME_LIST_SIZE = 5;

export const GamePage = () => {
  const { code, mode, provider } = useParams<any>();

  const isAuth = useStore(sessionModel.$isAuthenticated);
  const name = useStore(model.$gameName);

  useGameMessenger({
    scriptSrc: GAME_SCRIPT_URL,
    onBalanceChanged: model.balanceReceived,
  });

  React.useEffect(() => {
    model.pageMounted();
    return () => model.pageUnmounted();
  }, []);

  React.useEffect(() => {
    model.gameToggled({ code, mode, provider });
  }, [code, mode, provider]);

  if (!isAuth && mode === GameModes.Real) {
    return <Redirect to={paths.game(GameModes.Demo, provider, code)} />;
  }

  return (
    <GameTemplate
      header={
        <GameHeader
          onCloseClick={() =>
            model.setSubmitModalFn(() =>
              navigationModel.historyReplace(paths.home()),
            )
          }
          mode={mode}
          name={name}
        />
      }
    >
      <RedirectModal />
      <DemoModal />
      <BonusModal />
      <GameRules />
      <LeftSidebar />
      <GameContainer />
      <RigthSidebar />
    </GameTemplate>
  );
};

const BonusModal = () => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const { code, provider, mode } = useParams<any>();

  const isDemoMode = mode === GameModes.Demo;
  const active = useStore(activeBonusModel.$activeBonus);

  const maxBetModal = useModal();
  const noWageringModal = useModal();

  React.useEffect(() => {
    if (active?.max_bet) {
      if (
        checkBonusWageringInTheGame(active?.games, {
          code,
          provider_code: provider,
        })
      ) {
        if (!isDemoMode) {
          maxBetModal.show();
        }
      } else {
        noWageringModal.show();
      }
    }

    return () => {
      maxBetModal.hide();
      noWageringModal.hide();
    };
  }, [active, code, provider]);

  if (!active) {
    return null;
  }

  if (noWageringModal.isVisible) {
    return (
      <SecondaryInfoModal
        text={t('game:noWagering')}
        onModalClose={noWageringModal.hide}
        title={t('common:popup.message')}
      />
    );
  }

  return maxBetModal.isVisible ? (
    <SecondaryInfoModal
      onModalClose={maxBetModal.hide}
      text={currencyNumber(active.max_bet)}
      title={t('game:maxbet')}
    />
  ) : null;
};

const DemoModal = () => {
  const { t } = useTranslation();
  const { mode } = useParams<any>();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (mode === GameModes.Demo) {
      setIsOpen(true);
    }
  }, [mode]);

  return isOpen ? (
    <SecondaryInfoModal
      onModalClose={() => setIsOpen(false)}
      text={t('game:demoModal.text')}
      title={t('game:demoModal.title')}
    />
  ) : null;
};

const GameRules = () => {
  const isOpen = useStore(model.$rulesIsOpen);
  const rulesHtml = useStore(model.$gameRules);
  return isOpen ? (
    <GameRulesModal
      html={rulesHtml}
      onCloseFn={() => model.toggleRulesModal()}
    />
  ) : null;
};

const LeftSidebar = React.memo(() => {
  const { t } = useTranslation();

  const isAuth = !!useStore(sessionModel.$session);

  return (
    <section className="frame__sidebar">
      <div className="frame__sidebar-block">
        <span
          onClick={() =>
            model.setSubmitModalFn(() =>
              navigationModel.historyReplace(paths.home()),
            )
          }
          className="frame__logo"
        >
          <img src="/img/logo.svg" alt="" />
        </span>
        {isAuth ? (
          <>
            <div className="frame__user-wrap">
              <UserInfo bgColor />
              <UserBalance />
            </div>
            <Button onClick={() => depositModel.toggleDepositModal(true)}>
              {t('game:topup')}
            </Button>
          </>
        ) : (
          <>
            <Button
              theme="primary"
              onClick={() => authFormModel.loginFormOpened()}
            >
              {t('common:buttons.signin')}
            </Button>
            <div className="frame__img">
              <img src="/img/frame1.png" alt="" />
            </div>
            <Button
              onClick={() => authFormModel.loginFormOpened()}
              theme="primary"
            >
              {t('game:getBonus')}
            </Button>
          </>
        )}
      </div>
    </section>
  );
});

const RedirectModal = () => {
  const isOpen = useStore(model.$redirectModalIsOpen);
  const { t } = useTranslation();

  return isOpen ? (
    <SecondaryConfirmModal
      onRejectFn={() => model.closeModal()}
      title={t('game:redirectModal.title')}
      onSubmitFn={() => model.modalSubmitted()}
    >
      {t('game:redirectModal.text')}
    </SecondaryConfirmModal>
  ) : null;
};

const RigthSidebar = () => {
  const { t } = useTranslation();

  return (
    <section className="frame__sidebar">
      <div className="frame__sidebar-block frame__sidebar-block_small">
        <ButtonLink
          onClick={() =>
            model.setSubmitModalFn(() =>
              navigationModel.historyReplace(paths.category('all')),
            )
          }
        >
          {t('game:gameRoom')}
        </ButtonLink>
      </div>
      <div className="frame__sidebar-block frame__sidebar-block_shadow">
        <h2 className="frame__title">{t('game:popularSlots')}</h2>
        <div className="frame__scroll-wrap">
          <div className="frame-scroll-wrap__inner">{<PopularGamesList />}</div>
        </div>
      </div>
    </section>
  );
};

const PopularGamesList = () => {
  const popularGames = useStore(model.$renderedPopularGames);
  const isStatActive = useStore(searchModel.$isStatActive);

  // TODO: Подумать, как перенести логику в модель и избежать циклических зависимостей
  const handleGameClick = (game) => {
    const { provider_code, code } = game;

    if (isStatActive) {
      searchModel.gameClicked({ provider: provider_code, code });
    } else {
      hallModel.gameClicked({ provider: provider_code, code });
    }
  };

  return (
    <>
      {popularGames.slice(0, POPULAR_GAME_LIST_SIZE).map((game) => (
        <GameCard
          handlePlayButtonClick={() =>
            model.setSubmitModalFn(() => handleGameClick(game))
          }
          key={game.code}
          game={game}
        />
      ))}
    </>
  );
};

const checkBonusWageringInTheGame = (
  bonusGames: GameInfoInBonus[] = [],
  comparedGame: { code: string; provider_code: string },
) =>
  bonusGames.length === 0 ||
  bonusGames.some(
    ({ code, provider_code, name }) =>
      comparedGame.code === code &&
      comparedGame.provider_code === provider_code,
  );
