import * as React from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { userModel } from 'features/user';
import { languageModel } from 'features/i18';
import { skinModel } from 'features/skin-id';

import './style.css';

const getDefaultImgSrc = (skinId) => `/img/${skinId}/avatar.svg`;

export const UserInfo = React.memo(
  ({ bgColor = false }: { bgColor?: boolean }) => {
    const { t } = useTranslation();
    const [error, setError] = React.useState(false);
    const skinId = skinModel.selectors.useSkinId();

    const statusNames = useStore(languageModel.resourses.userStatusNames);
    const user = useStore(userModel.$user);

    if (!user) {
      return null;
    }

    const { avatar, name, status, email } = user;

    return (
      <div className="user-info">
        <div className="user-info__wrap">
          <div
            className={classNames(
              'user-info__img',
              bgColor && 'user-info__img_bg',
            )}
          >
            <img
              onError={() => setError(true)}
              src={error ? getDefaultImgSrc(skinId) : avatar}
              alt="avatar"
            />
          </div>
          <div className="user-info__data">
            <span className="user-info__name">{name || email}</span>
            <span className="user-info__status">
              {t('common:profile.status')}: {statusNames[status] || status}
            </span>
            {/*<UserRating rating={4} />*/}
          </div>
        </div>
      </div>
    );
  },
);

// const UserRating = ({ rating }: { rating: number }) => (
//   <div className="user-info__rating">
//     {new Array(5).fill('').map((_, idx) => {
//       const name = idx + 1 > rating ? 'user-star' : 'user-star-fill';
//       return <IconSprite key={idx} name={name} spriteName="common" />;
//     })}
//   </div>
// );
