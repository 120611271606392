import React from 'react';
import './style.css';

export const RadioButton: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ name, value, id, placeholder, checked, onChange, className = '' }) => {
  return (
    <div className={`main-form__checkbox ${className}`}>
      <input
        className="main-form__input-check"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label className="main-form__label-check" htmlFor={id}>
        {placeholder}
      </label>
    </div>
  );
};
