import React from 'react';
import './style.css';
import classNames from 'classnames';

export const ResultWrap = ({ children }: { children: React.ReactNode }) => {
  return <div className="results-wrap">{children}</div>;
};

export const ResultText = ({
  bg,
  children,
  style,
}: {
  style?: {};
  bg?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      style={style}
      className={classNames('results-text', bg && 'results-text_bg')}
    >
      {children}
    </div>
  );
};

export const ResultInfo = ({ children }: { children: React.ReactNode }) => {
  return <span className="results-info">{children}</span>;
};
