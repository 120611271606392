import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { reflect } from '@effector/reflect';
import i18n from 'i18next';

import { Button, Field, MainForm, Toast } from 'ui';
import { bonusModel, BonusSlider } from 'features/bonus';
import { FundItem, lotteryModel } from 'features/lotteries';
import { trimEvent, trimPreventedEvent } from 'lib/events';
import { mediaHooks } from 'lib/media';

import * as model from './model';
import './style.css';

export const MyBonusPage = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({});
  }, []);

  const lottery = useStore(lotteryModel.$activeLottery);

  const { isOpen, content } = useStore(model.$modal);

  return (
    <>
      {isOpen && (
        <Toast isOpen onAnimationEnd={model.closeModal}>
          {t(`bonus:promocode-${content}`)}
        </Toast>
      )}
      <PromoForm />
      <div className="border-wrap">
        <div className="bonus-slider__wrap bonus-slider__wrap--lk">
          <Slider />
        </div>
      </div>
      {!!lottery && (
        <div className="border-wrap">
          <FundItem lottery={lottery} />
        </div>
      )}
    </>
  );
};

const Slider = () => {
  const isMobile = mediaHooks.useIsMobile();
  const isTablet = mediaHooks.useIsTablet();
  const bonusList = useStore(bonusModel.$allBonusesList) as Bonus[];
  const isPending = useStore(bonusModel.$bonusesIsLoading);

  return (
    <BonusSlider
      withButtons={!isMobile}
      slidesToScroll={isTablet ? 1 : 2}
      withBullets={!isTablet}
      isPending={isPending}
      bonusList={bonusList}
    />
  );
};

const PromoForm = () => {
  const { t } = useTranslation();
  return (
    <PromoFormView
      label={t('lk:bonus.promocode.input.label')}
      className="promo-form"
    >
      <div className="main-form__bonus-wrap">
        <PromoField
          name="promocode"
          className="promocode__input"
          placeholder={t('lk:bonus.promocode.input.placeholder')}
        />
        <PromocodeFormButton type="submit">
          {t('common:buttons.activate')}
        </PromocodeFormButton>
      </div>
    </PromoFormView>
  );
};

const PromoFormView = reflect({
  view: MainForm,
  bind: {
    onSubmit: model.promoformSubmitted.prepend(trimPreventedEvent),
  },
});

const PromoField = reflect({
  view: Field,
  bind: {
    value: model.$promoCode,
    onChange: model.promocodeChanged.prepend(trimEvent),
    error: model.$promocodeIsIncorrect.map((isIncorrect) =>
      isIncorrect ? i18n.t('bonus:promocode-fail') : '',
    ),
  },
  hooks: {
    unmounted: model.promoFormReset,
  },
});

const PromocodeFormButton = reflect({
  view: Button,
  bind: {
    disabled: model.usePromocodeFx.pending,
  },
});
