import React from 'react';
import classNames from 'classnames';

import { useOnClickOutside } from 'lib/hooks';

import './style.css';

interface SelectProps {
  onChange?: (value: any) => void;
  options: string[][];
  value: string;
  placeholder?: string;
  className?: string;
  withInput?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export const Select = ({
  onChange,
  className,
  options,
  value,
  placeholder,
  disabled,
  withInput,
  error,
}: SelectProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(value || null);
  const [filterValue, setFilterValue] = React.useState('');
  const ref = React.useRef(null);

  useOnClickOutside({
    ref,
    handler: () => setIsOpen(false),
    disabled: !isOpen,
  });

  React.useEffect(() => {
    setActiveItem(value);
  }, [value]);

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterValue(e.target.value);
  };

  const handleChange = (value: string) => {
    if (disabled) return;
    setActiveItem(value.toString());
    setIsOpen(false);
    onChange && onChange(value.toString());
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
    setFilterValue('');
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const optionsList = filterValue
    ? options.filter((el) => {
        return el[1].toLowerCase().includes(filterValue.toLowerCase());
      })
    : options;

  const activeItemToshow: string[] | undefined = options.find(
    (item) => item[0] === activeItem,
  );

  return (
    <div
      ref={ref}
      className={classNames(
        isOpen ? 'choices is-open is-focused' : 'choices',
        error && 'choices__error',
        className,
      )}
    >
      <div
        onClick={handleOpen}
        className={classNames('choices__inner', disabled && 'disabled')}
      >
        {(activeItemToshow && activeItemToshow[1]) || (
          <span className="choices-placeholder">{placeholder}</span>
        )}
      </div>
      <div
        className={classNames(
          isOpen
            ? 'choices__list choices__list--dropdown is-active'
            : 'choices__list choices__list--dropdown',
        )}
      >
        {withInput && (
          <input
            value={filterValue}
            onChange={onFilterChange}
            ref={inputRef}
            type="text"
            className="choices__input choices__input--cloned"
          />
        )}
        <div className="choices__list">
          {optionsList.map((el, index) => {
            return (
              <div
                key={index}
                className="choices__item"
                onClick={() => handleChange(el[0])}
              >
                {el[1]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
