import { createEvent, createStore, sample } from 'effector';

const showLoader = createEvent<void | string>();
const hideLoader = createEvent<void>();
const setText = createEvent<string>();

const $text = createStore('');
const $showLoader = createStore(false);

$text.on(setText, (_, text) => text);
$text.on(hideLoader, () => '');

$showLoader.on(showLoader, () => true);
$showLoader.on(hideLoader, () => false);

sample({
  clock: showLoader,
  target: setText,
  fn: (param) => param || '',
});

export const stores = { $showLoader, $text };
export const events = { showLoader, hideLoader, setText };
