import React from 'react';
import { createEvent, createStore, guard, restore } from 'effector';

import { deviceModel } from 'features/device';
import { $allHallsNames, loadAllHallsNameFx } from 'features/halls';

export const pageLoaded = createEvent();
export const pageUnloaded = createEvent();

export const setInfoSectionRef = createEvent<React.RefObject<HTMLDivElement>>();
export const $infoSectionRef = restore(setInfoSectionRef, null);

export const $pageLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .reset(pageUnloaded);

export const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .reset(pageUnloaded);

guard({
  source: pageLoaded,
  filter: $allHallsNames.map((names) => names.length === 0),
  target: loadAllHallsNameFx,
});

guard({
  source: deviceModel.$deviceType,
  filter: $pageIsLoaded,
  target: loadAllHallsNameFx,
});
