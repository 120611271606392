import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { useDisclosure, useModal } from 'lib/hooks';
import { Button, ButtonLink, Counter, FlipBox, IconSprite } from 'ui';

import { activeBonusModel } from '../bonus-active';
import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  BonusTitle,
  CancelBonusModal,
  NotActiveMessage,
  SubmitActivateBonusModal,
  TotalCashbacks,
} from '../molecules';

import * as model from '../model';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const WeeklyCashbackCardAvailable: React.FC<Props> = (props) => {
  const cancelModal = useModal();
  const activateModal = useModal();

  const {
    isOpen: isBackSide,
    open: showBackSide,
    close: showFrontSide,
  } = useDisclosure();

  return (
    <>
      {activateModal.isVisible && (
        <SubmitActivateBonusModal
          bonus={props.bonus}
          onReject={activateModal.hide}
          onSubmit={activateModal.hide}
        />
      )}
      {cancelModal.isVisible && (
        <CancelBonusModal
          bonus={props.bonus}
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <FlipBox className="flip-card" isFlipped={isBackSide}>
        <FrontSide
          onCancel={cancelModal.show}
          onActivate={activateModal.show}
          onFlipButtonClick={showBackSide}
          {...props}
        />
        <BackSide
          onActivate={activateModal.show}
          onFlipButtonClick={showFrontSide}
          {...props}
        />
      </FlipBox>
    </>
  );
};

interface DoubleSidedCardProps extends Props {
  onFlipButtonClick: () => void;
  onCancel?: () => void;
  onActivate?: () => void;
}

const FrontSide: React.FC<DoubleSidedCardProps> = ({
  bonus,
  idx,
  className,
  onActivate,
  onCancel,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  const activeBonus = useStore(activeBonusModel.$activeBonus);

  const { activate_before, expired_at, event_type } = bonus;
  const hasActiveBonus = !!activeBonus;

  return (
    <BonusCardWrap
      iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
      className={classNames(
        className,
        event_type === 'TASK_ACCOMPLISHED' && 'welcome-bonus',
        model.isBirthdayBonus(bonus) && 'birthday',
        'available',
      )}
      idx={idx}
    >
      <div className="bonus-card__info">
        <BonusTitle
          bonus={bonus}
          color={bonus.event_type === 'WEEKLY_CASHBACK' ? 'blue' : ''}
        />
        <BonusSubtitle bonus={bonus} withEvent />
        <div
          className={classNames(
            'description-wrap',
            hasActiveBonus && 'has-active',
          )}
        >
          {(expired_at || activate_before) && (
            <Counter date={expired_at || activate_before} />
          )}
          <BonusDescription.MainInfo bonus={bonus} />
          <TotalCashbacks />
        </div>
      </div>
      <div className="bonus-card__nav">
        {hasActiveBonus ? (
          <NotActiveMessage />
        ) : (
          <div className="front-btns">
            <Button onClick={onCancel} theme="tertiary">
              <IconSprite name="delete-icon" />
            </Button>
            <Button onClick={onActivate}>{t('common:buttons.activate')}</Button>
          </div>
        )}
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:detailed')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const BackSide: React.FC<DoubleSidedCardProps> = ({
  className,
  bonus,
  idx = 0,
  onFlipButtonClick,
  onActivate,
}) => {
  const { t } = useTranslation();

  const day = '';

  return (
    <BonusCardWrap className={classNames('back-side', className)} idx={idx + 2}>
      <div className="bonus-card__info">
        <BonusSubtitle>
          {t('bonus:subtitle.WEEKLY_CASHBACK_FIERY')}
        </BonusSubtitle>
        <p className="cashback-descr">
          {t('bonus:WEEKLY_CASHBACK_FIERY_TEXT')}
          {day && t(`bonus:WEEKLY_CASHBACK_FIERY_TEXT_DAY_${day}`)}
        </p>
      </div>
      <div className="bonus-card__nav">
        <Button onClick={onActivate}>{t('common:buttons.activate')}</Button>
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:buttons.back')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};
