import React, { FormEvent, useRef } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  PaymentEmailForm,
  PaymentEmailModals,
  paymentsInfoModel,
  RequiredBetBalance,
} from 'features/payments-info';
import { UserBalance, userModel } from 'features/user';
import {
  CardInfoForm,
  deleteCardFieldsFromFields,
  PaymentDataField,
  PaymentGroupSelect,
  SubmitPaymentButton,
} from 'features/cashbox';
import { Field, IconSprite, MainForm, MainFormRow, Toast } from 'ui';
import { isInViewport } from 'lib/is-in-viewport';
import { paths } from '../../paths';

import * as model from './model';
import './style.css';

export const WithdrawPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  return (
    <>
      <CloseCardFormButton />
      <div className="acc-content__balance">
        <UserBalance />
        <RequiredBetBalance />
      </div>
      <WithdrawErrorModal />
      <Form />
      <PaymentEmailModals />
    </>
  );
};

const CloseCardFormButton = () => {
  const { t } = useTranslation();
  const cardFormIsOpen = useStore(model.$cardFormIsOpened);

  return cardFormIsOpen ? (
    <div
      className="acc-content__back-button"
      onClick={() => model.changeCardFormIsOpened(false)}
    >
      <IconSprite name="arrow-prev" />
      <span>{t('common:buttons.back')}</span>
    </div>
  ) : null;
};

const Form = () => {
  const cardFormIsOpen = useStore(model.$cardFormIsOpened);

  return cardFormIsOpen ? <CardForm /> : <MainWithdrawForm />;
};

const CardForm = () => {
  const disabled = useStore(model.$submitButtonIsDisabled);
  const sum = useStore(model.$sum);
  const fields = useStore(model.$dataFields);

  return (
    <CardInfoForm
      sum={sum}
      fields={fields}
      $dataFieldsErrors={model.$dataFieldsErrors}
      $dataFieldsValues={model.$dataFieldsValues}
      onFieldChanged={model.dataFieldChanged}
      disabled={disabled}
      type="deposit"
      onSubmit={model.formSubmitted}
    />
  );
};
const MainWithdrawForm = () => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const system = useStore(model.$seletectedPaymentSystem);
  const needToHideFields = useStore(model.$needToHideFields);
  const disabled = useStore(model.$submitButtonIsDisabled);
  const needToConfirmPaymentEmail = useStore(model.$needToConfirmPaymentEmail);

  const paymentListIsLoaded = useStore(model.$isPaymentSystemsIsLoaded);

  const scrollInToView = React.useCallback(() => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [ref]);

  React.useEffect(() => {
    if (system) {
      setImmediate(() => !isInViewport(ref.current) && scrollInToView());
    }
  }, [system, scrollInToView]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    model.formSubmitted();
  };

  return (
    <>
      <MainForm
        ref={ref}
        isForm={!needToConfirmPaymentEmail}
        onSubmit={onSubmit}
        className="cashbox-mainform"
      >
        <PaymentGroupSelectRow isLoading={!paymentListIsLoaded} />
        <div ref={ref}>
          {!needToHideFields && (
            <>
              <FieldsList />
              <SumField />
              <SubmitPaymentButton disabled={disabled}>
                {t('common:buttons.next')}
              </SubmitPaymentButton>
            </>
          )}
        </div>
      </MainForm>
    </>
  );
};

const FieldsList = () => {
  const fields = useStore(model.$dataFields);
  const needToFillCardForm = useStore(model.$needToFillCardForm);

  const filteredFields = React.useMemo(
    () => (needToFillCardForm ? deleteCardFieldsFromFields(fields) : fields),
    [fields, needToFillCardForm],
  );

  return (
    <>
      {filteredFields.map((f) => (
        <PaymentDataField
          onChange={(e) =>
            model.dataFieldChanged({
              fieldName: f.name,
              value: e.target.value,
            })
          }
          $dataFieldsErrors={model.$dataFieldsErrors}
          $dataFieldsValues={model.$dataFieldsValues}
          field={f}
          key={f.name}
        />
      ))}
    </>
  );
};

const WithdrawErrorModal = () => {
  const { t } = useTranslation();

  const modalErrorMessage = useStore(model.$modalErrorMessage);

  return modalErrorMessage ? (
    <Toast isOpen type="error" onAnimationEnd={model.resetModalError}>
      {modalErrorMessage === 'default'
        ? t('lk:withdraw.errors.defaultErrorText')
        : modalErrorMessage}
    </Toast>
  ) : null;
};

const PaymentGroupSelectRow = React.memo(
  ({ isLoading = false }: { isLoading?: boolean }) => {
    const { t } = useTranslation();
    const paymentSystems = useStore(model.$paymentSystemsList);
    const selectedPaymentSystem = useStore(model.$seletectedPaymentSystem);

    const needToConfirmAccount = useStore(model.$needToConfirmAccount);
    const emailConfirmed = useStore(userModel.$isEmailConfirmed);
    const needToConfirmPaymentEmail = useStore(
      model.$needToConfirmPaymentEmail,
    );
    const fullInfoIsProvided = useStore(paymentsInfoModel.$fullInfoIsProvided);
    const canWithdraw = useStore(model.$canWithdraw);
    const hasRequiredBet = useStore(paymentsInfoModel.$hasRequiredBet);

    const error = React.useMemo(() => {
      if (needToConfirmAccount) {
        return emailConfirmed
          ? t('common:validators.phoneNotConfirmed')
          : t('common:validators.emailNotConfirmed');
      }
      if (needToConfirmPaymentEmail) {
        return t('common:validators.paymentEmailNotConfirmed');
      }
      if (!fullInfoIsProvided) {
        return t('common:validators.notAllInfo');
      }
      if (!canWithdraw) {
        return t(`common:validators.cantWithdraw`);
      }
      if (hasRequiredBet) {
        return t(`common:validators.hasRequiredBet`);
      }
      return null;
    }, [
      fullInfoIsProvided,
      canWithdraw,
      hasRequiredBet,
      t,
      needToConfirmAccount,
      needToConfirmPaymentEmail,
      emailConfirmed,
    ]);

    const profileErrors = [
      t('common:validators.phoneNotConfirmed'),
      t('common:validators.emailNotConfirmed'),
      t('common:validators.notAllInfo'),
    ];

    return (
      <>
        <MainFormRow
          white
          label={t('lk:withdraw.method')}
          wrapClassName="main-form__method-wrap"
        >
          <PaymentGroupSelect
            isLoading={isLoading}
            paymentSystems={paymentSystems}
            currentPaymentSystem={selectedPaymentSystem}
            onChange={model.changePaymentSystem}
          />
          {error && !isLoading ? (
            <div className="payments-error">
              <IconSprite name="error-field" />
              {profileErrors.includes(error) ? (
                <Link className="payments-error__link" to={paths.profile()}>
                  {error}
                </Link>
              ) : (
                error
              )}
            </div>
          ) : null}
        </MainFormRow>
        {needToConfirmPaymentEmail && !needToConfirmAccount && (
          <PaymentEmailForm />
        )}
      </>
    );
  },
);

const SumField = () => {
  const { t } = useTranslation();

  const limits = useStore(model.$limits);
  const sum = useStore(model.$sum);
  const sumError = useStore(model.$sumError);

  const error = React.useMemo(() => {
    if (sumError.required) return t('common:validators.required');

    if (sumError.minNumber)
      return t('common:validators.withdrawmin', { count: sumError.minNumber });

    if (sumError.maxNumber)
      return t('common:validators.withdrawmax', { count: sumError.maxNumber });

    if (sumError.enoughBalance) {
      return t('common:validators.enoughBalance');
    }

    return '';
  }, [t, sumError]);

  const { minNumber, maxNumber } = limits;

  return (
    <MainFormRow label={t('lk:withdraw.sum.label')}>
      <Field
        type="number"
        placeholder={
          minNumber || minNumber
            ? `${minNumber} - ${maxNumber || 0}`
            : t('lk:withdraw.sum.placeholder')
        }
        error={error}
        name={'sum'}
        onChange={(e) => model.sumChanged(parseFloat(e.target.value) || 0)}
        value={sum || ''}
      />
    </MainFormRow>
  );
};
