import FingerprintJS from '@fingerprintjs/fingerprintjs';

const getFingerPrint = async () => {
  const fp = await FingerprintJS.load();

  const result = await fp.get();

  return result.visitorId;
};

export const fpApi = { getFingerPrint };
