import * as React from 'react';
import classNames from 'classnames';

import { IconSprite } from 'ui';
import { useLockBodyScroll } from 'lib/hooks';

import './style.css';

export const AccountContentTemplate = ({
  children,
  className,
  isMobile,
  title,
  onCloseClick = () => {},
  componentRef,
}: {
  title: string;
  isMobile: boolean;
  children: React.ReactNode;
  className?: string;
  onCloseClick?: () => void;
  componentRef?: React.Ref<HTMLDivElement>;
}) => {
  useLockBodyScroll(isMobile);

  return (
    <div
      ref={componentRef}
      className={classNames('acc-content', isMobile && 'mob', className)}
    >
      <h2 className="acc-content__title">{title}</h2>
      {children}
      <button
        onClick={onCloseClick}
        className="acc-content__close-btn"
        type="button"
      >
        <IconSprite name="page-close" />
      </button>
    </div>
  );
};
