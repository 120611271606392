export const IMAGES_NAMES: Record<string, string> = {
  card_rub: 'mastercardVisa.svg',
  visa_rus: 'mastercardVisa.svg',
  visa_uah: 'mastercardVisa.svg',
  card_new: 'mastercardVisa.svg',
  card_cpaytrz_merchant_inr: 'mastercardVisa.svg',
  card_eur: 'Mastercard.svg',
  br_card_in: 'mastercardVisa.svg',
  br_card_in_pw: 'koshel_and_period.svg',
  blackrabbit: 'mastercardVisa.svg',
  br_card_out: 'mastercardVisa.svg',
  br_card_out_pw: 'koshel_and_period.svg',
  blackrabbit_card: 'mastercardVisa.svg',
  card_verified: 'mastercardVisa.svg',
  bitcoin: 'bitcoin.svg',
  piastrix_bitcoin: 'bitcoin.svg',
  ethereum: 'ethereum.svg',
  piastrix_ethereum: 'ethereum.svg',
  sbp: 'sbp.svg',
  sbp_rub: 'sbp.svg',
  tether: 'tether.svg',
  piastrix_tether: 'tether.svg',
  perfectmoney: 'perfectMoney.svg',
  piastrix: 'piastrix.svg',
  piastrix_rub: 'piastrix.svg',
  piastrix_eur: 'piastrix.svg',
  piastrix_usd: 'piastrix.svg',
  piastrix_wallet: 'piastrix.svg',
  w1megafon: 'megafon_icon.svg',
  megafon_rub: 'megafon_icon.svg',
  w1mts: 'MTS_logo.svg',
  mts_rub: 'MTS_logo.svg',
  w1beeline: 'beeline.svg',
  beeline_rub: 'beeline.svg',
  webmoney_usd: 'webmoney.svg',
  kyivstar: 'kyivstar.svg',
  kyivstar_uah: 'kyivstar.svg',
  lifecell: 'lifecell.svg',
  lifecell_uah: 'lifecell.svg',
  vodafone: 'vodafone.svg',
  vodafone_uah: 'vodafone.svg',
  tele2: 'tele2.svg',
  tele2_rub: 'tele2.svg',
  card_uah: 'mastercardVisa.svg',
  ind_bank: 'ind_bank.png',
  inps_cpaytrz_merchantWallet_inr_phonepe: 'ind_wallet_phonepe.png',
  ind_wallet: 'ind_wallet.png',
  ind_wallet_paytm: 'ind_wallet_paytm.svg',
  inps_cpaytrz_merchantWallet_inr_paytm: 'ind_wallet_paytm.svg',
  ind_upi: 'ind_upi.png',
  ind_wallet_paypm: 'ind_wallet_paypm.svg',
  inps_cpaytrz_merchantWallet_inr_airtel_money: 'airtel-money.png',
  inps_cpaytrz_merchantNetBanking_inr_BOM: 'Bank of Maharashtra Logo.png',
  inps_cpaytrz_merchantNetBanking_inr_BARODA_RETAIL: 'Bank_of_Baroda_logo.png',
  inps_cpaytrz_merchantNetBanking_inr_BOI:
    'BOI-Bank-of-India-logo-BEB9E7695B-seeklogo.com.png',
  inps_cpaytrz_merchantNetBanking_inr_CB: 'Canara-Bank-logo.png',
  inps_cpaytrz_merchantWallet_inr_reliance_jio: 'JioMoney.png',
  inps_cpaytrz_merchantNetBanking_inr_CBOI: 'central-bank-of-india-logo.png',
  inps_cpaytrz_merchantNetBanking_inr_CUB: 'City Union Bank logo.png',
  inps_cpaytrz_merchantNetBanking_inr_DeutscheB: 'deutsche-bank.svg',
  inps_cpaytrz_merchantNetBanking_inr_COSMOS:
    'inps_cpaytrz_merchantNetBanking_inr_COSMOS.png',
  inps_cpaytrz_merchantNetBanking_inr_IDFCB:
    'inps_cpaytrz_merchantNetBanking_inr_IDFCB.svg',
  inps_cpaytrz_merchantNetBanking_inr_SVCB:
    'inps_cpaytrz_merchantNetBanking_inr_SVCB.svg',
  inps_cpaytrz_merchantNetBanking_inr_DCB:
    'DCB_Development_Credit_Bank.svg.png',
  inps_cpaytrz_merchantNetBanking_inr_DB: 'Dhanlaxmi Bank logo.png',
  inps_cpaytrz_merchantNetBanking_inr_DBC: 'Dhanlaxmi Bank logo.png',
  inps_cpaytrz_merchantNetBanking_inr_FB: 'Federal_bank_India.svg.png',
  inps_cpaytrz_merchantWallet_inr_freecharge: 'FreeCharge_Logo.png',
  inps_cpaytrz_merchantNetBanking_inr_HDFCB: 'HDFC_Bank_logo.png',
  inps_cpaytrz_merchantNetBanking_inr_ICICIB: 'icici_bank_logo_symbol.png',
  inps_cpaytrz_merchantNetBanking_inr_INDB:
    'Indian-bank-logo-indian-bank-logo-vector-hd.png',
  inps_cpaytrz_merchantNetBanking_inr_IOB: 'Indian_Overseas_Bank_Logo.svg.png',
  inps_cpaytrz_merchantNetBanking_inr_IB: 'indusland_bank.png',
  inps_cpaytrz_merchantNetBanking_inr_IDBOI:
    'Industrial development bank of India.png',
  inps_cpaytrz_merchantNetBanking_inr_JAKB:
    'Jammu and Kashmir_Bank_logo_logotype.png',
  inps_cpaytrz_merchantUPI_inr_jiomoney: 'JioMoney.png',
  inps_cpaytrz_merchantNetBanking_inr_KBL: 'Karnataka_Bank_svg_Logo.svg.png ',
  inps_cpaytrz_merchantNetBanking_inr_KVB: 'kvb.jpeg',
  inps_cpaytrz_merchantNetBanking_inr_KB: 'Kotak_Mahindra_Bank_logo.png',
  inps_cpaytrz_merchantNetBanking_inr_LVBNB: 'Lakshmi Vilas Bank Logo.png',
  inps_cpaytrz_merchantUPI_inr_googlepay: 'logobar-google-pay.png',
  inps_cpaytrz_merchantWallet_inr_mobikwik: 'MobiKwik-Logo-png.png',
  inps_cpaytrz_merchantWallet_inr_ola_money: 'Ola money.png',
  inps_cpaytrz_merchantNetBanking_inr_PNB: 'Punjab-National-Bank-300x200.png',
  inps_cpaytrz_merchantNetBanking_inr_PASB: 'Punjab_&_Sind_Bank.svg.png',
  inps_cpaytrz_merchantNetBanking_inr_SIB: 'South_Indian_Bank_Logo.svg.png',
  inps_cpaytrz_merchantNetBanking_inr_SCB: 'Standard_Chartered_logo.png',
  inps_cpaytrz_merchantNetBanking_inr_SBOI: 'State Bank of India logo.png',
  inps_cpaytrz_merchantNetBanking_inr_TMB:
    'Tamilnad Merchantile Bank_SVG_Logo.svg.png',
  inps_cpaytrz_merchantNetBanking_inr_UCOB: 'uco-bank_logo.png',
  inps_cpaytrz_merchantNetBanking_inr_UBOI: 'Union-bank-of-india-pnglogo.png',
  inps_cpaytrz_merchantUPI_inr_upi: 'UPI-Logo-PNG-715x342.png',
  inps_cpaytrz_merchantNetBanking_inr_YB: 'Yes_Bank_logo.png',
  inps_cpaytrz_transferBank_inr: 'ind_bank.png',
  inps_cpaytrz_transferUpi_inr: 'ind_upi.png',
  payment_card_rub_hpp: 'mastercardVisa.svg',
  payment_card_rub_out: 'mastercardVisa.svg',
  payment_card_bp2_rub_out: 'mastercardVisa.svg',
};

export enum CARD_FIELD_NAMES {
  CARD_EXP_MONTH = 'card_exp_month',
  CARD_EXP_YEAR = 'card_exp_year',
  CARD_CVV = 'card_cvv',
  CARD_HOLDER = 'card_holder',
  CARD_NUMBER = 'card_number',
}
