export function fileToByteArray(file: File) {
  return new Promise<number[]>((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray: number[] = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt?.target?.readyState == FileReader.DONE) {
          let arrayBuffer = evt.target.result as ArrayBuffer,
            array = new Uint8Array(arrayBuffer);
          for (let byte of array) {
            fileByteArray.push(byte);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const createFilesList = (files) => {
  var b = new ClipboardEvent('').clipboardData || new DataTransfer();
  for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
  return b.files;
};
