import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  Effect,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { tournamentApi } from 'api/tournament';
import { status } from 'patronum';
import { deviceModel } from 'features/device';
import { sessionModel } from 'features/session';

export const pageLoaded = createEvent<{ id: string }>();
export const pageUnloaded = createEvent();

const loadTournamentFx: Effect<{ id: string }, TournamentItem, Error> = attach({
  effect: createEffect<
    { id: string; isMobile: boolean },
    TournamentItem,
    Error
  >(({ id, isMobile }) => tournamentApi.getById(id, isMobile)),
  source: deviceModel.$isMobile,
  mapParams: ({ id }, isMobile) => ({
    id,
    isMobile,
  }),
});

const $pageIsLoaded = createStore<string | boolean>(false)
  .on(pageLoaded, (_, { id }) => id)
  .reset(pageUnloaded);

export const $tournamentData = createStore<TournamentItem | null>(null).reset(
  pageUnloaded,
);

export const $isTournamentParticipant = $tournamentData.map((data) =>
  Boolean(data?.player_participant),
);

export const tournamentJoined = createEvent<void>();

const joinTournamentFx = createEffect<string, void, Error>();
joinTournamentFx.use((id) => tournamentApi.join(id));

sample({
  clock: tournamentJoined,
  source: $tournamentData,
  fn: (src) => String(src?.id),
  target: joinTournamentFx,
});

sample({
  clock: joinTournamentFx.done,
  source: $pageIsLoaded,
  fn: (id) => ({ id: id as any }),
  target: loadTournamentFx,
});

// если страница загруженная и devicetype сменился загрузить моб. версию турнира
guard({
  source: sample($pageIsLoaded, deviceModel.$deviceType, (id) => ({ id })),
  filter: combine($pageIsLoaded, $tournamentData, (isLoaded, tournament) =>
    tournament ? !!isLoaded : false,
  ),
  // @ts-ignore
  target: loadTournamentFx,
});

guard({
  source: sample({
    source: $pageIsLoaded,
    clock: guard(sessionModel.$isAuthenticated, { filter: (isAuth) => isAuth }),
    fn: (id) => ({ id }),
  }),
  filter: $pageIsLoaded.map(Boolean),
  // @ts-ignore
  target: loadTournamentFx,
});

forward({
  from: pageLoaded,
  to: [loadTournamentFx],
});

sample({
  source: loadTournamentFx.doneData,
  target: $tournamentData,
});

export const $status = status({
  effect: loadTournamentFx,
  defaultValue: 'pending',
});

export const $error = restore(loadTournamentFx.fail, null);
