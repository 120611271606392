import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import classNames from 'classnames';

import { Button, ButtonLink, Counter, FlipBox, IconSprite } from 'ui';
import { useFormaters } from 'features/i18';
import { useModal } from 'lib/hooks';
import { DateTime } from 'lib/datetime';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  BonusTitle,
  GiftSpinsBonusTitle,
} from '../molecules';
import { WeeklyCashbackProgramCard } from './weekly-cashback-program-card';

import * as model from '../model';
import './style.css';

interface Props {
  titleTheme?: 'violet' | 'yellow' | 'lilac';
  className?: string;
  idx?: number;
  bonus: BonusProgram;
}

export const BonusProgramCard: React.FC<Props> = (props) => {
  if (model.isWeeklyCashbackBonusProgram(props.bonus)) {
    return <WeeklyCashbackProgramCard {...props} />;
  }

  const hasTwoRewards =
    props.bonus.reward_giftspins &&
    (props.bonus.reward_sum || props.bonus.reward_percent);

  if (hasTwoRewards) {
    return <DoubleSidedCard {...props} />;
  }

  return <OneSidedCard {...props} />;
};

const DoubleSidedCard: React.FC<Props> = (props) => {
  const [showBackSide, setShowBackSide] = React.useState(false);

  return (
    <FlipBox className="flip-card" isFlipped={showBackSide}>
      <FrontSide
        onFlipButtonClick={() => setShowBackSide((side) => !side)}
        {...props}
      />
      <BackSide
        onFlipButtonClick={() => setShowBackSide((side) => !side)}
        {...props}
      />
    </FlipBox>
  );
};

const FrontSide: React.FC<Props & { onFlipButtonClick: () => void }> = ({
  bonus,
  idx = 0,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <BonusCardWrap idx={idx}>
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />

        <div className="bonus-card__title plus">+</div>
        <GiftSpinsBonusTitle
          reward_giftspins={bonus.reward_giftspins as GiftSpin}
        />
        <BonusSubtitle>{t('bonus:freespins')}</BonusSubtitle>
        <div className="bonus-card__nav">
          <BonusProgramButton bonus={bonus} />
          <ButtonLink onClick={onFlipButtonClick} theme="secondary">
            {t('common:detailed')}
          </ButtonLink>
        </div>
      </div>
    </BonusCardWrap>
  );
};
const BackSide: React.FC<Props & { onFlipButtonClick: () => void }> = ({
  className,
  bonus,
  idx = 0,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <BonusCardWrap className={classNames('back-side', className)} idx={idx + 2}>
      <div className="bonus-card__info">
        <BonusSubtitle withReward bonus={bonus} />
        <BonusDescription.MainInfo
          withGiftspinsInfo={false}
          withDepositInfo
          bonus={bonus}
        />
        <BonusSubtitle
          className="second"
          showRewards={{ sum: false, percent: false, giftspins: true }}
          withEvent={false}
          withReward
          bonus={bonus}
        />
        <BonusDescription.GiftSpinInfo
          giftspins={bonus.reward_giftspins as GiftSpin}
        />
        <BonusProgramButton bonus={bonus} />
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:buttons.back')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const OneSidedCard: React.FC<Props> = ({ className, bonus, idx = 0 }) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  // const text = React.useMemo(() => getText(bonus, t, currencyNumber), [
  //   bonus,
  //   t,
  //   currencyNumber,
  // ]);

  return (
    <BonusCardWrap className={classNames(className, 'bonus-program')} idx={idx}>
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <BonusDescription.MainInfo bonus={bonus} withDepositInfo />
      </div>
      <div className="bonus-card__nav">
        {/*<Tooltip bonus={bonus} />*/}
        <BonusProgramButton bonus={bonus} />
      </div>
    </BonusCardWrap>
  );
};

const BonusProgramButton = ({ bonus }: { bonus: BonusProgram }) => {
  const { t } = useTranslation();

  const isReloadBonus = React.useMemo(() => model.isReloadBonusProgram(bonus), [
    bonus,
  ]);

  const buttonIsDisabled = React.useMemo(() => {
    if (isReloadBonus) {
      const currentDay = DateTime.getDay();
      if (!bonus.days_of_week.includes(currentDay)) {
        return true;
      }
    }
    return false;
  }, [bonus, isReloadBonus]);

  return (
    <Button
      disabled={buttonIsDisabled}
      onClick={() => model.bonusProgramClicked(bonus)}
    >
      {t('bonus:getBonus')}
    </Button>
  );
};

const Tooltip = ({ bonus }: { bonus: BonusProgram }) => {
  const { t } = useTranslation();
  const { hide, show, isVisible } = useModal();

  const {
    coupon,
    min_dep_sum,
    wager,
    max_bet,
    event_type,
    max_dep_sum,
  } = bonus;

  const TooltipRow = ({ description, text }) =>
    !!text ? (
      <div className="tooltip__row">
        <span className="tooltip__name">{description}</span>
        <span className="tooltip__count">{text}</span>
      </div>
    ) : null;

  return (
    <>
      <ButtonLink theme="secondary" onMouseEnter={show} onMouseLeave={hide}>
        {t('common:detailed')}
      </ButtonLink>
      <div className={classNames('tooltip', isVisible && 'active')}>
        <div className="tooltip__wrap">
          {event_type === 'COUPON' && (
            <TooltipRow
              description={t('bonus:tooltip.promocode')}
              text={coupon}
            />
          )}
          {event_type !== 'WEEKLY_CASHBACK' && (
            <>
              <TooltipRow
                description={t('bonus:tooltip.minDeposit')}
                text={min_dep_sum}
              />
              <TooltipRow
                description={t('bonus:tooltip.maxDeposit')}
                text={max_dep_sum}
              />
            </>
          )}
          <TooltipRow description={t('bonus:tooltip.wagering')} text={wager} />
          <TooltipRow
            description={t('bonus:tooltip.betForSpin')}
            text={max_bet}
          />
        </div>
      </div>
    </>
  );
};

export const BonusCardSkeleton = ({ idx }) => (
  <div className="bonus-card">
    <IconSprite
      className="bonus-card__icon"
      name={`bonus-card${(idx % 6) + 1}`}
    />
    <div className="bonus-card__wrap loading" />
  </div>
);

const getText = (bonus: BonusProgram, t: TFunction, currencyNumber) => {
  const {
    min_dep_sum,
    reward_giftspins,
    reward_percent,
    reward_sum,
    max_dep_sum,
  } = bonus;

  const prize = (() => {
    if (!!reward_percent) return `${reward_percent}%`;
    if (!!reward_sum) return currencyNumber(reward_sum);
    if (!!reward_giftspins && Object.keys(reward_giftspins).length !== 0)
      return `${reward_giftspins.spins} FS`;
  })();

  if (model.isDepositBonusProgram(bonus)) {
    return t('bonus:bonusProgramDepositText', {
      prize,
      min_dep_sum: currencyNumber(min_dep_sum),
      max_dep_sum: currencyNumber(max_dep_sum),
    });
  }

  // if (event_type === 'WEEKLY_CASHBACK') {
  //   return 'Текст программы кэшбэка';
  // }

  // if (event_type === 'TASK_ACCOMPLISHED') {
  //   return `Получите приз за выполнения задания`;
  // }

  return;
};
