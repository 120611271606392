import React from 'react';

import { Grid } from 'ui';
import { DEFAULT_RENDER_SIZE } from 'lib/constants';
import { GameCard, GameCardSkeleton } from './game-card';
import './style.css';

interface ListProps {
  gameList?: Game[];
  isPending?: boolean;
  size?: number;
}

export const GameList: React.FC<ListProps> = ({
  gameList,
  isPending,
  size = DEFAULT_RENDER_SIZE,
}) => {
  if (isPending) {
    return <GameSkeletonList size={size} />;
  }

  return (
    <Grid container>
      {gameList!.map((item) => (
        <GridGameItem key={item.code} game={item} />
      ))}
    </Grid>
  );
};

const GridGameItem = React.memo(({ game }: { game: Game }) => (
  <Grid className="grid-game-item" xs={4} md={3} lg={2} item>
    <GameCard game={game} />
  </Grid>
));

const GameSkeletonList = ({ size }: { size: number }) => {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: size }, (_, idx) => {
        return (
          <Grid key={idx} className="grid-game-item" xs={4} md={3} lg={2} item>
            <GameCardSkeleton />
          </Grid>
        );
      })}
    </Grid>
  );
};
