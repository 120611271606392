import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormaters } from 'features/i18';
import { IconSprite } from 'ui';
import { DateTime } from 'lib/datetime';

import {
  BonusCardWrap,
  BonusSubtitle,
  BonusTitle,
  GiftSpinsBonusTitle,
} from '../molecules';

import * as model from '../model';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const BonusCardWon: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const { idx, className, bonus } = props;

  const hasTwoRewards = model.bonusHasTwoRewards(props.bonus);

  const endDate = React.useMemo(
    () => (bonus.ended_at ? DateTime.getData(bonus.ended_at) : null),
    [bonus.ended_at],
  );

  if (hasTwoRewards) {
    return <TwoRewardsBonusWon {...props} />;
  }

  return (
    <BonusCardWrap className={className} idx={idx}>
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <img src={'/img/bonus-win.png'} alt="win-logo" />
        <div className="bonus-end-message">
          {t('bonus:messages.bonus-end-message')}
        </div>
        <div className="good-luck-message">
          <IconSprite name="bonus-success-message-icon" />
          <span>{t('bonus:messages.congrats')}</span>
        </div>
        {bonus.withdraw_sum !== 0 && (
          <div className="bonus-win-sum">
            {currencyNumber(bonus.withdraw_sum)}
          </div>
        )}
        {endDate && (
          <div className="bonus__end-date">
            {endDate.day}.{endDate.month}.{endDate.year}
          </div>
        )}
      </div>
    </BonusCardWrap>
  );
};

const TwoRewardsBonusWon: React.FC<Props> = ({ idx, className, bonus }) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const endDate = React.useMemo(
    () => (bonus.ended_at ? DateTime.getData(bonus.ended_at) : null),
    [bonus.ended_at],
  );

  return (
    <BonusCardWrap idx={idx} className={className}>
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <div className="bonus-card__title plus">+</div>
        <GiftSpinsBonusTitle
          reward_giftspins={bonus.reward_giftspins as GiftSpin}
        />
        <BonusSubtitle>{t('bonus:freespins')}</BonusSubtitle>
        <div className="bonus-end-message">
          {t('bonus:messages.bonus-end-message')}
        </div>
        <div className="good-luck-message">
          <IconSprite name="bonus-success-message-icon" />
          <span>{t('bonus:messages.congrats')}</span>
        </div>
        {bonus.due_amount !== 0 && (
          <div className="bonus-win-sum">
            {currencyNumber(bonus.due_amount)}
          </div>
        )}
        {!!endDate && (
          <div className="bonus__end-date">
            {endDate.day}.{endDate.month}.{endDate.year}
          </div>
        )}
      </div>
    </BonusCardWrap>
  );
};
