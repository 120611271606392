import React, { useEffect } from 'react';
import { useGate, useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, SecondaryInfoModal } from 'ui';
import { useFormaters } from 'features/i18';

import './style.css';
import * as wheelModel from '../model';
import { availableBonusesModel } from '../../bonus/bonus-available';
import { skinModel } from '../../skin-id';
import { mediaHooks } from '../../../lib/media';

export const WheelOfFortune = () => {
  useGate(wheelModel.pageGate);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const skinId = skinModel.selectors.useSkinId();
  const isLargeMobile = mediaHooks.useIsLargeMobile();

  return (
    <div className="wheel-game-page">
      <div className="wheel-block">
        <div className="cta cta--left">
          <div className="cta__text">
            {t('wof:get')} {t('wof:prizes')}
          </div>
        </div>
        <div className="wheel-container">
          <SpinButton />
          <div
            className="pepper"
            style={{
              backgroundImage: `url('/img/${skinId}/wheel-of-fortune/Pepper.png')`,
            }}
          />
          <div
            className="boom-container"
            style={{
              backgroundImage: `url('/img/${skinId}/wheel-of-fortune/RedVector.png')`,
            }}
          >
            <div
              className="boom"
              style={{
                backgroundImage: `url('/img/${skinId}/wheel-of-fortune/Boom.png')`,
              }}
            />
          </div>
          <div
            className="top-triangle"
            style={{
              backgroundImage: isLargeMobile
                ? `url('/img/${skinId}/wheel-of-fortune/VectorMob.svg')`
                : `url('/img/${skinId}/wheel-of-fortune/Vector.svg')`,
            }}
          />
          <Circle />
        </div>
        <div className="cta cta--right">
          <FreespinCount />
          <div className="cta__text">
            {t('wof:spin')} {t('wof:wheel')}
          </div>
        </div>
      </div>
      <MultiplierSelect />
    </div>
  );
};

const FreespinCount = () => {
  const { t } = useTranslation();
  const selectedMultiplier = useStore(wheelModel.$currentMultiplier);

  const spinsListByMultiplier = availableBonusesModel.selectors.useSpinsListByMultiplier(
    selectedMultiplier,
  );

  return (
    <div className="freespin-count">
      {t('wof:spinsLeft')}
      <span className="count">{spinsListByMultiplier.length}</span>
    </div>
  );
};

const MultiplierSelect = () => {
  const { currencyNumber } = useFormaters();
  const sections = useStore(wheelModel.$sections);
  const currentMultiplier = useStore(wheelModel.$currentMultiplier);
  const isSpinning = useStore(wheelModel.$isSpinning);
  const isCurrentSpinAgain = useStore(wheelModel.$boughtSpinHasAgainPrizeType);

  return (
    <div className="multiplier-select__wrap">
      {sections.map(({ multiplier, spin_cost }) => {
        return (
          <div key={multiplier} className="multiplier-select__item--wrap">
            <div className="spin-cost">
              {currencyNumber(spin_cost, { minimumFractionDigits: 0 })}
            </div>
            <Button
              disabled={isSpinning || isCurrentSpinAgain}
              theme="deposit"
              className={classNames(
                'multiplier-select__item',
                (currentMultiplier === multiplier || isCurrentSpinAgain) &&
                  'active',
              )}
              onClick={() => wheelModel.setCurrentMultiplier(multiplier)}
            >
              <div className="content-wrap">
                {multiplier}
                <span>x</span>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

const Circle = () => {
  const isSpinning = useStore(wheelModel.$isSpinning);
  const prizeList = useStore(wheelModel.$prizeList);
  const currentDeg = useStore(wheelModel.$currentDeg);

  const currentMultiplier = useStore(wheelModel.$currentMultiplier);
  const isLargeMobile = mediaHooks.useIsLargeMobile();

  const skinId = skinModel.selectors.useSkinId();

  return (
    <div
      className="red-border"
      style={{
        backgroundImage: isLargeMobile
          ? `url('/img/${skinId}/wheel-of-fortune/SubtractMob.png')`
          : `url('/img/${skinId}/wheel-of-fortune/Subtract.png')`,
      }}
    >
      <div className="stars">
        <div
          onTransitionEnd={() => wheelModel.spinAnimationIsFinished()}
          className={classNames('sectors', isSpinning && 'is-spinning')}
          style={{
            transform: `rotate(${currentDeg - 1}deg)`,
            backgroundImage: `url('/img/${skinId}/wheel-of-fortune/sectors.png')`,
          }}
        >
          <div id="sectors__content">
            {prizeList.map((prize, index) => (
              <PrizeSection
                prize={prize}
                key={`${currentMultiplier} ${prize.id}`}
                idx={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface PrizeSectionProps {
  prize: FortunePrize;
  idx: number;
}

const PrizeSection: React.FC<PrizeSectionProps> = ({ prize, idx }) => {
  const currentPrizeId = useStore(wheelModel.$currentPrizeId);

  return (
    <div
      className={classNames(
        `item${idx + 1} item`,
        currentPrizeId === prize.id && 'current-prize',
      )}
      key={prize.id}
    >
      <div className="content">
        <SectionTitle prize={prize} />
        <SectionContent prize={prize} />
      </div>
    </div>
  );
};

const SectionTitle = ({ prize }: { prize: FortunePrize }) => {
  const { type, bonus_action } = prize;
  const { currencyNumber } = useFormaters();
  const { t } = useTranslation();

  const rewardGiftspinsCount = React.useMemo(
    () => bonus_action?.reward_giftspins?.spins,
    [bonus_action],
  );
  const rewardSum = React.useMemo(() => bonus_action?.reward_sum, [
    bonus_action,
  ]);

  const rewardPercent = React.useMemo(() => bonus_action?.reward_percent, [
    bonus_action,
  ]);

  const hasPrize = Boolean(rewardSum || rewardPercent || rewardGiftspinsCount);

  const title = React.useMemo(() => {
    if (type === 'NONE') {
      return t(`wof:prizeTitles.NONE`);
    }

    if (type === 'AGAIN') {
      return t('wof:prizeTitles.AGAIN');
    }

    const reward =
      rewardGiftspinsCount ||
      (rewardSum && currencyNumber(rewardSum, { minimumFractionDigits: 0 })) ||
      (rewardPercent && `${rewardPercent}%`) ||
      '';

    return reward.toString();
  }, [type, bonus_action, t]);

  return (
    <div
      className={classNames('content__header', hasPrize && 'has-prize')}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

const SectionContent = ({ prize }: { prize: FortunePrize }) => {
  const { t } = useTranslation();

  const { bonus_action, type } = prize;

  const rewardGiftspinsCount = React.useMemo(
    () => bonus_action?.reward_giftspins?.spins,
    [bonus_action],
  );

  const sectionPrize = React.useMemo(() => {
    const isBonusAction = type === 'BONUS_ACTION';

    const bonusActionText = `<span className="deposit-text">${t(
      'wof:depositText',
    )}</span>`;

    if (rewardGiftspinsCount) {
      return `${t('wof:freespinsIn')} <br> ${
        bonus_action?.reward_giftspins?.game_name
      }${isBonusAction ? `<br>${bonusActionText}` : ''}`;
    }

    const result: string[] = [];

    const wager = bonus_action?.wager;

    if (isBonusAction) {
      result.push(`${bonusActionText}<br>`);
    }

    if (wager) {
      result.push(`${t('wof:withWager')} ${wager}`);
    }

    return result.join(' ');
  }, [prize, t]);

  return (
    <div
      className="content__text"
      dangerouslySetInnerHTML={{ __html: sectionPrize }}
    />
  );
};

const SpinsAreOverModal = () => {
  const { t } = useTranslation();
  const isOpen = useStore(wheelModel.$spinsOverModalIsOpen);

  return isOpen ? (
    <SecondaryInfoModal
      onModalClose={() => wheelModel.setSpinsOverModalIsOpen(false)}
      text={t('wof:spinsLeftModal.text')}
      title={t('wof:spinsLeftModal.title')}
    />
  ) : null;
};

const SpinButton = () => {
  const { t } = useTranslation();
  const disabled = useStore(wheelModel.$spinButtonIsDisabled);

  return (
    <div className="spin-btn-wrapper">
      <SpinsAreOverModal />
      <Button
        disabled={disabled}
        onClick={() => wheelModel.spinButtonClicked()}
      >
        {t('wof:buttons.spin')}
      </Button>
    </div>
  );
};
