import {
  combine,
  createStore,
  createEffect,
  createEvent,
  forward,
  guard,
  restore,
  sample,
  split,
} from 'effector';
import { condition, debug } from 'patronum';

import {
  DepositTypes,
  EnumBonusModalsContent,
  EnumEventType,
  GameModes,
} from 'lib/constants';
import { bonusApi } from 'api/bonus';
import { paths } from 'pages/paths';
import { authFormModel } from 'features/auth';
import { sessionModel } from 'features/session';
import { navigationModel } from 'features/navigation';
import { deviceModel } from 'features/device';

import { welcomeBonusProgramsModel } from '../bonus-welcome';
import { activeBonusModel } from '../bonus-active';
import { sequenceBonusesModel } from '../bonus-sequence';
import { reloadBonusesModel } from '../bonus-reload';
import { availableBonusesModel } from '../bonus-available';
import { guestBonusesModel } from '../bonus-guest';
import { $activeBonus } from '../bonus-active/model';
import { giftspinsModel } from '../giftspins';

import * as modalModel from './modal-model';
import { userModel } from 'features/user';
import { cashbackBonusModel } from '../bonus-cashback';
import { bonusTaskModel } from '../bonus-task-programs';
import { completedBonusesModel } from '../bonus-completed';
import { WheelOfFortuneApi } from 'api/wheel-of-fortune';
import React from 'react';

/**
 * Активный купон для депозита
 */

export const setCouponCode = createEvent<string>();
export const $couponCode = restore(setCouponCode, '');

/**
 * Загрузка и вывод бонусов
 */
export const bonusesMounted = createEvent();
export const bonusesUnmounted = createEvent();

export const playerBonusesRequested = createEvent();

export const $isMounted = createStore(false)
  .on(bonusesMounted, () => true)
  .on(bonusesUnmounted, () => false);

export const $allPlayerBonuses = combine(
  activeBonusModel.$activeBonus,
  sequenceBonusesModel.$sequenceBonuses,
  reloadBonusesModel.$reloadBonuses,
  welcomeBonusProgramsModel.$welcomeBonusPrograms,
  availableBonusesModel.$allAvailableBonuses,
  giftspinsModel.$playerGiftSpins,
  cashbackBonusModel.$cashbackBonus,
  bonusTaskModel.$bonusTaskPrograms,
  completedBonusesModel.$completedBonuses,
  (
    active,
    sequence,
    reload,
    welcome,
    available,
    giftspins,
    cashbackBonus,
    taskPrograms,
    completed,
  ) =>
    [
      active,
      ...sequence,
      ...reload,
      ...welcome,
      ...taskPrograms,
      cashbackBonus,
      ...available,
      ...giftspins,
      ...completed,
    ].filter(Boolean),
);

export const $allBonusesList = combine(
  $allPlayerBonuses,
  guestBonusesModel.$guestBonuses,
  sessionModel.$isAuthenticated,
  (playerBonuses, guestBonuses, isAuthenticated) =>
    isAuthenticated ? playerBonuses : guestBonuses,
);

export const $bonusesIsLoading = combine(
  activeBonusModel.loadActiveBonusFx.pending,
  sequenceBonusesModel.loadSequenceBonusesFx.pending,
  reloadBonusesModel.loadReloadBonusesFx.pending,
  availableBonusesModel.loadAvailableBonusesFx.pending,
  bonusTaskModel.loadStartedTaskProgramsFx.pending,
  availableBonusesModel.loadWofSpinsFx.pending,
  guestBonusesModel.loadGuestBonusesFx.pending,
  completedBonusesModel.loadCompletedBonusesFx.pending,
  (...args) => args.some((pending) => pending),
);

condition({
  source: bonusesMounted,
  if: sessionModel.$isAuthenticated,
  then: playerBonusesRequested,
  else: guestBonusesModel.loadGuestBonusesFx,
});

condition({
  source: guard(sessionModel.$isAuthenticated, { filter: $isMounted }),
  if: (isAuth) => isAuth,
  then: playerBonusesRequested,
  else: guestBonusesModel.loadGuestBonusesFx,
});

guard({
  source: deviceModel.$deviceType,
  filter: combine($isMounted, sessionModel.$isAuthenticated, (...data) =>
    data.every(Boolean),
  ),
  target: giftspinsModel.loadPlayerGiftspinsFx,
});

forward({
  from: playerBonusesRequested,
  to: [
    activeBonusModel.loadActiveBonusFx,
    sequenceBonusesModel.loadSequenceBonusesFx,
    reloadBonusesModel.loadReloadBonusesFx,
    bonusTaskModel.loadStartedTaskProgramsFx,
    availableBonusesModel.loadAvailableBonusesFx,
    availableBonusesModel.loadWofSpinsFx,
    welcomeBonusProgramsModel.loadWelcomeBonusProgramsFx,
    giftspinsModel.loadPlayerGiftspinsFx,
    cashbackBonusModel.loadCashbackBonusFx,
    completedBonusesModel.loadCompletedBonusesFx,
  ],
});

/**
 * Активация бонуса
 */
export const bonusActivated = createEvent<PlayerBonus>();

export const activatePlayerBonusFx = createEffect<
  number,
  PlayerBonus,
  ApiError
>(bonusApi.activateBonus);

sample({
  source: bonusActivated,
  target: activatePlayerBonusFx,
  fn: (bonus) => bonus.id,
});

forward({
  from: activatePlayerBonusFx.doneData,
  to: [
    availableBonusesModel.deleteBonus,
    userModel.loadUserBalanceFx.prepend(() => {}),
  ],
});

sample({
  source: activatePlayerBonusFx.doneData,
  target: $activeBonus,
  fn: (bonus) => {
    if (bonus.reward_giftspins) {
      bonus.reward_giftspins.spins_left = bonus.reward_giftspins.spins;
    }
    return bonus;
  },
});

sample({
  source: activatePlayerBonusFx.doneData,
  target: modalModel.$modal,
  fn: () => ({
    isOpen: true,
    content: EnumBonusModalsContent.ActivateDone,
  }),
});

/**
 * Клик по карточке гифтспина
 */
export const giftSpinClicked = createEvent<GiftSpin>();

sample({
  source: giftSpinClicked,
  target: navigationModel.historyReplace,
  fn: ({ provider_code, game_code }) =>
    paths.game(GameModes.Real, provider_code, game_code),
});

/**
 * Клик на кнопку карточки КФ
 */
export const wofSpinButtonClicked = createEvent<WofSpin>();

sample({
  clock: wofSpinButtonClicked,
  target: navigationModel.historyReplace,
  fn: paths.wheelGame,
});

/**
 * Клик на кнопку бонуса
 */
export const playerBonusClicked = createEvent<PlayerBonus>();

condition({
  source: playerBonusClicked,
  if: (bonus) => !!bonus.reward_giftspins,
  then: giftSpinClicked.prepend<PlayerBonus>(
    (bonus) => bonus.reward_giftspins as GiftSpin,
  ),
  else: condition<PlayerBonus>({
    if: (bonus) => bonus?.games?.length !== 0,
    then: navigationModel.historyReplace.prepend<PlayerBonus>((bonus) => {
      // @ts-ignore
      const wageringGame = bonus.games[0] as GameInfoInBonus;

      return paths.game(
        GameModes.Real,
        wageringGame.provider_code,
        wageringGame.code,
      );
    }),
    else: navigationModel.historyReplace.prepend(() => paths.category('all')),
  }),
});

/**
 * клик по бонусной программе
 */
export const bonusProgramClicked = createEvent<BonusProgram>();

const getBonusProgramAction = createEvent<BonusProgram>();

export const welcomeBonusProgramClicked = createEvent<BonusProgram>();
export const depositBonusProgramClicked = createEvent<BonusProgram>();

const activateWelcomeBonusProgramFx = createEffect<
  number,
  { task_id: number; giftspins: GiftSpin; type: string },
  ApiError
>(bonusApi.activateWelcomeBonusProgramTask);

condition({
  source: bonusProgramClicked,
  if: sessionModel.$isAuthenticated,
  then: getBonusProgramAction,
  else: authFormModel.loginFormOpened.prepend(() => {}),
});

split({
  source: getBonusProgramAction,
  match: {
    welcome: (bonus) => bonus.event_type === 'TASK_ACCOMPLISHED',
    deposit: (bonus) => isDepositBonusProgram(bonus),
  },
  cases: {
    welcome: welcomeBonusProgramClicked,
    deposit: depositBonusProgramClicked,
  },
});

sample({
  source: depositBonusProgramClicked,
  target: navigationModel.historyReplace,
  fn: () => paths.deposit(),
});

sample({
  source: depositBonusProgramClicked,
  target: setCouponCode,
  fn: (program) => program?.coupon || '',
});

sample({
  source: welcomeBonusProgramClicked,
  target: activateWelcomeBonusProgramFx,
  fn: ({ task }) => task.task_id,
});

sample({
  source: activateWelcomeBonusProgramFx.doneData,
  target: giftSpinClicked,
  fn: ({ giftspins }) => giftspins,
});

sample({
  source: activateWelcomeBonusProgramFx.doneData,
  fn: ({ task_id }) => task_id,
  target: welcomeBonusProgramsModel.deleteWelcomeBonusFromListByTaskId,
});

/**
 * Отмена бонуса
 */
export const setShowModalAfterCancel = createEvent<boolean>();
export const $showModalAfterCancel = restore(setShowModalAfterCancel, true);

export const bonusCanceled = createEvent<PlayerBonus>();

export const cancelPlayerBonusFx = createEffect<number, PlayerBonus, ApiError>(
  bonusApi.cancelBonus,
);

sample({
  source: bonusCanceled,
  target: cancelPlayerBonusFx,
  fn: (bonus) => bonus.id,
});

forward({
  from: cancelPlayerBonusFx.doneData,
  to: [
    availableBonusesModel.deleteBonus,
    activeBonusModel.deleteBonus,
    userModel.loadUserBalanceFx.prepend(() => {}),
    completedBonusesModel.loadCompletedBonusesFx.prepend(() => {}),
  ],
});

sample({
  source: guard(cancelPlayerBonusFx.doneData, {
    filter: $showModalAfterCancel,
  }),
  target: modalModel.$modal,
  fn: () => ({
    isOpen: true,
    content: EnumBonusModalsContent.CancelDone,
  }),
});

/**
 * Отмена спинов колеса фортуны
 */
export const cancelWofSpins = createEvent<void>();

export const cancelWofSpinsFx = createEffect(
  WheelOfFortuneApi.deleteAllWofSpins,
);

forward({
  from: cancelWofSpins,
  to: cancelWofSpinsFx,
});

availableBonusesModel.$wofSpins.reset(cancelWofSpinsFx.doneData);

sample({
  source: guard(cancelWofSpinsFx.doneData, {
    filter: $showModalAfterCancel,
  }),
  target: modalModel.$modal,
  fn: () => ({
    isOpen: true,
    content: EnumBonusModalsContent.WofSpinsCancelDone,
  }),
});

//  functions
export function isPlayerBonus(bonus: Bonus | WofSpin): bonus is PlayerBonus {
  return (
    (bonus as PlayerBonus).state !== undefined &&
    (bonus as PlayerBonus).payoff_type !== undefined
  );
}

export function isBonusProgramm(bonus: Bonus | WofSpin): bonus is BonusProgram {
  return !isPlayerBonus(bonus);
}

export function isWofSpin(bonus): bonus is WofSpin {
  return (bonus as WofSpin).prize !== undefined;
}

export function isGiftSpin(bonus): bonus is GiftSpin {
  return (bonus as GiftSpin).game_code !== undefined;
}

export function isDepositBonusProgram(bonus) {
  return Object.values(DepositTypes).includes(bonus.event_type);
}

export function isReloadBonusProgram(bonus: BonusProgram): boolean {
  return bonus.days_of_week.length !== 0;
}

export function isWeeklyCashbackBonusProgram(bonus: BonusProgram): boolean {
  return bonus.event_type === EnumEventType.WEEKLY_CASHBACK;
}

export function isWelcomeBonus(bonus): bonus is BonusProgram {
  return Boolean((bonus as BonusProgram).task?.task_id);
}

export function isBonusTaskProgram(bonus): bonus is BonusProgramTask {
  return Boolean((bonus as BonusProgramTask).giftspins);
}

export const isBirthdayBonus = ({ event_type }: PlayerBonus) =>
  event_type === 'BIRTHDAY';

export const bonusHasTwoRewards = (bonus: PlayerBonus) =>
  bonus.reward_giftspins &&
  (bonus.reward_amount ||
    bonus.reward_percent?.amount ||
    bonus.reward_percent?.percent);
