import React from 'react';
import { useStore } from 'effector-react';

import { IconSprite } from 'ui';
import { useLockBodyScroll, useOnClickOutside } from 'lib/hooks';

import { RegisterForm } from './register-form';
import { LoginForm } from './login-form';
import { SocialRegisterForm } from './social-register-form';

import * as model from '../model/form-model';
import './style.css';

export const AuthForm = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isOpen = useStore(model.$formIsOpen);
  const isLoginFormOpen = useStore(model.$isLoginFormOpen);
  const registerFormIsOpen = useStore(model.$registerFormIsOpen);
  const currentType = useStore(model.$formType);

  useLockBodyScroll(isOpen);
  useOnClickOutside({
    ref,
    handler: model.formClosed,
    ignorList: ['.popup-mask'],
    disabled: !isOpen,
  });

  return isOpen ? (
    <div className="mask active auth-mask">
      <div ref={ref} className="modal modal_signin active">
        <button
          className="modal__close"
          onClick={() => model.formClosed()}
          type="button"
        >
          <IconSprite name="frame-close" />
        </button>
        {isLoginFormOpen && <LoginForm />}
        {registerFormIsOpen &&
          (currentType === model.FormTypes.SOCIAL ? (
            <SocialRegisterForm />
          ) : (
            <RegisterForm />
          ))}
      </div>
    </div>
  ) : null;
};
