import React from 'react';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, IconSprite, NavLink } from 'ui';
import { SearchButtonMobile } from 'features/search';
import { deviceModel } from 'features/device';
import { authFormModel } from 'features/auth';
import { paths } from 'pages/paths';

import './style.css';
import { $session } from 'features/session/model';

export const PagesNav: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isAuth = !!useStore($session);
  const deviceType = useStore(deviceModel.$deviceType);
  const formIsOpened = useStore(authFormModel.$formIsOpen);

  const isMobile = deviceType === 'MOBILE';

  const needToHidePagesNav =
    pathname === paths.successfulPayment() ||
    pathname === paths.failedPayment();

  if (needToHidePagesNav || !isMobile) {
    return null;
  }

  return (
    <div className={classNames('pages-nav', formIsOpened && 'pd0')}>
      {isAuth ? (
        <nav className="nav">
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                active={paths.home() === pathname}
                to={paths.home()}
              >
                <IconSprite name="reg-one" />
                <span className="nav__text">{t('common:menu.home')}</span>
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                active={pathname.includes(paths.lk())}
                to={paths.lk()}
              >
                <IconSprite name="reg-two" />
                <span className="nav__text">{t('common:menu.profile')}</span>
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                active={paths.category('all') === pathname}
                to={paths.category('all')}
              >
                <IconSprite name="reg-three" />
                <span className="nav__text">{t('common:menu.games')}</span>
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                active={paths.presents() === pathname}
                to={paths.presents()}
              >
                <IconSprite name="reg-four" />
                <span className="nav__text">{t('common:menu.presents')}</span>
              </NavLink>
            </li>
            <li className="nav__item">
              <SearchButtonMobile />
            </li>
          </ul>
        </nav>
      ) : formIsOpened ? null : (
        <Button
          onClick={() => authFormModel.registerFormOpened()}
          theme="secondary"
        >
          {t('common:buttons.signup')}
        </Button>
      )}
    </div>
  );
});
