import { useStore } from 'effector-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionTitle,
  Article,
  ArticleInfo,
  Container,
  Section,
  SectionTitle,
} from 'ui';
import { paths } from 'pages/paths';
import { skinModel } from 'features/skin-id';
import { languageModel } from 'features/i18';

import * as model from './model';

export const FAQPage = () => {
  const { t } = useTranslation('');

  const clubName = useStore(languageModel.$clubName);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({});
  }, []);

  return (
    <>
      <Section className="faq" type="info">
        <Container padding={false} small>
          <SectionTitle>{t('faq:title')}</SectionTitle>
          <Article violet>
            <ArticleInfo>
              {/* MAIN */}
              <AccordionTitle>{t(`faq:main.title`)}</AccordionTitle>
              <Accordion head={t(`faq:main.questions.whyNeedToRegister.title`)}>
                {t(`faq:main.questions.whyNeedToRegister.text`, {
                  clubName,
                })}
              </Accordion>
              <Accordion head={t(`faq:main.questions.howToRegister.title`)}>
                {t(`faq:main.questions.howToRegister.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:main.questions.whyProvideMyPersonalData.title`)}
              >
                {t(`faq:main.questions.whyProvideMyPersonalData.text`)}
              </Accordion>
              <Accordion head={t(`faq:main.questions.whyEnterPassport.title`)}>
                {t(`faq:main.questions.whyEnterPassport.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:main.questions.whereIsMyAccountNumber.title`)}
              >
                {t(`faq:main.questions.whereIsMyAccountNumber.text`)}
              </Accordion>
              <Accordion
                head={t(
                  `faq:main.questions.doesRegistrationHaveAnyObligations.title`,
                )}
              >
                <Trans i18nKey="faq:main.questions.doesRegistrationHaveAnyObligations.text">
                  text <Link to={paths.terms()}>payments</Link>
                </Trans>
              </Accordion>
              <Accordion
                head={t(`faq:main.questions.howChangeMyPersonalData.title`)}
              >
                {t(`faq:main.questions.howChangeMyPersonalData.text`)}
              </Accordion>
              <Accordion head={t(`faq:main.questions.forgotPassword.title`)}>
                {t(`faq:main.questions.forgotPassword.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:main.questions.canSomeoneAccessMyAccount.title`)}
              >
                {t(`faq:main.questions.canSomeoneAccessMyAccount.text`)}
              </Accordion>
              <Accordion
                head={t(
                  `faq:main.questions.anotherPersonFoundOutMyPassword.title`,
                )}
              >
                {t(`faq:main.questions.anotherPersonFoundOutMyPassword.text`)}
              </Accordion>

              {/*PAYMENTS*/}
              <AccordionTitle>{t(`faq:payments.title`)}</AccordionTitle>
              <Accordion
                head={t(`faq:payments.questions.howToDeposit.title`, {
                  clubName,
                })}
              >
                {t(`faq:payments.questions.howToDeposit.text`)}
              </Accordion>
              <Accordion head={t(`faq:payments.questions.shouldDeposit.title`)}>
                {t(`faq:payments.questions.shouldDeposit.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:payments.questions.depositMethods.title`)}
              >
                {t(`faq:payments.questions.depositMethods.text`)}
              </Accordion>
              <Accordion head={t(`faq:payments.questions.howFast.title`)}>
                {t(`faq:payments.questions.howFast.text`)}
              </Accordion>
              <Accordion head={t(`faq:payments.questions.howSafe.title`)}>
                {t(`faq:payments.questions.howSafe.text`)}
              </Accordion>
              <Accordion head={t(`faq:payments.questions.howToWithdraw.title`)}>
                <Trans i18nKey="faq:payments.questions.howToWithdraw.text">
                  text <Link to={paths.terms()}>terms</Link>
                </Trans>
              </Accordion>
              <Accordion head={t(`faq:payments.questions.howToTrack.title`)}>
                {t(`faq:payments.questions.howToTrack.text`)}
              </Accordion>
              <Accordion head={t(`faq:payments.questions.commission.title`)}>
                {t(`faq:payments.questions.commission.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:payments.questions.notMyNameAccount.title`)}
              >
                {t(`faq:payments.questions.notMyNameAccount.text`)}
              </Accordion>

              {/* GAMES */}
              <AccordionTitle>{t(`faq:games.title`)}</AccordionTitle>
              <Accordion head={t(`faq:games.questions.lastGames.title`)}>
                {t(`faq:games.questions.lastGames.text`)}
              </Accordion>
              <Accordion head={t(`faq:games.questions.whatGames.title`)}>
                {t(`faq:games.questions.whatGames.text`, {
                  clubName,
                })}
              </Accordion>
              <Accordion head={t(`faq:games.questions.canPlayOnMobile.title`)}>
                {t(`faq:games.questions.canPlayOnMobile.text`)}
              </Accordion>
              <Accordion head={t(`faq:games.questions.connectionLost.title`)}>
                {t(`faq:games.questions.connectionLost.text`)}
              </Accordion>
              <Accordion head={t(`faq:games.questions.honestyGuarantee.title`)}>
                {t(`faq:games.questions.honestyGuarantee.text`)}
              </Accordion>
              <Accordion head={t(`faq:games.questions.macProblems.title`)}>
                {t(`faq:games.questions.macProblems.text`)}
              </Accordion>
              <Accordion head={t(`faq:games.questions.blackScreen.title`)}>
                {t(`faq:games.questions.blackScreen.text`)}
              </Accordion>

              {/* BONUSES */}
              <AccordionTitle>{t(`faq:bonuses.title`)}</AccordionTitle>
              <Accordion
                head={t(`faq:bonuses.questions.registrationBonus.title`)}
              >
                <Trans i18nKey="faq:bonuses.questions.registrationBonus.text">
                  text <Link to={paths.presents()}>presents</Link>text
                </Trans>
              </Accordion>
              <Accordion head={t(`faq:bonuses.questions.rules.title`)}>
                <Trans i18nKey="faq:bonuses.questions.rules.text">
                  text <Link to={paths.bonusConditions()}></Link> text
                </Trans>
              </Accordion>
              <Accordion head={t(`faq:bonuses.questions.depositBonus.title`)}>
                {t(`faq:bonuses.questions.depositBonus.text`)}
              </Accordion>
              <Accordion head={t(`faq:bonuses.questions.welcomeBonus.title`)}>
                {t(`faq:bonuses.questions.welcomeBonus.text`)}
              </Accordion>
              <Accordion
                head={t(`faq:bonuses.questions.withdrawMoneyFromBonus.title`)}
              >
                {t(`faq:bonuses.questions.withdrawMoneyFromBonus.text`)}
              </Accordion>
            </ArticleInfo>
          </Article>
        </Container>
      </Section>
    </>
  );
};
