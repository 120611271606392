import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { paths } from 'pages/paths';
import {
  DropMenu,
  DropMenuList,
  DropMenuListItem,
  DropMenuNav,
  IconSprite,
  NavLink,
} from 'ui';
import { SelectLanguage } from 'features/i18';
import { chatModel } from 'features/chat';
import { sessionModel } from 'features/session';

import { HeaderLogoutButton } from './header-logout-button';

export const HeaderNavMenu = ({
  isMobile,
  navListCount = 0,
}: {
  isMobile?: boolean;
  navListCount?: number;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const linksList = React.useMemo(() => getNavList(t), [t]);

  const { burgerList, navList } = getLists(
    linksList,
    isMobile ? 0 : navListCount,
  );

  return (
    <>
      <HeaderBurgerMenu
        list={burgerList}
        isMobile={isMobile}
        pathname={pathname}
      />
      {!isMobile && <NavList pathname={pathname} navList={navList} />}
    </>
  );
};

export const HeaderBurgerMenu = ({ list, isMobile, pathname }) => (
  <DropMenu>
    <FirstSecondBurgerNavList list={list} pathname={pathname} />
    <SecondBurgerNavList pathname={pathname} />
    <HeaderBurgerNav isMobile={isMobile} />
  </DropMenu>
);

const FirstSecondBurgerNavList = ({ list, pathname }) =>
  list?.length ? (
    <DropMenuList>
      {list.map(({ path, value, icon }, idx) => (
        <DropMenuListItem
          key={idx}
          value={value}
          to={path}
          icon={icon}
          active={pathname === path}
        />
      ))}
    </DropMenuList>
  ) : null;

const SecondBurgerNavList = ({ pathname }) => {
  const { t } = useTranslation();
  const isAuth = useStore(sessionModel.$isAuthenticated);

  const burgerNavList = React.useMemo(() => getBurgerNavList(t, isAuth), [
    t,
    isAuth,
  ]);

  return (
    <DropMenuList>
      {burgerNavList.map(({ path, value, icon }, idx) => (
        <DropMenuListItem
          key={idx}
          value={value}
          to={path}
          icon={icon}
          active={pathname === path}
        />
      ))}
    </DropMenuList>
  );
};

const HeaderBurgerNav = ({ isMobile }) => {
  const { t } = useTranslation();
  return (
    <DropMenuNav>
      <div className="main-form">
        <SelectLanguage />
      </div>
      <button
        onClick={() => chatModel.chatOpened()}
        className="burger-menu__link-help"
      >
        {t('common:menu.help')}
      </button>
      {isMobile && <HeaderLogoutButton withText />}
    </DropMenuNav>
  );
};

const NavList = ({ navList, pathname }) => (
  <nav className="nav nav_lk">
    <ul className="nav__list">
      {navList.map(({ icon, path, value }, idx) => (
        <li key={idx} className="nav__item">
          <IconSprite
            name={icon}
            className={classNames(icon === 'games-nav-icon' && 'games-icon')}
          />
          <NavLink active={pathname === path} to={path}>
            {value}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
);

const getNavList = (t): any[] => [
  {
    icon: 'present-icon',
    path: paths.promotions(),
    value: t('common:menu.stocks'),
  },
  {
    icon: 'games-nav-icon',
    value: t('common:menu.games'),
    path: paths.category('all'),
  },
  {
    icon: 'tour-nav-icon',
    path: paths.tournaments(),
    value: t('common:menu.tournaments'),
  },
  {
    icon: 'payments-icon',
    path: paths.payments(),
    value: t('common:menu.payments'),
  },
];

const getBurgerNavList = (t, isAuth) => {
  const list = [
    {
      value: t('common:menu.lottery'),
      path: paths.lotteries(),
      icon: 'lottery-icon',
    },
    {
      value: t('common:menu.loyalty'),
      path: paths.loyaltyProgram(),
      icon: 'menu-three',
    },
    {
      value: t('common:menu.presents'),
      path: paths.presents(),
      icon: 'menu-four',
    },
  ];

  if (isAuth) {
    list.push({
      value: t('common:menu.wheel-of-fortune'),
      path: paths.wheelGame(),
      icon: 'menu-wof',
    });
  }

  return list;
};

const getLists = (list: any[], count: number) => {
  const navList = list.slice(0, count);
  const burgerList = list.slice(count, list.length);
  return { navList, burgerList };
};
