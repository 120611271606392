import * as React from 'react';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { NavLink, Slider } from 'ui';
import { paths } from 'pages/paths';
import { i18nModel } from 'features/i18/i18n';

import * as model from '../model';

// const HALL_RANK = 1;

export const HallsNamesSlider = React.forwardRef(() => {
  // const hallNames = useStore(model.$hallsNamesByRank);

  // const renderedHall = hallNames[HALL_RANK] || [];

  const [needReinit, setNeedReinit] = React.useState(false);

  const renderedHall = useStore(model.$allHallsExceptZeroRank);

  const slides = React.useMemo(
    () =>
      renderedHall.map((hall, idx) => (
        <HallItem
          onImgLoaded={() => setNeedReinit(true)}
          hall={hall}
          key={idx}
        />
      )),
    [renderedHall],
  );

  return (
    <div className={classNames('partner-slider')}>
      <div className="border-top" />
      {renderedHall.length ? (
        <Slider
          withButtons
          dragFree
          slidesToScroll={1}
          align="center"
          needReinit={needReinit}
          onReinit={() => setNeedReinit(false)}
          slides={slides}
        />
      ) : null}
    </div>
  );
});

const HallItem = ({ hall, onImgLoaded }) => {
  const { category } = useParams<{ category: string }>();
  const i18nNames = useStore(i18nModel.resourses.gameMenu);
  const [hasImage, setHasImage] = React.useState(false);

  const handleLoad = () => {
    setHasImage(true);
    onImgLoaded();
  };

  return (
    <NavLink active={category === hall} to={paths.category(hall)}>
      <img
        style={{ display: hasImage ? 'inherit' : 'none' }}
        src={`/img/halls/${hall.toLowerCase()}.svg`}
        onLoad={handleLoad}
      />
      {!hasImage && (i18nNames[hall] || hall)}
    </NavLink>
  );
};
