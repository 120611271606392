import { statisticApi } from 'api/statistic';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { condition, debounce } from 'patronum';

import { deviceModel } from 'features/device';
import { $allGames, hallModel, loadAllGames } from 'features/halls';
import { navigationModel } from 'features/navigation';

/* Поиск */
export const searchTriggered = createEvent<string>();
export const searchCleared = createEvent();
export const showSearch = createEvent<void>();
export const hideSearch = createEvent<void>();

export const $searchResult = createStore<Game[]>([]).reset(searchCleared);
export const $searchQuery = createStore<string>('').reset(searchCleared);
export const $isQueryEmpty = $searchQuery.map((query) => query.length === 0);

sample({
  source: $searchQuery,
  clock: guard({
    source: $allGames,
    filter: $searchQuery.map((query) => query.length !== 0),
  }),
  target: $searchResult,
  fn: (query, allGames) => findGames(allGames, query),
});

sample({
  source: $allGames,
  clock: searchTriggered,
  target: $searchResult,
  fn: (allGames, query) => findGames(allGames, query),
});

condition({
  source: searchTriggered,
  if: $isQueryEmpty,
  then: hideSearch,
  else: showSearch,
});

forward({
  from: searchTriggered,
  to: $searchQuery,
});

searchTriggered.watch((query) => {
  if (query.length > 0) {
    navigationModel.historyReplace(`?search=${query}`);
  } else {
    navigationModel.clearParam('search');
  }
});

sample({
  source: searchCleared,
  target: navigationModel.clearParam,
  fn: () => 'search',
});

export const formOpened = createEvent<void>();
export const formClosed = createEvent<void>();
export const formToggled = createEvent<void>();

export const debouncedToggle = debounce<void>({
  source: formToggled,
  timeout: 200,
});

export const $isFormOpen = createStore<boolean>(false).on(
  debouncedToggle,
  (state) => !state,
);

export const $isSearchActive = createStore<boolean>(false)
  .on(showSearch, () => true)
  .on(hideSearch, () => false);

forward({
  from: $isFormOpen,
  to: loadAllGames,
});

condition({
  source: deviceModel.$deviceType,
  if: $isFormOpen,
  then: loadAllGames,
});

function findGames(games: Game[], query: string): Game[] {
  return games.filter(
    (game) =>
      game.name.toLowerCase().includes(query.toLowerCase()) ||
      game.tags?.toLowerCase().includes(query.toLowerCase()),
  );
}

/* Статистика */
const SEARCH_DELAY = 3000;

export const gameClicked = createEvent<{ provider: string; code: string }>();

const abort = createEvent();
const reset = createEvent();

const sendStatFx = createEffect<{ query: string; result_count: number }, any>(
  statisticApi.sendSearchData,
);
const sendStatClickFx = createEffect<number, any>(statisticApi.sendClick);

const abortableDelay = createEffect({
  handler: () =>
    new Promise<void>((resolve, reject) => {
      const id = setTimeout(() => {
        unwatch();
        resolve();
      }, SEARCH_DELAY);
      const unwatch = abort.watch(() => {
        unwatch();
        clearTimeout(id);
        reject(new Error('AbortError'));
      });
    }),
});

const $stat = combine({
  query: $searchQuery,
  result_count: $searchResult.map((games) => games.length),
}).reset(reset);

const $statId = restore(sendStatFx.doneData, null).reset(reset);

const $beforeTimerEnded = createStore(false)
  .on(showSearch, () => true)
  .on(abortableDelay.done, () => false)
  .reset(reset);

export const $isStatActive = createStore(false)
  .on(showSearch, () => true)
  .on(sendStatClickFx, () => false)
  .reset(reset);

// отправка первой части статистики
guard({
  source: searchTriggered,
  filter: $isQueryEmpty.map((is) => !is),
  target: abortableDelay,
});

sample({
  source: $stat,
  clock: abortableDelay.done,
  target: sendStatFx,
});

// отправка второй части статистики после клика (после 3х секунд)
sample({
  source: $statId,
  clock: guard(gameClicked, { filter: $statId.map((id) => Boolean(id)) }),
  target: sendStatClickFx,
});

// отправка всей статистики после клика (до 3х секунд)
sample({
  source: $stat,
  clock: guard(gameClicked, { filter: $beforeTimerEnded }),
  target: sendStatFx,
});

guard({
  source: sendStatFx.doneData,
  filter: $beforeTimerEnded,
  target: sendStatClickFx,
});

// После отправки статистики открываем игру
sample({
  source: gameClicked,
  clock: sendStatClickFx,
  target: hallModel.gameClicked,
});

// сброс таймера
forward({
  from: [searchCleared, gameClicked],
  to: abort,
});

// сброс статистики
forward({
  from: [sendStatClickFx.done, sendStatClickFx.fail, hideSearch],
  to: reset,
});
