import React from 'react';
import classNames from 'classnames';

import { Container } from 'ui';
import { useMedia } from 'lib/media';
import { OpenRegisterFormButton } from 'features/auth';

import {
  HeaderLogo,
  HeaderLoginButton,
  HeaderBalance,
  HeaderLogoutButton,
  HeaderLkLink,
  HeaderDepositLink,
  HeaderNavMenu,
} from '../molecules';

interface Props {
  isAuth: boolean;
  fixed?: boolean;
}

export const DesktopHeader = ({ isAuth, fixed }: Props) => {
  const navListCount = useMedia(
    ['(min-width: 1096px)', '(min-width: 768px)'],
    [4, 2],
    0,
  );

  return (
    <header className={classNames('header', fixed && 'fixed')}>
      <Container>
        <div className="header__left">
          <HeaderNavMenu navListCount={navListCount} />
        </div>
        <HeaderLogo />
        <div className="header__right">
          {isAuth ? (
            <>
              <HeaderLkLink />
              <HeaderBalance />
              <HeaderDepositLink />
              <HeaderLogoutButton />
            </>
          ) : (
            <div className="header__nav">
              <OpenRegisterFormButton />
              <HeaderLoginButton />
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};
