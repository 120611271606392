import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import i18n from 'i18next';

import {
  findCaptchaError,
  getApiErrorsArray,
  joinErrorsToString,
} from 'lib/error/formatApiError';
import { isValidCaptcha } from 'lib/validators';
import { captchaApi } from 'api/captcha';
import { errorModel } from 'features/error-boundary';
import { condition } from 'patronum';

export function createCaptcha(type: CaptchaType, field?: string) {
  const checkIsCaptchaRequiredFx = createEffect<null, boolean, ApiError>(() => {
    return captchaApi.checkIsCaptchaRequired(type);
  });
  const getCaptchaFx = createEffect(captchaApi.getCaptcha);

  const setRequired = createEvent<boolean>();
  const captchaChanged = createEvent<string>();
  const setCaptchaError = createEvent<string>();
  const resetCaptcha = createEvent<void>();
  const setApiCaptchaError = createEvent<string>();
  const refreshCaptcha = createEvent<void>();
  const setCaptchaInvalid = createEvent<string>();
  const checkCaptchaApiError = createEvent<ApiError>();
  const $captchaData = restore(getCaptchaFx.doneData, {
    image: '',
    task_id: '',
  }).reset(resetCaptcha);
  const $captchaSolution = restore(captchaChanged, '').reset([
    resetCaptcha,
    refreshCaptcha,
  ]);
  const $captchaError = restore(setCaptchaError, '').reset(resetCaptcha);
  const $isCaptchaRequired = createStore(false);
  const $isCaptchaFieldChanged = createStore(false)
    .on(captchaChanged, () => true)
    .reset(resetCaptcha);

  const $apiError = restore(setApiCaptchaError, '')
    .reset(captchaChanged)
    .reset(resetCaptcha);

  const $shownError = combine(
    $apiError,
    $captchaError,
    (apiError, error) =>
      (apiError
        ? i18n.t(`common:captcha.errors.${apiError}`)
        : error) as string,
  );

  const $isCaptchaValid = combine(
    $isCaptchaRequired,
    $isCaptchaFieldChanged,
    $captchaError,
    (isRequired, isChanged, error) => {
      if (!isRequired) {
        return true;
      }

      return isChanged && !Boolean(error);
    },
  );

  const $captcha = combine(
    $isCaptchaRequired,
    $captchaData,
    $captchaSolution,
    (isRequired, captchaData, solution) => {
      if (isRequired) {
        return {
          solution,
          task_id: captchaData.task_id,
        };
      }

      return {
        solution: '',
        task_id: '',
      };
    },
  );

  const checkRequired = sample({
    source: checkIsCaptchaRequiredFx.doneData,
    target: setRequired,
    fn: (isRequired) => {
      return field ? isRequired[field] : isRequired;
    },
  });

  condition({
    source: checkIsCaptchaRequiredFx.doneData,
    if: () => Boolean(field),
    then: checkRequired,
    else: setRequired,
  });

  forward({
    from: setRequired,
    to: $isCaptchaRequired,
  });

  guard({
    source: sample({
      source: checkCaptchaApiError,
      fn: (errors) => findCaptchaError(errors),
    }),
    filter: (errorText) => !!errorText,
    target: setApiCaptchaError,
  });

  guard({
    source: $isCaptchaRequired,
    filter: (is) => is,
    target: getCaptchaFx,
  });

  sample({
    source: captchaChanged,
    target: setCaptchaError,
    fn: (value) => isValidCaptcha(value),
  });

  forward({
    from: [resetCaptcha, refreshCaptcha],
    to: getCaptchaFx,
  });

  sample({
    source: setCaptchaInvalid,
    target: [setCaptchaError, refreshCaptcha],
  });

  sample({
    source: setApiCaptchaError,
    target: refreshCaptcha,
  });

  getCaptchaFx.fail.watch((data) => {
    let err = (data.error as unknown) as ApiError;
    const errText = joinErrorsToString(getApiErrorsArray(err));
    errorModel.createErrorNotify(errText);
  });

  return {
    $captcha,
    $apiError,
    $captchaData,
    checkCaptchaApiError,
    $isCaptchaRequired,
    $captchaSolution,
    $captchaError,
    $isCaptchaFieldChanged,
    $isCaptchaValid,
    checkIsCaptchaRequiredFx,
    getCaptchaFx,
    $shownError,
    captchaChanged,
    setCaptchaError,
    resetCaptcha,
    refreshCaptcha,
    setCaptchaInvalid,
    setApiCaptchaError,
  };
}
