import React from 'react';
import { useStore } from 'effector-react';
import { Redirect, useLocation, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Link } from 'react-router-dom';
import classNames from 'classnames';

import { AccountContentTemplate, Container, OffCanvas, Section } from 'ui';
import { UserBalance, UserInfo } from 'features/user';
import { deviceModel } from 'features/device';
import { paths } from 'pages/paths';

import { ProfilePage } from './profile/page';
import { WithdrawPage } from './withdraw/page';
import { DepositPage } from './deposit/page';
import { HistoryPage } from './history/page';
import { MyBonusPage } from './bonus/page';

import './style.css';

export const LkPage: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isMobile = useStore(deviceModel.$isMobile);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LkTemplate
      isMobile={isMobile}
      title={t(`lk:nav.${pathname.split('/lk/')[1]}`)}
    >
      <Switch>
        <Route path={paths.profile()} component={ProfilePage} exact />
        <Route path={paths.lkBonus()} component={MyBonusPage} exact />
        <Route path={paths.history()} component={HistoryPage} exact />
        <Route path={paths.withdraw()} component={WithdrawPage} exact />
        <Route path={paths.deposit()} component={DepositPage} exact />
        <Route path={paths.lk()}>
          {!isMobile && <Redirect to={paths.profile()} />}
        </Route>
        <Route path="*">
          <Redirect to={paths.lk()} />
        </Route>
      </Switch>
    </LkTemplate>
  );
};

interface Props {
  title: string;
  children: React.ReactNode;
  isMobile: boolean;
}

const LkTemplate = ({ title, children, isMobile }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const [isClosing, setisClosing] = React.useState(false);

  return (
    <>
      <Section type="account">
        <Container padding={false}>
          <div className={classNames('account', isMobile && 'mob')}>
            <div className="account__user">
              <UserInfo />
              <div className="balance-wrap">
                <UserBalance />
              </div>
            </div>
            <MenuProfile />
          </div>
          {isMobile ? (
            location.pathname === paths.lk() ? null : (
              <OffCanvas
                onAnimationEnd={() => {
                  setisClosing(false);
                  history.push(paths.lk());
                }}
                isRendered={!isClosing}
              >
                <AccountContentTemplate
                  onCloseClick={() => setisClosing(true)}
                  isMobile={isMobile}
                  title={title}
                >
                  {children}
                </AccountContentTemplate>
              </OffCanvas>
            )
          ) : (
            <AccountContentTemplate isMobile={isMobile} title={title}>
              {children}
            </AccountContentTemplate>
          )}
        </Container>
      </Section>
    </>
  );
};

const MenuProfile = React.memo(() => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const menuProfileList = [
    { label: t('lk:nav.bonus'), url: paths.lkBonus() },
    { label: t('lk:nav.profile'), url: paths.profile() },
    { label: t('lk:nav.deposit'), url: paths.deposit() },
    { label: t('lk:nav.withdraw'), url: paths.withdraw() },
    { label: t('lk:nav.history'), url: paths.history() },
  ];

  return (
    <nav className="account-nav">
      <ul className="account-nav__list">
        {menuProfileList.map((menuItem, index) => (
          <li className="account-nav__item" key={index}>
            <Link
              className={classNames(
                'account-nav__link',
                pathname === menuItem.url && 'active',
              )}
              to={menuItem.url}
            >
              {menuItem.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
});
