import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
  sample,
} from 'effector';
import axios from 'axios';

import { documentsApi } from 'api/documents';
import { DocTypes } from 'lib/constants';
import { toBase64 } from 'lib/files';

export const BYTE_IN_MEGABYTE = 1048576;
export const AVAILABLE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

export const pageLoaded = createEvent();
export const filesAdded = createEvent<File[]>();
export const validateFiles = createEvent<File[]>();
export const fileRemoved = createEvent<string>();
export const filesRemoved = createEvent<string[]>(); // TODO: временно
export const deleteUploadedFile = createEvent<number>();
export const fileDeleted = createEvent<number>();
export const docTypeChanged = createEvent<DocTypes>();
export const uploadSubmitted = createEvent();
export const setUploadingFile = createEvent<UploadingFile>();
export const setUploadingFileArray = createEvent<UploadingFile[]>();
export const uploadingFileDeleted = createEvent<UploadingFile>();

const getFilesFx = createEffect<any, FileListResponse>(async () =>
  documentsApi.getFiles(),
);
const deleteFileFx = createEffect<number, boolean>((id) =>
  documentsApi.deleteFile(id),
);
const getConstraintsFx = createEffect<null, FileContraints>(() =>
  documentsApi.getConstraints(),
);
const convertDataFx = createEffect<File[], UserFile[]>(async (files) => {
  const convertedFiles: UserFile[] = [];

  for (let i = 0; i < files.length; i++) {
    const file = await convertFile(files[i]);
    convertedFiles.push(file);
  }

  return convertedFiles;
});
const uploadFilesFx = createEffect<UserFile[], any>(async (files) => {
  let requestsArray: Promise<UserFile>[] = [];

  files.forEach((file) => {
    const cancelTokenSource = axios.CancelToken.source();
    let newFile = {
      name: file.name,
      progress: 0,
      cancel: () => cancelTokenSource.cancel(),
    };
    requestsArray.push(
      documentsApi.uploadFile(file, {
        cancelToken: cancelTokenSource.token,
        onUploadProgress: (progressEvent) => {
          setUploadingFile({
            name: file.name,
            cancel: () => {
              uploadingFileDeleted(newFile);
              cancelTokenSource.cancel();
            },
            progress: Math.ceil(
              (progressEvent.loaded / progressEvent.total) * 100,
            ),
          });
        },
      }),
    );
  });

  return Promise.allSettled(requestsArray);
});

export const $uploadedFiles = restore(
  getFilesFx.doneData.map((data) => data.docs || []),
  [],
).on(fileDeleted, (state, id) => state.filter((file) => file.id !== id));

export const $filesForUpload = createStore<File[]>([])
  .on(filesAdded, (state, files) => state.concat(files))
  .on(fileRemoved, (state, name) => state.filter((file) => file.name !== name))
  .on(filesRemoved, (state, names) => {
    return state.filter((file) => !names.includes(file.name));
  });
export const $filesInUploading = createStore<UploadingFile[]>([])
  .on(setUploadingFile, (store, value) => {
    let filtredArray = store.filter((el) => el.name !== value.name);
    filtredArray.push(value);
    return filtredArray;
  })
  .on(uploadingFileDeleted, (store, value) => {
    return store.filter((el) => el.name !== value.name);
  });

forward({ from: setUploadingFileArray, to: $filesInUploading });

export const $confirmedFiles = $uploadedFiles.map((docs) =>
  docs.filter((doc) => doc.confirmed),
);

export const $constraints = createStore<FileContraints>({
  available_upload_count: 10,
  max_upload_count: 10,
  max_upload_size: 10 * BYTE_IN_MEGABYTE,
})
  .on(getConstraintsFx.doneData, (_, payload) => payload)
  .on(filesAdded, (state, files) => ({
    ...state,
    available_upload_count: Number(state.available_upload_count) - files.length,
  }))
  .on(fileRemoved, (state) => ({
    ...state,
    available_upload_count: Number(state.available_upload_count) + 1,
  }));

export const $docType = createStore(DocTypes.Passport).on(
  docTypeChanged,
  (_, type) => type,
);

export const $uploadEnabled = $filesForUpload.map((files) => files.length > 0);

forward({
  from: pageLoaded,
  to: [getFilesFx, getConstraintsFx],
});

sample({
  source: combine({
    currentFiles: $filesForUpload,
    constraints: $constraints,
  }),
  clock: validateFiles,
  target: filesAdded,
  fn: ({ currentFiles, constraints }, files) => {
    if (files.length > constraints.available_upload_count) {
      const arr = files.slice(0, Number(constraints.available_upload_count));
      return checkFiles(
        arr,
        AVAILABLE_TYPES,
        Number(constraints.max_upload_size),
        currentFiles,
      );
    }

    return checkFiles(
      files,
      AVAILABLE_TYPES,
      Number(constraints.max_upload_size),
      currentFiles,
    );
  },
});

sample({
  source: $filesForUpload,
  clock: uploadSubmitted,
  target: convertDataFx,
  fn: (files) => files,
});

sample({
  source: convertDataFx.doneData,
  target: uploadFilesFx,
});

sample({
  source: uploadFilesFx.doneData,
  target: filesRemoved,
  fn: (files) => {
    let filesForRemove = files.filter((el) => el.status === 'fulfilled');
    return filesForRemove.map(({ value }) => value.name);
  },
});

forward({
  from: uploadFilesFx.doneData,
  to: getFilesFx,
});

forward({
  from: deleteUploadedFile,
  to: deleteFileFx,
});

sample({
  source: deleteUploadedFile,
  clock: deleteFileFx.doneData,
  target: fileDeleted,
});

function checkFiles(
  filesList: File[],
  allowedTypes: string[],
  maxSize: number,
  currentFiles: File[],
): File[] {
  let files: File[] = [];

  for (let i = 0; i < filesList.length; i++) {
    const file = filesList[i];

    if (file.size >= maxSize) {
      continue;
    }

    if (!allowedTypes.includes(file.type)) {
      continue;
    }

    if (currentFiles.includes(file)) {
      continue;
    }

    files.push(file);
  }

  return files;
}

async function convertFile(file: File): Promise<UserFile> {
  return {
    content: (await toBase64(file)).split(',')[1],
    content_type: file.type,
    name: file.name,
  };
}
