import i18n from 'i18next';
import { Form } from 'effector-forms/dist/factory';
import i18next from 'i18next';
import { createEvent, createStore, forward, guard } from 'effector';

export const getApiErrorsArray = (error: ApiError) => {
  return Object.entries(error.items);
};
export const joinErrorsToString = (arr: [string, any][]) => {
  return arr
    .map((el) => el.join('-'))
    .flat()
    .join(';');
};

export const getApiErrorsText = (data) => {
  let err = (data.error as unknown) as ApiError;
  return joinErrorsToString(getApiErrorsArray(err));
};

export const setErrorsToForm = (
  type: string,
  err: anyObj,
  form: Form<any>,
  rename,
) => {
  let unknownErrors: ApiError = { items: {}, empty: false };
  for (let key in err) {
    if (err.hasOwnProperty(key)) {
      const text = i18n.t(`${type}.${key}`);
      if (rename.hasOwnProperty(err[key])) {
        form.fields[rename[err[key]]].addError({
          rule: err[key],
          errorText: text,
        });
      } else {
        if (err.hasOwnProperty(key)) {
          form.fields[err[key]].addError({
            rule: err[key],
            errorText: text,
          });
        } else {
          unknownErrors.items[key] = err[key];
        }
      }
    }
  }
  return unknownErrors;
};

export const createErrorObject = (error: ApiError): Record<string, string> => {
  if (error?.empty === true) {
    return {};
  }
  const errors = {};

  Object.entries(error.items).forEach((error) => {
    const field = error[1];
    const errorDescription = camelCase(error[0]);

    if (errors[field]) {
      return;
    }

    return (errors[field] = errorDescription);
  });

  return errors;
};

export const findCaptchaError = (errors: ApiError): string => {
  if (errors.empty) return '';
  const captchaError = Object.entries(errors.items).find(
    ([errorMessage, field]) => field === 'captcha',
  );
  return Array.isArray(captchaError) ? camelCase(captchaError[0]) : '';
};

export const sortObjectArrayByFields = (
  errors: Record<string, string>,
  fields: string[],
) =>
  Object.entries(errors)
    .filter(
      ([fieldName]) =>
        fields.findIndex((sortField) => sortField === fieldName) !== -1,
    )
    .reduce((acc, [fieldName, errorText]) => {
      acc[fieldName] = errorText;
      return acc;
    }, {});

export const hasErrorsIsObject = (errors: ApiError, fields: string[]) =>
  Object.keys(createErrorObject(errors)).some(
    (error) => fields.findIndex((fieldError) => fieldError === error) !== -1,
  );

export const createSortedErrorObject = (errors, fields) => {
  return sortObjectArrayByFields(createErrorObject(errors), fields);
};

export const setApiErrorsToForm = (
  error: ApiError,
  form: Form<any>,
  i18nkey = 'common:validators',
) => {
  if (error.empty === true) return;

  const modifiedErrors = createErrorObject(error);

  Object.entries(form.fields).forEach(([fieldName, field]) => {
    if (modifiedErrors.hasOwnProperty(fieldName)) {
      field.addError({
        rule: modifiedErrors[fieldName],
        errorText: i18next.t(
          `${i18nkey}.${camelCase(modifiedErrors[fieldName])}`,
        ),
      });
    }
  });
};

function camelCase(str) {
  return str
    .split('.')
    .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
}

export const createCommonErrorModal = (ingoredFields: string[]) => {
  const closeModal = createEvent();
  const openModal = createEvent();

  const setError = createEvent<ApiError>();
  const clearApiError = createEvent();
  const $apiError = createStore<ApiError | null>(null)
    .on(setError, (_, apiError) => apiError)
    .reset(clearApiError);

  const $modalIsOpen = createStore(false)
    .on(openModal, () => true)
    .reset(closeModal);

  guard({
    source: guard($apiError, { filter: (error) => error !== null }),
    filter: (error) => !hasErrorsIsObject(error as ApiError, ingoredFields),
    target: openModal,
  });

  forward({
    from: closeModal,
    to: clearApiError,
  });

  return {
    $modalIsOpen,
    closeModal,
    openModal,
    setError,
    clearApiError,
    $apiError,
  };
};
