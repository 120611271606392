import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkButton } from 'ui';
import { paths } from 'pages/paths';

export const HeaderDepositLink = () => {
  const { t } = useTranslation();
  return (
    <LinkButton to={paths.deposit()} theme="secondary">
      {t('common:buttons.deposit')}
    </LinkButton>
  );
};
