import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { useStore } from 'effector-react';

import { Article, ArticleInfo, Container, Section, SectionTitle } from 'ui';

import * as model from './model';

import './style.css';

export const TermsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const { t } = useTranslation();
  const { text } = useStore(model.$pageResource);

  return (
    <>
      <Section type="info">
        <Container padding={false} small>
          <SectionTitle>{t('terms:title')}</SectionTitle>
          <Article violet className="terms-article">
            <ArticleInfo className="terms__wrap">
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
              />
            </ArticleInfo>
          </Article>
        </Container>
      </Section>
    </>
  );
};
