import React from 'react';
import { useStore } from 'effector-react';
import { Trans } from 'react-i18next';
import * as model from '../model';

export const BonusInfo = () => {
  const isShowInfo = useStore(model.$needShowBonusInfo);

  return isShowInfo ? (
    <div className="bonus-info">
      <Trans i18nKey="lk:deposit.bonusInfo">
        Доступен бонус 100% + 50 freespins <br /> при пополнении от 500₽
      </Trans>
    </div>
  ) : null;
};
