import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  restore,
  sample,
} from 'effector';
import { condition, some } from 'patronum';
import { createGate } from 'effector-react';

import { WheelOfFortuneApi } from 'api/wheel-of-fortune';
import { errorModel } from 'features/error-boundary';
import { getApiErrorsText } from 'lib/error-handler';
import { availableBonusesModel } from 'features/bonus/bonus-available';
import { depositModel } from 'features/cashbox';
import { userModel } from 'features/user';
import { sessionModel } from 'features/session';
import { authFormModel } from 'features/auth';
import { bonusModel } from 'features/bonus';

import { BuySpinErrors } from '../constants';

const ROUND_DEG = 360;
const DEG_PER_SECTOR = 45;
const SPIN_TIMES = ROUND_DEG * 5;

export const pageGate = createGate();

export const spinButtonClicked = createEvent();

const buySpinAndGetPrize = createEvent();
const spinAvailableSpin = createEvent();
const respinBoughtSpin = createEvent();

const deleteSpinFromAvailable = createEvent<{ id: number }>();
const spinScrolledSuccesfully = createEvent<WofSpin>();

export const spinAnimationIsFinished = createEvent();
const setIsSpinning = createEvent<boolean>();
export const $isSpinning = restore(setIsSpinning, false);

export const setCurrentDeg = createEvent<number>();
export const $currentDeg = restore(setCurrentDeg, 0);

/**
 * Effects
 */
export const getFortuneSectionsFx = createEffect<void, FortuneSection[]>(
  WheelOfFortuneApi.getFortuneSectionList,
);
export const getSpinPrizeFx = createEffect<number, WofSpin, ApiError>(
  WheelOfFortuneApi.getPrize,
);
export const buySpinPrizeFx = createEffect<
  { multiplier: number },
  WofSpin,
  ApiError
>(({ multiplier }) => WheelOfFortuneApi.buySpinAndGetPrize(multiplier));

const $boughtSpin = createStore<null | WofSpin>(null);

export const $boughtSpinHasAgainPrizeType = $boughtSpin.map(
  (spin) => spin?.prize?.type === 'AGAIN',
);

export const $currentPrizeId = createStore<null | number>(null);

export const $sections = createStore<FortuneSection[]>([]);

export const setCurrentMultiplier = createEvent<number>();
export const $currentMultiplier = restore(setCurrentMultiplier, 0);

export const $spinIsAvailable = combine(
  $currentMultiplier,
  availableBonusesModel.$spinsByMupltiplierRecord,
  (multiplier, record) => record[multiplier]?.length > 0,
);

const $multiplierNotSelected = $currentMultiplier.map(
  (multiplier) => !multiplier,
);

export const $currentSection = combine(
  $sections,
  $currentMultiplier,
  (sections, currentMultiplier) =>
    sections.find((section) => section.multiplier === currentMultiplier) ||
    null,
);

export const $prizeList = $currentSection.map(
  (section) => section?.prizes || [],
);

const $usedSpinId = createStore(0);

const $isLastFreeSpin = combine(
  availableBonusesModel.$spinsByMupltiplierRecord,
  $currentMultiplier,
  (record, multiplier) => record[multiplier]?.length === 1,
);

export const setSpinsOverModalIsOpen = createEvent<boolean>();
export const $spinsOverModalIsOpen = restore(setSpinsOverModalIsOpen, false);

export const $spinButtonIsDisabled = some({
  stores: [
    getFortuneSectionsFx.pending,
    getSpinPrizeFx.pending,
    buySpinPrizeFx.pending,
    $isSpinning,
  ],
  predicate: true,
});

sample({
  clock: bonusModel.wofSpinButtonClicked,
  target: setCurrentMultiplier,
  fn: (spin) => spin.multiplier,
});

forward({
  from: pageGate.open,
  to: [getFortuneSectionsFx, availableBonusesModel.loadWofSpinsFx],
});

$sections.on(getFortuneSectionsFx.doneData, (_, sections) =>
  sections.sort((a, b) => a.multiplier - b.multiplier),
);

sample({
  clock: guard({
    clock: getFortuneSectionsFx.doneData,
    filter: $multiplierNotSelected,
  }),
  target: setCurrentMultiplier,
  fn: (sections) => sections[0].multiplier,
});

$currentDeg.reset(setCurrentMultiplier);

sample({
  clock: guard($currentSection, { filter: Boolean }),
  target: $currentPrizeId,
  fn: (section) => section.prizes[0].id,
});

sample({
  source: getFortuneSectionsFx.fail,
  target: errorModel.createErrorNotify,
  fn: getApiErrorsText,
});

const checkFreeSpins = createEvent();

condition({
  source: spinButtonClicked,
  if: $boughtSpinHasAgainPrizeType,
  then: respinBoughtSpin,
  else: checkFreeSpins,
});

condition({
  source: checkFreeSpins,
  if: $spinIsAvailable,
  then: spinAvailableSpin,
  else: buySpinAndGetPrize,
});

sample({
  clock: respinBoughtSpin,
  source: $boughtSpin,
  target: getSpinPrizeFx,
  fn: (spin) => spin!.id,
});

$boughtSpin.reset(getSpinPrizeFx, buySpinPrizeFx);

sample({
  source: combine([
    availableBonusesModel.$spinsByMupltiplierRecord,
    $currentMultiplier,
  ]),
  clock: spinAvailableSpin,
  target: getSpinPrizeFx,
  fn: ([spinsRecord, multiplier]) => spinsRecord[multiplier][0].id,
});

sample({
  clock: buySpinAndGetPrize,
  source: $currentMultiplier,
  target: buySpinPrizeFx,
  fn: (multiplier) => ({ multiplier }),
});

sample({
  clock: guard({
    source: getSpinPrizeFx.doneData,
    filter: ({ prize }) => prize?.type !== 'AGAIN',
  }),
  target: $usedSpinId,
  fn: ({ id }) => id,
});

sample({
  clock: [buySpinPrizeFx.doneData, getSpinPrizeFx.doneData],
  target: $boughtSpin,
});

forward({
  from: [getSpinPrizeFx.doneData, buySpinPrizeFx.doneData],
  to: spinScrolledSuccesfully,
});

$currentPrizeId.on(spinScrolledSuccesfully, (_, { prize }) => prize!.id);

sample({
  source: spinScrolledSuccesfully,
  target: setIsSpinning,
  fn: () => true,
});

sample({
  source: combine([$currentDeg, $prizeList]),
  clock: spinScrolledSuccesfully,
  target: setCurrentDeg,
  fn: ([currentDeg, prizes], scrolledSpin) => {
    const currentSectorDeg = getCurrentSectorDeg(currentDeg);

    const expDeg = getDegForSector({
      prizes,
      currentDeg,
      prize: scrolledSpin.prize!,
    });

    const degToFoolRound =
      -currentSectorDeg - Math.trunc(-currentSectorDeg / ROUND_DEG) * ROUND_DEG;

    return SPIN_TIMES + -(-currentSectorDeg - expDeg - degToFoolRound);
  },
});

sample({
  source: getSpinPrizeFx.fail,
  target: errorModel.createErrorNotify,
  fn: (data) => getApiErrorsText(data),
});

sample({
  source: spinAnimationIsFinished,
  target: setIsSpinning,
  fn: () => false,
});

$currentDeg.on(spinAnimationIsFinished, (deg) => deg % ROUND_DEG);

forward({
  from: spinAnimationIsFinished,
  to: userModel.loadUserBalanceFx,
});

sample({
  source: $usedSpinId,
  clock: guard(spinAnimationIsFinished, {
    filter: $usedSpinId.map(Boolean),
  }),
  target: deleteSpinFromAvailable,
  fn: (id) => ({ id }),
});

sample({
  clock: guard({
    source: deleteSpinFromAvailable,
    filter: $isLastFreeSpin,
  }),
  target: setSpinsOverModalIsOpen,
  fn: () => true,
});

sample({
  clock: deleteSpinFromAvailable,
  source: availableBonusesModel.$wofSpins,
  target: availableBonusesModel.$wofSpins,
  fn: (spins, { id }) => spins.filter((spin) => spin.id !== id),
});

sample({
  clock: deleteSpinFromAvailable,
  target: $usedSpinId,
  fn: () => 0,
});

guard({
  source: buySpinPrizeFx.failData,
  filter: (errors) => !!errors.items[BuySpinErrors.NO_MONEY],
  target: [depositModel.toggleDepositModal.prepend(() => true)],
});

merge([buySpinPrizeFx.failData, getSpinPrizeFx.failData]).watch((error) => {
  if (
    error.items[BuySpinErrors.UNAVAILABLE] ||
    error.items[BuySpinErrors.USED] ||
    error.items[BuySpinErrors.EXPIRED]
  ) {
    const currentPath = `${window.location.pathname}${window.location.search}`;
    window.location.replace(`${currentPath}`);
  }
});

$isSpinning.reset(pageGate.close);
$currentDeg.reset(pageGate.close);
$currentMultiplier.reset(pageGate.close);
$boughtSpin.reset(pageGate.close);

// help functions
function getCurrentSectorDeg(degNow) {
  return -ROUND_DEG - (degNow - Math.trunc(degNow / ROUND_DEG) * ROUND_DEG);
}

function getDegForSector({
  prize,
  prizes,
  currentDeg,
}: {
  prize: FortunePrize;
  prizes: FortunePrize[];
  currentDeg: number;
}) {
  const winPrizeId = prizes.findIndex((el) => el.id === prize.id);

  if (winPrizeId >= 0) {
    return -(winPrizeId * DEG_PER_SECTOR);
  }

  return currentDeg;
}
