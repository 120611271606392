import { useStore } from 'effector-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, ResultText, ResultWrap } from 'ui';
import { GamesSection } from 'features/halls';
import { sessionHooks } from 'features/session';
import * as model from '../model';

export const FavouriteGamesSection = () => {
  const { t } = useTranslation();

  const isAuth = !!sessionHooks.useSession();
  const favoriteGames = useStore(model.$favoriteGames);
  const status = useStore(model.$loadFavoriteGamesStatus);

  const isLoading = status === 'pending';
  const totalCount = favoriteGames.length;

  if (!isAuth) {
    return (
      <Container>
        <ResultWrap>
          <ResultText bg>{t('game:hall.favourite.notAuth')}</ResultText>
        </ResultWrap>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <ResultWrap>
          {totalCount ? (
            <ResultText>
              {t('game:hall.favourite.listNotEmpty', { count: totalCount })}
            </ResultText>
          ) : (
            <ResultText>{t('game:hall.favourite.listEmpty')}</ResultText>
          )}
        </ResultWrap>
      </Container>

      <GamesSection
        gameList={favoriteGames}
        isPending={isLoading}
        showEmpty={false}
      />
    </>
  );
};
