import { httpClient } from 'lib/request';

/**
 * Получить последние выигрышы (выводы, большие выигрыши игр, турниров + альт данные если необходимо)
 */

function getAll(language: string): Promise<DailyWinnersResponse> {
  return httpClient.get(`daily-winners?locale=${language}`);
}

export const dayliWinnersApi = {
  getAll,
};
