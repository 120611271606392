import React from 'react';

import { Field } from 'ui';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatExpirationMonth,
  formatExpirationYear,
  formatHolder,
} from '../formaters';

export const CardField = ({ onChange, ...props }) => {
  return (
    <Field
      onChange={(e) => onChange(formatCreditCardNumber(e.target.value))}
      {...props}
    />
  );
};

export const HolderField = ({ onChange, ...props }) => {
  return (
    <Field
      onChange={(e) => onChange(formatHolder(e.target.value))}
      {...props}
    />
  );
};

export const ExpirationMonthField = ({ onChange, ...props }) => {
  return (
    <Field
      onChange={(e) => onChange(formatExpirationMonth(e.target.value))}
      placeholder="ММ"
      {...props}
    />
  );
};

export const ExpirationDateField = ({ onChange, ...props }) => {
  return (
    <Field
      onChange={(e) => onChange(formatExpirationDate(e.target.value))}
      placeholder="DD"
      {...props}
    />
  );
};

export const ExpirationYearField = ({ onChange, ...props }) => {
  return (
    <Field
      onChange={(e) => onChange(formatExpirationYear(e.target.value))}
      placeholder="YY"
      {...props}
    />
  );
};

export const CardCVVField = ({ value, onChange, ...props }) => {
  return (
    <Field
      {...props}
      value={value}
      onChange={(e) => onChange(formatCVC(e.target.value))}
      placeholder="CVV"
    />
  );
};
