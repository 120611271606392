import React from 'react';
import { useStore } from 'effector-react';
import { reflect } from '@effector/reflect';
import i18n from 'i18next';

import { IconSprite, Field } from 'ui';
import { trimEvent } from 'lib/events';
import './style.css';

export const Captcha = ({ captcha }: any) => {
  const isCaptchaRequired = useStore(captcha.$isCaptchaRequired);
  const { image } = useStore(captcha.$captchaData);

  React.useEffect(() => {
    captcha.checkIsCaptchaRequiredFx();

    return () => captcha.resetCaptcha();
  }, [captcha]);

  if (!isCaptchaRequired) {
    return null;
  }

  const refresh = () => {
    captcha.getCaptchaFx();
  };

  const CaptchaField = reflect({
    view: Field,
    bind: {
      value: captcha.$captchaSolution,
      onChange: captcha.captchaChanged.prepend(trimEvent),
      error: captcha.$shownError,
      placeholder: i18n.t('auth:inputs.captcha.placeholder'),
    },
  });

  return (
    <div className="captcha-block">
      <div className="captcha-block__wrap">
        <div className="captcha-block__code">
          <img src={`data:image/png;base64,${image}`} alt="captcha" />
        </div>
        <button
          className="captcha-block__update-btn"
          type="button"
          onClick={refresh}
        >
          <IconSprite name="update-icon" />
        </button>
        <CaptchaField />
      </div>
    </div>
  );
};
