import * as React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { BonusSection } from 'features/bonus';
import { deviceModel } from 'features/device';
import {
  $popularGames,
  $popularGamesStatus,
  GamesSection,
  GamesSliderSection,
} from 'features/halls';
import { JackpotSection } from 'features/jackpot';
import { LotterySection } from 'features/lotteries';
import { TournamentSection } from 'features/tournaments';
import { SearchResultDesktop } from 'features/search';
import { PaymentEmailModals } from 'features/payments-info';
import { PasswordRecoveryToast } from 'features/password-recovery';
import { Link } from 'ui';
import { Halls } from 'lib/constants';
import { useMedia } from 'lib/media';
import { paths } from 'pages/paths';

import * as model from './model';
import './style.css';

export const HomePage = () => {
  const isDesktop = useStore(deviceModel.$isDesktop);

  React.useEffect(() => {
    model.pageLoaded({ lotteryLimit: 2 });
    return () => model.pageUnloaded();
  }, [isDesktop]);

  return (
    <>
      <PasswordRecoveryToast />
      <PaymentEmailModals />
      <SearchResultDesktop urlWithSearch withLink withInfinityLoader={false} />
      <PopularGamesSection />
      <JackpotSection />
      <NewGamesSection />
      <TournamentSection />
      <BonusSection />
      <LotterySection />
      <SlotsGamesSection />
    </>
  );
};

const PopularGamesSection = () => {
  const { t } = useTranslation();
  const popularGames = useStore($popularGames);
  const popularGamesStatus = useStore($popularGamesStatus);

  const lessThan400Px = useMedia(['(max-width: 400px)'], [true], false);

  return (
    <GamesSliderSection
      className="popular-games-section main-page__game-slider"
      gameList={popularGames}
      withButton
      align={lessThan400Px ? 'center' : 'start'}
      isPending={popularGamesStatus === 'pending'}
      title={t('common:categories.popular')}
      icon={<img src="/img/icons/popular-icon.svg" alt="" />}
    />
  );
};

const NewGamesSection = () => {
  const { t } = useTranslation();

  const newGames = useStore(model.$newGames);
  const newGamesIsLoading = useStore(model.$newGamesIsLoading);

  return (
    <GamesSliderSection
      withButton
      gameList={newGames}
      isPending={newGamesIsLoading}
      title={t('common:categories.novelty')}
      className="new-games-section main-page__game-slider"
      icon={<img src="/img/icons/new-games-icon.svg" alt="" />}
    />
  );
};

const SlotsGamesSection = () => {
  const { t } = useTranslation();
  const slotsGames = useStore(model.$slotsGames);
  const slotsGamesIsLoading = useStore(model.$slotsGamesIsLoading);

  return (
    <GamesSection
      withInfinityLoader={false}
      gameList={slotsGames}
      isPending={slotsGamesIsLoading}
      withScroll={false}
      showEmpty={false}
      withLink
      redirectUrl={Halls.Slots}
      onMoreGamesButtonClick={() => model.moreGamesButtonClicked()}
      navChildren={
        <div onClick={() => model.moreGamesButtonClicked()}>
          <Link to={paths.category(Halls.Slots)} border theme="primary">
            {t('common:buttons.more-games-v2')}
          </Link>
        </div>
      }
    />
  );
};
