import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { BlockTitle, Container, Section } from 'ui';
import { LotteryList } from './lottery-list';
import * as model from '../model';

export const LotterySection = () => {
  const { t } = useTranslation();

  const hasError = useStore(model.$hasError);
  const listLength = useStore(model.$totalRenderSize);

  if (hasError || listLength === 0) {
    return null;
  }

  return (
    <Section type="game" className="lottery-section">
      <Container>
        <BlockTitle
          icon={<img src="/img/icons/star-lottery-icon.svg" alt="" />}
          title={t('common:menu.lottery')}
        />
        <LotteryList />
      </Container>
    </Section>
  );
};
