import React, { useEffect, useMemo, useRef } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { reflect } from '@effector/reflect';

import { useFormaters } from 'features/i18';
import { Button, Field, IconSprite, MainForm, MainFormRow, Toast } from 'ui';
import { CardInfoForm, PaymentGroupSelect, SubmitPaymentButton } from '.';
import { PaymentDataField } from './payment-data-field';
import { BonusInfo } from './bonus-info';
import { deleteCardFieldsFromFields } from '../helpers';

import './style.css';
import * as model from '../model';
import { isInViewport } from 'lib/is-in-viewport';
import { deviceModel } from 'features/device';

import platform from 'platform';

export const DepositContent = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageMounted();

    return () => model.pageUnmounted();
  }, []);

  return (
    <>
      <Subtitle />
      <DepositForms />
      <DepositErrorModal />
    </>
  );
};

const Subtitle = () => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();
  const currentBonus = useStore(model.$currentBonusProgramForDeposit);

  const sumReward = useMemo(() => {
    if (currentBonus) {
      const { reward_percent, reward_sum } = currentBonus;
      if (reward_percent) {
        return `${reward_percent}%`;
      }

      if (reward_sum) {
        return currencyNumber(reward_sum);
      }
    }

    return '';
  }, [currentBonus?.reward_percent, currentBonus?.reward_sum]);

  if (!currentBonus) {
    return null;
  }

  const { reward_giftspins, dep_number } = currentBonus;

  return (
    <div className="acc-content__bonus-wrap">
      <span className="acc-content__bonus-name">
        {dep_number === 1
          ? t('lk:deposit.subtitle.firstDeposit')
          : t('lk:deposit.subtitle.deposit')}{' '}
      </span>
      <span className="acc-content__bonus-percent">
        {sumReward}
        {!!reward_giftspins &&
          `${sumReward && ` ${t('common:and')} `} ${
            reward_giftspins?.spins
          } ${t('lk:deposit.subtitle.freespins')}`}
      </span>
    </div>
  );
};

// Специфичные для страницы компоненты
const DepositForms = () => {
  const { t } = useTranslation();
  const depositPayload = useStore(model.$depositTransactionData);
  const showFrame = useStore(model.$showFrame);
  const showWindow = useStore(model.$showWindow);

  const needToMountForm = depositPayload?.data_type === 'FORM';

  const cardFormIsOpen = useStore(model.$cardFormIsOpened);

  const isMobile = useStore(deviceModel.$isMobile);

  const isSafari = platform.name === 'Safari';
  const browserVersion = parseInt(platform.version.split('.')[0]);
  const isNewVersion = browserVersion >= 15;

  if (needToMountForm) {
    return (
      <DepositFrame url={''}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(depositPayload!.data),
          }}
        />
      </DepositFrame>
    );
  }

  return (
    <>
      {cardFormIsOpen && (
        <div
          className="acc-content__back-button"
          onClick={() => model.changeCardFormIsOpened(false)}
        >
          <IconSprite name="arrow-prev" />
          <span>{t('common:buttons.back')}</span>
        </div>
      )}
      {showFrame && depositPayload ? (
        <DepositFrame
          url={depositPayload.data_type === 'POST' ? '' : depositPayload.data}
        >
          {depositPayload.data_type === 'POST' && (
            <PostTerminalForm data={depositPayload} />
          )}
        </DepositFrame>
      ) : cardFormIsOpen ? (
        <CardForm />
      ) : (
        <MainDepositForm />
      )}

      {showWindow &&
        depositPayload &&
        (isMobile && isSafari && isNewVersion ? (
          <HiddenButtonRedirect depositPayload={depositPayload} />
        ) : (
          redirectToPaysystem({
            post: depositPayload.data_type === 'POST',
            url: depositPayload.data,
          })
        ))}
    </>
  );
};

const HiddenButtonRedirect = ({ depositPayload }) => {
  const linkRef = useRef(null);

  const href =
    depositPayload.data_type === 'POST'
      ? '/deposit-proccessing'
      : depositPayload.data;

  const handle = () => {
    window.location = href;
  };

  useEffect(() => {
    if (linkRef.current) {
      // @ts-ignore
      linkRef.current.click();
    }
  }, [linkRef]);

  return (
    <button ref={linkRef} onClick={handle} style={{ display: 'none' }}></button>
  );
};

const MainDepositForm = () => {
  const { t } = useTranslation();

  const isLoaded = useStore(model.$depositSystemsIsLoaded);
  const system = useStore(model.$seletectedPaymentSystem);
  const ref = useRef<HTMLDivElement>(null);

  const scrollInToView = React.useCallback(() => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [ref]);

  React.useEffect(() => {
    if (system) {
      setImmediate(() => !isInViewport(ref.current) && scrollInToView());
    }
  }, [system, scrollInToView]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    model.mainFormSubmitted();
  };

  return (
    <>
      <PaymentGroupRow loading={!isLoaded} label={t('lk:deposit.method')} />
      <MainForm ref={ref} className="cashbox-mainform" onSubmit={handleSubmit}>
        <PaymentDataFieldsList />
        <BonusInfo />
        {isLoaded && (
          <>
            <SumField />
            <BonusCheckbox />
            <div className="main-form__present-wrap quick-btns">
              <ButtonsQuickSum onChange={model.sumChanged} />
            </div>
            <SubmitButton>{t('common:buttons.topup')}</SubmitButton>
          </>
        )}
      </MainForm>
    </>
  );
};

const PaymentDataFieldsList = () => {
  const fields = useStore(model.$dataFields);

  const needToFillCardForm = useStore(model.$needToFillCardForm);

  const filteredFields = React.useMemo(
    () => (needToFillCardForm ? deleteCardFieldsFromFields(fields) : fields),
    [fields, needToFillCardForm],
  );

  return (
    <>
      {filteredFields.map((f) => (
        <PaymentDataField
          onChange={(e) =>
            model.dataFieldChanged({
              fieldName: f.name,
              value: e.target.value,
            })
          }
          $dataFieldsErrors={model.$dataFieldsErrors}
          $dataFieldsValues={model.$dataFieldsValues}
          field={f}
          key={f.name}
        />
      ))}
    </>
  );
};

const CardForm = () => {
  const disabled = useStore(model.$submitButtonIsDisabled);
  const sum = useStore(model.$sum);
  const fields = useStore(model.$dataFields);

  return (
    <CardInfoForm
      sum={sum}
      fields={fields}
      $dataFieldsErrors={model.$dataFieldsErrors}
      $dataFieldsValues={model.$dataFieldsValues}
      onFieldChanged={model.dataFieldChanged}
      disabled={disabled}
      type="withdraw"
      onSubmit={model.formSubmitted}
    />
  );
};

const DepositErrorModal = () => {
  const { t } = useTranslation();
  const modalErrorMessage = useStore(model.$modalErrorMessage);
  return modalErrorMessage ? (
    <Toast isOpen type="error" onAnimationEnd={model.resetModalError}>
      {modalErrorMessage === 'default'
        ? t('lk:deposit.errors.defaultErrorText')
        : modalErrorMessage}
    </Toast>
  ) : null;
};

// Общие компоненты
// Список платежных систем
const PaymentGroupRow = ({ label, loading }) => {
  const paymentSystems = useStore(model.$paymentSystemsList);
  const currentPaymentSystem = useStore(model.$seletectedPaymentSystem);

  return (
    <MainFormRow
      white
      label={label}
      className="method__row"
      wrapClassName="main-form__method-wrap"
    >
      <PaymentGroupSelect
        paymentSystems={paymentSystems}
        currentPaymentSystem={currentPaymentSystem}
        isLoading={loading}
        onChange={model.changePaymentSystem}
      />
    </MainFormRow>
  );
};

const SumField = () => {
  const { t } = useTranslation();

  const limits = useStore(model.$limits);
  const sum = useStore(model.$sum);
  const sumError = useStore(model.$sumError);

  const error = React.useMemo(() => {
    if (sumError.required) return t('common:validators.required');

    if (sumError.minNumber)
      return t('common:validators.depositmin', { count: sumError.minNumber });

    if (sumError.maxNumber)
      return t('common:validators.depositmax', { count: sumError.maxNumber });

    return '';
  }, [t, sumError]);

  const { minNumber, maxNumber } = limits;

  return (
    <MainFormRow label={t('lk:deposit.sum.label')}>
      <Field
        type="number"
        placeholder={
          minNumber || maxNumber
            ? `${minNumber || 0} - ${maxNumber || `∞`}`
            : t('lk:deposit.sum.label')
        }
        error={error}
        name={'sum'}
        onChange={(e) => model.sumChanged(parseFloat(e.target.value) || 0)}
        value={sum || ''}
      />
    </MainFormRow>
  );
};

const BonusCheckbox = React.memo(() => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const sum = useStore(model.$bonusSum);
  const value = useStore(model.$needBonus);

  const bonusSum = currencyNumber(sum || 0, { minimumFractionDigits: 0 });

  return sum ? (
    <div className="main-form__present-wrap">
      <div className="main-form__present-checkbox">
        <input
          className="main-form__input-check"
          type="checkbox"
          name="#"
          id="ukn"
          onChange={() => model.changeNeedBonus(!value)}
          defaultChecked={value}
        />
        <label className="main-form__present-label" htmlFor="ukn">
          <span className="main-form__present-count">{bonusSum}</span>
          <span>{t('lk:deposit.checkbox.descr')}</span>
        </label>
      </div>
    </div>
  ) : null;
});

const QUICK_SUM = [1000, 3000, 5000];

const ButtonsQuickSum = React.memo(
  ({ onChange }: { onChange: (value: number) => void }) => {
    const { currencyNumber } = useFormaters();

    const limits = useStore(model.$limits);

    return (
      <div className="main-form__sum-wrap">
        {QUICK_SUM.filter((sum) =>
          sum >= limits.minNumber && limits.maxNumber
            ? sum <= limits.maxNumber
            : true,
        ).map((sum) => (
          <Button
            theme="deposit"
            key={`quik-${sum}`}
            onClick={() => onChange(sum)}
          >
            {currencyNumber(sum, { minimumFractionDigits: 0 })}
          </Button>
        ))}
      </div>
    );
  },
);

const SubmitButton = reflect({
  view: SubmitPaymentButton,
  bind: {
    disabled: model.$submitButtonIsDisabled,
  },
});

interface FrameProps {
  url: string;
  children: React.ReactNode;
}

export const DepositFrame = ({ url, children }: FrameProps) => (
  <iframe
    src={url}
    className="postTerminalFrame"
    name="postTerminalFrame"
    width="100%"
    height="570"
    frameBorder="0"
    style={{ backgroundColor: 'white', borderRadius: '10px' }}
    title="Депозит"
  >
    {children}
  </iframe>
);

export const PostTerminalForm = ({
  data,
}: {
  data: RegisterDepositResponse;
}) => {
  const form = React.useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    form.current.submit();
  }, []);

  return (
    <form method="post" ref={form} action={data.data}>
      {Object.keys(data.post_params).map((key) => (
        <input
          type="hidden"
          name={key}
          key={key}
          value={data.post_params[key]}
        />
      ))}
    </form>
  );
};

function redirectToPaysystem({ post, url }: { post: boolean; url: string }) {
  const href = post ? '/deposit-proccessing' : url;

  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.href = href;
  link.click();

  model.userRedirectedToPaymentSystem();
}
