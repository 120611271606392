import React from 'react';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router';

import { AuthForm, authFormModel } from 'features/auth';
import { inviteCodeModel } from 'features/bonus';
import { DepositCanvas } from 'features/cashbox';
import { fpModel } from 'features/fingerPrint';
import { sessionHooks, sessionModel } from 'features/session';
import { emailVerification, userModel } from 'features/user';
import { favouriteGamesModel } from 'features/favorite';
import { paymentsInfoModel } from 'features/payments-info';
import { passwordRecoveryModel } from 'features/password-recovery';
import { loyaltyProgramModel } from 'features/loyalty-program';
import { skinModel } from '../../skin-id';

export const CommonLoader = ({ children }) => {
  const isAuthenticated = useStore(sessionModel.$isAuthenticated);
  const isWaiting = useStore(sessionModel.$isWaitingFor);
  const skinIdIsLoaded = useStore(skinModel.stores.$skinIdIsLoaded);

  const inviteCodeParamName = useStore(inviteCodeModel.$couponParamName);

  const { search, hash } = useLocation();

  let searchParams = '';

  if (hash.includes('?')) {
    searchParams = hash.split('?')[1];
  } else {
    searchParams = search;
  }

  sessionHooks.useSessionFetch();

  React.useEffect(() => {
    authFormModel.checkLocationHash(hash);
  }, [hash]);

  /**
   * Ивенты которые должны срабатывать только один раз (менять с осторожностью)
   */
  // TODO: вынести в модель
  React.useEffect(() => {
    fpModel.getFP();
    skinModel.events.getSkinId();
    inviteCodeModel.setParams(searchParams);
    emailVerification.checkEmailParam(searchParams);
    paymentsInfoModel.checkPaymentEmailParam(searchParams);
    passwordRecoveryModel.checkForgotCodeParam(searchParams);
    authFormModel.checkSearchParams({ search: searchParams });
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      inviteCodeModel.getCouponParamNameFx();
      userModel.loadUserSummaryFx();
      userModel.loadUserFx();
      favouriteGamesModel.loadFavoriteGames();
      loyaltyProgramModel.getUserLoyaltyProgramProgress();
    } else {
      userModel.resetUser();
    }
  }, [isAuthenticated]);

  const isLoaded = !isWaiting && skinIdIsLoaded;

  return isLoaded ? (
    <>
      {children}
      {isAuthenticated ? <DepositCanvas /> : <AuthForm />}
    </>
  ) : null;
};
