import React from 'react';
import { useTranslation } from 'react-i18next';

import { skinModel } from 'features/skin-id';

import './style.css';

const IMG_BG_SRC = './img/successful-payment-bg.png';

export const SuccessfulPaymentPage = () => {
  const { t } = useTranslation();
  const isHab = skinModel.selectors.useIsHabanero();

  return (
    <div
      className="payment-info-wrap"
      style={{
        background: isHab
          ? 'initial'
          : 'radial-gradient(50% 50% at 50% 50%, #2F8A43 0%, #25133C 100%)',
      }}
    >
      <div className="payment-info-content">
        {isHab && <img src={IMG_BG_SRC} className="bg-img" />}
        <div className="message">
          <h3>{t('successful-payment:title')}</h3>
        </div>
      </div>
    </div>
  );
};
