import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useFormaters } from 'features/i18';
import { EnumEventType } from 'lib/constants';

import { isDepositBonusProgram, isPlayerBonus } from '../model';

export const BonusSubtitle: React.FC<Props> = ({
  children,
  bonus,
  withReward,
  withEvent = true,
  showRewards = { sum: true, percent: true, giftspins: true },
  className,
}) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const rewardText = React.useMemo(() => {
    if (bonus && withReward) {
      const rewardSum = isPlayerBonus(bonus)
        ? bonus.reward_amount
        : bonus.reward_sum;

      if (rewardSum && showRewards.sum) {
        return `${currencyNumber(rewardSum)} `;
      }

      const percent = isPlayerBonus(bonus)
        ? bonus.reward_percent?.percent
        : bonus.reward_percent;

      if (percent && showRewards.percent) {
        return `${percent}% `;
      }

      if (
        showRewards.giftspins &&
        bonus.reward_giftspins &&
        Object.keys(bonus.reward_giftspins).length !== 0
      ) {
        return (
          <>
            {bonus.reward_giftspins.spins}{' '}
            {t('bonus:subtitle.freeSpins', {
              count: bonus.reward_giftspins.spins,
            })}
          </>
        );
      }
    }

    return '';
  }, [bonus, withReward]);

  if (bonus) {
    return (
      <span className={classNames('bonus-card__subtitle', className)}>
        {rewardText}
        {withEvent && t(`bonus:subtitle.${getI18nSubtitleKey(bonus)}`)}
      </span>
    );
  }

  return <span className="bonus-card__subtitle">{children}</span>;
};

function getI18nSubtitleKey(bonus: PlayerBonus | BonusProgram): string {
  if (isDepositBonusProgram(bonus)) {
    // @ts-ignore
    const days = bonus.days_of_week || [];

    if (bonus.dep_number === 1) {
      return 'FIRST_DEPOSIT';
    }
    // @ts-ignore
    if (bonus.on_birthday) {
      return 'BIRTHDAY_COUPON_DEPOSIT';
    }

    if (days.length !== 0) {
      return `DEPOSIT_RELOAD_${days[0]}`;
    }

    return 'DEPOSIT';
  }

  if (
    isPlayerBonus(bonus) &&
    bonus.event_type === EnumEventType.WEEKLY_CASHBACK
  ) {
    return 'WEEKLY_CASHBACK_ACCUMULATED';
  }

  return bonus.event_type;
}

interface Props {
  children?: React.ReactNode;
  bonus?: PlayerBonus | BonusProgram;
  withReward?: boolean;
  className?: string;
  withEvent?: boolean;
  showRewards?: {
    sum: boolean;
    percent: boolean;
    giftspins: boolean;
  };
}
