import { httpClient } from 'lib/request';

// популярные игры
const getPopularList = (isMobile: boolean): Promise<Game[]> =>
  httpClient.get(`games/top?mobile=${isMobile}&period=30`);

// любимые игры
const getFavouriteList = (): Promise<Game[]> =>
  httpClient.get(`players/current/games/favorites`);

// название всех игровых залов
const getAllHallsNames = (deviceType: DeviceType): Promise<HallName[]> =>
  httpClient.get(`halls/names?type=${deviceType}`);

// получить все залы по типу
const getAllHalls = (deviceType: DeviceType): Promise<HallInfo> =>
  httpClient.get(`halls/${deviceType.toLowerCase()}`);

// получить все игры по типу
const getAllGames = (deviceType: DeviceType): Promise<Game[]> =>
  httpClient.get(`halls/${deviceType.toLowerCase()}/games`);

// игровые залы desktop
const getAllDesktopHalls = (): Promise<HallInfo> =>
  httpClient.get('halls/desktop');

// игровые залы mobile
const getAllMobileHalls = (): Promise<HallInfo> =>
  httpClient.get('halls/mobile');

// игры в залах desktop
const getAllDesktopGames = (): Promise<Game[]> =>
  httpClient.get('halls/desktop/games');

// игры в залах mobile
const getAllMobileGames = (): Promise<Game[]> =>
  httpClient.get('halls/mobile/games');

// информация о зале
const getHallInfo = (hallName: string): Promise<HallInfo> =>
  httpClient.get(`halls/${hallName}`);

export const hallApi = {
  getAllGames,
  getAllHalls,
  getAllHallsNames,
  getAllDesktopHalls,
  getAllMobileHalls,
  getAllDesktopGames,
  getAllMobileGames,
  getFavouriteList,
  getHallInfo,
  getPopularList,
};
