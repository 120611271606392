import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useKeyPress, useLockBodyScroll, useOnClickOutside } from 'lib/hooks';
import { Button, IconSprite, MainFormButton } from 'ui';

import './style.css';

interface ModalProps {
  children: React.ReactNode;
  onModalClose: () => void;
  className?: string;
  secondary?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onModalClose,
  className,
  secondary,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const modalRoot = document.getElementById('modal-root');

  useLockBodyScroll(!!modalRoot);
  useOnClickOutside({
    ref,
    handler: onModalClose,
  });

  return modalRoot
    ? ReactDOM.createPortal(
        <div className="popup-mask active">
          <div
            className={classNames(
              'popup',
              className,
              secondary && 'secondary-modal',
            )}
            ref={ref}
          >
            <button onClick={onModalClose}>
              <IconSprite name="close-btn" className="close-btn" />
            </button>
            {children}
          </div>
        </div>,
        modalRoot,
      )
    : null;
};

interface SecondaryInfoModalProps {
  onModalClose: () => void;
  className?: string;
  text: string;
  title: string;
  btnText?: string;
}

export const SecondaryInfoModal: React.FC<SecondaryInfoModalProps> = ({
  title,
  text,
  onModalClose,
  className,
  btnText = 'OK',
}) => {
  useKeyPress({ targetKey: 'Enter', handler: onModalClose });
  return (
    <Modal className={className} onModalClose={onModalClose} secondary>
      <h3 className="secondary-title">{title}</h3>
      <span className="secondary-sum">{text}</span>
      <Button onClick={onModalClose}>{btnText}</Button>
    </Modal>
  );
};

interface InfoModalProps {
  children: React.ReactNode;
  onModalClose: () => void;
  className?: string;
}

export const InfoModal: React.FC<InfoModalProps> = ({
  children,
  onModalClose,
  className,
}) => {
  useKeyPress({ targetKey: 'Enter', handler: onModalClose });
  return (
    <Modal className={className} onModalClose={onModalClose}>
      {children}
      <Button onClick={onModalClose}>Ok</Button>
    </Modal>
  );
};

interface SubmitModalProps {
  children: React.ReactNode | string;
  onRejectFn: () => void;
  onSubmitFn: () => void;
  disabledBtns?: boolean;
  className?: string;
}

export const SubmitModal: React.FC<SubmitModalProps> = ({
  onRejectFn,
  children,
  onSubmitFn,
  disabledBtns,
  className,
}) => {
  return (
    <Modal onModalClose={onRejectFn} className={className}>
      {children}
      <div className="popup__btns">
        <Button disabled={disabledBtns} onClick={onSubmitFn}>
          Да
        </Button>
        <Button disabled={disabledBtns} onClick={onRejectFn}>
          Нет
        </Button>
      </div>
    </Modal>
  );
};

interface SecondarySubmitModalProps {
  children?: React.ReactNode | string;
  title?: string;
  onRejectFn: () => void;
  onSubmitFn: () => void;
  disabledBtns?: boolean;
  className?: string;
  submitText?: string;
  rejectText?: string;
}

export const SecondaryConfirmModal: React.FC<SecondarySubmitModalProps> = ({
  onRejectFn,
  children,
  onSubmitFn,
  disabledBtns,
  className,
  submitText,
  rejectText,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onModalClose={onRejectFn} className={className} secondary>
      <h3 className="secondary-title">{title}</h3>
      {children}
      <div className="popup__btns">
        <Button disabled={disabledBtns} onClick={onSubmitFn}>
          {submitText || t('common:popup.yes')}
        </Button>
        <MainFormButton className="reject-btn" onClick={onRejectFn}>
          {rejectText || t('common:popup.close')}
        </MainFormButton>
      </div>
    </Modal>
  );
};

export const CommonErrorModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return isOpen ? (
    <InfoModal onModalClose={onClose}>
      {t('common:popup.defaultErrorText')}
    </InfoModal>
  ) : null;
};
