import React from 'react';
import { useLocation } from 'react-router';
import { useStore } from 'effector-react';

import { BlockTitle, IconSprite } from 'ui';
import { SearchResultMobile } from 'features/search';
import { deviceModel } from 'features/device';
import { sessionModel } from 'features/session';
import { useLockBodyScroll, useOnClickOutside } from 'lib/hooks';
import { Search } from './search';

import * as model from '../model';
import './style.css';

export const SearchMobile = () => {
  const { search } = useLocation();
  const searchGameMenuIsOpen = useStore(model.$isFormOpen);
  const isMobile = useStore(deviceModel.$isMobile);
  const isAuthenticated = useStore(sessionModel.$isAuthenticated);

  React.useEffect(() => {
    const initialQuery = new URLSearchParams(search).get('search') || '';
    if (initialQuery && isMobile) {
      if (isAuthenticated) {
        model.formToggled();
      } else {
        model.searchCleared();
      }
    }
  }, [isAuthenticated, isMobile]);
  // не добавлять search в список зависимостей (закрывается поиск при вводе символа)

  const searchGames = useStore(model.$searchResult);
  const searchQuery = useStore(model.$searchQuery);
  const ref = React.useRef<HTMLDivElement>(null);

  useLockBodyScroll(searchGameMenuIsOpen);
  useOnClickOutside({
    ref,
    handler: model.formToggled,
    ignorList: ['.burger'],
    disabled: !searchGameMenuIsOpen,
  });

  if (!searchGameMenuIsOpen) {
    return null;
  }

  return (
    <div id="scroll-games" ref={ref} className="search-abs active">
      <BlockTitle title="Поиск" className="search-title" />
      <div className="search-form">
        <div className="search-form__wrap">
          <Search />
          <button type="button" className="search-form__btn">
            <IconSprite name="search-btn" spriteName="common" />
          </button>
        </div>
      </div>

      <SearchResultMobile games={searchGames} query={searchQuery} />
    </div>
  );
};
