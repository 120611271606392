import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { useFormaters } from 'features/i18';
import * as model from '../model';
import './style.css';

export const DailyWinnersList = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    model.mounted();

    return () => model.unmounted();
  }, []);

  return (
    <div className="jackpot daily-winners">
      <h3 className="jackpot__name">{t('common:winners.winning-now')}</h3>
      <div className="jackpot__wrap">
        <WinnerListWrap />
      </div>
    </div>
  );
};

const WinnerListWrap = React.memo(() => {
  const dailyWinnersData = useStore(model.$renderedWinners);

  return dailyWinnersData.length !== 0 ? (
    <WinnerList list={dailyWinnersData} />
  ) : (
    <SkeletonList />
  );
});

interface WinnerItemProps {
  winner: DailyWinner;
}

const WinnerItem: React.FC<WinnerItemProps> = ({ winner }) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const { game_name, player, sum } = winner;

  return (
    <div className={'jackpot__item winner-item'}>
      {winner.images?.logo && (
        <div className="jackpot__item-img">
          <img src={winner.images?.logo} alt="" />{' '}
        </div>
      )}
      <div style={{ width: '100%' }}>
        <div className="jackpot__row">
          <div title={`${game_name}`} className="jackpot__title">
            {game_name}
          </div>
          <span className="jackpot__info">{`${currencyNumber(sum, {
            minimumFractionDigits: 0,
          })}`}</span>
        </div>
        <span className="jackpot__text">
          {player} {t('common:winners.just-now')}
        </span>
      </div>
    </div>
  );
};

const WinnerList = ({ list }: { list: DailyWinner[] }) => {
  return (
    <div className="jackpot__wrap">
      {list.map((winner, idx) => (
        <WinnerItem
          key={`${winner.player}${winner.game_name}${idx}${Date.now()}`}
          winner={winner}
        />
      ))}
    </div>
  );
};

const WinnerItemSkeleton = () => (
  <div className="jackpot__item">
    <div className="jackpot__row">
      <span className="jackpot__title skelet"></span>
      <span className="jackpot__info"></span>
    </div>
    <span className="jackpot__text"></span>
  </div>
);

const SkeletonList = () => (
  <div className="jackpot__wrap">
    {Array.from({ length: 5 }, (_, idx) => (
      <WinnerItemSkeleton key={idx} />
    ))}
  </div>
);
