import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';
import { useStore } from 'effector-react';

import {
  Button,
  Container,
  Field,
  MainForm,
  MainFormRow,
  Section,
  SectionTitle,
} from 'ui';
import { trimEvent } from 'lib/events';
import { passwordRecoveryModel } from 'features/password-recovery';
import { languageModel } from 'features/i18';

import * as model from './model';
import './style.css';

export const ConfirmNewPasswordPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const email = useStore(languageModel.resourses.contactEmail);

  return (
    <>
      <Section className="confirm-password" type="info">
        <Container>
          <SectionTitle>{t('confirm-password:title')}</SectionTitle>
          <div className="password">
            <div className="password__wrap">
              <p className="password__info">{t('change-password:info')}</p>
              <NewPasswordForm />
              <span className="password__text">
                {t('change-password:email')}
              </span>
              <a className="password__link" href={`mailto:${email}`}>
                {email}
              </a>
              {/*<span className="password__text">*/}
              {/*  {t('change-password:feedback')}*/}
              {/*</span>*/}
              {/*<div className="password__row">*/}
              {/*  <a className="password__link" href="tel:84978450375">*/}
              {/*    +84978450375,*/}
              {/*  </a>*/}
              {/*  <a className="password__link" href="tel:7580245880">*/}
              {/*    +7580245880*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

const NewPasswordForm = () => {
  const { t } = useTranslation();

  const { fields, submit, errorText, isValid, isTouched } = useForm(
    passwordRecoveryModel.$confirmPasswordForm,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  };

  return (
    <MainForm
      noBorder
      className="password-reset__email"
      onSubmit={handleSubmit}
    >
      <MainFormRow label={t('confirm-password:fields.password.label')} column>
        <Field
          placeholder={t('confirm-password:fields.password.placeholder')}
          name="password"
          type="password"
          error={errorText('password')}
          value={fields.password.value}
          onChange={fields.password.onChange.prepend(trimEvent)}
          onBlur={fields.password.onBlur.prepend(trimEvent)}
        />
      </MainFormRow>
      <MainFormRow label={t('confirm-password:fields.repassword.label')} column>
        <Field
          name="repassword"
          type="password"
          placeholder={t('confirm-password:fields.repassword.placeholder')}
          error={errorText('repassword')}
          value={fields.repassword.value}
          onChange={fields.repassword.onChange.prepend(trimEvent)}
          onBlur={fields.repassword.onBlur.prepend(trimEvent)}
        />
      </MainFormRow>
      <Button disabled={!isValid || !isTouched} type="submit">
        {t('confirm-password:btnText')}
      </Button>
    </MainForm>
  );
};
