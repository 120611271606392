import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './style.css';

export const WinnerTable: React.FC<TableProps> = ({
  title,
  children,
  footer,
  className,
  bodyId,
  id,
}) => {
  return (
    <div id={id} className={classNames('tour-table', className)}>
      <div className="tour-table__wrap">
        <h3 className="tour-table__title">{title}</h3>
        <div
          id={bodyId}
          className={classNames('tour-table__body', footer && 'withFooter')}
        >
          {children}
        </div>
        {footer && <div className="tour-table__footer">{footer}</div>}
      </div>
    </div>
  );
};

export const WinnerTableRow: React.FC<RowProps> = ({ values, head }) => {
  const width = `${100 / values.length}%`;

  return (
    <div className={classNames('tour-table__row', head && 'tour-table__head')}>
      {values.map((item, idx) => {
        if (typeof item === 'object' && !!item) {
          return (
            <span
              style={{ width }}
              key={idx}
              className={classNames('tour-table__col', item.className)}
            >
              {item.value}
            </span>
          );
        }
        return (
          <span style={{ width }} key={idx} className={'tour-table__col'}>
            {item}
          </span>
        );
      })}
    </div>
  );
};

interface RowProps {
  values: RowValue[];
  head?: boolean;
}

type RowValue = { className?: string; value: ReactNode } | string;

interface TableProps {
  title?: string;
  bodyId?: string;
  footer?: React.ReactNode;
  className?: string;
  id?: string;
}
