import { httpClient } from 'lib/request';

/**
 * Проверить необходимость каптчи
 * @param captchaType {CaptchaType} - эндпойнт для конкретного типа каптчи
 * @returns boolean
 */
function checkIsCaptchaRequired(captchaType: CaptchaType): Promise<boolean> {
  return httpClient.get(`captchas/${captchaType}`);
}

const getCaptchaRegRequirement = (): Promise<CaptchaRegRequirementResponse> =>
  httpClient.get('/captchas/registration');

const getCaptchaOtherRequirement = (): Promise<CaptchaRegRequirementResponse> =>
  httpClient.get('/captchas/other');

const getCaptcha = (): Promise<GetCaptchaResponse> =>
  httpClient.post('captchas');

export const captchaApi = {
  getCaptcha,
  checkIsCaptchaRequired,
  getCaptchaRegRequirement,
  getCaptchaOtherRequirement,
};
