import { AxiosRequestConfig } from 'axios';

import { httpClient } from 'lib/request';

/**
 * Получить список загруженных документов с детализацией
 */
function getFiles(): Promise<FileListResponse> {
  return httpClient.get('players/current/files');
}

/**
 * Получить данные загруженного файла
 */
function getFile(id: number): Promise<UserFile> {
  return httpClient.get(`players/current/files/${id}`);
}

/**
 * Получить ограничения по размеру и количеству на загрузку файлов
 */
function getConstraints(): Promise<FileContraints> {
  return httpClient.get(`players/current/files/info`);
}

/**
 * Загрузить файл документа для верификации
 */
function uploadFile(data: UserFile, config?: AxiosRequestConfig) {
  return httpClient.post('players/current/files', data, config);
}

/**
 * Удалить файл (не отмеченный как 'confirmed')
 */
function deleteFile(id: number): Promise<boolean> {
  return httpClient.delete(`players/current/files/${id}`);
}

export const documentsApi = {
  getFiles,
  getFile,
  getConstraints,
  uploadFile,
  deleteFile,
};
