import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';

import { bonusApi } from 'api/bonus';
import { bonusModel } from 'features/bonus';
import { lotteryModel } from 'features/lotteries';
import { condition, spread } from 'patronum';
import { availableBonusesModel } from 'features/bonus/bonus-available';

export const pageLoaded = createEvent<Record<string, string>>();
export const pageUnloaded = createEvent();

/**
 * PROMOCODE
 */
export const promoFormReset = createEvent();
export const promoformSubmitted = createEvent<void>();
export const promocodeChanged = createEvent<string>();

export const usePromocodeFx = createEffect<
  string,
  ActivatePromocodeResponse,
  Error
>(bonusApi.activePromocode);

export const $promoCode = restore(promocodeChanged, '').reset(promoFormReset);

export const setPromocodeIsIncorrect = createEvent<boolean>();
export const $promocodeIsIncorrect = restore(setPromocodeIsIncorrect, false)
  .reset(promocodeChanged)
  .reset(promoFormReset);

guard({
  source: sample($promoCode, promoformSubmitted),
  filter: (promocode) => !!promocode,
  target: usePromocodeFx,
});

condition({
  source: usePromocodeFx.doneData,
  if: (data) => promocodeIsCorrect(data),
  then: bonusModel.playerBonusesRequested,
});

condition({
  source: usePromocodeFx.doneData,
  if: (data) => promocodeIsCorrect(data),
  then: promoFormReset,
});

sample({
  source: guard(usePromocodeFx.doneData, {
    filter: (data) => !promocodeIsCorrect(data),
  }),
  target: setPromocodeIsIncorrect,
  fn: () => true,
});

/**
 * MODAL
 */
type Content = 'success' | 'fail' | '';

export const closeModal = createEvent();
export const setModal = createEvent<Content>();

export const $modal = createStore<{ isOpen: boolean; content: Content }>({
  isOpen: false,
  content: '',
})
  .on(setModal, (_, content) => ({ isOpen: true, content }))
  .reset(closeModal);

condition({
  source: usePromocodeFx.doneData,
  if: (data) => promocodeIsCorrect(data),
  then: setModal.prepend(() => 'success'),
});

/**
 * INIT
 */
forward({
  from: pageLoaded,
  to: [bonusModel.bonusesMounted, lotteryModel.getLotteryWithUserFx],
});

function promocodeIsCorrect(data: ActivatePromocodeResponse) {
  return !!data.bonus || (!!data.spins && !!data.spins.length);
}
