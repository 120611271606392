import * as React from 'react';
import { useParams } from 'react-router';
import { useStore } from 'effector-react';

import { usePrevious } from 'lib/hooks';
import { GameFrame } from './game-frame';
import { $gameURL } from 'pages/game/model';

export const GameContainer = () => {
  // const { code } = useParams<any>();
  const gameUrl = useStore($gameURL);
  // const prevCode = usePrevious(code);

  // const isNewCodeGame = React.useMemo(() => prevCode !== code, [prevCode, code]);

  return <GameFrame src={gameUrl} />;
};

// TODO: Импорт
