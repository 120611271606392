import React from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';

import { useFormaters } from 'features/i18';
import { loyaltyProgramModel } from 'features/loyalty-program';
import { EnumBonusState, EnumEventType } from 'lib/constants';

import { BonusStars } from '../atoms';
import { isPlayerBonus } from '../model';

export const BonusTitle = React.memo(
  ({
    bonus,
    children,
    color,
    whiteStars,
    showSpinsLeft,
  }: {
    bonus?: PlayerBonus | BonusProgram;
    color?: string;
    children?: React.ReactNode;
    whiteStars?: boolean;
    showSpinsLeft?: boolean;
  }) => {
    const { numberWithSpace } = useFormaters();
    const userLoyalty = useStore(loyaltyProgramModel.$userLoyaltyProgress);

    const rewardSum = React.useMemo(() => {
      if (!bonus) {
        return null;
      }

      if (isPlayerBonus(bonus)) {
        if (
          bonus.state === EnumBonusState.Available ||
          bonus.state === EnumBonusState.Active
        ) {
          return bonus.reward_percent?.amount || bonus.reward_amount;
        }

        return bonus.reward_amount;
      } else {
        return bonus.reward_sum;
      }
    }, [bonus]);

    if (!bonus) {
      return (
        <h3 className={classNames('bonus-card__title', color)}>
          {children}
          <BonusStars />
        </h3>
      );
    }

    const { reward_giftspins, event_type } = bonus;

    const percent = isPlayerBonus(bonus)
      ? bonus.reward_percent?.percent
      : bonus.reward_percent;

    if (event_type === EnumEventType.WEEKLY_CASHBACK && !isPlayerBonus(bonus)) {
      return (
        <h3 className={classNames('bonus-card__title white cashback', color)}>
          {`${userLoyalty?.cashback_percent || 0}`}
          <span className="giftspin__subtitle-span">%</span>
          <BonusStars white={whiteStars} />
        </h3>
      );
    }

    if (rewardSum) {
      return (
        <h3
          className={classNames(
            'bonus-card__title',
            'currency',
            'sum',
            color,
            bonus.event_type === EnumEventType.WEEKLY_CASHBACK && 'cashback',
          )}
        >
          {numberWithSpace(rewardSum).split(' ').join(' ')} P
          <BonusStars white={whiteStars} />
        </h3>
      );
    }

    if (percent) {
      return (
        <h3 className={classNames('bonus-card__title', 'percent', color)}>
          {percent}
          <span className="percent">%</span>
          <BonusStars white={whiteStars} />
        </h3>
      );
    }

    if (reward_giftspins && Object.keys(reward_giftspins).length !== 0) {
      return (
        <GiftSpinsBonusTitle
          showSpinsLeft={showSpinsLeft}
          reward_giftspins={reward_giftspins}
        />
      );
    }

    return null;
  },
);

export const GiftSpinsBonusTitle = ({
  reward_giftspins,
  whiteStars,
  showSpinsLeft,
}: {
  reward_giftspins: GiftSpin;
  whiteStars?: boolean;
  showSpinsLeft?: boolean;
}) => {
  return (
    <h3 className={classNames('bonus-card__title', 'giftspins')}>
      {showSpinsLeft ? reward_giftspins.spins_left : reward_giftspins.spins}{' '}
      <span className="giftspin__subtitle-span">FS</span>
      <BonusStars white={whiteStars} />
    </h3>
  );
};
