import { createEvent, restore } from 'effector';

export const setTextAndFn = createEvent<{ text: string; fn: Function }>();
export const setText = createEvent<string>();
export const close = createEvent();

export const $text = restore(setText, '')
  .on(setTextAndFn, (_, { text }) => text)
  .reset(close);
export const $isOpen = $text.map((text) => !!text);

export const setOnCloseFn = createEvent<Function>();
export const $onClose = restore(setOnCloseFn, () => {}).on(
  setTextAndFn,
  (_, { fn }) => fn,
);

$onClose.watch(close, (fn) => {
  fn();
  setOnCloseFn(() => {});
});
