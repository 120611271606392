import React from 'react';
import classNames from 'classnames';
import './style.css';

interface SectionProps {
  children: React.ReactNode | React.ReactNode[];
  type: 'info' | 'lottery' | 'account' | 'game';
  className?: string;
  id?: string;
}

export const Section: React.FC<SectionProps> = ({
  type,
  className,
  id,
  children,
}) => {
  return (
    <section id={id} className={classNames(`${type}-section`, className)}>
      {children}
    </section>
  );
};
