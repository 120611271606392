import React from 'react';
import { useStore } from 'effector-react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Container, Section } from 'ui';
import { JackpotArticle, JackpotWinnersTable } from 'features/jackpot';
import { GamesSection } from 'features/halls';

import './style.css';
import * as model from './model';

export const JackpotPage = () => {
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    window.scrollTo(0, 0);

    model.events.pageLoaded({ jackpotId: id });
    return () => model.events.pageUnloaded();
  }, []);

  const jackpotData = useStore(model.stores.$jackpot);
  const winners = useStore(model.stores.$winners);

  const pending = useStore(model.stores.$loading);

  return (
    <>
      <Helmet>
        {jackpotData?.title && <title>{jackpotData.title}</title>}
        {jackpotData?.keywords && (
          <meta name="keywords" content={jackpotData.keywords} />
        )}
      </Helmet>
      <JackpotArticle className="jackpot-article--page" jackpot={jackpotData} />
      <Section type="info">
        <Container>
          <JackpotDescription description={jackpotData?.description || ''} />
          <JackpotWinnersTable id="jackpot-winner-table" winners={winners} />
        </Container>
      </Section>
      <GamesSection
        withLink
        sectionId="jackpot-games"
        withScroll={false}
        gameList={jackpotData?.games || []}
        isPending={pending}
        showEmpty={false}
      />
    </>
  );
};

const JackpotDescription = ({ description }: { description: string }) => (
  <div
    className="tour-descr resources"
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(description),
    }}
  />
);
