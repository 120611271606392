import React from 'react';
import { useTranslation } from 'react-i18next';

import { Counter, Button, Link } from 'ui';
import { mediaHooks } from 'lib/media';
import { lotteryModel } from 'features/lotteries';
import { lotteryApi } from 'api/lottery';
import { useFormaters } from 'features/i18';
import { paths } from 'pages/paths';

import './style.css';

interface Props {
  lottery: LotteryItem;
}

// eslint-disable-next-line quotes
const DEFAULT_IMG_URL = "url('/img/l1.png')";

export const LotteryItem: React.FC<Props> = ({ lottery }) => {
  const {
    name,
    prize_fund,
    end_at,
    state,
    start_image_id,
    currency,
    id,
  } = lottery;

  const isTablet = mediaHooks.useIsTablet();

  const { currencyNumber } = useFormaters();
  const { t } = useTranslation();

  const backgroundImage = start_image_id
    ? `url('${lotteryApi.getImage(start_image_id)}')`
    : DEFAULT_IMG_URL;

  return (
    <div className="lot">
      <div className="lot__wrap" style={{ backgroundImage }}>
        <div className="lot__content">
          <h4 className="lot__name">{name}</h4>
          <div className="lot__price">
            {currencyNumber(prize_fund, { currency })}
          </div>
          <Counter isEnd={state === 'COMPLETED'} date={end_at} />
          {!isTablet && state === 'ACTIVE' && (
            <Button onClick={() => lotteryModel.buttonClicked(lottery)}>
              {t('common:buttons.buy')}
            </Button>
          )}
          <div className="details-link">
            <Link to={paths.lottery(id)}>{t('common:buttons.more')}</Link>
          </div>
        </div>
      </div>
      {isTablet && (
        <div className="lot__footer">
          {state === 'ACTIVE' && (
            <Button onClick={() => lotteryModel.buttonClicked(lottery)}>
              {t('common:buttons.buy')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
