import * as React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';

import { passwordRecoveryModel } from '..';

export const PasswordRecoveryToast = () => {
  const { t } = useTranslation();
  const { content, isOpen } = useStore(
    passwordRecoveryModel.$confirmPasswordModal,
  );
  return (
    <Toast
      isOpen={isOpen}
      onAnimationEnd={passwordRecoveryModel.closeConfirmPasswordModal}
      type={content === 'general_error' ? 'error' : 'success'}
    >
      {t(`change-password:modal.${content}`)}
    </Toast>
  );
};
