import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import {
  BlockTitle,
  Container,
  Link,
  ResultText,
  ResultWrap,
  Section,
} from 'ui';
import { DEFAULT_RENDER_SIZE, GameListTypes } from 'lib/constants';
import { paths } from 'pages/paths';
import { GameList } from 'features/halls';
import { InifiniteLoader } from 'features/infinite-loader';
import { searchModel } from 'features/search';

interface GameListProps {
  gameList: Game[];
  gameListName?: string;
  isPending: boolean;
  skeletonSize?: number;
  withLink?: boolean;
  withScroll?: boolean;
  withButton?: boolean;
  withInfinityLoader?: boolean;
  showEmpty?: boolean;
  title?: string;
  className?: string;
  icon?: string;
  link?: string;
  urlWithSearch?: boolean;
  renderSize?: number;
  navChildren?: React.ReactNode;
  onMoreGamesButtonClick?: () => void;
  redirectUrl?: string;
  sectionId?: string;
}

export const GamesSection = ({
  gameList,
  isPending,
  title,
  className,
  gameListName = 'hall',
  renderSize = 12,
  skeletonSize = DEFAULT_RENDER_SIZE,
  withScroll = true,
  withLink = false,
  withButton = true,
  withInfinityLoader = true,
  showEmpty = true,
  icon = 'games-icon',
  urlWithSearch = false,
  sectionId,
  navChildren,
  onMoreGamesButtonClick = () => {},
  redirectUrl = GameListTypes.All,
}: GameListProps) => {
  const { t } = useTranslation();
  const searchQuery = useStore(searchModel.$searchQuery);

  const url = urlWithSearch
    ? paths.searchGames(searchQuery)
    : paths.category(redirectUrl);

  if (!gameList?.length && !showEmpty) {
    return null;
  }

  if (!gameList?.length && !isPending) {
    return (
      <ResultWrap>
        <ResultText bg>{t('common:listEmpty')}</ResultText>
      </ResultWrap>
    );
  }

  return (
    <Section id={sectionId} className={className} type="game">
      <Container>
        <BlockTitle title={title || t('common:menu.games')} icon={icon}>
          {(urlWithSearch || withLink) && (
            <div onClick={() => onMoreGamesButtonClick()}>
              <Link border to={url}>
                {urlWithSearch
                  ? t('common:buttons.showAll')
                  : t('common:buttons.more-games-v1')}
              </Link>
            </div>
          )}
        </BlockTitle>

        {withInfinityLoader ? (
          <InifiniteLoader
            name={gameListName}
            renderSize={renderSize}
            list={gameList}
            withScroll={withScroll}
            withButton={withButton}
            render={(list) => (
              <GameList
                gameList={list}
                isPending={isPending}
                size={skeletonSize}
              />
            )}
          />
        ) : (
          <GameList
            gameList={gameList.slice(0, DEFAULT_RENDER_SIZE)}
            isPending={isPending}
            size={skeletonSize}
          />
        )}
        {navChildren && <div className="game-nav">{navChildren}</div>}
      </Container>
    </Section>
  );
};
