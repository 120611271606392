import { createStore, createEvent, sample, forward } from 'effector';

import { tournamentModel } from 'features/tournaments';

export const pageLoaded = createEvent<{
  tournamentLimit: number;
}>();
export const pageUnloaded = createEvent();

export const $pageIsLoaded = createStore(false)
  .on(pageLoaded, () => true)
  .reset(pageUnloaded);

sample({
  source: pageLoaded,
  target: tournamentModel.listInitialized,
  fn: ({ tournamentLimit }) => tournamentLimit,
});

forward({
  from: pageUnloaded,
  to: tournamentModel.listUnmounted,
});
