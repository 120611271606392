import * as React from 'react';
import { useTrackers } from './hooks';
import { YMInitializer } from 'react-yandex-metrika';

export const Metrics = () => {
  useTrackers();

  return (
    <YMInitializer
      accounts={[88477937]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      }}
      version="2"
    />
  );
};
