import * as React from 'react';
import ReactDom from 'react-dom';
import { Router } from 'react-router';
import { Helmet } from 'react-helmet';
import { YMInitializer } from 'react-yandex-metrika';

import { version } from '../package.json';
import 'features/i18/i18n';
import { history } from 'lib/routing';
import { App } from './app';
import { skinModel } from './features/skin-id';
import { SkinIds } from './features/skin-id/model';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

window['__VERSION__'] = version;

const root = document.querySelector('#root');

const render = () => {
  if (root) {
    ReactDom.render(
      <Router history={history}>
        <React.Suspense fallback={null}>
          <YMInitializer
            accounts={[88477937]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
            }}
            version="2"
          />
          <Head />
          <App />
        </React.Suspense>
      </Router>,
      root,
    );
  }
};

const Head = () => {
  const skinId = skinModel.selectors.useSkinId();

  if (skinId === SkinIds.HA) {
    return (
      <Helmet>
        <title>El Habanero</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#341c51" />
        <meta name="theme-color" content="#25133b" />
      </Helmet>
    );
  }

  if (skinId === SkinIds.GO) {
    return (
      <Helmet>
        <title>Golden Ocean</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/go.apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/go.favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/go.favicon-16x16.png"
        />
        <link rel="manifest" href="/go.site.webmanifest" />
        <link
          rel="mask-icon"
          href="/go.safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#303051" />
        <meta name="theme-color" content="#393862" />
      </Helmet>
    );
  }

  return null;
};

render();

serviceWorkerRegistration.register();
