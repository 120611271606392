import { authApi } from 'api/auth';
import { rules } from 'lib/validators';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  restore,
  sample,
  split,
} from 'effector';
import { createForm } from 'effector-forms';
import { condition, debounce, reshape, some, status } from 'patronum';

import { currenciesModel } from 'features/currencies';
import { fpModel } from 'features/fingerPrint';
import { sessionModel } from 'features/session';
import { captchaModel } from 'features/captcha';
import { DEFAULT_CURRENCY } from 'lib/constants';

import {
  $userAgent,
  $mirrorUrl,
} from 'features/user/model/user-additional-data';
import { setApiErrorsToForm } from 'lib/error/formatApiError';
import { clearHash, clearParams } from 'features/navigation/model';
import { deviceModel } from 'features/device';
import { languageModel } from 'features/i18';

export enum FormTypes {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SOCIAL = 'SOCIAL',
}

type RegisterDone = {
  params: RegisterBody;
  result: RegisterResponse;
};

const NETWORK_SESSION_KEY = 'currentNetwork';

export const addRegisterCaptcha = captchaModel.createCaptcha('registration');
const { $captcha: $captchaReg } = addRegisterCaptcha;

export const addLoginCaptcha = captchaModel.createCaptcha('other');
const { $captcha: $captchaLogin } = addLoginCaptcha;

export const checkLocationHash = createEvent<string>('checkLocationHash');
export const openFormByHash = createEvent<string>('openFormByHash');

export const loginFormOpened = createEvent<FormTypes | void>('loginFormOpened');
export const registerFormOpened = createEvent<FormTypes | void>(
  'registerFormOpened',
);
export const formClosed = createEvent('formClosed');

export const setType = createEvent<FormTypes>('setType');
export const $formType = restore(setType, null);

export const setRulesAgree = createEvent<boolean>('setRulesAgree');
export const $isRulesAgree = restore(setRulesAgree, true);

export const userRegisteredByEmail = createEvent<RegisterDone>(
  'userRegisteredByEmail',
);
export const userRegisteredBySocial = createEvent<RegisterDone>(
  'userRegisteredBySocial',
);
export const userRegisteredByPhone = createEvent<RegisterDone>(
  'userRegisteredByPhone',
);

export const { $isPhoneType, $isSocialType, $isEmailType } = reshape({
  source: $formType,
  shape: {
    $isPhoneType: (type) => type === FormTypes.PHONE,
    $isSocialType: (type) => type === FormTypes.SOCIAL,
    $isEmailType: (type) => type === FormTypes.EMAIL,
  },
});

export const setOauthToken = createEvent<string>('setOauthToken');
export const $oauthToken = restore(setOauthToken, '');

export const $isLoginFormOpen = createStore<boolean>(false, {
  name: '$isLoginFormOpen',
})
  .on(loginFormOpened, () => true)
  .on([registerFormOpened, formClosed], () => false);

export const $registerFormIsOpen = createStore<boolean>(false, {
  name: '$registerFormIsOpen',
})
  .on(registerFormOpened, () => true)
  .on([loginFormOpened, formClosed], () => false);

export const loginFx = createEffect<LoginData, Session, ApiError>({
  handler: (data) => authApi.login(data),
  name: 'loginFx',
});

export const checkUserFx = createEffect<string, oauthUserData>({
  handler: authApi.infoUser,
  name: 'checkUserFx',
});

export const checkUserInSocialFx = createEffect<string, UserDataInSocial>({
  handler: authApi.infoUserInSocial,
  name: 'checkUserFx',
});

export const oauthLoginFX = createEffect<oauthLoginData, Session>({
  handler: authApi.oauthLogin,
  name: 'oauthLoginFX',
});

export const sendConfirmCodeToEmailFx = createEffect<string, boolean, ApiError>(
  {
    handler: authApi.sendConfirmCodeToEmail,
    name: 'sendConfirmCodeToEmailFx',
  },
);

export const registerFx = createEffect<
  RegisterBody,
  RegisterResponse,
  ApiError
>({
  handler: authApi.register,
  name: 'registerFx',
});

const checkEmailForRegisterFx = createEffect<
  string,
  CheckEmailResponse,
  ApiError
>({
  handler: authApi.checkEmail,
  name: 'checkEmailForRegisterFx',
});

export const loginSocialFx = createEffect<string, string>({
  handler: (type) => {
    return authApi.loginWithSocial(
      {
        xreferer: window.document.referrer,
        callback_url: window.document.location.href,
      },
      type,
    );
  },
  name: 'loginSocialFx',
});

export const $confirmingEmail = createStore('', {
  name: '$confirmingEmail',
}).on(sendConfirmCodeToEmailFx.done, (_, { params: email }) => email);

export const $userSocialInfo = createStore<UserDataInSocial | null>(null, {
  name: '$userSocialInfo',
});
forward({
  from: checkUserInSocialFx.doneData,
  to: $userSocialInfo,
});

export const $confirmCodeIsSent = createStore(false, {
  name: '$confirmCodeIsSent',
})
  .on(sendConfirmCodeToEmailFx.doneData, () => true)
  .reset(sendConfirmCodeToEmailFx);

export const $userInSocialWasChecked = status({
  effect: checkUserInSocialFx,
}).map((status) => status !== 'initial' && status !== 'pending');

export const $needCodeForRegistration = combine(
  $userInSocialWasChecked,
  $userSocialInfo,
  $isSocialType,
  (checked, info, isSocialType) =>
    Boolean(isSocialType && checked && !info?.email),
);

export const $formIsOpen = some({
  predicate: true,
  stores: [$isLoginFormOpen, $registerFormIsOpen],
});

const isNeedValidateEmail = () => $isEmailType.getState();
const isNeedToValidatePhone = () => $isPhoneType.getState();
const isNeedPasswordForRegister = () => $isEmailType.getState();
const isNeedCodeForRegister = () => $needCodeForRegistration.getState();

export const $loginForm = createForm({
  fields: {
    login: {
      init: '',
      rules: [
        rules.phone(isNeedToValidatePhone),
        rules.email(isNeedValidateEmail),
      ],
      validateOn: ['blur'],
    },
    password: {
      init: '',
      rules: [rules.required()],
      validateOn: ['blur'],
    },
  },
});

export const $registerForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [
        rules.required(isNeedValidateEmail),
        rules.email(isNeedValidateEmail),
      ],
      validateOn: ['blur'],
    },
    phone: {
      init: '',
      rules: [rules.phone(isNeedToValidatePhone)],
      validateOn: ['blur'],
    },
    password: {
      init: '',
      rules: [
        rules.required(isNeedPasswordForRegister),
        rules.minLength(6, isNeedPasswordForRegister),
      ],
      validateOn: ['blur'],
    },
    currency: {
      init: DEFAULT_CURRENCY,
    },
    code: {
      init: '',
      rules: [rules.required(isNeedCodeForRegister)],
    },
  },
  validateOn: ['submit'],
});

export const $sendCodeToEmailForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
});

export const $submitLoginButtonIsDisabled = combine(
  addLoginCaptcha.$isCaptchaValid,
  loginFx.pending,
  (captchaValid, pending) => !captchaValid || pending,
);

export const $registerButtonIsDisabled = combine(
  $isRulesAgree,
  registerFx.pending,
  addRegisterCaptcha.$isCaptchaValid,
  (rulesAgree, pending, captchaValid) =>
    !rulesAgree || pending || !captchaValid,
);

export const $socialRegisterButtonIsDisabled = combine(
  $isRulesAgree,
  registerFx.pending,
  addRegisterCaptcha.$isCaptchaValid,
  $userInSocialWasChecked,
  $needCodeForRegistration,
  $confirmCodeIsSent,
  (
    rulesAgree,
    pending,
    captchaValid,
    checked,
    needCodeForRegistration,
    codeIsSent,
  ) =>
    !rulesAgree ||
    !captchaValid ||
    !checked ||
    pending ||
    (needCodeForRegistration && !codeIsSent),
);

/**
 * SEARCH PARAMS
 */
const SEARCH_PARAMS_KEY = 'visitparams';

export const checkSearchParams = createEvent<{ search: string }>();
export const $checkedSearchParams = createStore('');

const setSearchParams = createEvent<string>();
const $searchParams = restore(setSearchParams, '');

const setSearchParamsToLSFx = createEffect<string, void>((search) => {
  localStorage.setItem(SEARCH_PARAMS_KEY, search);
});

const getSearchParamsFromLSFx = createEffect<void, string>(
  () =>
    new Promise((resolve, reject) =>
      !!localStorage.getItem(SEARCH_PARAMS_KEY)
        ? resolve(localStorage.getItem(SEARCH_PARAMS_KEY) as string)
        : reject(),
    ),
);

sample({
  clock: checkSearchParams,
  target: $checkedSearchParams,
  fn: ({ search }) => search,
});

sample({
  clock: checkSearchParams,
  target: getSearchParamsFromLSFx,
});

sample({
  clock: getSearchParamsFromLSFx.doneData,
  target: setSearchParams,
});

sample({
  source: $checkedSearchParams,
  clock: getSearchParamsFromLSFx.failData,
  target: [setSearchParams, setSearchParamsToLSFx],
});

/**
 * LOGIN
 */
// call loginFx if form is valid
sample({
  clock: $loginForm.formValidated,
  source: [$captchaLogin, $userAgent, fpModel.$fp, $mirrorUrl],
  target: loginFx,
  fn: ([captcha, useragent, fp, mirror_url], data) => {
    return {
      ...data,
      captcha,
      login_info: {
        after_registration: false,
        useragent,
        fp,
        mirror_url,
      },
    };
  },
});

loginSocialFx.done.watch(({ result: URL, params: network }) => {
  window.document.location.replace(URL);
  sessionStorage.setItem(NETWORK_SESSION_KEY, network);
});

forward({
  from: loginFx.doneData,
  to: [$loginForm.reset, formClosed],
});

loginFx.failData.watch((errors) => {
  setApiErrorsToForm(errors, $loginForm);
});

forward({
  from: loginFx.failData,
  to: addLoginCaptcha.checkCaptchaApiError,
});

forward({
  from: [loginFx.doneData],
  to: sessionModel.setSession,
});

/**
 * REGISTRATION
 */

forward({
  from: [registerFormOpened],
  to: currenciesModel.getCurrencies,
});

guard({
  source: sample({
    source: combine([
      $registerForm.fields.email.$value,
      $registerForm.fields.email.$errors,
    ]),
    clock: debounce({
      source: $registerForm.fields.email.onBlur,
      timeout: 300,
    }),
    fn: ([value, errors]) => ({ value, errors }),
  }),
  filter: ({ errors }) => errors.length === 0,
  target: checkEmailForRegisterFx.prepend<{ value: string }>(
    ({ value }) => value,
  ),
});

checkEmailForRegisterFx.doneData.watch((data) => {
  const error: ApiError = { empty: true, items: {} };

  if (data.notValid) {
    error.empty = false;
    error.items['email.invalid'] = 'email';
  }
  if (data.exist) {
    error.empty = false;
    error.items['email.exists'] = 'email';
  }

  setApiErrorsToForm(error, $registerForm);
  setApiErrorsToForm(error, $sendCodeToEmailForm);
});

sample({
  clock: $registerForm.formValidated,
  source: [
    $captchaReg,
    $userAgent,
    $formType,
    $oauthToken,
    $searchParams,
    languageModel.$language,
  ],
  target: registerFx,
  fn: ([captcha, useragent, reg_type, token, params, language], data) => {
    return {
      ...data,
      language,
      reg_type: reg_type || FormTypes.EMAIL,
      params,
      useragent,
      captcha,
      referer: document.referrer,
      token,
      network: sessionStorage.getItem(NETWORK_SESSION_KEY) || '',
    };
  },
});

split({
  source: registerFx.done,
  match: {
    social: ({ params }) => params.reg_type === 'SOCIAL',
    email: ({ params }) => params.reg_type === 'EMAIL',
    phone: ({ params }) => params.reg_type === 'PHONE',
  },
  cases: {
    social: userRegisteredBySocial,
    email: userRegisteredByEmail,
    phone: userRegisteredByPhone,
  },
});

sample({
  source: registerFx.done,
  target: setSearchParamsToLSFx,
  fn: () => '',
});

$searchParams.reset(registerFx.done);

registerFx.done.watch(() => sessionStorage.removeItem(NETWORK_SESSION_KEY));

sample({
  source: guard(currenciesModel.$currencies, {
    filter: (currencies) => currencies.available.length === 1,
  }),
  target: $registerForm.setForm,
  fn: ({ available }) => ({ currency: available[0] }),
});

sample({
  source: combine([$userAgent, fpModel.$fp, $mirrorUrl]),
  clock: userRegisteredByEmail,
  target: loginFx,
  fn: ([useragent, fp, mirror_url], { params }) => ({
    login: params.email,
    password: params.password,
    login_info: {
      after_registration: true,
      useragent,
      fp,
      mirror_url,
    },
    captcha: {
      solution: '',
      task_id: '',
    },
  }),
});

forward({
  from: userRegisteredByPhone,
  to: [formClosed, loginFormOpened.prepend(() => FormTypes.PHONE)],
});

sample({
  source: userRegisteredByPhone,
  target: $loginForm.fields.login.onChange,
  fn: ({ params }) => params?.phone || '',
});

// Form type - email, phone or social
const $userFormType = createStore<FormTypes | null>(null, {
  name: 'userFormType',
});
const setUserType = createEvent<FormTypes>('setUserType');
$userFormType.on(setUserType, (_, newType) => newType);

export const switchFormType = createEvent<void>('switchFormType');

sample({
  clock: switchFormType,
  source: $formType,
  fn: (currentType) =>
    currentType === FormTypes.PHONE ? FormTypes.EMAIL : FormTypes.PHONE,
  target: [setType, setUserType],
});

sample({
  source: userRegisteredByPhone,
  fn: () => FormTypes.PHONE,
  target: setUserType,
});

sample({
  source: userRegisteredByEmail,
  fn: () => FormTypes.EMAIL,
  target: setUserType,
});

const registerFormOpenedWithoutType = createEvent<void>();

condition({
  source: registerFormOpened,
  if: Boolean,
  then: setType,
  else: registerFormOpenedWithoutType,
});

condition({
  // @ts-ignore
  source: merge([loginFormOpened, registerFormOpenedWithoutType]),
  if: $userFormType.map(Boolean),
  then: setType,
  else: condition({
    if: deviceModel.$isMobile,
    then: setType.prepend(() => FormTypes.PHONE),
    else: setType.prepend(() => FormTypes.EMAIL),
  }),
});

// Set errors if registerFx failed
registerFx.failData.watch((errors) => {
  setApiErrorsToForm(errors, $registerForm);
});

forward({
  from: registerFx.failData,
  to: addRegisterCaptcha.checkCaptchaApiError,
});

// clear forms when modal close
forward({
  from: formClosed,
  to: [$registerForm.reset, $loginForm.reset, $sendCodeToEmailForm.reset],
});

condition({
  source: checkLocationHash,
  if: sessionModel.$isAuthenticated,
  then: clearHash,
  else: openFormByHash,
});

split({
  source: openFormByHash,
  match: {
    login: (payload) => payload.includes('#login'),
    registration: (payload) => payload.includes('#registration'),
  },
  cases: {
    login: loginFormOpened,
    registration: registerFormOpened,
  },
});

forward({
  from: formClosed,
  to: clearHash,
});

/**
 * OAUTH
 */

// Login user if exists
sample({
  source: $userAgent,
  clock: guard(checkUserFx.done, { filter: ({ result }) => !!result }),
  target: oauthLoginFX,
  fn: (useragent, { params, result }) => ({
    useragent,
    token: params,
    ...(result.email && { login: result?.email }),
  }),
});

forward({
  from: checkUserFx,
  to: setOauthToken,
});

// open register form if user not exists
guard({
  source: checkUserFx.done,
  filter: ({ result }) => !result,
  target: [registerFormOpened.prepend(() => FormTypes.SOCIAL)],
});

sample({
  source: guard(checkUserFx.done, { filter: ({ result }) => !result }),
  target: checkUserInSocialFx,
  fn: ({ params: token }) => token,
});

forward({
  from: checkUserFx.finally,
  to: clearParams,
});

sample({
  source: combine([$userAgent, $oauthToken, $confirmingEmail]),
  clock: userRegisteredBySocial,
  target: oauthLoginFX,
  fn: ([useragent, token, email], { params }) => ({
    useragent,
    token,
    ...((email || params?.email) && { login: email || params?.email }),
  }),
});

// Show app
sample({
  source: checkUserFx.finally,
  target: sessionModel.setIsPending,
  fn: () => false,
});

// Set session after login
forward({
  from: oauthLoginFX.doneData,
  to: sessionModel.setSession,
});

forward({
  from: oauthLoginFX.doneData,
  to: formClosed,
});

sample({
  source: $sendCodeToEmailForm.formValidated,
  target: sendConfirmCodeToEmailFx,
  fn: ({ email }) => email,
});

// Set errors if registerFx failed
sendConfirmCodeToEmailFx.failData.watch((errors) => {
  setApiErrorsToForm(errors, $sendCodeToEmailForm);
});
