import React from 'react';
import { useStore } from 'effector-react';

import { DEFAULT_RENDER_SIZE } from 'lib/constants';
import { $allGamesStatus, GamesSection } from 'features/halls';
import { SearchResultMessage } from 'features/search';
import { deviceModel } from 'features/device';

import * as model from '../model';

interface Props {
  withScroll?: boolean;
  withLink?: boolean;
  urlWithSearch?: boolean;
  withInfinityLoader?: boolean;
}

export const SearchResultDesktop = ({
  withScroll = true,
  withLink = false,
  urlWithSearch = false,
  withInfinityLoader = true,
}: Props) => {
  const isMobile = useStore(deviceModel.$isMobile);
  const searchGames = useStore(model.$searchResult);
  const searchQuery = useStore(model.$searchQuery);
  const isQueryEmpty = useStore(model.$isQueryEmpty);

  const gamesStatus = useStore($allGamesStatus);
  const gameIsLoaded = gamesStatus === 'done';

  const isShowSearch = !isQueryEmpty && gameIsLoaded;

  if (isMobile) {
    return null;
  }

  return isShowSearch ? (
    <>
      <SearchResultMessage value={searchQuery} count={searchGames.length} />
      <GamesSection
        withInfinityLoader={withInfinityLoader}
        withButton={false}
        urlWithSearch={urlWithSearch}
        withLink={withLink}
        withScroll={withScroll}
        gameList={searchGames}
        isPending={false}
        skeletonSize={DEFAULT_RENDER_SIZE}
        showEmpty={false}
      />
    </>
  ) : null;
};
