import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { createReflect } from '@effector/reflect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, IconSprite, MainForm, MainFormRow, Toast } from 'ui';
import { trimPreventedEvent } from 'lib/events';
import { Captcha } from 'features/captcha';

import { passwordRecoveryModel as model } from 'features/password-recovery';
import './style.css';

export const UserPasswordForm = () => {
  const { t } = useTranslation();

  const restoreType = useStore(model.$restoreType);

  useEffect(() => {
    model.pageLoaded();

    return () => model.pageUnloaded();
  }, []);

  const { isOpen, content } = useStore(model.$modal);

  const isEmailType = restoreType === model.RestoreTypes.EMAIL;
  const isPhoneType = restoreType === model.RestoreTypes.PHONE;

  return (
    <>
      {isOpen && (
        <Toast
          isOpen
          type={content.toLowerCase().includes('error') ? 'error' : 'success'}
          onAnimationEnd={model.closeModal}
        >
          {t(`change-password:modal.${content}`)}
        </Toast>
      )}
      <SwitchRestoreType />
      <MainForm className="password-reset__fields-form" isForm={false}>
        {isEmailType && <RestoreByEmailForm />}
        {isPhoneType && <RestoreByPhoneForm />}
      </MainForm>
    </>
  );
};

const SwitchRestoreType = () => {
  const { t } = useTranslation();
  const type = useStore(model.$restoreType);

  const toggleToPhone = () => {
    model.restoreTypeChanged(model.RestoreTypes.PHONE);
  };

  const toggleToEmail = () => {
    model.restoreTypeChanged(model.RestoreTypes.EMAIL);
  };

  return (
    <MainForm className="restore-type__form" isForm={false} noBorder>
      <MainFormRow label={t('change-password:restoreType.title')}>
        <div className="restore-type__wrap">
          <ul className="modal__tabs-head">
            <li
              className={classNames(
                'modal__tabs-item',
                type === model.RestoreTypes.PHONE && 'active',
              )}
              onClick={toggleToPhone}
            >
              {t('change-password:restoreType.email')}
            </li>
            <li
              className={classNames(
                'modal__tabs-item',
                type === model.RestoreTypes.EMAIL && 'active',
              )}
              onClick={toggleToEmail}
            >
              {t('change-password:restoreType.phone')}
            </li>
          </ul>
        </div>
      </MainFormRow>
    </MainForm>
  );
};

const RestoreByEmailForm = () => {
  const { t } = useTranslation();
  const canChangeByEmail = useStore(model.$recoveryUserTypes.byEmail);

  if (!canChangeByEmail) {
    return (
      <WarningText>
        {t('change-password:warnings:emailMustBeConfirmed')}
      </WarningText>
    );
  }
  return (
    <MainFormRow>
      <div className="change-password__field">
        <form onSubmit={model.formSubmited.prepend(trimPreventedEvent)}>
          <Captcha captcha={model.resetPasswordEmailCaptcha} />
          <SubmitChange>{t('change-password:button')}</SubmitChange>
        </form>
      </div>
    </MainFormRow>
  );
};

const RestoreByPhoneForm = () => {
  const { t } = useTranslation();

  const canChangeByPhone = useStore(model.$recoveryUserTypes.byPhone);

  if (!canChangeByPhone) {
    return (
      <WarningText>
        {t('change-password:warnings:phoneMustBeConfirmed')}
      </WarningText>
    );
  }

  return (
    <MainFormRow>
      <div className="change-password__field">
        <form onSubmit={model.formSubmited.prepend(trimPreventedEvent)}>
          <Captcha captcha={model.resetPasswordPhoneCaptcha} />
          <SubmitChange>{t('change-password:button')}</SubmitChange>
        </form>
      </div>
    </MainFormRow>
  );
};

const WarningText = ({ children }) => {
  return (
    <div className="recovery__warning-text">
      <IconSprite name="warning-field" />
      {children}
    </div>
  );
};

const reflectButton = createReflect(Button);

const SubmitChange = reflectButton({
  disabled: model.$isFormDisabled,
  type: 'submit',
});
