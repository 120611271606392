import React from 'react';
import classNames from 'classnames';
import './styles.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const Input = ({
  autoComplete,
  value,
  onChange,
  name,
  type,
  className,
  onBlur,
  disabled,
  placeholder,
  inputRef,
  onFocus,
}: Props) => {
  const classProps = classNames('input', className);
  return (
    <input
      ref={inputRef}
      className={classProps}
      autoComplete={autoComplete}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      type={type}
    />
  );
};
