import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { IconSprite, NavLinkButton } from 'ui';
import * as model from '../model';

export const SearchButtonMobile = () => {
  const { t } = useTranslation();

  const opened = useStore(model.$isFormOpen);

  return (
    <NavLinkButton active={opened} onClick={() => model.formToggled()}>
      <IconSprite name={opened ? 'reg-five-active' : 'reg-five'} />
      <span className="nav__text">{t('common:menu.search')}</span>
    </NavLinkButton>
  );
};
