import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDisclosure, useModal } from 'lib/hooks';
import { Button, ButtonLink, Counter, FlipBox, IconSprite } from 'ui';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  BonusTitle,
  CancelBonusModal,
  GiftSpinsBonusTitle,
} from '../molecules';

import * as model from '../model';

interface Props {
  bonus: PlayerBonus;
  className?: string;
  idx?: number;
}

export const BonusCardActive: React.FC<Props> = (props) => {
  const hasTwoRewards = model.bonusHasTwoRewards(props.bonus);

  if (hasTwoRewards) {
    return <TwoSidedCard {...props} />;
  }

  return <OneSidedCard {...props} />;
};

const TwoSidedCard: React.FC<Props> = (props) => {
  const cancelModal = useModal();

  const {
    isOpen: isBackSide,
    open: showBackSide,
    close: showFrontSide,
  } = useDisclosure();

  return (
    <>
      {cancelModal.isVisible && (
        <CancelBonusModal
          bonus={props.bonus}
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <FlipBox className="flip-card" isFlipped={isBackSide}>
        <FrontSide
          onCancel={cancelModal.show}
          onFlipButtonClick={showBackSide}
          {...props}
        />
        <BackSide onFlipButtonClick={showFrontSide} {...props} />
      </FlipBox>
    </>
  );
};

interface DoubleSidedCardProps extends Props {
  onFlipButtonClick: () => void;
  onCancel?: () => void;
}

const FrontSide: React.FC<DoubleSidedCardProps> = ({
  bonus,
  className,
  idx,
  onFlipButtonClick,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { expired_at, activate_before, reward_giftspins } = bonus;

  return (
    <BonusCardWrap
      iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
      idx={idx}
      className={classNames(className, 'active-bonus')}
    >
      <div className="bonus-card__info">
        <BonusTitle bonus={bonus} />
        <BonusSubtitle bonus={bonus} />
        <BonusProgressLine bonus={bonus} />
        <GiftSpinsBonusTitle
          showSpinsLeft
          reward_giftspins={reward_giftspins as GiftSpin}
        />
        <BonusSubtitle>{t('bonus:freespins')}</BonusSubtitle>
        {bonus.wager && (
          <BonusDescription.Item
            className="front-side-wager"
            description={t('bonus:info.wager')}
            text={`x${bonus.wager}`}
          />
        )}
        <div className="description-wrap has-active">
          {(expired_at || activate_before) && (
            <Counter date={expired_at || activate_before} />
          )}
        </div>
        <div className="bonus-card__nav">
          <div className="front-btns">
            <Button onClick={onCancel} theme="tertiary">
              <IconSprite name="delete-icon" />
            </Button>
            <Button
              // @ts-ignore
              disabled={reward_giftspins?.spins_left === 0}
              onClick={() =>
                model.giftSpinClicked(reward_giftspins as GiftSpin)
              }
            >
              {t('bonus:buttons.spinFS')}
            </Button>
          </div>
          <ButtonLink onClick={onFlipButtonClick} theme="secondary">
            {t('common:detailed')}
          </ButtonLink>
        </div>
      </div>
    </BonusCardWrap>
  );
};

const BackSide: React.FC<DoubleSidedCardProps> = ({
  bonus,
  className,
  idx,
  onFlipButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <BonusCardWrap
      iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
      idx={idx}
      className={classNames(className, 'back-side', 'active-bonus')}
    >
      <div className="bonus-card__info has-active is-active-bonus">
        <BonusSubtitle withReward bonus={bonus} />
        <BonusDescription.MainInfo withGiftspinsInfo={false} bonus={bonus} />
        <BonusSubtitle
          className="second"
          showRewards={{ sum: false, percent: false, giftspins: true }}
          withEvent={false}
          withReward
          bonus={bonus}
        />
        <BonusDescription.GiftSpinInfo
          giftspins={bonus.reward_giftspins as GiftSpin}
        />
        <Button
          disabled={bonus.reward_giftspins?.spins_left === 0}
          onClick={() =>
            model.giftSpinClicked(bonus.reward_giftspins as GiftSpin)
          }
        >
          {t('bonus:buttons.spinFS')}
        </Button>
        <ButtonLink onClick={onFlipButtonClick} theme="secondary">
          {t('common:buttons.back')}
        </ButtonLink>
      </div>
    </BonusCardWrap>
  );
};

const OneSidedCard: React.FC<Props> = ({ bonus, className, idx }) => {
  const { t } = useTranslation();
  const cancelModal = useModal();

  const { event_type, reward_giftspins } = bonus;

  return (
    <>
      {cancelModal.isVisible && (
        <CancelBonusModal
          bonus={bonus}
          onReject={cancelModal.hide}
          onSubmit={cancelModal.hide}
        />
      )}
      <BonusCardWrap
        idx={idx}
        iconName={model.isBirthdayBonus(bonus) ? 'bonus-pinata-icon' : ''}
        className={classNames(
          className,
          event_type === 'TASK_ACCOMPLISHED' && 'welcome-bonus',
          model.isBirthdayBonus(bonus) && 'birthday',
        )}
      >
        <div className="bonus-card__info">
          <BonusTitle
            bonus={bonus}
            showSpinsLeft
            color={bonus.event_type === 'WEEKLY_CASHBACK' ? 'blue' : ''}
          />
          <BonusSubtitle>{t(`bonus:subtitle.${event_type}`)}</BonusSubtitle>
          <span className="player-bonus-card__subtitle">
            {t('bonus:wagered')}
          </span>
          <BonusProgressLine bonus={bonus} />
          <BonusDescription.MainInfo bonus={bonus} />
        </div>
        <div className="bonus-card__nav">
          <div className="front-btns">
            <Button onClick={cancelModal.show} theme="tertiary">
              <IconSprite name="delete-icon" />
            </Button>
            <Button
              disabled={reward_giftspins?.spins_left === 0}
              onClick={() => model.playerBonusClicked(bonus)}
            >
              {t('bonus:buttons.spinFS')}
            </Button>
          </div>
        </div>
      </BonusCardWrap>
    </>
  );
};

const BonusProgressLine = ({ bonus }: { bonus: PlayerBonus }) => {
  return (
    <div className="player-bonus-card__progress">
      <div className="player-bonus-card__progress-head">
        <span>{bonus.cumulated_sum}</span>
        <span>{bonus.required_amount}</span>
      </div>
      <div className="player-bonus-card__progress-bar">
        <span style={{ width: `${bonus.actual_percent}%` }} />
      </div>
    </div>
  );
};
