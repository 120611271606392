import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { condition, spread } from 'patronum';
import i18n from 'i18next';

import { RegTypes } from 'lib/constants';
import { paymentsInfoApi } from 'api/payments-info';
import { navigationModel } from 'features/navigation';
import { userModel } from 'features/user';
import { sendEmailCodeFx } from 'features/user/model/confirm-email';
import { createErrorObject } from 'lib/error/formatApiError';
import { isValidEmail } from 'lib/validators';

/**
 * Информация для совершения платежек (лимиты, возможен ли вывод и т.д.)
 */

export const withdrawalCanceled = createEvent();

export const getWithdrawalLimitsFx = createEffect<
  void,
  WithdrawLimitsResponse,
  Error
>(() => paymentsInfoApi.getWithdrawLimits());

export const $fullInfoIsProvided = createStore<boolean>(false);

export const $withdrawLimits = createStore<WithdrawLimits>({
  available_sum: 0,
  daily: 0,
  monthly: 0,
  weekly: 0,
});

export const $cancelLimit = createStore<number>(0);
export const $cancelMade = createStore<number>(0).on(
  withdrawalCanceled,
  (state, _) => state + 1,
);
export const $isCancelLimited = createStore<boolean>(false);
export const $isCancelAvailable = combine(
  $isCancelLimited,
  $cancelMade,
  $cancelLimit,
  (isLimited, count, limit) => {
    if (!isLimited) {
      return true;
    }

    return count < limit;
  },
);

export const $depositWageringFee = createStore<FeedepositWageringFee>({
  fee: 0,
  required_bet: 0,
});

export const $hasRequiredBet = $depositWageringFee.map(
  ({ required_bet }) => required_bet > 0,
);

spread({
  source: getWithdrawalLimitsFx.doneData,
  targets: {
    feedeposit_wagering_fee: $depositWageringFee,
    withdraw_limits: $withdrawLimits,
    full_info: $fullInfoIsProvided,
  },
});

/**
 * Платёжный email
 */

const DEFAULT_RETRY_TIME_SECONDS = 15;

export const formMounted = createEvent<void>();
export const formUnmounted = createEvent<void>();
export const resetForm = createEvent<void>();

export const getPaymentEmail = createEvent<void>();

export const minusSecondToRetry = createEvent<void>();

export const getPaymentEmailFx = createEffect<void, string, Error>(
  paymentsInfoApi.getPaymentEmail,
);

export const sendLinkToEmailFx = createEffect<
  sendLinkToEmailBody,
  {},
  ApiError
>(paymentsInfoApi.sendCodeToEmail);

export const tickFx = createEffect(
  () => new Promise((resolve) => setTimeout(resolve, 1000)),
);

export const $linkIsSent = createStore(false)
  .on(sendLinkToEmailFx.doneData, () => true)
  .reset(resetForm);

export const $paymentEmail = createStore<string>('')
  .on(getPaymentEmailFx.doneData, (email, response) => response || email)
  .on(
    getWithdrawalLimitsFx.doneData,
    (email, { payment_email }) => payment_email || email,
  );

export const $secondsToRetry = createStore(0).on(
  minusSecondToRetry,
  (seconds) => seconds - 1,
);

export const emailSubmitted = createEvent<void>();
export const emailChanged = createEvent<string>();
export const $email = restore(emailChanged, '');

export const $submittedEmail = createStore('');

const $emailValidationError = createStore('')
  .on(emailChanged, (_, email) => isValidEmail(email))
  .reset(formUnmounted);

const $sendLinkToEmailErrors = createStore<Record<string, string>>({})
  .on(sendLinkToEmailFx.failData, (_, errors) => createErrorObject(errors))
  .reset(emailChanged);

const $emailFieldApiError = $sendLinkToEmailErrors.map(
  (errors) => errors['email'],
);

export const $shownPhoneError = combine(
  $emailValidationError,
  $emailFieldApiError,
  (error, apiError) =>
    apiError ? i18n.t(`lk:withdraw.paymentEmail.errors.${apiError}`) : error,
);

export const $isFormDisabled = combine(
  $emailValidationError,
  $emailFieldApiError,
  $secondsToRetry,
  $email,
  sendEmailCodeFx.pending,
  (validatonError, apiError, seconds, email, pending) =>
    !!validatonError || !!apiError || !!seconds || !email || pending,
);

guard({
  source: getPaymentEmail,
  filter: $paymentEmail.map((email) => Boolean(email)),
  target: getPaymentEmailFx,
});

sample({
  source: $email,
  clock: emailSubmitted,
  target: sendLinkToEmailFx,
  fn: (email) => ({ email, mirror_url: window.location.href }),
});

sample({
  source: $email,
  clock: emailSubmitted,
  target: $submittedEmail,
});

sample({
  source: sendLinkToEmailFx.doneData,
  target: $secondsToRetry,
  fn: () => DEFAULT_RETRY_TIME_SECONDS,
});

guard({
  source: $secondsToRetry,
  filter: (seconds) => seconds === DEFAULT_RETRY_TIME_SECONDS,
  target: tickFx,
});

forward({
  from: tickFx.doneData,
  to: minusSecondToRetry,
});

condition({
  source: tickFx.doneData,
  if: $secondsToRetry.map((seconds) => seconds !== 0),
  then: tickFx,
});

sample({
  source: guard(formUnmounted, { filter: $linkIsSent.map((sent) => !sent) }),
  target: $email,
  fn: () => '',
});

/**
 * PAYCODE платёжного емейла
 */
const PAYCODE_PARAM = 'pay-code';

export const checkPaymentEmailParam = createEvent<string>();

export const confirmPaymentEmailByCodeFx = createEffect<
  string,
  string,
  ApiError
>(paymentsInfoApi.confirmPaymentEmail);

const $confirmedEmail = restore(confirmPaymentEmailByCodeFx.doneData, null);

export const resetPayCode = createEvent();
export const $payCode = createStore<string>('').reset(resetPayCode);

sample({
  source: checkPaymentEmailParam,
  target: $payCode,
  fn: (search) => {
    const params = new URLSearchParams(search);
    return params.get(PAYCODE_PARAM) ?? '';
  },
});

guard({
  source: $payCode,
  filter: (code) => !!code,
  target: confirmPaymentEmailByCodeFx,
});

forward({
  from: confirmPaymentEmailByCodeFx,
  to: [resetPayCode, navigationModel.clearParam.prepend(() => PAYCODE_PARAM)],
});

guard({
  source: confirmPaymentEmailByCodeFx.doneData,
  filter: (email) => Boolean(email),
  target: $paymentEmail,
});

guard({
  source: sample({
    source: userModel.$user,
    clock: confirmPaymentEmailByCodeFx.doneData,
    fn: (user, paymentEmail) => ({ user, paymentEmail }),
  }),
  filter: ({ user, paymentEmail }) => {
    if (user?.registration_type === RegTypes.EMAIL) {
      return user.email === paymentEmail;
    }
    return false;
  },
  target: userModel.setEmailIsConfirmed,
});

guard({
  source: sample({
    source: $confirmedEmail,
    clock: userModel.setUser,
    fn: (confirmedPaymentEmail, user) => ({ confirmedPaymentEmail, user }),
  }),
  filter: ({ confirmedPaymentEmail, user }) => {
    if (user?.registration_type === RegTypes.EMAIL && !!confirmedPaymentEmail) {
      return confirmedPaymentEmail === user.email;
    }
    return false;
  },
  target: userModel.setEmailIsConfirmed,
});

/**
 * МОДАЛКА
 */
enum ModalContent {
  LinkIsSent = 'linkIsSent',
  SendLinkError = 'sendLinkError',
  EmailConfirmed = 'emailConfirmed',
  СonfirmCodeError = 'confirmCodeError',
}

export const resetModal = createEvent();
export const setModal = createEvent<EmailConfirmModal>();
export const $modal = createStore<EmailConfirmModal>({
  isOpen: false,
  content: '',
})
  .on(setModal, (_, data) => data)
  .reset(resetModal);

//  подтверждение email doneData
condition({
  source: confirmPaymentEmailByCodeFx.doneData,
  if: (email) => Boolean(email),
  then: setModal.prepend(() => ({
    isOpen: true,
    content: ModalContent.EmailConfirmed,
  })),
  else: setModal.prepend(() => ({
    isOpen: true,
    content: ModalContent.СonfirmCodeError,
  })),
});

// ошибка подтверждения
sample({
  source: confirmPaymentEmailByCodeFx.failData,
  target: $modal,
  fn: () => ({
    isOpen: true,
    content: ModalContent.СonfirmCodeError,
  }),
});
