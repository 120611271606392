import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Button, SecondaryInfoModal } from 'ui';
import { useModal } from 'lib/hooks';
import { userModel } from 'features/user';
import { sessionModel } from 'features/session';

import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  GiftSpinsBonusTitle,
  FreespinImg,
} from '../molecules';

import * as model from '../model';
import { authFormModel } from '../../auth';

interface Props {
  className?: string;
  bonus: BonusProgram;
  idx: number;
}

export const WelcomeBonusCard: React.FC<Props> = ({
  className,
  bonus,
  idx,
}) => {
  const userIsConfirmed = useStore(userModel.$userAccountIsConfirmed);
  const isAuth = useStore(sessionModel.$isAuthenticated);

  const { t } = useTranslation();
  const modal = useModal();

  const { giftspins } = bonus.task;
  const { confirmed_acc_only } = bonus;
  const { spins } = giftspins;

  const handleActivateClick = () => {
    if (confirmed_acc_only && isAuth && !userIsConfirmed) {
      modal.show();
    } else {
      model.bonusProgramClicked(bonus);
    }
  };

  return (
    <>
      {modal.isVisible && (
        <SecondaryInfoModal
          onModalClose={modal.hide}
          text={t('bonus:popup.welcomeBonusText')}
          title={t('bonus:popup.welcomeBonusTitle')}
        />
      )}
      <BonusCardWrap className={classNames(className, 'task-gs')} idx={idx}>
        <div className="bonus-card__info">
          <GiftSpinsBonusTitle reward_giftspins={giftspins} />
          <BonusSubtitle>
            {t('bonus:subtitle.freeSpins', { count: spins })}
          </BonusSubtitle>
          <FreespinImg />
          <BonusDescription.GiftSpinInfo giftspins={giftspins} />
        </div>
        <div className="bonus-card__nav">
          <Button onClick={handleActivateClick}>
            {t('bonus:buttons.activeWelcome')}
          </Button>
        </div>
      </BonusCardWrap>
    </>
  );
};
