import { httpClient } from 'lib/request';

/**
 * Получить лимиты на вывод для игрока
 */
function getWithdrawLimits(): Promise<WithdrawLimitsResponse> {
  return httpClient.get('players/current/withdrawal-info');
}

/**
 * Получить платежный email
 */
function getPaymentEmail(): Promise<string> {
  return httpClient.get('players/current/payment-email');
}

/**
 * Отправить код подтверждения платежного email
 * @param data
 */
function sendCodeToEmail(data: sendLinkToEmailBody): Promise<{}> {
  return httpClient.post('players/current/payment-email', data);
}

/**
 * Подтвердить платежного email по коду
 * @param {string} code - код подтверждения
 */
function confirmPaymentEmail(code: string): Promise<string> {
  return httpClient.post('payment-emails', code, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
}

export const paymentsInfoApi = {
  getWithdrawLimits,
  getPaymentEmail,
  confirmPaymentEmail,
  sendCodeToEmail,
};
