import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { debug, status } from 'patronum';

import { DateTime } from 'lib/datetime';
import { bonusApi } from 'api/bonus';
import { isPlayerBonus } from 'features/bonus/model';
import { useStoreMap } from 'effector-react';

export const addAvaiableBonus = createEvent<PlayerBonus>();
export const deleteBonus = createEvent<PlayerBonus>();
export const addWofSpins = createEvent<WofSpin[]>();

export const loadAvailableBonusesFx = createEffect(
  bonusApi.getAvailableBonuses,
);

export const loadWofSpinsFx = createEffect<void, WofSpin[], ApiError>(
  bonusApi.getWofSpins,
);

export const $availableBonuses = createStore<PlayerBonus[]>([])
  .on(addAvaiableBonus, (state, bonus) => [...state, bonus])
  .on(deleteBonus, (state, bonus) =>
    state.filter((item) => item.id !== bonus.id),
  );

export const $wofSpins = restore(loadWofSpinsFx.doneData, []).on(
  addWofSpins,
  (state, spins) => [...state, ...spins],
);

export const $spinsByMupltiplierRecord = $wofSpins.map((spins) =>
  spins.sort(sortByDate).reduce<Record<number, WofSpin[]>>((record, spin) => {
    const { multiplier } = spin;

    if (record[multiplier]) {
      record[multiplier].push(spin);
    } else {
      record[multiplier] = [spin];
    }

    return record;
  }, {}),
);

export const $allAvailableBonuses = combine(
  $availableBonuses,
  $spinsByMupltiplierRecord,
  (available, spinsRecord) =>
    [
      ...available,
      ...Object.values(spinsRecord).reduce(
        (list, spins) => [...list, spins[0]],
        [],
      ),
    ].sort(sortByDate),
);

sample({
  source: loadAvailableBonusesFx.doneData,
  target: $availableBonuses,
  fn: ({ data }) => data,
});

// сортировка бонусов по дате окончания
function sortByDate(
  a: PlayerBonus | WofSpin,
  b: PlayerBonus | WofSpin,
): number {
  const firstDate = isPlayerBonus(a) ? a.activate_before : a.expire_at;
  const secondDate = isPlayerBonus(b) ? b.activate_before : b.expire_at;

  return DateTime.getMS(firstDate) - DateTime.getMS(secondDate);
}

const useSpinsListByMultiplier = (multiplier) =>
  useStoreMap({
    store: $spinsByMupltiplierRecord,
    keys: [multiplier],
    fn: (record, [multiplier]) => record[multiplier] || [],
  });

export const selectors = {
  useSpinsListByMultiplier,
};
