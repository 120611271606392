import { createEvent, createStore, restore } from 'effector';

import { DeviceTypes } from 'lib/constants';

export const setDeviceType = createEvent<DeviceType>();
export const $deviceType = restore(setDeviceType, DeviceTypes.Desktop);
export const $isDesktop = $deviceType.map(
  (type) => type === DeviceTypes.Desktop,
);
export const $isMobile = $deviceType.map((type) => type === DeviceTypes.Mobile);

export const $isTriggered = createStore<boolean>(false).on(
  setDeviceType,
  () => true,
);
