import { httpClient } from 'lib/request';

export const getAvaiableLocalesList = (): Promise<AvaiableLocalesResponse> => {
  return httpClient.get('languages');
};

export const getAllResourceByScope = (
  locale: string,
  scopes: string,
): Promise<Record<string, {}>> => {
  return httpClient.get(`i18n/resources?locale=${locale}&scopes=${scopes}`);
};

export const getCurrentResource = (
  locale: string,
  scope: string,
  resource: string,
): Promise<string> => {
  return httpClient.get(`i18n/scopes/${scope}/${resource}?locale=${locale}`);
};

export const getResourceByScope = (
  locale: string,
  scope: string,
): Promise<Record<string, string>> => {
  return httpClient.get(`i18n/scopes/${scope}/resources?locale=${locale}`);
};

const getCurrentLanguage = (): Promise<{ language: string }> => {
  return httpClient.get('languages/current');
};

const changeLanguage = (language: string) =>
  httpClient.put('/languages/current', { language });

export const i18nApi = {
  getAllResourceByScope,
  getAvaiableLocalesList,
  getCurrentLanguage,
  getResourceByScope,
  changeLanguage,
  getCurrentResource,
};
