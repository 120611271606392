import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useStore } from 'effector-react';
import { reflect } from '@effector/reflect';
import { useField, useForm } from 'effector-forms';
import classNames from 'classnames';

import {
  IconSprite,
  Field,
  Button,
  SectionTitle,
  MainForm,
  MainFormRow,
} from 'ui';
import { mediaHooks } from 'lib/media';
import { Captcha } from 'features/captcha';
import { paths } from 'pages/paths';
import { trimPreventedEvent } from 'lib/events';

import * as model from '../model/form-model';
import './style.css';

export const LoginForm = () => {
  const { t } = useTranslation();
  const isLargeMobile = mediaHooks.useIsLargeMobile();

  const type = useStore(model.$formType);

  return (
    <div className="modal__content">
      {isLargeMobile && (
        <div className="modal__head">
          <h3 className="modal__info-title">{t('auth:login.title')}</h3>
          <button
            onClick={() => model.formClosed()}
            className="modal-head__close"
            type="button"
          >
            <IconSprite name="page-close" />
          </button>
        </div>
      )}
      <div className="modal__wrap">
        {!isLargeMobile ? (
          <SectionTitle>{t('auth:login.title')}</SectionTitle>
        ) : null}
        <SwitchLoginType />
        <MainForm
          noBorder
          onSubmit={model.$loginForm.submit.prepend(trimPreventedEvent)}
        >
          {/* IF NUMBER USE NUMBER FIELD ELSE EMAIL */}
          {type === 'PHONE' ? <PhoneRow /> : <EmailRow />}

          <PasswordRow />
          <div className="password">
            <Link
              to={paths.changePassword()}
              onClick={() => model.formClosed()}
              className="main-form__remind"
            >
              {t('auth:login.forgotPassword')}
            </Link>
            <Captcha captcha={model.addLoginCaptcha} />
            <SubmitButton type="submit">
              {t('common:buttons.signin')}
            </SubmitButton>
            <div className="main-form__soc-wrap">
              <span className="main-form__soc-title">
                {t('auth:login.loginSocial')}
              </span>
              <ul className="soc-wrap">
                <li>
                  <span onClick={() => model.loginSocialFx('google')}>
                    <IconSprite name="google-icon" />
                  </span>
                </li>
                <li>
                  <span onClick={() => model.loginSocialFx('vk')}>
                    <IconSprite name="vk-icon" />
                  </span>
                </li>
                {/* <li>
                  <span onClick={() => model.loginSocialFx('fb')}>
                    <IconSprite name="fb-icon" />
                  </span>
                </li> */}
                <li>
                  <span onClick={() => model.loginSocialFx('mailRu')}>
                    <IconSprite name="mailru-icon" />
                  </span>
                </li>
                <li>
                  <span onClick={() => model.loginSocialFx('ok')}>
                    <IconSprite name="odnoklasniki-icon" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </MainForm>
        <div className="modal__sign-in">
          <span
            className="modal__sign-link"
            onClick={() => model.registerFormOpened()}
          >
            {t('common:buttons.signup')}
          </span>
        </div>
      </div>
    </div>
  );
};

const SwitchLoginType = () => {
  const { t } = useTranslation();
  const { reset } = useForm(model.$loginForm);
  const type = useStore(model.$formType);

  const handleSwitch = () => {
    reset();
    model.switchFormType();
  };

  return (
    <ul className="modal__tabs-head">
      <li
        className={classNames('modal__tabs-item', type === 'PHONE' && 'active')}
        onClick={handleSwitch}
      >
        {t('auth:byPhone')}
      </li>
      <li
        className={classNames('modal__tabs-item', type === 'EMAIL' && 'active')}
        onClick={handleSwitch}
      >
        {t('auth:byEmail')}
      </li>
    </ul>
  );
};

const PhoneRow = () => {
  const { t } = useTranslation();

  const { value, onChange, errorText, onBlur } = useField(
    model.$loginForm.fields.login,
  );

  return (
    <MainFormRow label={t('auth:inputs.phone.label')}>
      <Field
        error={errorText()}
        inputComponent={
          <PhoneInput
            inputProps={{
              required: true,
              autoFocus: true,
            }}
            inputClass="field__input"
            value={value}
            autoFormat
            specialLabel=""
            onChange={(val) => onChange(val)}
            onBlur={() => onBlur()}
            placeholder={t('auth:inputs.phone.placeholder')}
          />
        }
      />
    </MainFormRow>
  );
};

const EmailRow = () => {
  const { t } = useTranslation();
  const { value, onChange, errorText, onBlur } = useField(
    model.$loginForm.fields.login,
  );

  return (
    <MainFormRow label={t('auth:inputs.email.label')}>
      <Field
        autoComplete="on"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => onBlur()}
        name="email"
        type="email"
        placeholder={t('auth:inputs.email.placeholder')}
        error={errorText()}
      />
    </MainFormRow>
  );
};

export const PasswordRow = () => {
  const { t } = useTranslation();
  const { value, onChange, errorText, onBlur } = useField(
    model.$loginForm.fields.password,
  );

  return (
    <MainFormRow label={t('auth:inputs.password.label')}>
      <Field
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => onBlur()}
        name="email"
        type="password"
        placeholder={t('auth:inputs.password.placeholder')}
        error={errorText()}
      />
    </MainFormRow>
  );
};

const SubmitButton = reflect({
  view: Button,
  bind: {
    disabled: model.$submitLoginButtonIsDisabled,
  },
});
