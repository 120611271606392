import { bonusApi } from 'api/bonus';
import { attach, createEffect, restore } from 'effector';
import { deviceModel } from 'features/device';

export const loadPlayerGiftspinsFx = attach({
  effect: createEffect<boolean, GiftSpin[], ApiError>((mobile) =>
    bonusApi.getAvaibleGiftspins(mobile),
  ),
  source: deviceModel.$isMobile,
  mapParams: (_, isMobile) => isMobile,
});

export const $playerGiftSpins = restore(loadPlayerGiftspinsFx.doneData, []);
