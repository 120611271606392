import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { lotteryApi } from 'api/lottery';
import { status, spread } from 'patronum';
import { paths } from 'pages/paths';
import { navigationModel } from 'features/navigation';

export const pageLoaded = createEvent<{ id: string }>();
export const pageUnloaded = createEvent();

export const loadLotteryFx = createEffect<string, LotteryItem, Error>({
  handler: (id) => lotteryApi.getLotteryById(id),
});

export const $status = status({
  effect: loadLotteryFx,
  defaultValue: 'pending',
});

export const $lotteryData = createStore<LotteryItem | null>(null).reset(
  pageUnloaded,
);

export const $error = restore(loadLotteryFx.fail, null);

spread({
  source: pageLoaded,
  targets: {
    id: loadLotteryFx,
  },
});

sample({
  source: loadLotteryFx.doneData,
  target: $lotteryData,
});

sample({
  source: $error,
  fn: () => paths.error(),
  target: navigationModel.historyPush,
});
