import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useStore } from 'effector-react';
import { useField, useForm } from 'effector-forms';
import classNames from 'classnames';
import { reflect } from '@effector/reflect';

import {
  Button,
  Field,
  IconSprite,
  MainForm,
  MainFormRow,
  SectionTitle,
  Select,
} from 'ui';
import { currenciesModel } from 'features/currencies';
import { mediaHooks } from 'lib/media';
import { Captcha } from 'features/captcha/';
import { paths } from 'pages/paths';
import { trimEvent, trimPreventedEvent } from 'lib/events';

import './style.css';
import * as model from '../model/form-model';

export const RegisterForm = () => {
  const { t } = useTranslation();

  const isRulesAgree = useStore(model.$isRulesAgree);
  const isLargeMobile = mediaHooks.useIsLargeMobile();
  const isPhoneType = useStore(model.$isPhoneType);

  return (
    <div className="modal__content">
      {!isLargeMobile ? (
        <div className="modal__img">
          <img
            src={isPhoneType ? '/img/presents.png' : '/img/presents-1.png'}
            alt=""
          />
        </div>
      ) : (
        <div className="modal__head">
          <h3 className="modal__info-title">{t('auth:register.title')}</h3>
          <button
            onClick={() => model.formClosed()}
            className="modal-head__close"
            type="button"
          >
            <IconSprite name="page-close" />
          </button>
        </div>
      )}

      <div className="modal__wrap">
        <div className="modal__wrap-inner">
          {!isLargeMobile ? (
            <SectionTitle>{t('auth:register.title')}</SectionTitle>
          ) : (
            <div className="modal__img-header">
              <img src="/img/bonus12.png" alt="" />
            </div>
          )}
          <SwitchResisterType />
          <MainForm
            onSubmit={model.$registerForm.submit.prepend(trimPreventedEvent)}
            noBorder
          >
            {/* IF NUMBER USE NUMBER FIELD ELSE EMAIL */}
            {isPhoneType ? (
              <>
                <PhoneRow />
                <p className="main-form__sub-info">
                  {t('auth:register.messageAboutSms')}
                </p>
              </>
            ) : (
              <>
                <EmailRow />
                <PasswordRow />
              </>
            )}
            <div className="main-form__check-rules">
              <input
                className="main-form__input-check"
                type="checkbox"
                id="rules"
                name="rules"
                checked={isRulesAgree}
                onChange={() => model.setRulesAgree(!isRulesAgree)}
              />
              <label className="main-form__label-rules" htmlFor="rules">
                {t('auth:register.iAgreeWith')}
                <Link
                  onClick={() => model.formClosed()}
                  to={paths.terms()}
                  className="main-form__rules-link"
                >
                  {t('auth:register.casinoRules')}
                </Link>
              </label>
            </div>
            <CurrencySelect />
            <Captcha captcha={model.addRegisterCaptcha} />
            <SubmitRegisterButton className="sign-up-btn" type="submit">
              {t('auth:register.submitButton')}
            </SubmitRegisterButton>
            <div className="main-form__soc-wrap">
              <span className="main-form__soc-title">
                {t('auth:login.loginSocial')}
              </span>
              <ul className="soc-wrap">
                <li>
                  <span onClick={() => model.loginSocialFx('google')}>
                    <IconSprite name="google-icon" />
                  </span>
                </li>
                <li>
                  <span onClick={() => model.loginSocialFx('vk')}>
                    <IconSprite name="vk-icon" />
                  </span>
                </li>
                {/* <li>
                  <span onClick={() => model.loginSocialFx('fb')}>
                    <IconSprite name="fb-icon" />
                  </span>
                </li> */}
                <li>
                  <span onClick={() => model.loginSocialFx('mailRu')}>
                    <IconSprite name="mailru-icon" />
                  </span>
                </li>
                <li>
                  <span>
                    <IconSprite
                      name="odnoklasniki-icon"
                      onClick={() => model.loginSocialFx('ok')}
                    />
                  </span>
                </li>
              </ul>
            </div>
          </MainForm>
          <div className="modal__sign-in">
            <span className="modal__sign-title">
              {t('auth:register.haveAccount')}
            </span>
            <span
              onClick={() => model.loginFormOpened()}
              className="modal__sign-link"
            >
              {t('auth:login.title')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SwitchResisterType = () => {
  const { t } = useTranslation();
  const { reset } = useForm(model.$registerForm);
  const type = useStore(model.$formType);

  const handleSwitch = () => {
    reset();
    model.switchFormType();
  };

  return (
    <ul className="modal__tabs-head">
      <li
        className={classNames('modal__tabs-item', type === 'PHONE' && 'active')}
        onClick={handleSwitch}
      >
        {t('auth:byPhone')}
      </li>
      <li
        className={classNames('modal__tabs-item', type === 'EMAIL' && 'active')}
        onClick={handleSwitch}
      >
        {t('auth:byEmail')}
      </li>
    </ul>
  );
};

const PhoneRow = () => {
  const { t } = useTranslation();

  const { value, onChange, errorText, onBlur } = useField(
    model.$registerForm.fields.phone,
  );

  return (
    <MainFormRow label={t('auth:inputs.phone.label')}>
      <Field
        error={errorText()}
        inputComponent={
          <PhoneInput
            inputProps={{
              required: true,
              autoFocus: true,
            }}
            inputClass="field__input"
            value={value}
            autoFormat
            specialLabel=""
            onChange={(val) => onChange(val)}
            onBlur={() => onBlur()}
            placeholder={t('auth:inputs.phone.placeholder')}
          />
        }
      />
    </MainFormRow>
  );
};

const EmailRow = () => {
  const { t } = useTranslation();

  const { value, onChange, errorText, onBlur } = useField(
    model.$registerForm.fields.email,
  );

  return (
    <MainFormRow label={t('auth:inputs.email.label')}>
      <Field
        value={value}
        onChange={onChange.prepend(trimEvent)}
        onBlur={() => onBlur()}
        name="email"
        type="email"
        placeholder={t('auth:inputs.email.placeholder')}
        error={errorText()}
      />
    </MainFormRow>
  );
};

const PasswordRow = () => {
  const { t } = useTranslation();

  const { value, onChange, errorText, onBlur } = useField(
    model.$registerForm.fields.password,
  );
  return (
    <MainFormRow label={t('auth:inputs.password.label')}>
      <Field
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => onBlur()}
        name="password"
        placeholder={t('auth:inputs.password.placeholder')}
        type="password"
        error={errorText()}
      />
    </MainFormRow>
  );
};

const CurrencySelect = () => {
  const { t } = useTranslation();

  const { value, onChange } = useField(model.$registerForm.fields.currency);

  const currencies = useStore(currenciesModel.$availableCurrencies);

  const currenciesOptions = React.useMemo(
    () => currencies.map((el) => [el, el]),
    [currencies],
  );

  if (currencies.length <= 1) {
    return null;
  }

  return (
    <MainFormRow
      label={t('auth:register.currency')}
      wrapClassName="main-form__select-wrap"
    >
      <Select
        className="currency-select"
        onChange={onChange}
        value={value}
        options={currenciesOptions}
      />
    </MainFormRow>
  );
};

const SubmitRegisterButton = reflect({
  view: Button,
  bind: {
    disabled: model.$registerButtonIsDisabled,
  },
});
