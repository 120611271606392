import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { WinnerTable, WinnerTableRow } from 'ui';
import { useFormaters } from 'features/i18';
import { DEFAULT_RENDER_WINNERS_SIZE } from 'lib/constants';

export const TournamentTable: React.FC<Props> = ({
  tournament,
  footer,
  showEmptyTable = true,
  winnersSize = DEFAULT_RENDER_WINNERS_SIZE,
  withLoader = true,
}) => {
  const { t } = useTranslation();
  const [renderSize, setRenderSize] = React.useState(winnersSize);
  const { currencyNumber } = useFormaters();

  const { prizes, participants = [] } = tournament;

  if (participants?.length === 0 && !showEmptyTable) {
    return null;
  }

  const totalCount =
    participants?.length > prizes.length ? participants.length : prizes.length;

  const handleScroll = () => {
    const different = totalCount - renderSize;
    setRenderSize(
      (size) => size + (different > winnersSize ? winnersSize : different),
    );
  };

  return (
    <WinnerTable
      className="tournament-table"
      title={t('tournament:table.title')}
      bodyId="tournamentTable"
      footer={footer}
    >
      <InfiniteScroll
        scrollableTarget="tournamentTable"
        next={handleScroll}
        hasMore={renderSize < totalCount && withLoader}
        loader={null}
        dataLength={renderSize}
      >
        <WinnerTableRow
          head
          values={[
            {
              value: '№',
              className: 'tour-table__number',
            },
            {
              value: t('tournament:table.gamer'),
              className: 'tour-table__gamer',
            },
            {
              value: t('tournament:table.prize'),
              className: 'tour-table__prize',
            },
          ]}
        />
        {Array.from({
          length: renderSize < totalCount ? renderSize : totalCount,
        }).map((_, idx) => {
          const currentParticipiant = participants[idx];
          return (
            <WinnerTableRow
              key={currentParticipiant?.player_id || idx}
              values={[
                {
                  value: idx + 1,
                  className: 'tour-table__number',
                },
                {
                  value: currentParticipiant?.nickname || '————————',
                  className: 'tour-table__gamer',
                },
                {
                  value: currencyNumber(
                    currentParticipiant?.prize || prizes[idx] || 0,
                  ),
                  className: 'tour-table__prize',
                },
              ]}
            />
          );
        })}
      </InfiniteScroll>
    </WinnerTable>
  );
};

interface Props {
  tournament: TournamentItem;
  footer?: React.ReactNode[] | React.ReactNode;
  showEmptyTable?: boolean;
  winnersSize?: number;
  withLoader?: boolean;
}
