import { httpClient } from 'lib/request';
import { BASE_URI } from 'lib/constants';

// Получить список активных лотерей
function getActiveLotteries(): Promise<LotteryResponseType> {
  return httpClient.get(`draws/active`);
}

// Получить список завершенных лотерей
function getCompletedLotteries(): Promise<LotteryResponseType> {
  return httpClient.get(`draws/completed`);
}

// получить главную лотерею
function getMainLottery(): Promise<LotteryItem> {
  return httpClient.get(`draws/main`);
}

function getImage(id: number) {
  return `${BASE_URI}draws/images/${id}`;
}

// Получить лотерею по ID
function getLotteryById(id: string): Promise<LotteryItem> {
  return httpClient.get(`draws/${id}`);
}
// получить лотереи с участием игрока
function getLotteryWithUser(): Promise<LotteryResponseType> {
  return httpClient.get('players/current/draws');
}

export const lotteryApi = {
  getLotteryWithUser,
  getActiveLotteries,
  getCompletedLotteries,
  getMainLottery,
  getLotteryById,
  getImage,
};
