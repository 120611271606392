import i18n from 'i18next';

import { validateEmail, validateIsFilled, validatePhone } from './rules';

export function isValidEmail(value: string, shortMessage = false): string {
  if (!validateIsFilled(value)) {
    return i18n.t(`common:validators.required${shortMessage ? 'Short' : ''}`);
  }

  if (!validateEmail(value)) {
    return i18n.t('common:validators.email');
  }

  return '';
}

export function isValidPhone(value: string, shortMessage = false): string {
  if (!validateIsFilled(value)) {
    return i18n.t(`common:validators.required${shortMessage ? 'Short' : ''}`);
  }

  if (!validatePhone(value)) {
    return i18n.t('common:validators.phone');
  }

  return '';
}

export function isValidCaptcha(value: string): string {
  if (!validateIsFilled(value)) {
    return i18n.t(`common:validators.required`);
  }

  return '';
}
