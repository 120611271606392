import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { createReflect, reflect } from '@effector/reflect';

import {
  Button,
  Container,
  Field,
  MainForm,
  MainFormRow,
  Section,
  SectionTitle,
  Toast,
} from 'ui';
import { trimEvent, trimPreventedEvent } from 'lib/events';
import { Captcha } from 'features/captcha';
import { languageModel } from 'features/i18';
import { passwordRecoveryModel as model } from 'features/password-recovery';

import './style.css';

export const ChangePasswordPage = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();
    return () => model.pageUnloaded();
  }, []);

  const email = useStore(languageModel.resourses.contactEmail);

  return (
    <>
      <Section className="change-password" type="info">
        <Container>
          <SectionTitle>{t('change-password:title')}</SectionTitle>
          <div className="password">
            <div className="password__wrap">
              <p className="password__info">{t('change-password:info')}</p>
              <ResetUserPasswordForm />
              <span className="password__text">
                {t('change-password:email')}
              </span>
              <a className="password__link" href={`mailto:${email}`}>
                {email}
              </a>
              {/*<span className="password__text">*/}
              {/*  {t('change-password:feedback')}*/}
              {/*</span>*/}
              {/*<div className="password__row">*/}
              {/*  <a className="password__link" href="tel:84978450375">*/}
              {/*    +84978450375,*/}
              {/*  </a>*/}
              {/*  <a className="password__link" href="tel:7580245880">*/}
              {/*    +7580245880*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export const ResetUserPasswordForm = () => {
  const { t } = useTranslation();
  const restoreType = useStore(model.$restoreType);

  const isEmailType = restoreType === model.RestoreTypes.EMAIL;
  const isPhoneType = restoreType === model.RestoreTypes.PHONE;

  const labelText =
    (isEmailType && (t('change-password:changeViaEmail') as string)) ||
    ((isPhoneType && t('change-password:changeViaPhone')) as string) ||
    '';

  return (
    <>
      <InfoToast />
      <SwitchRestoreType />
      <MainForm className="password-reset__fields-form" isForm={false}>
        <MainFormRow className="password-reset__email" label={labelText}>
          <div
            className="change-password__field"
            style={{
              display: isEmailType ? 'block' : 'none',
            }}
          >
            <form onSubmit={model.formSubmited.prepend(trimPreventedEvent)}>
              <RestoreFieldEmail
                type="email"
                name="email"
                placeholder={t('auth:inputs.email.placeholder')}
              />
              <Captcha captcha={model.resetPasswordEmailCaptcha} />
              <SubmitButton />
            </form>
          </div>

          <div
            className="change-password__field"
            style={{
              display:
                restoreType === model.RestoreTypes.PHONE ? 'block' : 'none',
            }}
          >
            <form onSubmit={model.formSubmited.prepend(trimPreventedEvent)}>
              <RestoreFieldPhone
                placeholder={t('auth:inputs.phone.placeholder')}
              />
              <Captcha captcha={model.resetPasswordPhoneCaptcha} />
              <SubmitButton />
            </form>
          </div>
        </MainFormRow>
      </MainForm>
    </>
  );
};
const InfoToast = () => {
  const { t } = useTranslation();

  const { isOpen, content } = useStore(model.$modal);

  return isOpen ? (
    <Toast
      isOpen
      type={content.toLowerCase().includes('error') ? 'error' : 'success'}
      onAnimationEnd={model.closeModal}
    >
      {t(`change-password:modal.${content}`)}
    </Toast>
  ) : null;
};

const SwitchRestoreType = () => {
  const { t } = useTranslation();
  const type = useStore(model.$restoreType);

  const toggleToPhone = () => {
    model.restoreTypeChanged(model.RestoreTypes.PHONE);
  };

  const toggleToEmail = () => {
    model.restoreTypeChanged(model.RestoreTypes.EMAIL);
  };

  return (
    <div className="restore-type__wrap change-password__page">
      <ul className="modal__tabs-head">
        <li
          className={classNames(
            'modal__tabs-item',
            type === model.RestoreTypes.PHONE && 'active',
          )}
          onClick={toggleToPhone}
        >
          {t('change-password:restoreType.email')}
        </li>
        <li
          className={classNames(
            'modal__tabs-item',
            type === model.RestoreTypes.EMAIL && 'active',
          )}
          onClick={toggleToEmail}
        >
          {t('change-password:restoreType.phone')}
        </li>
      </ul>
    </div>
  );
};

const SubmitButton = () => {
  const { t } = useTranslation();

  const rateLimitTimeLeft = useStore(model.phoneRequestRateLimitModel.timeLeft);

  return (
    <SubmitChange>
      {t('change-password:button')}{' '}
      {rateLimitTimeLeft ? `(${rateLimitTimeLeft})` : ''}
    </SubmitChange>
  );
};

const RestoreFieldEmail = reflect({
  view: Field,
  bind: {
    value: model.$valueEmail,
    onChange: model.valueEmailChanged.prepend(trimEvent),
    error: model.$emailError,
  },
});

const PhoneField = ({ error, ...props }) => {
  return (
    <Field
      error={error}
      inputComponent={
        <PhoneInput inputClass="field__input" specialLabel="" {...props} />
      }
    />
  );
};

const RestoreFieldPhone = reflect({
  view: PhoneField,
  bind: {
    value: model.$valuePhone,
    onChange: (value, country, e, formattedValue) =>
      model.valuePhoneChanged({ value, formattedValue }),
    error: model.$phoneError,
  },
});

const reflectButton = createReflect(Button);

const SubmitChange = reflectButton({
  disabled: model.$isFormDisabled,
  type: 'submit',
});
