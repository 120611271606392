import React from 'react';
import { IconSprite } from 'ui/atoms/svg';
import './style.css';
import classNames from 'classnames';

interface Props {
  children?: React.ReactNode;
  icon?: string | React.ReactNode;
  title?: string;
  className?: string;
}

export const BlockTitle = ({ children, icon, title, className }: Props) => {
  return (
    <div className={classNames('game-head', className)}>
      <div className="game-head__name-wrap">
        {typeof icon === 'string' ? (
          <IconSprite className="block-title__svg" name={icon} />
        ) : (
          <div className="block-title__svg">{icon}</div>
        )}
        <h2 className="game-head__name">{title}</h2>
      </div>
      {children}
    </div>
  );
};

export const LotteryTitle = ({ children }: Props) => {
  return <h2 className="lottery-title">{children}</h2>;
};

export const SectionTitle = ({ children }: Props) => {
  return <h1 className="section-title">{children}</h1>;
};
