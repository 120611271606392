import {
  attach,
  createEffect,
  createEvent,
  createStore,
  combine,
  guard,
  sample,
  Effect,
} from 'effector';

import { dayliWinnersApi } from 'api/winners';
import { languageModel } from 'features/i18';
import { condition, debug } from 'patronum';

export const mounted = createEvent<void>();
export const unmounted = createEvent<void>();

export const getDailyWinnersFx: Effect<
  void,
  DailyWinnersResponse,
  ApiError
> = attach({
  effect: createEffect<string, DailyWinnersResponse, ApiError>(
    dayliWinnersApi.getAll,
  ),
  source: languageModel.$language,
  mapParams: (_, language) => language,
});

export const $mounted = createStore<boolean>(false)
  .on(mounted, () => true)
  .reset(unmounted);

export const $dailyWinners = createStore<DailyWinner[]>([]);

/**
 * Вывод всего списка победителей с интервалом и его обновление
 */
// Количество строк в контейнере (5 отображается + 1 для анимации)
const BLOCK_SIZE = 6;
// Интервал между сдвигом строк в контейнере
const TICK = 6000;

const pointerInitiated = createEvent<number>();
const pointerChanged = createEvent<number>();
const startIndexChanged = createEvent<number>();
const renderedListChanged = createEvent<DailyWinner[]>();
const lastWinnerChanged = createEvent<number>();
const sliceWinners = createEvent<void>();

const tickFx = createEffect(
  () => new Promise((resolve) => setTimeout(resolve, TICK)),
);

const $startIndex = createStore(0).on(startIndexChanged, (_, index) => index);
const $pointer = createStore(0)
  .on(pointerInitiated, (_, counter) => counter)
  .on(pointerChanged, (_, counter) => counter);
const $allWinnersSize = $dailyWinners.map((winners) => winners.length);
export const $renderedWinners = createStore<DailyWinner[]>([]).on(
  renderedListChanged,
  (_, list) => list,
);
const $lastWinner = createStore<number>(0).on(
  lastWinnerChanged,
  (_, index) => index,
);

condition({
  source: mounted,
  if: $dailyWinners.map((list) => list.length === 0),
  then: getDailyWinnersFx,
  else: tickFx,
});

sample({
  source: getDailyWinnersFx.doneData,
  fn: ({ winners }) => winners,
  target: $dailyWinners,
});

// Устанавливаем начальное значение указателя
sample({
  clock: getDailyWinnersFx.doneData,
  source: $lastWinner,
  target: pointerInitiated,
  fn: (last, { winners }) =>
    last > 0 && last < winners.length ? last : winners.length,
});

// Впервые запускаем обрезку массива (если он больше размера контейнера)
guard({
  source: pointerInitiated,
  filter: $allWinnersSize.map((size) => size > BLOCK_SIZE),
  target: sliceWinners,
});

// Высчитываем начальный индекс сдвига
sample({
  clock: sliceWinners,
  source: $pointer,
  target: startIndexChanged,
  fn: (counter) => counter - BLOCK_SIZE,
});

// Обрезаем массив
sample({
  clock: startIndexChanged,
  source: combine({
    startIndex: $startIndex,
    counter: $pointer,
    winners: $dailyWinners,
  }),
  target: renderedListChanged,
  fn: ({ startIndex, counter, winners }) => winners.slice(startIndex, counter),
});

// Декрементим значение указателя
sample({
  clock: renderedListChanged,
  source: combine({
    startIndex: $startIndex,
    counter: $pointer,
    size: $allWinnersSize,
  }),
  target: pointerChanged,
  fn: ({ startIndex, counter, size }) => (startIndex > 0 ? counter - 1 : size),
});

// И запоминаем указатель
sample({
  clock: renderedListChanged,
  source: $pointer,
  target: lastWinnerChanged,
});

// Если указатель больше нуля, запускаем интервал
// иначе снова запрашиваем данные из API
condition({
  source: $renderedWinners,
  if: $startIndex.map((startIndex) => startIndex > 0),
  then: tickFx,
  // TODO: ts-ignore
  // @ts-ignore
  else: getDailyWinnersFx,
});

// После окончания интервала перезапускаем обрезку массива
guard({
  source: tickFx.done,
  filter: $mounted,
  target: sliceWinners,
});
