import { createEffect, restore } from 'effector';

import { bonusApi } from 'api/bonus';

const LIMIT = 5;

export const loadCompletedBonusesFx = createEffect(() =>
  bonusApi.getAllCompletedBonuses(LIMIT),
);

export const $completedBonuses = restore(loadCompletedBonusesFx.doneData, []);
