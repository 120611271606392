import { createEvent, guard, sample } from 'effector';

import { navigationModel } from 'features/navigation';
import { passwordRecoveryModel } from 'features/password-recovery';
import { paths } from 'pages/paths';

export const pageLoaded = createEvent<void>();
export const pageUnloaded = createEvent<void>();

guard({
  source: pageLoaded,
  filter: passwordRecoveryModel.$canChangePassword.map((can) => !can),
  target: navigationModel.historyReplace.prepend(() => paths.home()),
});

sample({
  source: pageUnloaded,
  target: passwordRecoveryModel.$forgotPasswordCode,
  fn: () => '',
});
