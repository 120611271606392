import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { useStore } from 'effector-react';

import { httpClient } from 'lib/request';

export enum SkinIds {
  HA = 'ha',
  GO = 'go',
}

const getSkinId = createEvent<void>();
const getSkinIdFx = createEffect<void, SkinIds>(() =>
  httpClient.get(`properties/skin-id`),
);

const $skinId = createStore('');
const $skinIdIsLoaded = createStore(false);

const setThemeIsLoaded = createEvent<boolean>();
const $themeIsLoaded = restore(setThemeIsLoaded, false);

forward({ from: getSkinId, to: getSkinIdFx });
forward({ from: getSkinIdFx.doneData, to: $skinId });
$skinIdIsLoaded.on(getSkinIdFx.finally, () => true);

const useSkinId = () => useStore($skinId);

const useIsHabanero = () => useSkinId() === SkinIds.HA;
const useIsGO = () => useSkinId() === SkinIds.GO;

export const selectors = { useSkinId, useIsGO, useIsHabanero };
export const stores = { $skinId, $skinIdIsLoaded, $themeIsLoaded };
export const events = { getSkinId, setThemeIsLoaded };
