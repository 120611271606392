import * as React from 'react';
import { skinModel } from 'features/skin-id';

type IconSpriteProps = React.SVGProps<SVGSVGElement> & {
  spriteName?: string;
  name: string;
  style?: string;
};

export const IconSprite: React.FC<IconSpriteProps> = React.memo(
  ({ spriteName = 'common', name, style, className, ...props }) => {
    const skinId = skinModel.selectors.useSkinId();

    return (
      <svg
        className={className}
        style={style}
        aria-hidden="true"
        focusable="false"
        role="img"
        {...props}
      >
        <use
          xlinkHref={`/img/icons/${skinId}.${spriteName}-sprite.svg#${name}`}
        />
      </svg>
    );
  },
);
