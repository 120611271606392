import { httpClient } from 'lib/request';

const checkEmail = (email: string): Promise<CheckEmailResponse> =>
  httpClient.get(`players/emails/single?email=${email}`);

const infoUser = (token: string): Promise<oauthUserData> =>
  httpClient.get(`/oauth/tokens/${token}`);

const infoUserInSocial = (token: string): Promise<UserDataInSocial> =>
  httpClient.get(`oauth/users/${token}`);

const register = (data: RegisterBody): Promise<RegisterResponse> => {
  return httpClient.post(`players`, data);
};
const login = (data: LoginData): Promise<Session> => {
  return httpClient.post('login', data);
};
const loginWithToken = (data: LoginData): Promise<Session> => {
  return httpClient.post('login/refresh-token', data);
};
const loginWithSocial = (data: SocialLoginData, type: string): Promise<any> => {
  return httpClient.post(`oauth/${type}`, data);
};
const oauthLogin = (data: oauthLoginData): Promise<Session> => {
  return httpClient.post(`oauth/login`, data);
};
const sendConfirmCodeToEmail = (email: string): Promise<boolean> => {
  return httpClient.post('oauth/code', { email });
};

export const authApi = {
  checkEmail,
  login,
  register,
  infoUser,
  loginWithToken,
  loginWithSocial,
  infoUserInSocial,
  oauthLogin,
  sendConfirmCodeToEmail,
};
