import { CARD_FIELD_NAMES } from './constants';

export function getDataFieldError(
  field: PaymentDataField,
  value: string,
): DataFieldError {
  const fieldErrors: DataFieldError = {};

  if (field.required && !value) {
    fieldErrors.required = true;
  }

  const needToTestRegexp = field.required ? true : Boolean(value);

  if (
    needToTestRegexp &&
    field.regexp &&
    !new RegExp(field.regexp).test(value)
  ) {
    fieldErrors.regexp = true;
  }

  if (field.name === CARD_FIELD_NAMES.CARD_EXP_YEAR) {
    const currentYear = parseInt(
      new Date().getFullYear().toString().substring(2),
    );
    const yearNum = parseInt(value);

    // С 22го года карты бессрочны
    if (yearNum < 22 || yearNum > currentYear + 10) {
      fieldErrors.invalidDate = true;
    }
  }

  return fieldErrors;
}

export const deleteCardFieldsFromFields = (fields: PaymentDataField[]) => {
  const cardFields = Object.values(CARD_FIELD_NAMES);
  return fields.filter((f) => !cardFields.includes(f.name as CARD_FIELD_NAMES));
};
