import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import classNames from 'classnames';

import { Accordion, Button, IconSprite, ResultText, Select } from 'ui';
import { useMedia } from 'lib/media';
import { DateTime } from 'lib/datetime';
import { languageModel, useFormaters } from 'features/i18';

import * as model from './model';
import './style.css';

enum Status {
  CHARGE_BACK = 'error',
  PROVIDER_ERROR = 'error',
  DECLINE = 'error',
  HOLD = 'process',
  PENDED = 'process',
  REQUESTED = 'process',
  SENT_TO_PS = 'process',
  DOCS = 'process',
  CONFIRM = 'success',
  COMPLETE = 'success',
}

interface TableProps {
  payment: HistoryItem;
  accordion: boolean;
}

export const HistoryPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded();

    return () => model.pageUnloaded();
  }, []);

  return (
    <>
      <HistoryTypeSelect />
      <HistoryTable />
    </>
  );
};

const HistoryTypeSelect = () => {
  const { t } = useTranslation();

  const currentType = useStore(model.$currentListType);

  const onChange = (type: HistoryType) => {
    model.historyTypeChanged(type);
  };

  return (
    <form className="main-form" action="#">
      <div className="main-form__filer-wrap">
        <div className="main-form__select-wrap main-form__select-wrap_ftransaction">
          <Select
            options={[
              ['ALL', t('lk:history.select.all')],
              ['WITHDRAW', t('lk:history.select.withdrawals')],
              ['DEPOSIT', t('lk:history.select.deposits')],
            ]}
            value={currentType}
            onChange={onChange}
          />
        </div>
      </div>
    </form>
  );
};

const HistoryTable = () => {
  const { t } = useTranslation();
  const accardionTable = useMedia(
    ['(max-width: 600px)', '(min-width: 768px) and (max-width: 900px)'],
    [true, true],
    false,
  );

  const historyList = useStore(model.$historyListByType);
  const isLoading = useStore(model.loadHistoryListFx.pending);

  if (isLoading) {
    return (
      <ResultText style={{ marginTop: '20px' }}>
        {t('common:state.isLoading')}
      </ResultText>
    );
  }

  if (historyList.length === 0) {
    return (
      <ResultText style={{ marginTop: '20px' }}>
        {t('lk:history.isEmpty')}
      </ResultText>
    );
  }

  return (
    <div className="table">
      {!accardionTable && (
        <div className="table__row table__head">
          <span className="table__name table__id">ID</span>
          <span className="table__name table__created">
            {t('lk:history.table.created')}
          </span>
          <span className="table__name table__amount">
            {t('lk:history.table.sum')}
          </span>
          <span className="table__name table__bank">
            {t('lk:history.table.method')}
          </span>
          <span className="table__name status">
            {t('lk:history.table.status')}
          </span>
        </div>
      )}
      {historyList.map((item) => (
        <TableRow key={item.id} accordion={accardionTable} payment={item} />
      ))}
    </div>
  );
};

const TableRow = ({ accordion, payment }: TableProps) => {
  const { t } = useTranslation();
  const { currencyNumber } = useFormaters();

  const {
    id,
    paymentSystem,
    registrationDate,
    state,
    amount,
    cancelable,
  } = payment;

  const { title } = languageModel.selectors.usePaymentDescription(
    paymentSystem,
  );

  const date = DateTime.toStringData(registrationDate);
  const status = Status[state];

  const cancelPayment = () => {
    model.cancelPayment(id);
  };

  if (accordion) {
    return (
      <Accordion
        contentClassName="table__row table__row_content"
        head={
          <div className="table__row">
            <span className="table__name table__amount">
              {currencyNumber(amount)}
            </span>
            <div className={classNames(status, 'status')}>
              <div className="status__icon">
                <IconSprite name={`status-${status}`} />
              </div>
              <span className="status__name">
                {t(`lk:history.state.${state}`)}
              </span>
            </div>
            {cancelable && (
              <Button onClick={cancelPayment}>
                {t('common:buttons.cancel')}
              </Button>
            )}
          </div>
        }
      >
        <div className="table__col">
          <span className="table__name table__bank">
            {t('lk:history.table.method')}
          </span>
          <span className="table__name table__bank">{title}</span>
        </div>
        <div className="table__col">
          <span className="table__name table__created">{date}</span>
          <span className="table__name table__id">ID {id}</span>
        </div>
      </Accordion>
    );
  }

  return (
    <div className="table__row">
      <span className="table__name table__id">{id}</span>
      <span className="table__name table__created">{date}</span>
      <span className="table__name table__amount">
        {currencyNumber(amount, { minimumFractionDigits: 0 })}
      </span>
      <span className="table__name table__bank">{title}</span>
      <div className={classNames('status', status)}>
        <div className="status__icon">
          <IconSprite name={`status-${status}`} />
        </div>
        <span className="status__name">{t(`lk:history.state.${state}`)}</span>
      </div>
      {cancelable && (
        <Button onClick={cancelPayment} theme="primary">
          {t('common:buttons.cancel')}
        </Button>
      )}
    </div>
  );
};
