import React, { useEffect, useState } from 'react';
import fscreen from 'fscreen';

import './style.css';

export const GameFrame = ({ src }: { src: string }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    document.addEventListener('keydown', setFullscreen);

    return () => document.removeEventListener('keydown', setFullscreen);
  }, []);

  return loading ? (
    <div className="frame__frame loading">Загрузка</div>
  ) : (
    <section className="frame__frame">
      <iframe src={src} key={src} id="game-iframe" title="game container" />
    </section>
  );
};

function setFullscreen(e: any): void {
  if (e.which === 122) {
    e.preventDefault();
    fscreen.requestFullscreen(
      document.querySelector('.frame__frame') as Element,
    );
  }
}
