import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector';
import { status } from 'patronum';

import { jackpotApi } from 'api/jackpot';
import { sessionModel } from 'features/session';

export const mounted = createEvent<void>();
export const unmounted = createEvent<void>();
export const loadMainJackpot = createEvent();

export const getMainJackpotFx = createEffect<void, Jackpot, ApiError>(
  jackpotApi.getMain,
);

export const $mainJackpotData = createStore<Jackpot | null>(null);
export const $status = status({
  effect: getMainJackpotFx,
  defaultValue: 'pending',
});
export const $mainJackpotIsNotLoaded = $status
  .map((status) => status !== 'done')
  .reset(sessionModel.$isAuthenticated);

export const $isMounted = createStore(false)
  .on(mounted, () => true)
  .reset(unmounted);

export const $error = restore(getMainJackpotFx.failData, null);

forward({
  from: mounted,
  to: loadMainJackpot,
});

guard({
  source: sessionModel.$isAuthenticated,
  filter: $isMounted,
  target: loadMainJackpot,
});

guard({
  source: loadMainJackpot,
  filter: $mainJackpotIsNotLoaded,
  target: getMainJackpotFx,
});

sample({
  source: getMainJackpotFx.doneData,
  target: $mainJackpotData,
});

// Update
const FREQUENCY_DEFAULT = 5000;

export const startPolling = createEvent<void>();
export const stopPolling = createEvent<void>();
const setFrequency = createEvent<number>();
const setJackpotSum = createEvent<number>();
const startTick = createEvent<void>();

const getJackpotByIdFx = createEffect<any, Jackpot>(jackpotApi.getById);

const $frequency = restore(setFrequency, FREQUENCY_DEFAULT);
const $jackpotId = $mainJackpotData.map((jackpot) =>
  jackpot ? jackpot.jackpot_id : null,
);
export const $jackpotSum = $mainJackpotData
  .map((jackpot) => jackpot?.current_sum || 0)
  .on(setJackpotSum, (_, sum) => sum);
const $canPolling = createStore(false)
  .on(startPolling, () => true)
  .on(stopPolling, () => false);

const tickFx = createEffect(
  (time) => new Promise((resolve) => setTimeout(resolve, time)),
);

forward({
  from: mounted,
  to: [startPolling, startTick],
});

sample({
  source: guard(getMainJackpotFx.doneData, { filter: (data) => !!data }),
  target: setFrequency,
  fn: ({ frequency }) => frequency! * 1000,
});

guard({
  source: tickFx.done,
  filter: $canPolling,
  // target: tickFx,
  target: startTick,
});

sample({
  clock: startTick,
  source: $frequency,
  target: tickFx,
});

// не работает...
// sample({
//   clock: tickFx.done,
//   source: guard($frequency, { filter: $canPolling }),
//   target: tickFx,
// })

sample({
  clock: guard(tickFx.done, { filter: $jackpotId.map((id) => !!id) }),
  source: $jackpotId,
  target: getJackpotByIdFx,
});

sample({
  source: getJackpotByIdFx.doneData,
  target: setFrequency,
  fn: ({ frequency }) => frequency! * 1000,
});

sample({
  source: getJackpotByIdFx.doneData,
  target: setJackpotSum,
  fn: ({ current_sum }) => current_sum!,
});

forward({
  from: unmounted,
  to: stopPolling,
});

// Winners
export const getJackpotWinners = createEvent<number>();

const loadJackpotWinnersFx = createEffect<number, JackpotWinner[], ApiError>(
  jackpotApi.getWinnersJackpotById,
);

export const $winners = createStore<JackpotWinner[]>([]);

forward({
  from: getJackpotWinners,
  to: loadJackpotWinnersFx,
});

sample({
  source: loadJackpotWinnersFx.doneData,
  target: $winners,
  fn: (data) => data.sort(sortWinnersByPrize),
});

sample({
  source: guard($mainJackpotData, {
    filter: $mainJackpotData.map((is) => Boolean(is)),
  }),
  target: getJackpotWinners,
  fn: (jackpot) => jackpot!.jackpot_id,
});

function sortWinnersByPrize(a: JackpotWinner, b: JackpotWinner): number {
  return b.sum - a.sum;
}
