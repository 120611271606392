/* Base */
export const BASE_URI = '/api/';

export enum Languages {
  RU = 'RU',
  EN = 'EN',
}

export enum RegTypes {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SOCIAL = 'SOCIAL',
}

export enum DeviceTypes {
  Mobile = 'MOBILE',
  Desktop = 'DESKTOP',
}

export enum GameListTypes {
  All = 'all',
  ByHall = 'byHall',
  Popular = 'popular',
  Search = 'search',
  Favorite = 'favorite',
}

export enum Halls {
  All = 'all',
  Favorite = 'favorite',
  New = 'NEW',
  Slots = 'SLOTS',
}

export enum GameModes {
  Demo = 'DEMO',
  Real = 'REAL',
}

export enum ContactorTypes {
  Phone = 'phone',
  Card = 'card',
  Email = 'email',
}

export enum ErrorsPaymentsNames {
  CantWithdraw = 'СantWithdraw',
  FullInfoProvided = 'FullInfoProvided',
  PhoneIsConfirmed = 'phoneIsConfirmed',
  EmailIsConfirmed = 'emailIsConfirmed',
  CardNotAllowed = 'cardIsNotAllowed',
  ContractorInvalid = 'contractor.invalid',
  PaymentNotExists = 'paymentSystem.notExists',
  Profile = 'profile',
  HasRequiredBet = 'HasRequiredBet',
}

export const DEFAULT_CURRENCY = 'RUB';

export const DEFAULT_RENDER_SIZE = 12;

export const DEFAULT_RENDER_WINNERS_SIZE = 20;

export const GAME_SCRIPT_URL = 'https://dodocorra.com/gm/lib/game_messenger.js';

export enum DocTypes {
  Passport = 'passport',
  Certificate = 'certificate',
}

export enum EnumPeriodicity {
  Reusable = 'Reusable',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Once = 'Once',
}

export enum EnumDays {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
}

export enum EnumEventType {
  DEPOSIT = 'DEPOSIT',
  BIRTHDAY = 'BIRTHDAY',
  REGISTRATION_COUPON = 'REGISTRATION_COUPON',
  EXCLUSIVE = 'EXCLUSIVE',
  CONFIRMED_REGISTRATION = 'CONFIRMED_REGISTRATION',
  WEEKLY_CASHBACK = 'WEEKLY_CASHBACK',
  COUPON = 'COUPON',
  TASK_ACCOMPLISHED = 'TASK_ACCOMPLISHED',
  WOF_PRIZE = 'WOF_PRIZE',
}

export enum EnumBonusState {
  Available = 'AVAILABLE',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Win = 'WIN',
  Killed = 'KILLED',
}

export enum EnumPayOff {
  Instant = 'INSTANT',
  Delayed = 'DELAYED',
}

export enum EnumBonusModalsContent {
  Activate = 'activate',
  Cancel = 'cancel',
  ActivateDone = 'activatedDone',
  CancelDone = 'canceledDone',
  WofSpinsCancelDone = 'WofSpinsCancelDone',
}

export enum DepositTypes {
  'DEPOSIT',
}
