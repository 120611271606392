import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { reflect, createReflect } from '@effector/reflect';

import { Button, Field, IconSprite, MainFormButton, MainFormRow } from 'ui';
import { trimEvent, trimPreventedEvent } from 'lib/events';
import { userModel } from 'features/user';

import * as model from './model';

export const Email = () => {
  const { t } = useTranslation();

  const canChangeEmail = useStore(model.$canChangeEmail);
  const userIsLoaded = useStore(userModel.$userIsLoaded);

  React.useEffect(() => {
    model.formMounted();
    return () => {
      model.formUnmounted();
    };
  }, []);

  if (!userIsLoaded) {
    return (
      <MainFormRow label={t('lk:profile.email.label')}>{null}</MainFormRow>
    );
  }

  return (
    <MainFormRow className="email-row" label={t('lk:profile.email.label')}>
      {canChangeEmail ? <EmailChangeAllowed /> : <EmailChangeDenied />}
    </MainFormRow>
  );
};

const EmailChangeAllowed = () => {
  const { t } = useTranslation();
  const email = useStore(userModel.$email);
  const isEmailConfirmed = useStore(userModel.$isEmailConfirmed);
  const isConfirmSended = useStore(model.$isConfirmSended);

  const ref = React.useRef<HTMLInputElement>(null);
  const didMount = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (didMount.current) {
      if (!isConfirmSended) {
        ref.current?.focus();
      }
    } else didMount.current = true;
  }, [isConfirmSended]);

  if (isEmailConfirmed) {
    return <EmailString email={email || ''} />;
  }

  return (
    <form className="contacts__email-form">
      <EmailField inputRef={ref} placeholder={t('lk:profile.email.label')} />
      <EmailConfirmation
        submitButton={<SubmitEmail>{t('common:buttons.confirm')}</SubmitEmail>}
      />
    </form>
  );
};

const EmailChangeDenied = () => {
  const { t } = useTranslation();
  const email = useStore(userModel.$email);
  const isEmailConfirmed = useStore(userModel.$isEmailConfirmed);

  if (isEmailConfirmed) {
    return <EmailString email={email || ''} />;
  }

  return (
    <div
      style={{ justifyContent: 'space-between' }}
      className="main-form__text-content-row"
    >
      <span>{email}</span>
      <EmailConfirmation
        submitButton={
          <ConfirmEmail>{t('common:buttons.confirm')}</ConfirmEmail>
        }
      />
    </div>
  );
};

const EmailConfirmation = ({ submitButton }: { submitButton: any }) => {
  const { t } = useTranslation();
  const isConfirmSended = useStore(model.$isConfirmSended);
  const canChangeEmail = useStore(model.$canChangeEmail);

  const refreshConfimation = (): void => {
    if (canChangeEmail) {
      model.resetForm();
    } else {
      model.requestEmailConfirmationFx({
        mirror_url: window.location.origin,
      });
      // model.emailConfirmed();
    }
  };

  return isConfirmSended ? (
    <div className="try-again__message">
      <span>{t('lk:profile.email.goToEmail')}</span>
      <MainFormButton onClick={refreshConfimation}>
        {t('common:buttons.sendAgain')}
      </MainFormButton>
    </div>
  ) : (
    submitButton
  );
};

const EmailString = ({ email }: { email: string }) => (
  <div className="main-form__text-content-row">
    <span>
      {email}
      <IconSprite name="success-icon" />
    </span>
  </div>
);

const reflectButton = createReflect(Button);

const EmailField = reflect({
  view: Field,
  bind: {
    value: model.$newEmail,
    onChange: model.emailChanged.prepend(trimEvent),
    error: model.$shownError,
    disabled: model.$isConfirmSended,
  },
});

const SubmitEmail = reflectButton({
  disabled: model.$isformDisabled,
  onClick: model.emailSubmited.prepend(trimPreventedEvent),
  type: 'submit',
});

const ConfirmEmail = reflectButton({
  onClick: model.emailConfirmed.prepend(trimPreventedEvent),
  type: 'button',
});
