import * as React from 'react';
import { Redirect } from 'react-router';

import { paths } from 'pages/paths';

import './style.css';

export const Error404Page = () => {
  return <Redirect to={paths.home()} />;
};
