import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormaters } from 'features/i18';
import classNames from 'classnames';

import * as model from '../model';

const Wrap = ({ children }) => {
  return <div className="player-bonus-card__info">{children}</div>;
};
const Item = ({ text, description, className = '' }) => (
  <div className={classNames('player-bonus-card__info-item', className)}>
    <span>{description}</span>
    <span>{text}</span>
  </div>
);

const MainInfo = React.memo(
  ({
    bonus,
    withDepositInfo,
    withCashbackInfo,
    withGiftspinsInfo = true,
  }: {
    bonus: PlayerBonus | BonusProgram;
    withDepositInfo?: boolean;
    withCashbackInfo?: boolean;
    withGiftspinsInfo?: boolean;
  }) => {
    const { t } = useTranslation();
    const { currencyNumber } = useFormaters();

    const bonusSize = model.isPlayerBonus(bonus)
      ? bonus.cost_amount
      : bonus.reward_sum ||
        (bonus.reward_percent && `${bonus.reward_percent}%`);

    const {
      max_bet,
      wager,
      max_withdraw_sum,
      min_dep_sum,
      max_dep_sum,
      reward_giftspins,
      min_spending,
    } = bonus as PlayerBonus & BonusProgram;

    return (
      <BonusDescription.Wrap>
        {!!bonusSize && (
          <BonusDescription.Item
            description={t('bonus:info.bonusSize')}
            text={
              typeof bonusSize === 'number'
                ? currencyNumber(bonusSize, { minimumFractionDigits: 0 })
                : bonusSize
            }
          />
        )}
        {withDepositInfo && (
          <>
            {!!min_dep_sum && (
              <BonusDescription.Item
                description={
                  withCashbackInfo
                    ? t('bonus:info.minCashback')
                    : t('bonus:info.minDeposit')
                }
                text={currencyNumber(min_dep_sum, {
                  minimumFractionDigits: 0,
                })}
              />
            )}
            {!!max_dep_sum && (
              <BonusDescription.Item
                description={t('bonus:info.maxDeposit')}
                text={currencyNumber(max_dep_sum, {
                  minimumFractionDigits: 0,
                })}
              />
            )}
          </>
        )}
        {!!wager && (
          <BonusDescription.Item
            description={t('bonus:info.wager')}
            text={`x${wager}`}
          />
        )}
        {!!max_withdraw_sum && (
          <BonusDescription.Item
            description={t('bonus:info.maxWithdraw')}
            text={currencyNumber(max_withdraw_sum, {
              minimumFractionDigits: 0,
            })}
          />
        )}
        {!!max_bet && (
          <BonusDescription.Item
            text={currencyNumber(max_bet, { minimumFractionDigits: 0 })}
            description={t('bonus:info.maxBet')}
          />
        )}
        {!!min_spending && (
          <BonusDescription.Item
            description={t('bonus:info.minSpending')}
            text={currencyNumber(min_spending, { minimumFractionDigits: 0 })}
          />
        )}
        {withGiftspinsInfo && (
          <>
            {reward_giftspins?.game_name && (
              <BonusDescription.Item
                description={t('bonus:info.gs_game_name')}
                text={reward_giftspins?.game_name}
              />
            )}
            {reward_giftspins?.bet_line && (
              <BonusDescription.Item
                description={t('bonus:info.gs_bet_line')}
                text={currencyNumber(reward_giftspins?.bet_line, {
                  minimumFractionDigits: 0,
                })}
              />
            )}
            {reward_giftspins?.lines && (
              <BonusDescription.Item
                description={t('bonus:info.gs_lines')}
                text={reward_giftspins?.lines}
              />
            )}
          </>
        )}
      </BonusDescription.Wrap>
    );
  },
);

const GiftSpinInfo = ({ giftspins }: { giftspins: GiftSpin }) => {
  const { t } = useTranslation();
  const { game_name, bet_line, lines } = giftspins;
  const { currencyNumber } = useFormaters();

  return (
    <BonusDescription.Wrap>
      {game_name && (
        <BonusDescription.Item
          description={t('bonus:info.gs_game_name')}
          text={game_name}
        />
      )}
      {bet_line && (
        <BonusDescription.Item
          description={t('bonus:info.gs_bet_line')}
          text={currencyNumber(bet_line, { minimumFractionDigits: 0 })}
        />
      )}
      {lines && (
        <BonusDescription.Item
          description={t('bonus:info.gs_lines')}
          text={lines}
        />
      )}
    </BonusDescription.Wrap>
  );
};

export const BonusDescription = {
  Wrap,
  Item,
  MainInfo,
  GiftSpinInfo,
};
