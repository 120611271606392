import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';
import { useStore } from 'effector-react';
import {
  Button,
  Field,
  IconSprite,
  MainForm,
  MainFormRow,
  RadioButton,
  Select,
  Toast,
} from 'ui';
import { locationModels } from 'features/locations';
import { DateTime } from 'lib/datetime';
import { $language } from 'features/i18/model';
import { trimPreventedEvent } from 'lib/events';

import * as model from '../model';

export const UserInfoForm = () => {
  const { t } = useTranslation();
  const { fields, submit, errorText, eachValid, touched } = useForm(
    model.$userInfoForm,
  );
  const pending = useStore(model.updateProfileFx.pending);
  const countries = useStore(locationModels.$countries);
  const regions = useStore(locationModels.$regions);
  const cities = useStore(locationModels.$cities);

  const successModalIsOpen = useStore(model.$successModalIsOpen);
  const errorModalIsOpen = useStore(
    model.$updateProfileErrorModal.$modalIsOpen,
  );

  const formatLocations = (data: (City | Region | Country)[]) =>
    data.map(({ id, name }) => [String(name), name]);

  return (
    <>
      <Toast
        isOpen={errorModalIsOpen}
        type="error"
        onAnimationEnd={model.$updateProfileErrorModal.closeModal}
      >
        {t('common:popup.defaultErrorText')}
      </Toast>
      <Toast isOpen={successModalIsOpen} onAnimationEnd={model.closeModal}>
        {t('lk:profile.modal.success')}
      </Toast>
      <MainForm
        className="user-info__form"
        onSubmit={submit.prepend(trimPreventedEvent)}
      >
        <MainFormRow label={t('lk:profile.name.label')}>
          <Field
            error={errorText('name')}
            name="name"
            onBlur={() => fields.name.onBlur()}
            onChange={(e) => fields.name.onChange(e.target.value)}
            value={fields.name.value}
            placeholder={t('lk:profile.name.placeholder')}
          />
        </MainFormRow>
        <MainFormRow label={t('lk:profile.surname.label')}>
          <Field
            error={errorText('surname')}
            name="surname"
            onBlur={() => fields.surname.onBlur()}
            onChange={(e) => fields.surname.onChange(e.target.value)}
            value={fields.surname.value}
            placeholder={t('lk:profile.surname.placeholder')}
          />
        </MainFormRow>
        <DatePickRow />
        <MainFormRow
          wrapClassName="main-form__checkbox-wrap"
          label={t('lk:profile.gender.label')}
        >
          <Field
            error={errorText('gender')}
            wrapperClassName="autowidth"
            fieldTextClassName="error-text"
            noWarnIcon
            inputComponent={
              <RadioButton
                className="gender-first-radio"
                name="gender"
                id="MALE"
                checked={fields.gender.value === 'MALE'}
                value={fields.gender.value}
                placeholder={t('lk:profile.gender.male')}
                onChange={() => {
                  fields.gender.onChange('MALE');
                }}
              />
            }
          ></Field>
          <Field
            wrapperClassName="autowidth"
            fieldTextClassName="error-text"
            noWarnIcon
            inputComponent={
              <RadioButton
                name="gender"
                id="FEMALE"
                checked={fields.gender.value === 'FEMALE'}
                value={fields.gender.value}
                placeholder={t('lk:profile.gender.female')}
                onChange={() => {
                  fields.gender.onChange('FEMALE');
                }}
              />
            }
          ></Field>
        </MainFormRow>
        <MainFormRow
          label={t('lk:profile.country.label')}
          wrapClassName="main-form__select-wrap"
        >
          <Field
            error={errorText('country')}
            wrapperClassName="autowidth"
            noWarnIcon
            inputComponent={
              <Select
                placeholder={t('lk:profile.country.placeholder')}
                value={fields.country.value}
                onChange={(value) => {
                  fields.country.onChange(value);
                }}
                options={formatLocations(countries)}
              />
            }
          ></Field>
        </MainFormRow>

        <MainFormRow
          label={t('lk:profile.region.label')}
          wrapClassName="main-form__select-wrap"
        >
          <Field
            error={errorText('region')}
            wrapperClassName="autowidth"
            noWarnIcon
            inputComponent={
              <Select
                placeholder={t('lk:profile.region.placeholder')}
                value={fields.region.value}
                onChange={(value) => {
                  fields.region.onChange(value);
                }}
                options={formatLocations(regions)}
              />
            }
          ></Field>
        </MainFormRow>
        <MainFormRow
          label={t('lk:profile.city.label')}
          wrapClassName="main-form__select-wrap"
        >
          <Field
            error={errorText('locality')}
            wrapperClassName="autowidth"
            noWarnIcon
            inputComponent={
              <Select
                value={fields.locality.value}
                onChange={(value) => {
                  fields.locality.onChange(value);
                }}
                options={formatLocations(cities)}
                placeholder={t('lk:profile.city.placeholder')}
              />
            }
          ></Field>
        </MainFormRow>
        <MainFormRow label={t('lk:profile.street.label')}>
          <Field
            error={errorText('street')}
            name="street"
            onBlur={() => fields.street.onBlur()}
            onChange={(e) => fields.street.onChange(e.target.value)}
            value={fields.street.value}
            placeholder={t('lk:profile.street.placeholder')}
          />
        </MainFormRow>
        <MainFormRow label={t('lk:profile.house.label')}>
          <Field
            error={errorText('house')}
            name="house"
            onBlur={() => fields.house.onBlur()}
            onChange={(e) => fields.house.onChange(e.target.value)}
            value={fields.house.value}
            placeholder={t('lk:profile.house.placeholder')}
          />
        </MainFormRow>
        <MainFormRow label={t('lk:profile.apartment.label')}>
          <Field
            error={errorText('apartment')}
            name="apartment"
            onBlur={() => fields.apartment.onBlur()}
            onChange={(e) => fields.apartment.onChange(e.target.value)}
            value={fields.apartment.value}
            placeholder={t('lk:profile.apartment.placeholder')}
          />
        </MainFormRow>
        <Button type="submit" disabled={pending || !eachValid || !touched}>
          {t('common:buttons.save')}
        </Button>
      </MainForm>
    </>
  );
};

const DatePickRow = () => {
  const { t } = useTranslation();
  const wasBirthdayFilled = useStore(model.$wasBirthdayFilled);
  const language = useStore($language);

  const { fields, errorText } = useForm(model.$userInfoForm);

  const minUserAge = 18;
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const minYear = new Date().getFullYear() - minUserAge;
  const yearsArray = DateTime.getYearsArray(
    new Date().getFullYear() - minUserAge,
    100,
  );
  const [mountsArray, setMounthArray] = useState(
    DateTime.getMonthArray(language),
  );

  const [daysArr, setDaysArr] = useState(() => {
    const m = +fields.month.value;
    const y = +fields.year.value;
    let days = 31;
    if (m && y) {
      days = DateTime.daysInMonth(m, y);
    }
    return DateTime.getDaysArray(days);
  });

  const setDays = (m: number, y: number) => {
    let days = 31;
    if (m && y) {
      days = DateTime.daysInMonth(m, y);
    }
    setDaysArr(DateTime.getDaysArray(days));
  };

  const birthDayChange = (value: string, name: string): void => {
    const bithdate = {
      day: fields.day.value,
      year: fields.year.value,
      month: fields.month.value,
    };
    const calculateDay = (year, month) => {
      const daysTotal = DateTime.daysInMonth(month, year);
      return String(+bithdate.day > daysTotal ? 1 : bithdate.day);
    };
    if (name === 'year') {
      if (!!bithdate.day && !!bithdate.month) {
        let day = calculateDay(+value, +bithdate.month);
        if (day !== bithdate.day) {
          fields.day.onChange(day);
          fields.year.onChange(value);
        } else {
          fields.year.onChange(value);
        }
      } else {
        fields.year.onChange(value);
      }

      if (!!bithdate.month) {
        setDays(+bithdate.month, +value);
      }

      // if selected min age, render day and month not more then current
      if (+value === minYear) {
        let allMounts = DateTime.getMonthArray(language);
        allMounts.length = currentMonth + 1;
        setMounthArray(allMounts);

        if (!!bithdate.month && +bithdate.month > currentMonth + 1) {
          // reset value if month more then current
          fields.month.onChange('');
        } else if (!!bithdate.month && +bithdate.month === currentMonth + 1) {
          // render days till current
          setDaysArr(DateTime.getDaysArray(currentDay));
          if (+bithdate.day > currentDay) {
            fields.day.onChange('');
          } else {
            fields.day.onChange(bithdate.day);
          }
        }
      } else if (mountsArray.length < 12) {
        setMounthArray(DateTime.getMonthArray(language));
      }
    }
    if (name === 'month') {
      if (!!fields.day.value && !!fields.year.value) {
        let day = calculateDay(+fields.year.value, +value);
        if (day !== fields.day.value) {
          fields.month.onChange(value);
          fields.day.onChange(day);
        } else {
          fields.month.onChange(value);
        }
      } else {
        fields.month.onChange(value);
      }

      if (!!bithdate.year) {
        if (+value === currentMonth + 1 && +bithdate.year === minYear) {
          // render days till current
          setDaysArr(DateTime.getDaysArray(currentDay));
        } else {
          setDays(+value, +bithdate.year);
        }
      }
    }
    if (name === 'day') {
      fields.day.onChange(value);
    }
  };

  const error = errorText('year') || errorText('month') || errorText('day');

  return (
    <MainFormRow
      wrapClassName="main-form__select-row"
      label={t('lk:profile.birthday.label')}
    >
      <Field
        fieldTextClassName="error-text"
        error={!!errorText('day')}
        wrapperClassName="autowidth"
        noWarnIcon
        inputComponent={
          <Select
            onChange={(value) => birthDayChange(value, 'day')}
            value={fields.day.value}
            error={!!errorText('day')}
            disabled={wasBirthdayFilled}
            placeholder={t('lk:profile.birthday.day')}
            options={
              wasBirthdayFilled && !!fields.day.value
                ? daysArr.filter((el) => el[0] === fields.day.value)
                : daysArr
            }
          />
        }
      ></Field>
      <Field
        error={!!errorText('month')}
        wrapperClassName="autowidth"
        fieldTextClassName="error-text"
        noWarnIcon
        inputComponent={
          <Select
            onChange={(value) => birthDayChange(value, 'month')}
            value={fields.month.value}
            error={!!errorText('month')}
            placeholder={t('lk:profile.birthday.month')}
            disabled={wasBirthdayFilled}
            options={
              wasBirthdayFilled && !!fields.month.value
                ? mountsArray.filter((el) => el[0] === fields.month.value)
                : mountsArray
            }
          />
        }
      ></Field>
      <Field
        error={!!errorText('year')}
        style={{ minWidth: '94px' }}
        wrapperClassName="autowidth"
        fieldTextClassName="error-text"
        noWarnIcon
        inputComponent={
          <Select
            onChange={(value) => birthDayChange(value, 'year')}
            value={fields.year.value}
            placeholder={t('lk:profile.birthday.year')}
            error={!!errorText('year')}
            disabled={wasBirthdayFilled}
            options={
              wasBirthdayFilled && !!fields.year.value
                ? yearsArray.filter((el) => String(el[0]) === fields.year.value)
                : yearsArray
            }
          />
        }
      ></Field>
      {error && (
        <span className="birthday-error">
          <IconSprite name="error-field" width="16" height="16" />
          {error}
        </span>
      )}
    </MainFormRow>
  );
};
