import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { BlockTitle, Container, Section } from 'ui';
import { BonusList, bonusModel } from 'features/bonus';
import { PaginationBlock } from 'features/pagination';
import { useMedia } from 'lib/media';

import * as model from './model';

export const PresentsPage = () => {
  const { t } = useTranslation();
  const bonusIsLoading = useStore(bonusModel.$bonusesIsLoading);

  const bonusLimit = useMedia(
    ['(max-width: 1031px)', '(max-width: 1279px)'],
    [6, 8],
    10,
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    model.pageLoaded({ bonusLimit });
    bonusModel.bonusesMounted();

    return () => model.pageUnloaded();
  }, [bonusLimit]);

  // TODO
  const bonusList = useStore(model.$renderedList) as (
    | PlayerBonus
    | BonusProgram
  )[];

  return (
    <>
      <Section type="info">
        <Container>
          <BlockTitle icon="donkey-icon" title={t('common:menu.presents')} />
          <BonusList
            list={bonusList}
            isLoading={bonusIsLoading}
            skeletonSize={bonusLimit / 2}
          />
          <PaginationBlock pagination={model.pagination} />
        </Container>
      </Section>
    </>
  );
};
