import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  Effect,
  guard,
  merge,
  restore,
  sample,
} from 'effector';
import { condition, status } from 'patronum';

import { hallApi } from 'api/hall';
import { DeviceTypes, GameModes } from 'lib/constants';
import { gameApi } from 'api/game';
import { deviceModel } from 'features/device';
import { navigationModel } from 'features/navigation';
import { userModel } from 'features/user';
import { sessionModel } from 'features/session';
import { paths } from 'pages/paths';
import { authFormModel } from 'features/auth';

export const hallChosen = createEvent<string>();
export const loadPopularGames = createEvent<void>();
export const loadAllGames = createEvent<void>();
export const loadGamesByHall = createEvent<string>();
export const mobileGameRequested = createEvent<{
  code: string;
  provider: string;
  mode: GameMode;
}>();
export const gameClicked = createEvent<GameParams>();
export const openGame = createEvent<GameParams>();
const openDesktopGame = createEvent<GameParams>();
const openMobileGame = createEvent<GameParams>();

export const loadAllGamesFx = createEffect<DeviceType, Game[], Error>(
  hallApi.getAllGames,
);
export const loadGamesByHallFx = createEffect<string, HallInfo, Error>(
  hallApi.getHallInfo,
);
export const loadPopularGamesFx = createEffect<boolean, Game[], Error>(
  hallApi.getPopularList,
);

export const loadAllHallsNameFx: Effect<void, HallName[], Error> = attach({
  effect: createEffect<DeviceType, HallName[], Error>(async (type) =>
    hallApi.getAllHallsNames(type),
  ),
  source: deviceModel.$deviceType,
  mapParams: (_, deviceType) => deviceType,
});

export const loadMobileGameUrlFx = createEffect<GameRequest, string>(
  gameApi.getUrl,
);

export const $allGames = restore(loadAllGamesFx.doneData, [])
  .reset(deviceModel.setDeviceType)
  .reset(sessionModel.$isAuthenticated);

export const $allGamesStatus = status({
  effect: loadAllGamesFx,
  defaultValue: 'pending',
});

export const $popularGames = restore(loadPopularGamesFx.doneData, [])
  .reset(deviceModel.setDeviceType)
  .reset(sessionModel.$isAuthenticated);

export const $popularGamesStatus = status({
  effect: loadPopularGamesFx,
  defaultValue: 'pending',
});

export const $gamesByHall = createStore<GamesByHallStore | {}>({})
  .on(loadGamesByHallFx.doneData, (oldState, data) => ({
    ...oldState,
    [data.name]: data.games,
  }))
  .reset(deviceModel.setDeviceType)
  .reset(sessionModel.$isAuthenticated);

export const $mobGamesByHall = createStore<GamesByHallStore | {}>({})
  .on(loadGamesByHallFx.doneData, (oldState, data) => {
    if (data.types.includes('MOBILE')) {
      return {
        ...oldState,
        [data.name]: getOnlyMobGames(data.games),
      };
    }
    return oldState;
  })
  .reset(deviceModel.setDeviceType);

export const $gamesByHallStatus = status({
  effect: loadGamesByHallFx,
  defaultValue: 'pending',
});

export const $hallNamesStatus = status({
  effect: loadAllHallsNameFx,
  defaultValue: 'initial',
});

export const $allHallsNames = restore(loadAllHallsNameFx.doneData, []);

export const $hallsNamesByRank = $allHallsNames.map(
  (names) =>
    names.reduce((acc, { rank, name }) => {
      acc[rank] = acc[rank] ? [...acc[rank], name] : [name];
      return acc;
    }, {}) as HallNamesByRank,
);

export const $allHallsExceptZeroRank = $allHallsNames.map((halls) =>
  halls.filter((hall) => hall.rank !== 0).map((hall) => hall.name),
);

guard({
  source: sample({
    source: deviceModel.$deviceType,
    clock: merge([loadPopularGames, sessionModel.$isAuthenticated]),
    fn: (type) => type === DeviceTypes.Mobile,
  }),
  filter: combine(
    $popularGames,
    loadPopularGamesFx.pending,
    (popularGames, isPending) => popularGames.length === 0 && !isPending,
  ),
  target: loadPopularGamesFx,
});

guard({
  source: sample({
    source: deviceModel.$deviceType,
    clock: merge([loadAllGames, sessionModel.$isAuthenticated]),
  }),
  filter: combine(
    $allGames,
    loadPopularGamesFx.pending,
    (allGames, isPending) => allGames.length === 0 && !isPending,
  ),
  target: loadAllGamesFx,
});

guard({
  source: sample($gamesByHall, loadGamesByHall, (halls, hallName) => ({
    halls,
    hallName,
  })),
  filter: ({ halls, hallName }) => !halls[hallName],
  target: loadGamesByHallFx.prepend(({ hallName }: any) => hallName),
});

sample({
  source: mobileGameRequested,
  target: loadMobileGameUrlFx,
  fn: ({ mode, provider, code }) => ({
    code,
    mode,
    provider_code: provider,
    fp: null,
    mirror_url: null,
    mobile: true,
    xreferer: null,
    exit_url: window.location.href,
    useragent: null,
  }),
});

loadMobileGameUrlFx.doneData.watch((url) => {
  // Редирект к провайдеру
  // eslint-disable-next-line no-restricted-globals
  window.location.href = url;
});

const getOnlyMobGames = (games: Game[]) => games.filter((game) => game.mobile);

// Обработка клика по игре
condition({
  source: gameClicked,
  if: sessionModel.$isAuthenticated,
  then: openGame,
  else: authFormModel.loginFormOpened.prepend(() => {}),
});

condition({
  source: openGame,
  if: deviceModel.$isDesktop,
  then: openDesktopGame,
  else: openMobileGame,
});

sample({
  source: userModel.$isBalancePositive,
  clock: openDesktopGame,
  target: navigationModel.historyReplace,
  fn: (isReal, { provider, code }) => {
    const mode = isReal ? GameModes.Real : GameModes.Demo;
    return paths.game(mode, provider, code);
  },
});

sample({
  source: userModel.$isBalancePositive,
  clock: openMobileGame,
  target: mobileGameRequested,
  fn: (isReal, { provider, code }) => {
    const mode = isReal ? GameModes.Real : GameModes.Demo;
    return { mode, provider, code };
  },
});
