import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui';

import * as model from '../model';
import {
  BonusCardWrap,
  BonusDescription,
  BonusSubtitle,
  FreespinImg,
  GiftSpinsBonusTitle,
} from '../molecules';

interface Props {
  className?: string;
  giftspin: GiftSpin;
  idx: number;
}

export const GiftspinCard: React.FC<Props> = ({ className, giftspin, idx }) => {
  const { t } = useTranslation();

  const { type } = giftspin;

  return (
    <BonusCardWrap
      className={classNames(className, type === 'TASK' && 'task-gs')}
      idx={idx}
    >
      <div className="bonus-card__info">
        <GiftSpinsBonusTitle reward_giftspins={giftspin} />
        <BonusSubtitle>
          {t('bonus:subtitle.freeSpins', { count: giftspin.spins })}
        </BonusSubtitle>
        <FreespinImg />
        <BonusDescription.GiftSpinInfo giftspins={giftspin} />
      </div>
      <div className="bonus-card__nav">
        <Button onClick={() => model.giftSpinClicked(giftspin)}>
          {t('bonus:buttons.spinFS')}
        </Button>
      </div>
    </BonusCardWrap>
  );
};
